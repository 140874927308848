import { FormValues } from '.'

import { FormFieldProps } from 'components/formik/FormField'

type FormFieldDataObj = {
  [Property in keyof Omit<FormValues, 'comments'>]: FormFieldProps
}

export const formFieldData: FormFieldDataObj = {
  firstName: {
    autoComplete: 'name',
    label: 'First name',
    name: 'firstName',
    required: true
  },
  middleName: {
    autoComplete: 'name',
    label: 'Middle names',
    name: 'middleName',
    required: true
  },
  lastName: {
    autoComplete: 'name',
    label: 'Last name',
    name: 'lastName',
    required: true
  },
  preferredName: {
    label: 'Preferred name',
    name: 'preferredName'
  },
  dateOfBirth: {
    label: 'Date of birth',
    name: 'dateOfBirth',
    required: true
  },
  addressLine1: {
    label: 'Address line 1',
    name: 'addressLine1',
    required: true
  },
  addressLine2: {
    label: 'Address line 2',
    name: 'addressLine2'
  },
  suburb: {
    label: 'Suburb',
    name: 'suburb'
  },
  city: {
    label: 'City',
    name: 'city',
    required: true
  },
  postcode: {
    label: 'Postcode',
    name: 'postcode',
    required: true
  },
  nzMobilePhoneNumber: {
    label: 'NZ mobile phone number',
    name: 'nzMobilePhoneNumber',
    placeholder: 'e.g. 0211234567',
    required: true
  },
  driversLicenceNumber: {
    label: 'NZ Drivers licence number',
    name: 'driversLicenceNumber',
    required: true
  },
  driversLicenceVersion: {
    label: 'NZ Drivers licence version',
    name: 'driversLicenceVersion',
    required: true
  },
  passportNumber: {
    label: 'Passport number',
    name: 'passportNumber',
    required: true
  },
  passportExpiry: {
    label: 'Passport expiry',
    name: 'passportExpiry',
    required: true
  },
  ethnicity: {
    label: 'What ethnicities best describe you?',
    name: 'ethnicity',
    required: true
  }
}
