import { BugsnagErrorBoundary } from '@bugsnag/plugin-react'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { QueryParamProvider } from 'use-query-params'

import axios from 'utils/axios'
import history from 'utils/history'
import { getCookie } from 'utils/cookies'

import ErrorFallback from 'components/ErrorFallback'
import Routes from 'components/Routes'
import { Router, Route } from 'react-router-dom'
import ScrollToTop from 'components/layout/ScrollToTop'

export interface AppRootProps {
  ErrorBoundary?: BugsnagErrorBoundary
}

export default function AppRoot({ ErrorBoundary }: AppRootProps): JSX.Element {
  axios.interceptors.request.use(
    (request: AxiosRequestConfig) => {
      if (
        request.method !== 'GET' &&
        request.url !== `${process.env.REACT_APP_GOLDIE_REST_ENDPOINT}/login`
      ) {
        request.headers['X-CSRF-Token'] = getCookie('CSRF-TOKEN')
      }

      return request
    },
    (error: AxiosError) => {
      return Promise.reject(error)
    }
  )

  return (
    <Router history={history}>
      <ScrollToTop>
        <QueryParamProvider ReactRouterRoute={Route}>
          {ErrorBoundary ? (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Routes />
            </ErrorBoundary>
          ) : (
            <Routes />
          )}
        </QueryParamProvider>
      </ScrollToTop>
    </Router>
  )
}
