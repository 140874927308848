import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  username: {
    marginBottom: theme.spacing(2.625),
    lineHeight: '1'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    paddingTop: theme.spacing(8)
  },
  noPerformance: {
    textTransform: 'uppercase',
    color: theme.palette.secondBackground?.main,
    background: theme.palette.info.contrastText,
    padding: theme.spacing(0.4, 4)
  },
  buyGold: {
    textTransform: 'uppercase',
    color: theme.palette.secondBackground?.main,
    background: theme.palette.info.contrastText,
    lineHeight: '1',

    marginTop: theme.spacing(0.4),
    padding: theme.spacing(0.4, 2)
  }
}))
