import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  price: {
    color: theme.palette.primary.main,
    fontSize: '1rem'
  },
  livePrice: {
    color: theme.palette.primary.main
  },
  aboutDesc: {
    margin: theme.spacing(2, 0, 2),
    color: theme.palette.primary.main
  },
  aboutDescSecond: {
    lineHeight: 1.2,
    color: theme.palette.black.main,
    opacity: 0.8,
    margin: theme.spacing(2, 0, 0)
  },
  aboutDescSeeLess: {
    textTransform: 'uppercase',
    textIndent: '3.5rem',
    color: theme.palette.primary.main,
    fontWeight: 700
  },
  lineGraph: {
    height: '40vh',
    position: 'relative',
    margin: theme.spacing(0, 0, 4),
    width: '100%'
  },
  perShareText: {
    '& > p': {
      color: theme.palette.primary.main,

      '&:last-child': {
        fontSize: '0.5rem',
        textTransform: 'uppercase'
      }
    }
  },
  perShareValue: {
    color: theme.palette.primary.main
  },
  aboveTheFoldContainer: {
    height: 'calc(100vh - 100px - 70px)', // minus headerish - footerish
    flexWrap: 'nowrap'
  },
  graphContainer: {
    height: '30vh',
    width: '100%',
    position: 'relative',

    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4)
  },

  buyButtonWrapper: {
    position: 'sticky',
    left: 0,
    right: 0,
    zIndex: 9,
    background: theme.palette.background.default,
    padding: theme.spacing(1, 0),

    transition: 'top 0.5s',

    '& > .isSticky': {
      background: theme.palette.black.main
    }
  },

  slider: {
    color: theme.palette.default.main,

    '& .MuiSlider-thumb': {
      height: '1rem',
      width: '1rem',
      backgroundColor: theme.palette.default.main,
      border: 'none',
      filter: `drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.20)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.12))`,
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit'
      },
      '&:before': {
        display: 'none'
      }
    },

    '& .MuiSlider-track': {
      color: theme.palette.primary.main
    },

    '& .MuiSlider-rail': {
      color: theme.palette.default.dark
    },

    '& .MuiSlider-mark': {
      display: 'none'
    },
    '& .MuiSlider-markLabel:nth-child(4)': {
      top: '100% !important'
    },

    '& .MuiSlider-markLabel:nth-child(6)': {
      top: '100% !important',
      left: '90% !important',
      color: theme.palette.black.main,

      [theme.breakpoints.up('sm')]: {
        left: '96% !important'
      }
    }
  },

  sizeSpan: {
    paddingRight: theme.spacing(0.5),
    fontSize: '1.125rem',
    color: theme.palette.primary.main
  },

  amountInput: {
    border: 'none',
    background: 'transparent',
    fontSize: '1.125rem',
    color: 'transparent',
    textAlign: 'right',

    caretColor: theme.palette.primary.main,

    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',

    '&:focus': {
      outline: 'none'
    }
  },

  weightInput: {
    border: 'none',
    background: 'transparent',
    fontSize: '1.125rem',
    color: theme.palette.primary.main,
    textAlign: 'right',

    '&:focus': {
      outline: 'none'
    }
  },
  productImage: {
    //borderRadius: 40,
    //maxWidth: '100%',
    width: '100%',
    maxHeight: '100%',
    objectFit: 'contain'
    // height: 'auto',
    //maxHeight: '100%',
    //maxWidth: '100%',
    //height: '100%',
    //height: 'auto',
    // margin: '0 auto'
  },
  productSwitcherImage: {
    height: '4rem',
    width: 'auto'
  },
  dot: {
    height: '0.5rem',
    width: '0.5rem',
    //backgroundColor: theme.palette.black.main,
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.black.main
  },
  activeDot: {
    height: '0.5rem',
    width: '0.5rem',
    backgroundColor: theme.palette.black.main,
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.black.main
  },
  divider: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    borderColor: theme.palette.black.main
  }
}))
