import { gql } from '@apollo/client'

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    currentUser {
      email
      id
      otpVerified
      authenticatorAppEnabled
      profile {
        bankAccountName
        bankAccountNumber
        firstName
        identifier
        onboarded
        preferredName
        reference
        addressLine1
        addressLine2
        suburb
        city
        postcode
        lastName
        partialIdentityVerified
        dateOfBirth
        referralCode
        driversLicenceNumber
        driversLicenceVersion
        passportExpiry
        passportNumber
        verificationMethod
        ethnicity
      }
      securitiesOwned {
        product {
          name
          description
          id
          tradable
        }
        securities {
          ownedAllotmentsCount
        }
        allotmentsOwned
        hasHistory
      }
      wallet {
        balance
        userId
        expirableWalletCreditBalance
        walletBalance
      }
      vaults {
        dateOfBirth
        email
        id
        name
        relationship
        balance
        securitiesOwned {
          product {
            name
            description
            id
            tradable
          }
          securities {
            ownedAllotmentsCount
          }
          allotmentsOwned
          hasHistory
        }
      }
      activatedVault {
        dateOfBirth
        email
        id
        name
        relationship
        balance
        securitiesOwned {
          product {
            name
            description
            id
            tradable
          }
          securities {
            ownedAllotmentsCount
          }
          allotmentsOwned
          hasHistory
        }
      }
      firstTimeTopUpWithPoli
      autoInvests {
        amount
        duration
        nextAutoinvestDate
        id
        product {
          name
        }
        investBy
      }
    }
  }
`

export const GENERATE_OTP = gql`
  mutation generateOtp {
    generateOtp: generateOtp(input: {})
  }
`

export const PRODUCTS = gql`
  query products {
    products {
      id
      name
      description
      sku
      tradable
    }
  }
`
