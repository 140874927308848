import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { voucherPath } from 'components/Routes/VoucherFlowRoutes'
import DefaultLayout from 'components/layout/DefaultLayout'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { convertCamelCaseToWords } from 'utils/util'

interface StateProps {
  status: string
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: '3rem',
    marginTop: theme.spacing(4)
  },
  status: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: '3rem'
  }
}))

export default function POLiTransferStatus(): JSX.Element {
  const { state } = useLocation<StateProps>()

  const classes = useStyles()

  const status = convertCamelCaseToWords(state?.status ?? '')

  const history = useHistory()

  const redirectURL = localStorage.getItem('url_after_POLi_transfer')

  const voucherForm = localStorage.getItem('voucher_form')

  useEffect(() => {
    if (redirectURL) {
      history.push(redirectURL)
    } else if (voucherForm) {
      history.push({
        pathname: voucherPath(),
        state: JSON.parse(voucherForm ?? '')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DefaultLayout backgroundColor='white'>
      <Typography variant='h1' component='h1' className={classes.header}>
        POLi TRANSFER
      </Typography>
      <Typography
        variant='h2'
        className={classes.status}
        textTransform='uppercase'
      >
        {status}
      </Typography>
    </DefaultLayout>
  )
}
