import { Box, useMediaQuery } from '@mui/material'
import { GoogleMap, OverlayView, useJsApiLoader } from '@react-google-maps/api'
import theme from 'utils/theme'
import useStyles from './styles'

interface ILocation {
  lat: number
  lng: number
}

// hardcode: default location of the vault
const GEOMETRY_LOCATION_LAT = -41.2867533
const GEOMETRY_LOCATION_LNG = 174.7761809

export default function Location(): JSX.Element {
  const classes = useStyles()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    libraries: ['geometry', 'drawing'],
    language: 'en'
  })

  const center: ILocation = {
    lat: GEOMETRY_LOCATION_LAT,
    lng: GEOMETRY_LOCATION_LNG
  }

  const containerStyle = {
    width: '100%',
    height: matches ? '300px' : '400px'
  }

  const googleMapOptions: google.maps.MapOptions = {
    fullscreenControl: false,
    isFractionalZoomEnabled: false,
    zoomControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    mapTypeId: 'satellite'
  }

  return (
    <Box className={classes.map}>
      {isLoaded && (
        <GoogleMap
          id='circle-example'
          mapContainerStyle={containerStyle}
          center={center}
          zoom={17}
          options={googleMapOptions}
          tilt={200}
        >
          <OverlayView
            position={center}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div className={classes.circle} />
          </OverlayView>
        </GoogleMap>
      )}
    </Box>
  )
}
