import { Grid, Typography } from '@mui/material'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import { verificationOtherPath, verifyingPath } from 'components/Routes'
import LoadingWrapper from 'components/layout/LoadingWrapper'
import { FormikContextType, useFormikContext } from 'formik'
import {
  AmlVerificationAnswerInput,
  AmlVerificationAnswerOption,
  AmlVerificationQuestion,
  AnswerAmlQuestionsInput,
  useAnswerAmlQuestionsMutation,
  useGetAmlVerificationQuestionsQuery,
  useRequestIdentityVerificationMutation
} from 'generated/graphql'
import * as _ from 'lodash'
import { forwardRef, useImperativeHandle } from 'react'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { FormValues } from '../'
import MultiSelectQuestion from './MultiSelectQuestion'
import SingleSelectQuestion from './SingleSelectQuestion'
export type FinancialDetailsFormProps = RouteComponentProps

export const isOptionSelected = (
  values: FormValues,
  identifier: AmlVerificationQuestion['identifier'],
  optionId: AmlVerificationAnswerOption['identifier']
): boolean => {
  if (!values[identifier].length) return false
  return values[identifier].includes(optionId)
}

const FinancialDetailsForm = forwardRef(
  ({ history }: FinancialDetailsFormProps, ref): JSX.Element => {
    const { setToast } = useAuth()

    const { state } = useLocation<{ verificationMethod: string }>()

    const {
      isSubmitting,
      values,
      errors,
      touched
    }: FormikContextType<FormValues> = useFormikContext()

    const { data: questionSectionsData, loading: questionSectionsLoading } =
      useGetAmlVerificationQuestionsQuery({
        // TODO: error handling
        onError: error =>
          setToast({
            open: true,
            type: 'error',
            duration: 3000,
            message: error.message
          })
      })

    const [answerAmlQuestions, { loading: answerAmlQuestionsLoading }] =
      useAnswerAmlQuestionsMutation({
        // TODO: error handling
        onError: error =>
          setToast({
            open: true,
            type: 'error',
            duration: 3000,
            message: error.message
          })
      })

    const [
      requestIdentityVerification,
      { loading: requestVerificationLoading }
    ] = useRequestIdentityVerificationMutation({
      onCompleted: () => {
        history.push(verifyingPath())
      },
      // TODO: error handling
      onError: error =>
        setToast({
          open: true,
          type: 'error',
          duration: 3000,
          message: error.message
        })
    })

    useImperativeHandle(ref, () => ({
      handleClickVerify() {
        if (_.isEmpty(errors)) {
          answerAmlQuestions({ variables: { input: buildAnswerInput() } }).then(
            response => {
              if (state.verificationMethod === 'other') {
                return history.push(verificationOtherPath())
              }
              if (!response.errors) {
                requestIdentityVerification({ variables: { input: {} } }).then(
                  response => {
                    if (!response.errors) {
                      history.push(verifyingPath())
                    } else {
                      // TODO: error handling
                    }
                  }
                )
              } else {
                // TODO: error handling
              }
            }
          )
        }
      }
    }))

    function buildAnswerInput(): AnswerAmlQuestionsInput {
      const answers: Array<AmlVerificationAnswerInput> =
        questionSectionsData?.questionSections
          ? questionSectionsData?.questionSections.reduce(
              (answers: Array<AmlVerificationAnswerInput>, section) => {
                const sectionAnswers: Array<AmlVerificationAnswerInput> =
                  section.questions.reduce(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (sectionAnswers: any, question) => {
                      const formValue = values[question.identifier]
                      const answer: Partial<AmlVerificationAnswerInput> = {
                        questionId: question.id
                      }

                      switch (question.format) {
                        case 'multi_select':
                          answer.chosenAnswerOptionIds = formValue
                          break
                        case 'single_select':
                          answer.chosenAnswerOptionIds = [formValue]
                          break
                        case 'free_text':
                          answer.answer = formValue || 'N/A'
                          break
                        default:
                          break
                      }

                      const questionOptionHasCommentsRequired =
                        question.answerOptions.some(
                          option => option.commentsRequired
                        )

                      if (questionOptionHasCommentsRequired) {
                        const questionComments = Object.values(
                          values.comments[question.identifier]
                        )
                        answer.comments = questionComments?.join(', ')
                      }

                      return [...sectionAnswers, answer]
                    },
                    []
                  )

                return [...answers, ...sectionAnswers]
              },
              []
            )
          : []

      return { answers }
    }

    return (
      <>
        <Typography my={2.5} variant='h6'>
          &emsp;Part of our anti-money laundering obligations includes
          understanding where your investment income comes from and how you
          intend to use Goldie.
        </Typography>
        <LoadingWrapper loading={questionSectionsLoading}>
          <Grid container spacing={2}>
            {!!questionSectionsData?.questionSections?.length &&
              questionSectionsData?.questionSections?.map(section => {
                return section.questions.map(question => {
                  switch (question.format) {
                    case 'multi_select':
                      return (
                        <MultiSelectQuestion
                          key={question.id}
                          {...question}
                          values={values}
                          errors={errors}
                          touched={touched}
                        />
                      )

                    default:
                      return (
                        <SingleSelectQuestion
                          key={question.id}
                          {...question}
                          questions={section.questions}
                          values={values}
                          errors={errors}
                          touched={touched}
                        />
                      )
                  }
                })
              })}
          </Grid>
        </LoadingWrapper>
        <Grid mt={8} mb={6}>
          <Button
            color='primary'
            disabled={
              isSubmitting ||
              requestVerificationLoading ||
              answerAmlQuestionsLoading
            }
            fullWidth
            type='submit'
          >
            finish
          </Button>
        </Grid>
      </>
    )
  }
)

export default FinancialDetailsForm
