import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { forgotPasswordPath } from 'components/Routes'
import { signUpPath } from 'components/Routes'
import { useAuth } from 'components/AuthProvider'

import Button from 'components/Button'
import DefaultLayout from 'components/layout/DefaultLayout'
import { Grid, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { Field, FieldProps, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { TextField } from 'formik-mui'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  link: {
    color: theme.palette.primary.main
  }
}))

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Email is required')
})

export default function ResendConfirmationEmail(): JSX.Element {
  const classes = useStyles()

  const { resend, loading } = useAuth()

  const recaptchaEnabled = JSON.parse(
    process.env.REACT_APP_RECAPTCHA_ENABLED || 'true'
  )
  const { executeRecaptcha } = useGoogleReCaptcha()

  async function handleSubmit(values: { email: string }) {
    if (!executeRecaptcha && recaptchaEnabled) return

    const token =
      recaptchaEnabled &&
      executeRecaptcha &&
      (await executeRecaptcha('users/resend'))

    resend(values.email, token)
  }

  return (
    <DefaultLayout containerMaxWidth='xs' heading='Resend confirmation email'>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue }) => (
          <Form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field name='email'>
                  {({ field, form, meta }: FieldProps) => (
                    <TextField
                      field={field}
                      form={form}
                      meta={meta}
                      fullWidth
                      required
                      label='Email address'
                      onChange={e => {
                        setFieldValue('email', e.target.value)
                      }}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item container sx={{ justifyContent: 'center' }}>
                <Button
                  color='primary'
                  fullWidth
                  disabled={loading}
                  type='submit'
                >
                  Resend instructions
                </Button>
              </Grid>

              <Grid item container justifyContent='space-around'>
                <Link
                  color='textPrimary'
                  component={RouterLink}
                  to={forgotPasswordPath()}
                  underline='always'
                  className={classes.link}
                >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item container justifyContent='space-around'>
                <Link
                  color='textPrimary'
                  component={RouterLink}
                  to={signUpPath()}
                  underline='always'
                  className={classes.link}
                >
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </DefaultLayout>
  )
}
