import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

interface ScrollToTopProps {
  children: JSX.Element
}

export default function ScrollToTop({
  children
}: ScrollToTopProps): JSX.Element {
  const location = useLocation()
  const { pathname } = location
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [pathname])

  return children
}
