import HelpIcon from '@mui/icons-material/Help'
import { Divider, Grid, Slide, Typography } from '@mui/material'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import Currency from 'components/Currency'
import { dashboardPath, tradePath } from 'components/Routes'
import CustomTooltip from 'components/Tooltip/Tooltip'
import TradingPaused from 'components/TradingPaused/TradingPaused'
import DefaultLayout from 'components/layout/DefaultLayout'
import { REVIEW_ORDER_EXPIRED, TRADING_IS_PAUSED } from 'constants/index'
import {
  ReviewOrderQuery,
  namedOperations,
  useCreateMarketTransactionMutation
} from 'generated/graphql'
import useProducts from 'hooks/useProducts'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { formattedPrice } from 'utils/util'
import { PORTFOLIO_PERFORMANCE_FOR_CURRENT_USER } from '../TradeProduct/queries'
import useStyles from './styles'

export interface Props {
  reviewOrderData?: ReviewOrderQuery
  reviewOrderId: string
  reviewOrderLoading?: boolean
}

export const FEE_PLAN_TYPES: { [key: string]: string } = {
  INSURANCE: 'Standard Insurance Fee',
  TRANSACTION: 'Standard Transaction Fee'
}

export default function ReviewSale({
  reviewOrderData,
  reviewOrderId,
  reviewOrderLoading
}: Props): JSX.Element {
  const classes = useStyles()
  const { setToast } = useAuth()
  const history = useHistory()

  const [tradingPaused, setTradingPaused] = useState<boolean>(false)

  const {
    fees,
    totalPriceWithFees,
    totalPriceWithoutFees,
    weight,
    pricePerUnit,
    vault: activatedVault,
    product
  } = reviewOrderData?.reviewOrder || {}

  const productId = product?.id

  const { products } = useProducts()

  const [createMarketTransaction, { loading: createMarketTransactionLoading }] =
    useCreateMarketTransactionMutation({
      onCompleted: () => {
        setTradingPaused(false)
        setToast({
          open: true,
          message: `Good as ${product?.name}! Market transaction created.`,
          type: 'success',
          duration: 3000
        })
        history.push(
          tradePath({
            routeParams: {
              productId: products?.find(
                productItem =>
                  productItem.name.toLowerCase() === product?.name.toLowerCase()
              )?.id
            }
          })
        )
      },
      onError: error => {
        if (
          error.graphQLErrors[0].extensions?.['error_status'] ===
          TRADING_IS_PAUSED
        ) {
          setTradingPaused(true)
        }
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        })
        if (
          error.graphQLErrors[0].extensions?.['error_status'] ===
          REVIEW_ORDER_EXPIRED
        ) {
          history.push(dashboardPath())
        }
      },
      // Refetches the current user (so that the correct vault and
      // wallet details are displayed on the Dashboard)
      refetchQueries: [
        namedOperations.Query.getCurrentUser,
        {
          query: PORTFOLIO_PERFORMANCE_FOR_CURRENT_USER,
          variables: { productId }
        }
      ],
      variables: {
        input: {
          reviewOrderId: parseInt(reviewOrderId),
          vaultId: activatedVault ? Number(activatedVault.id) : null
        }
      }
    })

  const transactionFee = useMemo(() => {
    return fees?.find(fee => fee.plan.name === FEE_PLAN_TYPES.TRANSACTION)?.fee
  }, [fees])

  const handleConfirm = () => {
    if (productId) {
      createMarketTransaction()
    }
  }

  const handleEditClick = () => {
    history.push(`/sell/${reviewOrderId}`)
  }

  const transactionInformation = [
    {
      label: `${product?.name} price per gram`,
      value: formattedPrice(Number(pricePerUnit) * 10),
      prefix: '$',
      unit: 'NZD'
    },
    {
      label: `Grams to sell`,
      value: `${Number(weight) / 10}g`,
      prefix: '',
      unit: ''
    },
    {
      label: 'Sale amount',
      value: formattedPrice(Number(totalPriceWithoutFees ?? 0)),
      prefix: '$',
      unit: 'NZD'
    }
  ]

  if (tradingPaused) {
    return <TradingPaused />
  }

  return (
    <DefaultLayout
      wrapperContent={{ mt: 0, mb: 2, px: 0 }}
      wrapperContainer={{ p: 0, position: 'relative' }}
      loading={reviewOrderLoading}
    >
      <Grid item>
        <Grid item overflow='hidden' container justifyContent='center' mb={4}>
          <Slide direction='up' in={true} timeout={700}>
            <Grid item>
              <Typography component='h1' variant='h2' textAlign='center'>
                {Number(weight) / 10}g {product?.name}
              </Typography>
              <Typography component='h2' textAlign='center'>
                Double check your sale
              </Typography>
            </Grid>
          </Slide>
        </Grid>

        <Grid item container spacing={2}>
          {transactionInformation.map(info => (
            <Grid
              item
              container
              justifyContent='space-between'
              alignItems='center'
              key={info.label}
            >
              <Grid item>
                <Typography className={classes.label}>{info.label}</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.value}>
                  {info.prefix + info.value}
                  <Typography component='span'> {info.unit}</Typography>
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Divider />

        <Grid item container>
          <Grid
            item
            container
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item display='flex' gap={0.5}>
              <Typography className={classes.label}>Transaction fee</Typography>
              <CustomTooltip
                title='This is the cost to sell. It is 0.5% of the sale amount rounded to the nearest cent.'
                Icon={<HelpIcon color='primary' fontSize='small' />}
                iconSize='small'
              />
            </Grid>
            <Grid item>
              <Typography className={classes.value}>
                {'$' + Number(transactionFee ?? 0).toFixed(2)}
                <Typography component='span'> NZD</Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Divider />

        <Grid
          item
          container
          xs={12}
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid item>
            <Typography component='p' variant='body1' fontWeight='700'>
              Total
            </Typography>
          </Grid>
          <Grid item>
            <Typography component='p' variant='body1' fontWeight='700'>
              <Currency amount={Number(totalPriceWithFees ?? 0)} />
            </Typography>
          </Grid>
        </Grid>

        <Grid item container mt={3} gap={1} className={classes.stickyFooter}>
          <Grid item xs={4}>
            <Button
              fullWidth
              onClick={handleEditClick}
              disabled={createMarketTransactionLoading}
              color='white'
            >
              edit
            </Button>
          </Grid>
          <Grid item flex={1}>
            <Button
              color='white'
              fullWidth
              type='submit'
              disabled={createMarketTransactionLoading}
              onClick={handleConfirm}
              loading={createMarketTransactionLoading}
            >
              Confirm Sell
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </DefaultLayout>
  )
}
