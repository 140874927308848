import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import HelpIcon from '@mui/icons-material/Help'
import { Box, Grid, TextField, Typography } from '@mui/material'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import CountUpPrice from 'components/CountUpPrice'
import { reviewPath } from 'components/Routes'
import CustomTooltip from 'components/Tooltip/Tooltip'
import DefaultLayout from 'components/layout/DefaultLayout'
import LoadingWrapper from 'components/layout/LoadingWrapper'
import {
  DEBOUNCE_INTERVAL,
  TRADING_IS_PAUSED,
  patternOneDigitsAfterComma
} from 'constants/index'
import { useFormik } from 'formik'
import {
  useReviewOrderForBuyQuery,
  useUpdateReviewOrderForBuyMutation
} from 'generated/graphql'
import useDebounce from 'hooks/useDebounce'
import {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { formattedPrice, roundUpDollarsAndCents } from 'utils/util'
import * as Yup from 'yup'
import PauseTrading from '../PauseTrading/PauseTrading'
import useStyles from './styles'

interface MatchParams {
  reviewOrderId: string
}

interface FormValues {
  amount: string
  weight: number
  spotPrice?: number
}

const initialValues: FormValues = {
  amount: '0',
  weight: 0
}

export type BuyProps = RouteComponentProps<MatchParams>

const validationSchema = Yup.object().shape({
  amount: Yup.string(),
  weight: Yup.number()
    .required('Weight (grams) is required field')
    .test('is-decimal', 'Specify grams to buy to 1 decimal place', value => {
      if (value !== undefined) {
        return patternOneDigitsAfterComma.test(value.toString())
      }
      return true
    })
})

export default function Buy({
  match: {
    params: { reviewOrderId }
  }
}: BuyProps): JSX.Element {
  const classes = useStyles()
  const history = useHistory()

  const [helperTextAmountInput, setHelperTextAmountInput] = useState<string>('')
  const [tradingPaused, setTradingPaused] = useState<boolean>(false)

  const { setToast } = useAuth()

  const {
    values,
    errors,
    touched,
    setTouched,
    setValues,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      history.push(
        reviewPath({
          queryParams: { type: 'buy' },
          routeParams: { reviewOrderId }
        })
      )
    },
    enableReinitialize: true
  })

  const { data: reviewOrderData, loading: reviewOrderLoading } =
    useReviewOrderForBuyQuery({
      variables: {
        reviewOrderId: Number(reviewOrderId)
      },
      onCompleted: data => {
        const { totalPriceWithoutFees, weight, spotPrice } =
          data.reviewOrder || {}
        setValues({
          amount: totalPriceWithoutFees
            ? roundUpDollarsAndCents(Number(totalPriceWithoutFees)).toString()
            : '0',
          weight: weight ? Number(weight) / 10 : 0,
          spotPrice
        })
        setTradingPaused(false)
      },
      onError: error => {
        if (
          error.graphQLErrors[0].extensions?.['error_status'] ===
          TRADING_IS_PAUSED
        ) {
          setTradingPaused(true)
        }
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        })
      }
    })

  const {
    spotPrice,
    totalFees: initialTotalFees,
    totalPriceWithFees: initialTotalPriceWithFees
  } = reviewOrderData?.reviewOrder || {}

  const [updateReviewOrder, { data: updateReviewOrderData, loading }] =
    useUpdateReviewOrderForBuyMutation()

  const {
    totalFees: updatedTotalFees,
    totalPriceWithFees: updatedTotalPriceWithFees
  } = updateReviewOrderData?.reviewOrder || {}

  const totalFees = useMemo(() => {
    return updatedTotalFees ? updatedTotalFees : initialTotalFees
  }, [updatedTotalFees, initialTotalFees])

  const totalPrices = useMemo(() => {
    return updatedTotalPriceWithFees
      ? updatedTotalPriceWithFees
      : initialTotalPriceWithFees
  }, [updatedTotalPriceWithFees, initialTotalPriceWithFees])

  const debouncedWeight = useDebounce<number>(values.weight, DEBOUNCE_INTERVAL)
  const debouncedAmount = useDebounce<string>(values.amount, DEBOUNCE_INTERVAL)

  useEffect(() => {
    if (!errors.weight && touched.weight) {
      handleChangeWeight(values.weight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedWeight])

  useEffect(() => {
    if (!errors.amount && touched.amount) {
      const weight = calculateDecigramsRoundedDown(Number(values.amount))
      handleChangeWeight(weight, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAmount])

  const handleChangeWeight = (weight: number, rounded?: boolean) => {
    updateReviewOrder({
      variables: {
        input: {
          reviewOrderId: Number(reviewOrderId),
          weight: parseFloat(Number(weight * 10).toFixed(2))
        }
      },
      onError: error => setErrors({ weight: error.message, amount: undefined }),
      onCompleted: data => {
        const { totalPriceWithoutFees, weight, spotPrice } =
          data.reviewOrder || {}
        setValues({
          amount: totalPriceWithoutFees
            ? roundUpDollarsAndCents(Number(totalPriceWithoutFees)).toString()
            : '0',
          weight: weight ? Number(weight) / 10 : 0,
          spotPrice
        })
        setErrors({ weight: undefined, amount: undefined })
        setTouched({ amount: false, weight: false })
        if (
          Number(values.amount) !==
            Number(Number(totalPriceWithoutFees).toFixed(2)) &&
          rounded
        ) {
          setHelperTextAmountInput(
            `We've rounded $${values.amount} to ${roundUpDollarsAndCents(
              Number(totalPriceWithoutFees)
            ).toString()}`
          )
        }
      }
    })
  }

  const calculateDecigrams = useCallback(
    (amount: number) =>
      amount > 0 ? parseFloat((amount / Number(spotPrice) / 10).toFixed(1)) : 0,
    [spotPrice]
  )

  const calculateDecigramsRoundedDown = useCallback(
    (amount: number) =>
      amount > 0
        ? parseFloat((Math.floor(amount / Number(spotPrice)) / 10).toFixed(1))
        : 0,
    [spotPrice]
  )

  const handleIncreaseAmount = useCallback(() => {
    setHelperTextAmountInput('')
    if (!loading) {
      const amount = Number(values.amount) + Number(spotPrice)
      const weight = calculateDecigrams(amount)
      setTouched({ amount: false, weight: true })
      setValues({ amount: amount.toFixed(2).toString(), weight })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, spotPrice, values.amount])

  const handleDecreaseAmount = useCallback(() => {
    setHelperTextAmountInput('')
    if (!loading) {
      const amount = Number(values.amount) - Number(spotPrice)
      const weight = calculateDecigrams(amount)
      setTouched({ amount: false, weight: true })
      setValues({
        amount: amount > 0 ? amount.toFixed(2).toString() : '0',
        weight
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, spotPrice, values.amount])

  const handleChangeWeightInput = (e: ChangeEvent<HTMLInputElement>) => {
    setTouched({ amount: false, weight: true })
    handleChange(e)
    setHelperTextAmountInput('')
  }

  const handleBlurWeightInput = (e: FocusEvent<HTMLInputElement>) => {
    setTouched({ amount: false, weight: true })
    handleBlur(e)
    setHelperTextAmountInput('')
  }

  const handleChangeAmountInput = (e: ChangeEvent<HTMLInputElement>) => {
    setTouched({ amount: true, weight: false })
    handleChange(e)
    setHelperTextAmountInput('')
  }

  const renderReviewAndPay = () => {
    return (
      <Grid className={classes['review-&-pay']}>
        <Box component='div'>
          <CountUpPrice
            value={totalPrices}
            prefix='$'
            duration={0.5}
            variant='body1'
          />
          <Box>
            <Typography>Includes fee of </Typography>
            <CountUpPrice value={totalFees} prefix='$' duration={0.5} />
          </Box>
        </Box>
        <Button
          style={{ width: '100px' }}
          color='white'
          onClick={() => handleSubmit()}
        >
          Review & Buy
        </Button>
      </Grid>
    )
  }

  if (tradingPaused) {
    return <PauseTrading />
  }

  return (
    <DefaultLayout
      wrapperContent={{ mt: 2 }}
      stickyFooter={renderReviewAndPay()}
      showBackIcon
      heading='Buy'
    >
      <Grid
        container
        flexDirection='column'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid
          item
          container
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
        >
          <Grid
            item
            container
            justifyContent='center'
            alignItems='center'
            gap={1}
          >
            <Typography variant='h3' fontWeight='800'>
              ${formattedPrice(Number((spotPrice ?? 0) * 10))}
            </Typography>
            <CustomTooltip
              Icon={<HelpIcon color='primary' />}
              title='
                This is the buy price. 
                It is the price the market will sell at currently. 
                It is slightly different to the current mid-price or sell price.
              '
              placement='bottom'
            />
          </Grid>
          <Grid item container justifyContent='center' gap={1}>
            <Typography className={classes.perGram}>Per Gram</Typography>
            <Typography className={classes.perGram}>(NZD)</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} my={4} container justifyContent='center'>
          <model-viewer
            src='GoldNugget_002.glb'
            ar={false}
            camera-controls
            disable-zoom
            poster='poster.webp'
            shadow-intensity='1'
            alt='A 3D gold bar'
          ></model-viewer>
        </Grid>

        <LoadingWrapper loading={reviewOrderLoading}>
          <form noValidate onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={2}>
              <Grid item container gap={1}>
                <Grid item>
                  <Box
                    className={classes.button}
                    onClick={handleIncreaseAmount}
                  >
                    <ArrowUpward color='primary' />
                  </Box>
                </Grid>
                <Grid item flex={1}>
                  <TextField
                    name='amount'
                    label='Purchase amount'
                    type='number'
                    fullWidth
                    value={values.amount}
                    disabled={loading}
                    onChange={handleChangeAmountInput}
                    error={!!errors.amount}
                    helperText={helperTextAmountInput}
                    FormHelperTextProps={{
                      className: classes.helperTextAmount
                    }}
                    InputProps={{
                      startAdornment: (
                        <Typography mt={2} ml={1}>
                          $
                        </Typography>
                      ),
                      className: classes.amountInput
                    }}
                  />
                  <Typography
                    className={
                      !!errors.amount
                        ? classes.incrementsError
                        : classes.increments
                    }
                  >
                    {`(Buy increments of $${formattedPrice(spotPrice)})`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box
                    className={classes.button}
                    onClick={handleDecreaseAmount}
                  >
                    <ArrowDownward color='primary' />
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name='weight'
                  type='number'
                  label={`Weight in ${reviewOrderData?.reviewOrder.product.name} (grams)`}
                  fullWidth
                  disabled={loading}
                  onChange={handleChangeWeightInput}
                  onBlur={handleBlurWeightInput}
                  value={values.weight}
                  helperText={Boolean(errors.weight) && errors.weight}
                  error={Boolean(errors.weight)}
                />
              </Grid>
            </Grid>
          </form>
        </LoadingWrapper>
      </Grid>
    </DefaultLayout>
  )
}
