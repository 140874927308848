import { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import {
  verificationErrorPath,
  verificationPartialSuccessPath,
  verificationSuccessPath
} from 'components/Routes'
import { useGetIdentityVerificationStatusQuery } from 'generated/graphql'

import { Box, Theme, Typography } from '@mui/material'
import DefaultLayout from 'components/layout/DefaultLayout'
import LoadingWrapper from 'components/layout/LoadingWrapper'

import { makeStyles } from '@mui/styles'
import { useAuth } from 'components/AuthProvider'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',

    position: 'relative',
    height: '100%',
    marginTop: theme.spacing(4),
    background: theme.palette.error.main
  },
  loading: {
    margin: 'auto',
    color: theme.palette.primary.main
  }
}))

export type VerifyingProps = RouteComponentProps

export default function Verifying({ history }: VerifyingProps): JSX.Element {
  const classes = useStyles()

  const [loading, setLoading] = useState<boolean>(true)

  const { refreshCurrentUser, setToast } = useAuth()

  const {
    data: statusData,
    loading: statusLoading,
    startPolling,
    stopPolling
  } = useGetIdentityVerificationStatusQuery({
    fetchPolicy: 'network-only',
    // TODO: error handling
    onError: error =>
      setToast({
        open: true,
        message: error.message,
        type: 'error',
        duration: 3000
      }),
    onCompleted: async data => {
      const user = await refreshCurrentUser()
      const { onboarded, partialIdentityVerified } = user?.profile || {}
      const {
        identityVerificationRequestInProgress: requestInProgress,
        identityVerified
      } = data?.identityVerificationStatus || {}

      if (!requestInProgress) {
        const isPartialVerified = !identityVerified && partialIdentityVerified
        const isSuccess = identityVerified && onboarded

        if (isPartialVerified) {
          history.push(verificationPartialSuccessPath())
        } else if (isSuccess) {
          history.push(verificationSuccessPath())
        } else {
          history.push(verificationErrorPath())
        }
      }
    }
  })

  const {
    identityVerificationFailureReasons: failureReasons,
    identityVerificationRequestInProgress: requestInProgress,
    identityVerified
  } = statusData?.identityVerificationStatus || {}

  useEffect(() => {
    if (statusData) {
      if (requestInProgress) {
        startPolling(500)
        setLoading(true)
        return () => {
          stopPolling()
          setLoading(false)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failureReasons, requestInProgress, statusData, identityVerified])

  return (
    <DefaultLayout
      heading='Hold on while we verify your details'
      wrapperContainer={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }}
    >
      <Typography variant='body1'>(This may take 5-10 seconds)</Typography>
      <Box className={classes.wrapper}>
        <LoadingWrapper
          className={classes.loading}
          loading={statusLoading || loading}
        >
          {/* This is a hack as LoadingWrapper requires `children` */}
          <div />
        </LoadingWrapper>
      </Box>
    </DefaultLayout>
  )
}
