interface Props {
  color?: string
}

export default function Personal({ color = '#292D32' }: Props): JSX.Element {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.1394 21.62C17.2594 21.88 16.2194 22 14.9994 22H8.99937C7.77937 22 6.73937 21.88 5.85938 21.62C6.07937 19.02 8.74937 16.97 11.9994 16.97C15.2494 16.97 17.9194 19.02 18.1394 21.62Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 2H9C4 2 2 4 2 9V15C2 18.78 3.14 20.85 5.86 21.62C6.08 19.02 8.75 16.97 12 16.97C15.25 16.97 17.92 19.02 18.14 21.62C20.86 20.85 22 18.78 22 15V9C22 4 20 2 15 2ZM12 14.17C10.02 14.17 8.42 12.56 8.42 10.58C8.42 8.60002 10.02 7 12 7C13.98 7 15.58 8.60002 15.58 10.58C15.58 12.56 13.98 14.17 12 14.17Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.5819 10.58C15.5819 12.56 13.9819 14.17 12.0019 14.17C10.0219 14.17 8.42188 12.56 8.42188 10.58C8.42188 8.60002 10.0219 7 12.0019 7C13.9819 7 15.5819 8.60002 15.5819 10.58Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
