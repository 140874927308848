import * as React from 'react'

import { MarketOrder, useMarketOrdersForProductQuery } from 'generated/graphql'

/** styles */
import useStyles from './styles'
import {
  API_DATE_FORMAT,
  formatDate,
  UI_DATE_FORMAT,
  roundUpDollarsAndCents,
  roundDownDollarsAndCents
} from 'utils/util'
import { TableBaseProps } from './TableBase/models'
import TableBase from './TableBase'

interface Props {
  productId: string
}

export default function TableTransactionHistory({ productId }: Props) {
  const classes = useStyles()

  const { data: marketOrdersData } = useMarketOrdersForProductQuery({
    variables: { productId: Number(productId) },
    skip: !productId
  })

  const tableRows = React.useRef<TableBaseProps<MarketOrder>['rows']>([
    {
      id: 'date',
      label: 'DATE',
      render: data =>
        formatDate({
          date: data?.createdAt,
          inputFormat: API_DATE_FORMAT,
          outputFormat: UI_DATE_FORMAT
        }) || ''
    },
    {
      id: 'buy_price',
      label: 'BUY PRICE',
      render: data => {
        if (data.orderType === 'buy') {
          return roundUpDollarsAndCents(
            Number(data.pricePerUnit) * data.quantity
          )
        } else {
          return ''
        }
      }
    },
    {
      id: 'sell_price',
      label: 'SELL PRICE',
      render: data => {
        if (data.orderType === 'sell') {
          return roundDownDollarsAndCents(
            Number(data.pricePerUnit) * data.quantity
          )
        } else {
          return ''
        }
      }
    },
    {
      id: 'amount',
      label: 'AMOUNT',
      render: data => data?.quantity || ''
    }
  ]).current

  const marketOrdersForProduct = marketOrdersData?.marketOrdersForProduct

  if (!marketOrdersForProduct || marketOrdersForProduct?.length < 1) {
    return <></>
  }

  return (
    <TableBase
      className={classes.tableTransition}
      rows={tableRows}
      data={marketOrdersForProduct}
    />
  )
}
