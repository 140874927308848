import AuthenticatedRoute from 'components/Routes/AuthenticatedRoute'
import PublicRoute from 'components/Routes/PublicRoute'
import Activity from 'components/pages/Activity'
import BankAccounts from 'components/pages/BankAccount'
import Buy from 'components/pages/Buy'
import BuyCelebration from 'components/pages/BuyCelebration'
import ChangePassword from 'components/pages/ChangePassword'
import ComingSoon from 'components/pages/ComingSoon'
import ConfirmEmail from 'components/pages/ConfirmEmail'
import Contact from 'components/pages/Contact/Contact'
import CreateReviewOrder from 'components/pages/CreateReviewOrder'
import DepositFunds from 'components/pages/DepositFunds'
import DeviceSettings from 'components/pages/DeviceSettings'
import ForgotPassword from 'components/pages/ForgotPassword'
import Gifts from 'components/pages/Gifts'
import KidVault from 'components/pages/KidVault'
import CreateKidVault from 'components/pages/KidVault/Create'
import UpdateKidVault from 'components/pages/KidVault/Update'
import Login from 'components/pages/Login'
import More from 'components/pages/More'
import MyVault from 'components/pages/MyVault'
import TradeProduct from 'components/pages/TradeProduct'
import OnboardingWizard from 'components/pages/OnboardingWizard'
import VerificationError from 'components/pages/OnboardingWizard/VerificationError'
import VerificationPartialSuccess from 'components/pages/OnboardingWizard/VerificationPartialSuccess'
import VerificationSuccess from 'components/pages/OnboardingWizard/VerificationSuccess'
import Verifying from 'components/pages/OnboardingWizard/Verifying'
import POLiTransferLoading from 'components/pages/POLiTransferLoading'
import POLiTransferStatus from 'components/pages/POLiTransferStatus'
import PersonalDetails from 'components/pages/PersonalDetails'
import PrivacyPolicy from 'components/pages/PrivacyPolicy'
import InformationMemorandum from 'components/pages/InformationMemorandum'
import ProfileSettings from 'components/pages/ProfileSettings'
import Recharge from 'components/pages/Recharge'
import RechargeConfirm from 'components/pages/RechargeConfirm/RechargeConfirm'
import RedeemVoucher from 'components/pages/RedeemVoucher'
import ReferralCode from 'components/pages/ReferralCode'
import ResendConfirmationEmail from 'components/pages/ResendConfirmationEmail'
import ResetPassword from 'components/pages/ResetPassword'
import Review from 'components/pages/Review/Review'
import SignUp from 'components/pages/SignUp'
import TermsAndConditions from 'components/pages/TermsAndConditions'
import CatalistTermsAndConditions from 'components/pages/CatalistTermsAndConditions'
import Tutorial from 'components/pages/Tutorial'
import TwoStepAuthentication from 'components/pages/TwoStepAuthentication'
import VerifyOtp from 'components/pages/VerifyOtp'
import Wallet from 'components/pages/Wallet'
import Welcome from 'components/pages/Welcome'
import WithdrawFunds from 'components/pages/WithdrawFunds'
import { Redirect, Route, Switch } from 'react-router-dom'
import buildLink from 'utils/buildLink'
import VoucherFlow from './VoucherFlowRoutes/VoucherFlowRoutes'
import { PathArgs } from './types'
import AutoInvest from 'components/pages/AutoInvest'
import AddAutoInvest from 'components/pages/AddAutoInvest'
import InstallApp from 'components/pages/InstallApp'
import VerificationOther from 'components/pages/OnboardingWizard/VerificationOther'
import AutoInvestSuccess from 'components/pages/AutoInvestSuccess'

/**
 * TODO: consider "verified" routes - like "authenticated", but hiding screens that require
 * the user to have completed AML and ID verification
 */

function buyPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/buy/:reviewOrderId' })
}
function buyCelebrationPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/buyCelebration/:productId' })
}
function confirmEmailPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/confirm' })
}
function createReviewOrderPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/new' })
}
function dashboardPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/dashboard' })
}

function forgotPasswordPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/forgot_password' })
}

function loginPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/login' })
}
function privacyPolicyPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/privacy_policy' })
}

function InformationMemorandumPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/product_disclosure_statement' })
}

function resendConfirmationEmailPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/resend_confirmation_email' })
}
function resetPasswordPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/reset_password' })
}
function reviewPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/review/:reviewOrderId' })
}
function sellPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/sell/:reviewOrderId' })
}
function signUpPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/sign_up' })
}
function termsAndConditionsPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/terms_and_conditions' })
}
function catalistTermsAndConditionsPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/catalist_terms_and_conditions' })
}

function verificationPartialSuccessPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/verification_partial_success' })
}
function verificationErrorPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/verification_error' })
}
function verificationSuccessPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/verification_success' })
}
function verificationOtherPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/verification_other' })
}
function verifyFinancialDetailsPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/verify/financial_details' })
}
function verifyIdDetailsPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/verify/id_details' })
}
function verifyPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/verify' })
}
function verifyGetStartedPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/verify/get_started' })
}
function verifyPersonalDetailsPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/verify/personal_details' })
}
function verifyingPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/verifying' })
}
function verifyOtpPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/verify_otp' })
}

function welcomePath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/welcome' })
}
function comingSoonPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/coming_soon' })
}

function tutorialPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/tutorial' })
}

function homePath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/home' })
}
function contactPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/contact' })
}

/* TRADE ROUTES */
function tradePath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/trade/:productId' })
}

/* VAULT ROUTES */
function myVaultPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/vault' })
}
function myVaultProductPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/vault/:productId' })
}

/* ACCOUNT ROUTES */
function settingsPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/more/profile_settings' })
}
function personalDetailsPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/more/personal_details' })
}
function bankAccountPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/more/bank_account' })
}

function twoStepAuthenticationPath(args?: PathArgs): string {
  return buildLink({
    ...args,
    path: '/account/more/two_step_authentication'
  })
}
function twoStepDownloadPath(args?: PathArgs): string {
  return buildLink({
    ...args,
    path: '/account/more/two_step_authentication/download'
  })
}
function twoStepScanPath(args?: PathArgs): string {
  return buildLink({
    ...args,
    path: '/account/more/two_step_authentication/scan'
  })
}
function twoStepCodePath(args?: PathArgs): string {
  return buildLink({
    ...args,
    path: '/account/more/two_step_authentication/code'
  })
}
function deviceSettingsPath(args?: PathArgs): string {
  return buildLink({
    ...args,
    path: '/account/more/device_settings'
  })
}
function kidVaultPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/more/kid_vault' })
}
function createKidVaultPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/more/kid_vault/create' })
}
function updateKidVaultPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/more/kid_vault/update/:id' })
}
function walletPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/more/wallet' })
}
function depositFundsPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/more/deposit_funds' })
}
function rechargeConfirmPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/more/recharge_confirm' })
}
function rechargePath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/more/recharge' })
}
function withdrawFundsPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/more/withdraw' })
}
function activityPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/more/activity' })
}
function changePasswordPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/more/password' })
}
function referralsPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/more/referrals' })
}
function morePath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/more' })
}
function voucherFlowPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/gifts/voucher' })
}
function redeemVoucherPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/gifts/redeem' })
}
function giftsPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/gifts' })
}

// POLi transfer routes
function POLiTransferLoadingPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/wallet/POLi_transfer/loading' })
}
function POLiTransferStatusPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/wallet/POLi_transfer/status' })
}

// Auto invest
function autoInvestPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/auto_invest' })
}
function addAutoInvestPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/auto_invest/add' })
}
function autoInvestSuccessPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/auto_invest/success' })
}

function installAppPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/install_app' })
}

export const PublicRoutes = () => {
  return [
    <PublicRoute path={loginPath()} component={Login} key={loginPath()} />,
    <PublicRoute path={signUpPath()} component={SignUp} key={signUpPath()} />,
    <PublicRoute
      path={verifyOtpPath()}
      component={VerifyOtp}
      key={verifyOtpPath()}
    />,
    <PublicRoute
      path={confirmEmailPath()}
      component={ConfirmEmail}
      key={confirmEmailPath()}
    />,
    <PublicRoute
      path={forgotPasswordPath()}
      component={ForgotPassword}
      key={forgotPasswordPath()}
    />,
    <PublicRoute
      path={resendConfirmationEmailPath()}
      component={ResendConfirmationEmail}
      key={resendConfirmationEmailPath()}
    />,
    <PublicRoute
      path={resetPasswordPath()}
      component={ResetPassword}
      key={resetPasswordPath()}
    />
  ]
}

const Routes = (): JSX.Element => {
  return (
    <Switch>
      {PublicRoutes()}
      <Route path={privacyPolicyPath()} component={PrivacyPolicy} />
      <Route
        path={InformationMemorandumPath()}
        component={InformationMemorandum}
      />
      <Route path={termsAndConditionsPath()} component={TermsAndConditions} />
      <Route
        path={catalistTermsAndConditionsPath()}
        component={CatalistTermsAndConditions}
      />
      <AuthenticatedRoute path={welcomePath()} component={Welcome} />

      <AuthenticatedRoute path={activityPath()} component={Activity} />
      <AuthenticatedRoute
        path={createReviewOrderPath()}
        component={CreateReviewOrder}
      />
      <AuthenticatedRoute path={buyPath()} component={Buy} />
      <AuthenticatedRoute path={reviewPath()} component={Review} />
      <AuthenticatedRoute path={settingsPath()} component={ProfileSettings} />
      <AuthenticatedRoute
        path={buyCelebrationPath()}
        component={BuyCelebration}
      />
      <AuthenticatedRoute path={depositFundsPath()} component={DepositFunds} />
      <AuthenticatedRoute
        path={POLiTransferLoadingPath()}
        component={POLiTransferLoading}
      />
      <AuthenticatedRoute
        path={POLiTransferStatusPath()}
        component={POLiTransferStatus}
      />
      <AuthenticatedRoute
        path={rechargeConfirmPath()}
        component={RechargeConfirm}
      />
      <AuthenticatedRoute path={rechargePath()} component={Recharge} />
      <AuthenticatedRoute
        path={withdrawFundsPath()}
        component={WithdrawFunds}
      />
      <AuthenticatedRoute
        path={personalDetailsPath()}
        component={PersonalDetails}
      />
      <AuthenticatedRoute
        path={changePasswordPath()}
        component={ChangePassword}
      />
      <AuthenticatedRoute
        path={twoStepAuthenticationPath()}
        component={TwoStepAuthentication}
      />
      <AuthenticatedRoute
        path={deviceSettingsPath()}
        component={DeviceSettings}
      />
      <AuthenticatedRoute path={bankAccountPath()} component={BankAccounts} />

      <AuthenticatedRoute
        path={redeemVoucherPath()}
        component={RedeemVoucher}
      />

      <AuthenticatedRoute path={walletPath()} component={Wallet} />
      <AuthenticatedRoute path={verifyPath()} component={OnboardingWizard} />
      <AuthenticatedRoute path={verifyingPath()} component={Verifying} />
      <AuthenticatedRoute
        path={verificationSuccessPath()}
        component={VerificationSuccess}
      />
      <AuthenticatedRoute
        path={verificationErrorPath()}
        component={VerificationError}
      />
      <AuthenticatedRoute
        path={verificationPartialSuccessPath()}
        component={VerificationPartialSuccess}
      />
      <AuthenticatedRoute
        path={verificationOtherPath()}
        component={VerificationOther}
      />
      <AuthenticatedRoute path={comingSoonPath()} component={ComingSoon} />

      <AuthenticatedRoute
        path={createKidVaultPath()}
        component={CreateKidVault}
      />
      <AuthenticatedRoute
        path={updateKidVaultPath()}
        component={UpdateKidVault}
      />
      <AuthenticatedRoute path={kidVaultPath()} component={KidVault} />
      <AuthenticatedRoute path={tutorialPath()} component={Tutorial} />
      <AuthenticatedRoute path={referralsPath()} component={ReferralCode} />
      <AuthenticatedRoute path={contactPath()} component={Contact} />

      {/* TRADE ROUTES */}
      <AuthenticatedRoute path={tradePath()} component={TradeProduct} />

      <AuthenticatedRoute path={dashboardPath()} component={TradeProduct} />

      {/* VAULT ROUTES */}
      <AuthenticatedRoute path={myVaultPath()} component={MyVault} />
      {/* END VAULT ROUTES */}

      {/* ACCOUNT ROUTES */}
      <AuthenticatedRoute path={morePath()} component={More} />
      <AuthenticatedRoute path={voucherFlowPath()} component={VoucherFlow} />
      <AuthenticatedRoute path={giftsPath()} component={Gifts} />
      <AuthenticatedRoute
        path={autoInvestSuccessPath()}
        component={AutoInvestSuccess}
      />
      <AuthenticatedRoute
        path={addAutoInvestPath()}
        component={AddAutoInvest}
      />
      <AuthenticatedRoute path={autoInvestPath()} component={AutoInvest} />
      <AuthenticatedRoute path={installAppPath()} component={InstallApp} />
      {/* END ACCOUNT ROUTES */}
      <Redirect from='/interest' to={dashboardPath()} />
      <Redirect from='/thanks_for_your_interest' to={dashboardPath()} />
      <Redirect from='/' to={dashboardPath()} />
    </Switch>
  )
}

export default Routes
export {
  POLiTransferLoadingPath,
  POLiTransferStatusPath,
  activityPath,
  bankAccountPath,
  buyCelebrationPath,
  buyPath,
  catalistTermsAndConditionsPath,
  changePasswordPath,
  comingSoonPath,
  confirmEmailPath,
  contactPath,
  createKidVaultPath,
  createReviewOrderPath,
  dashboardPath,
  depositFundsPath,
  deviceSettingsPath,
  forgotPasswordPath,
  giftsPath,
  homePath,
  kidVaultPath,
  loginPath,
  morePath,
  myVaultPath,
  myVaultProductPath,
  personalDetailsPath,
  privacyPolicyPath,
  InformationMemorandumPath,
  rechargeConfirmPath,
  rechargePath,
  redeemVoucherPath,
  referralsPath,
  resendConfirmationEmailPath,
  resetPasswordPath,
  reviewPath,
  sellPath,
  settingsPath,
  signUpPath,
  termsAndConditionsPath,
  tradePath,
  tutorialPath,
  twoStepAuthenticationPath,
  twoStepCodePath,
  twoStepDownloadPath,
  twoStepScanPath,
  updateKidVaultPath,
  verificationErrorPath,
  verificationPartialSuccessPath,
  verificationSuccessPath,
  verifyFinancialDetailsPath,
  verifyGetStartedPath,
  verifyIdDetailsPath,
  verifyOtpPath,
  verificationOtherPath,
  verifyPath,
  verifyPersonalDetailsPath,
  verifyingPath,
  voucherFlowPath,
  walletPath,
  welcomePath,
  withdrawFundsPath,
  autoInvestPath,
  addAutoInvestPath,
  autoInvestSuccessPath,
  installAppPath
}
