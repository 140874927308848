export default function MenuIcon({ className }: { className?: string }) {
  return (
    <svg
      id='hamburger'
      className={className}
      width={30}
      height={20}
      viewBox='0 0 60 40'
    >
      <g
        stroke='#211E16'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path id='top_line' d='M10,10 L50,10 Z'></path>
        <path id='bottom_line' d='M10,30 L50,30 Z'></path>
      </g>
    </svg>
  )
}
