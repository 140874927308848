import { Box } from '@mui/material'

interface GoldieLogoProps {
  width?: string | number
  height?: string | number
  fill?: string
}

export default function GoldieLogo({
  width = '100%',
  height = '100%',
  fill
}: Readonly<GoldieLogoProps>): JSX.Element {
  // TODO: Come back to this component an rename it and improve it.
  return (
    <Box margin='0.25rem 0 0'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        x='0'
        y='0'
        version='1.1'
        viewBox='0 0 552.56 223.94'
        width={width}
        height={height}
        fill={fill}
      >
        <path d='M71.43 223.94c21.75 0 28.47-14.4 28.47-30.07v-79.66c0-16-3.52-21.75-16.63-21.75H58.31c-7.36 0-8.96 5.12-8.96 11.84v16c0 6.72 1.6 11.84 8.96 11.84h1.6v25.27c0 5.44-2.88 8.64-8.64 8.64h-2.64c-7.36 0-8.96-5.12-8.96-11.84V69.74c0-6.72 1.6-11.84 8.96-11.84h2.64c5.76 0 8 2.88 8.64 7.36.32 2.56 2.24 4.48 4.8 4.48h18.55c13.12 0 16.63-3.52 16.63-19.51V30.07C99.9 14.4 93.18 0 71.43 0h-42C6.72 0 0 14.08 0 30.07v163.79c0 16 6.72 30.07 29.43 30.07h42zM177.63 0h-39.35c-22.71 0-29.43 14.08-29.43 30.07v163.79c0 16 6.72 30.07 29.43 30.07h39.35c21.75 0 28.47-14.4 28.47-30.07V30.07C206.1 14.4 199.39 0 177.63 0zm-11.2 154.2c0 6.72-1.6 11.84-8.96 11.84-7.36 0-8.96-5.12-8.96-11.84V69.74c0-6.72 1.6-11.84 8.96-11.84 7.36 0 8.96 5.12 8.96 11.84v84.46zM283.52 170.51H264c-7.68 0-9.28-5.12-9.28-9.6V20.47c0-9.6-3.52-20.47-19.83-20.47s-19.83 10.88-19.83 20.47v182.99c0 9.6 3.52 20.47 19.83 20.47h48.63c16.32 0 19.83-10.88 19.83-20.47v-13.44c0-9.59-3.52-19.51-19.83-19.51zM435.47 0c-16.32 0-19.83 10.88-19.83 20.47v182.99c0 9.6 3.52 20.47 19.83 20.47 16.32 0 19.83-10.88 19.83-20.47V20.47c0-9.59-3.52-20.47-19.83-20.47zM513.21 54.38h19.51c16.31 0 19.83-10.88 19.83-20.47V20.47c0-9.6-3.52-20.47-19.83-20.47h-48.63c-16.32 0-19.83 10.88-19.83 20.47v182.99c0 9.6 3.52 20.47 19.83 20.47h48.63c16.31 0 19.83-10.88 19.83-20.47v-13.44c0-9.6-3.52-19.51-19.83-19.51h-19.51c-7.68 0-9.28-5.12-9.28-9.6v-19.19c0-4.48 1.6-9.6 9.28-9.6h19.19c13.12 0 16.64-3.52 16.64-19.51 0-16-3.52-20.15-16.64-20.15h-19.19c-7.68 0-9.28-5.12-9.28-9.6V63.98c0-4.48 1.6-9.6 9.28-9.6zM367.01 0h-34.87c-16.32 0-19.83 10.88-19.83 20.47v182.99c0 9.6 3.52 20.47 19.83 20.47h34.87c30.31 0 39.67-20.06 39.67-41.9V41.9c0-21.84-9.36-41.9-39.67-41.9zm0 154.2c0 6.72-1.6 11.84-8.96 11.84h-6.08V57.9h6.08c7.36 0 8.96 5.12 8.96 11.84v84.46z' />
      </svg>
    </Box>
  )
}
