import styled from '@emotion/styled'
import { Container, Grid, LinearProgress } from '@mui/material'
import * as React from 'react'
import useStyles from './styles'
import { CONTAINER_WIDTH } from 'constants/index'

interface ProgressBarProps {
  step: number
  length: number
}

interface LinearProgressProps {
  step: number
  isCompleted: boolean
  isCurrent: boolean
}

const CustomLinearProgress = styled(LinearProgress)({
  '& .MuiLinearProgress-bar': {
    transition: 'transform .05s linear',
    WebkitTransition: 'transform .05s linear'
  }
})

function Linear({
  linear,
  step
}: {
  linear: LinearProgressProps
  step: number
}) {
  const classes = useStyles()

  const { isCompleted, isCurrent } = linear

  const [progress, setProgress] = React.useState(isCompleted ? 100 : 0)

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (isCurrent) {
        setProgress(oldProgress => {
          if (oldProgress >= 100) {
            return 100
          }
          return oldProgress + 2
        })
      } else {
        setProgress(0)
      }
    }, 100)

    return () => {
      clearInterval(timer)
      setProgress(0)
    }
  }, [step, isCurrent])

  return (
    <CustomLinearProgress
      variant='determinate'
      defaultValue={0}
      value={isCompleted ? 100 : progress}
      className={classes.linearProgress}
    />
  )
}

export default function ProgressBar({
  step,
  length
}: ProgressBarProps): JSX.Element {
  const classes = useStyles()

  const linearProgress: LinearProgressProps[] = Array.from(
    new Array(length)
  ).map((_, index) => ({
    step: index,
    isCompleted: step > index,
    isCurrent: step === index
  }))

  return (
    <Container className={classes.progressBar} maxWidth={CONTAINER_WIDTH}>
      {linearProgress.map(linear => (
        <Grid item xs={12 / length} key={linear.step}>
          <Linear linear={linear} step={step} />
        </Grid>
      ))}
    </Container>
  )
}
