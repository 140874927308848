import {
  Elements,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useAuth } from 'components/AuthProvider'
import { usePayments } from 'components/PaymentsProvider'
import { FormEvent } from 'react'
import useStyles from './styles'
import {
  namedOperations,
  useBuyVoucherMutation,
  useVerifyStripeTransactionMutation,
  useWalletForCurrentUserQuery
} from 'generated/graphql'
import { PaymentsResponse } from 'api/payments/types'
import { useHistory } from 'react-router-dom'
import { dashboardPath } from 'components/Routes'

interface CreditOrDebitCardProps {
  createTransaction?: () => void
  setDisabledButton: (disabled: boolean) => void
}

interface CreditOrDebitCheckoutProps extends CreditOrDebitCardProps {
  payment: PaymentsResponse
}

const CreditOrDebitCheckout = ({
  createTransaction,
  setDisabledButton,
  payment
}: CreditOrDebitCheckoutProps): JSX.Element => {
  const classes = useStyles()

  const stripe = useStripe()
  const elements = useElements()
  const history = useHistory()

  const { review_data, payment_intent } = payment ?? {}

  const { setToast } = useAuth()

  const [verifyStripeTransaction] = useVerifyStripeTransactionMutation()

  const [buyVoucher] = useBuyVoucherMutation()

  const { refetch } = useWalletForCurrentUserQuery({ skip: true })

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setDisabledButton(true)

    if (!stripe) return
    if (!elements) return

    const {
      data: {
        walletForCurrentUser: { balance }
      }
    } = await refetch()

    if (Number(balance) < Number(review_data.wallet_use_amount)) {
      return setToast({
        message: 'Your wallet balance has changed, please try again',
        open: true,
        type: 'error',
        duration: 3000
      })
    }

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required'
    })

    if (error) {
      setToast({
        message: error.message as string,
        open: true,
        type: 'error',
        duration: 3000
      })
      setDisabledButton(false)
    } else {
      const { data } = await verifyStripeTransaction({
        variables: {
          input: {
            paymentIntentIdentifier: payment_intent?.id as string
          }
        }
      })

      if (data?.verifyStripeTransaction?.success) {
        if (createTransaction) {
          createTransaction()
        } else {
          await buyVoucher({
            variables: {
              input: {
                paymentIntentId: payment.payment_intent?.id as string,
                toEmail: review_data?.to_email,
                toName: review_data?.to_name,
                amount: Number(review_data?.voucher_amount)
              }
            },
            onCompleted: () => {
              setToast({
                message: 'Your gift it on its way 💝',
                open: true,
                type: 'success',
                duration: 3000
              })
              history.push(dashboardPath())
            },
            onError: error => {
              setToast({
                message: error.message,
                open: true,
                type: 'error',
                duration: 3000
              })
            },
            refetchQueries: [namedOperations.Query.walletForCurrentUser],
            awaitRefetchQueries: true
          })
        }
      }
    }
  }

  return (
    <form
      className={classes.form}
      id='credit-or-debit-form'
      data-testid='credit-or-debit-form'
      onSubmit={handleSubmit}
    >
      <PaymentElement id='credit-or-debit-form-payment-element' />
    </form>
  )
}

export default function CreditOrDebitCard(
  props: CreditOrDebitCardProps
): JSX.Element | null {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_CLIENT_PUBLIC_KEY ?? ''
  )

  const { payment } = usePayments()

  if (payment === undefined) {
    return null
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret: payment.payment_intent?.client_secret }}
    >
      <CreditOrDebitCheckout {...props} payment={payment} />
    </Elements>
  )
}
