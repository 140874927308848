import { formattedPrice } from 'utils/util'

import { CurrencySuperscript } from 'components/Superscript'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  a11y: {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    width: '1px',
    margin: '-1px',
    padding: 0,
    border: 0
  }
}))

export interface Currency {
  symbol: string
  label: string
  code: string
}

interface Currencies {
  [key: string]: Currency
}

export const currencies: Currencies = {
  NZD: {
    symbol: '$',
    label: 'New Zealand Dollars',
    code: 'NZD'
  }
}
const defaultCurrency = 'NZD'

export interface CurrencyProps {
  amount: number | string
  code?: string
  isSuperscript?: boolean
  suffix?: string
}

export default function Currency({
  amount,
  code = defaultCurrency,
  isSuperscript = false,
  suffix
}: CurrencyProps): JSX.Element {
  const classes = useStyles()

  const parsedAmount: number =
    typeof amount === 'string' ? parseFloat(amount.replace(/,/g, '')) : amount
  const formattedAmount: string = formattedPrice(amount)

  const renderCurrencyCode = (): JSX.Element => {
    return (
      <abbr className={classes.a11y} title={currencies[code].label}>
        {code}
      </abbr>
    )
  }

  return (
    <span>
      {Math.sign(parsedAmount) === -1 ? '-' : ''}
      {isSuperscript ? (
        <CurrencySuperscript
          text={
            <>
              {formattedAmount}
              {renderCurrencyCode()}
            </>
          }
          suffix={suffix}
        />
      ) : (
        <>
          <span>
            {currencies[code].symbol}
            {formattedAmount}
            {suffix}
          </span>
          {renderCurrencyCode()}
        </>
      )}
    </span>
  )
}
