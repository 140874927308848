import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  title: {
    textTransform: 'uppercase',
    textIndent: '3.5rem',
    color: theme.palette.primary.main,
    fontWeight: 700
  },
  map: {
    margin: theme.spacing(0, 0.5, 3.75)
  },
  divider: {
    borderColor: theme.palette.divider,
    margin: theme.spacing(0, 5)
  },

  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.9)',
      boxShadow: `0 0 0 0 ${theme.palette.primary.main}`
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0  10px rgba(255, 82, 82, 0)'
    },
    '100%': {
      transform: 'scale(0.9)',
      boxShadow: '0 0 0 0 rgba(255, 82, 82, 0)'
    }
  },

  circle: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',

    background: theme.palette.primary.main,
    animation: '$pulse 2s infinite'
  }
}))
