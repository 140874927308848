import { User } from 'types'
import axios from 'utils/axios'

export enum AuthorizationStatus {
  OK = 'ok',
  Unauthorized = 'unauthorized',
  VerifyOtp = 'verify_otp'
}

export interface LoginParams {
  email: string
  password: string
  remember_me: boolean
  recaptcha_token?: string
}

export interface VerifyOtpParams {
  recaptcha_token?: string
  otp_attempt: string
}

export interface LoginResponse {
  otp_user_id?: number
  remember_me?: boolean
  status: AuthorizationStatus
}

export interface VerifyOtpResponse {
  status: AuthorizationStatus
  user?: User
}

export async function login(params: LoginParams): Promise<LoginResponse> {
  const { recaptcha_token, ...user } = params

  const response = await axios.post<LoginResponse>('/login', {
    user,
    recaptcha_token
  })

  return response.data
}

export async function verifyOtp(
  params: VerifyOtpParams
): Promise<VerifyOtpResponse> {
  const { recaptcha_token, ...user } = params

  const response = await axios.post<VerifyOtpResponse>('/login', {
    user,
    recaptcha_token
  })

  return response.data
}

export async function logout(): Promise<null> {
  const response = await axios.delete('/logout')

  return response.data
}
