import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    zIndex: 1,
    padding: theme.spacing(0),

    '& > section': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      margin: theme.spacing(4, 0)
    },

    '& .MuiStepLabel-iconContainer': {
      zIndex: 3,

      '& > svg': {
        width: '16px',
        height: '16px',

        '& > text': {
          fill: theme.palette.white.main
        }
      }
    },

    '& .Mui-active': {
      color: `${theme.palette.primary.main} !important`
    },

    '& #step-1': {
      '& > span': {
        alignItems: 'flex-start'
      },

      '& .MuiStepLabel-label': {
        marginTop: theme.spacing(1),
        fontSize: '12px',
        whiteSpace: 'nowrap',
        textAlign: 'start',
        color: '#C4C4C4'
      }
    },

    '& #step-2': {
      '& > span': {
        alignItems: 'center'
      },

      '& .MuiStepConnector-root': {
        zIndex: 2,
        top: 7,
        left: 'calc(-82%)',
        right: 'calc(54%)'
      },

      '& .MuiStepLabel-label': {
        marginTop: theme.spacing(1),
        fontSize: '12px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        color: '#C4C4C4'
      }
    },

    '& #step-3': {
      '& > span': {
        alignItems: 'flex-end'
      },

      '& .MuiStepConnector-root': {
        zIndex: 2,
        top: 7,
        left: 'calc(-45%)',
        right: 'calc(18%)'
      },

      '& .MuiStepLabel-label': {
        marginTop: theme.spacing(1),
        fontSize: '12px',
        whiteSpace: 'nowrap',
        textAlign: 'end',
        color: '#C4C4C4'
      }
    }
  },
  header: {
    width: '100%',
    margin: theme.spacing(2, 0, 3),

    '& > h1': {
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: '3rem',
      color: theme.palette.primary.main
    }
  },
  stepLabel: {
    fontSize: '16px',

    '& svg': {
      color: '#EAEAEA'
    }
  }
}))
