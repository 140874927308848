import { Box, Grid, Typography } from '@mui/material'
import { PaymentsParams, ReviewPaymentData } from 'api/payments/types'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button/Button'
import DepositDetail from 'components/DepositDetail'
import { usePayments } from 'components/PaymentsProvider'
import LoadingWrapper from 'components/layout/LoadingWrapper/LoadingWrapper'
import { ITransactionForm } from 'components/pages/VoucherConfirm/VoucherConfirm'
import { useCreatePoliDirectDebitMutation } from 'generated/graphql'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useLocation } from 'react-router-dom'
import CreditOrDebitCard from './CreditOrDebitCard'
import useStyles from './styles'

const DEFAULT_WALLET_USE_AMOUNT = 1

interface TransactionButtonsProps {
  price: number
  setDisabledButton: (disabled: boolean) => void
  createTransaction?: () => void
  paymentParams?: PaymentsParams
  handleCreatePaymentSuccess?: (payment: ReviewPaymentData) => void
  loading?: boolean
  setTransactionForm?: (params: ITransactionForm | null) => void
}

enum EActivatedButton {
  CreditOrDebit = 'CreditOrDebit',
  POLiTransfer = 'POLiTransfer',
  BankTransfer = 'BankTransfer'
}

const TransactionButtons = ({
  price,
  setDisabledButton,
  createTransaction,
  paymentParams,
  handleCreatePaymentSuccess,
  loading,
  setTransactionForm
}: TransactionButtonsProps): JSX.Element => {
  const classes = useStyles()

  const { user, setToast } = useAuth()

  const location = useLocation()

  const [activatedButton, setActivatedButton] =
    useState<EActivatedButton | null>(null)

  const { createPayment, loading: paymentLoading, error } = usePayments()

  useEffect(() => {
    if (error) {
      setToast({
        open: true,
        duration: 3000,
        type: 'error',
        message: error
      })
    }
  }, [error, setToast])

  const [createPoliDirectDebit, { loading: createPoliDirectDebitLoading }] =
    useCreatePoliDirectDebitMutation({
      onCompleted: data => {
        if (data.createPoliDirectDebit?.navigationUrl) {
          window.location.replace(data.createPoliDirectDebit?.navigationUrl)
        }
      },
      onError: error => {
        setToast({
          open: true,
          duration: 3000,
          type: 'error',
          message: error.message
        })
      }
    })

  const firstTimeTopUpWithPoli = useMemo(() => {
    return Boolean(user?.firstTimeTopUpWithPoli)
  }, [user])

  const loadingButton = useMemo(() => {
    return paymentLoading || createPoliDirectDebitLoading || Boolean(loading)
  }, [createPoliDirectDebitLoading, loading, paymentLoading])

  const creditFee = useMemo(() => {
    const balanceInWallet =
      price < 1 ? price + DEFAULT_WALLET_USE_AMOUNT : price

    const formulaFee: number =
      (balanceInWallet +
        Number(process.env.REACT_APP_STRIPE_PROCESSING_FEE_CHARGE)) /
      (1 - Number(process.env.REACT_APP_STRIPE_PROCESSING_FEE_PERCENTAGE) / 100)

    return Number(formulaFee - balanceInWallet).toFixed(2)
  }, [price])

  const poliFee = useMemo(() => {
    // Include a 1% fee, capped at 4$.
    return firstTimeTopUpWithPoli ? Number(Math.min(0.01 * price, 3)) : 0
  }, [firstTimeTopUpWithPoli, price])

  const recaptchaEnabled = JSON.parse(
    process.env.REACT_APP_RECAPTCHA_ENABLED || 'true'
  )

  const { executeRecaptcha } = useGoogleReCaptcha()

  const scrollToBottom = useCallback((timer?: number, selectorStr?: string) => {
    const selector = selectorStr ?? 'body'
    setTimeout(() => {
      const bodyElem = document.querySelector(selector)
      if (bodyElem) {
        bodyElem.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'end'
        })
      }
    }, timer ?? 500)
  }, [])

  const handleClickButton = (type: EActivatedButton) => {
    setActivatedButton(type)
    setDisabledButton(true)
    if (type === EActivatedButton.BankTransfer && setTransactionForm) {
      setTransactionForm(null)
    }
  }

  const handleClickPOLiButton = async () => {
    setDisabledButton(true)
    setActivatedButton(EActivatedButton.POLiTransfer)

    if (paymentParams && paymentParams.payment_type === 'top_up_to_wallet') {
      localStorage.setItem(
        'url_after_POLi_transfer',
        location.pathname + location.search
      )
    } else if (paymentParams && paymentParams.payment_type === 'buy_voucher') {
      localStorage.setItem('voucher_form', JSON.stringify(paymentParams))
    }

    await createPoliDirectDebit({
      variables: {
        input: {
          amount: price + poliFee
        }
      }
    })
  }

  const handleCreatePayment = async () => {
    if (!executeRecaptcha && recaptchaEnabled) return

    try {
      const recaptchaToken =
        recaptchaEnabled && executeRecaptcha
          ? await executeRecaptcha('payments/create')
          : undefined

      createPayment(
        {
          amount: paymentParams?.amount?.toString() ?? price.toString(),
          payment_type: paymentParams?.payment_type ?? 'top_up_to_wallet',
          note: paymentParams?.note,
          toEmail: paymentParams?.toEmail,
          toName: paymentParams?.toName,
          stripe_token: paymentParams?.stripe_token
        },
        recaptchaToken,
        res => {
          setActivatedButton(EActivatedButton.CreditOrDebit)
          setDisabledButton(false)
          scrollToBottom(1000, '#buy-confirmation-button')
          if (handleCreatePaymentSuccess) {
            handleCreatePaymentSuccess(res.review_data)
          }
        }
      )
    } catch (e) {
      return
    }
  }

  const renderPaymentMethods = (activatedButton: EActivatedButton | null) => {
    switch (activatedButton) {
      case EActivatedButton.CreditOrDebit:
        scrollToBottom(2000, '#buy-confirmation-button')
        return (
          <CreditOrDebitCard
            createTransaction={createTransaction}
            setDisabledButton={setDisabledButton}
          />
        )

      case EActivatedButton.BankTransfer:
        scrollToBottom()
        return <DepositDetail />

      default:
        return <></>
    }
  }

  const getClassButton = (typeButton: EActivatedButton) => {
    return activatedButton === typeButton
      ? classes.wrapperActivatedBtn
      : classes.wrapperBtn
  }

  const getColorButton = (typeButton: EActivatedButton) => {
    return activatedButton === typeButton ? 'primary' : 'default'
  }

  return (
    <LoadingWrapper loading={loadingButton} centred>
      <Grid container mt={1} spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Button
            fullWidth
            color={getColorButton(EActivatedButton.CreditOrDebit)}
            type='button'
            onClick={handleCreatePayment}
            disabled={loadingButton}
            className={getClassButton(EActivatedButton.CreditOrDebit)}
          >
            <Box>
              <Typography className={classes.titleBtn}>
                Credit or debit card
              </Typography>
              <Typography className={classes.textFee}>
                plus PROCESSING FEE, +${creditFee}
              </Typography>
            </Box>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            color={getColorButton(EActivatedButton.POLiTransfer)}
            type='button'
            onClick={handleClickPOLiButton}
            disabled={loadingButton}
            className={getClassButton(EActivatedButton.POLiTransfer)}
          >
            <Box>
              <Typography className={classes.titleBtn}>
                Poli transfer
              </Typography>
              <Typography className={classes.textFee}>
                Plus PROCESSING FEE, +${poliFee.toFixed(2)}, INSTANT DEPOSIT
              </Typography>
            </Box>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            color={getColorButton(EActivatedButton.BankTransfer)}
            type='button'
            onClick={() => handleClickButton(EActivatedButton.BankTransfer)}
            disabled={loadingButton}
            className={getClassButton(EActivatedButton.BankTransfer)}
          >
            <Box>
              <Typography className={classes.titleBtn}>
                Bank transfer
              </Typography>
              <Typography className={classes.textFee}>
                NO PROCESSING FEE, DEPOSIT 2-4 BUSINESS DAYS
              </Typography>
            </Box>
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Box>{renderPaymentMethods(activatedButton)}</Box>
        </Grid>
      </Grid>
    </LoadingWrapper>
  )
}

export default TransactionButtons
