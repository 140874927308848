import { useAuth } from 'components/AuthProvider'
import {
  DataPoint,
  GraphPeriodType,
  buildDataHistoricalHoldingBalances
} from 'components/PerformanceGraph'
import {
  useGetHistoricalPerformanceQuery,
  useProductPerformanceQuery
} from 'generated/graphql'
import useProducts from 'hooks/useProducts'
import useVault from 'hooks/useVault/useVault'
import { useEffect, useMemo, useState } from 'react'
interface Props {
  productId?: number
  graphPeriod: GraphPeriodType
}

export function usePerformanceGraph({ graphPeriod }: Props) {
  const { activatedVaultId } = useVault()

  const { products } = useProducts()

  const { user } = useAuth()

  const defaultVaultId = useMemo(() => {
    return user?.vaults[0].id
  }, [user?.vaults])

  const [totalProductPerformanceToday, setTotalProductPerformanceToday] =
    useState<number>(0)

  const {
    loading: loadingProductPerformance,
    refetch: fetchProductPerformance
  } = useProductPerformanceQuery({ skip: true })

  const { data: historicalPerformance, loading } =
    useGetHistoricalPerformanceQuery({
      variables: {
        timeframe: graphPeriod,
        productIds: null,
        vaultIds: activatedVaultId
          ? [Number(activatedVaultId)]
          : [Number(defaultVaultId)]
      }
    })

  useEffect(() => {
    products.forEach(({ id }) => {
      fetchProductPerformance({ productId: Number(id) }).then(({ data }) => {
        const { productPerformance } = data
        setTotalProductPerformanceToday(prev =>
          prev
            ? Number(prev) + Number(productPerformance.currentValue)
            : Number(productPerformance.currentValue)
        )
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const builtData: DataPoint[] = useMemo(() => {
    return buildDataHistoricalHoldingBalances({
      data: historicalPerformance,
      productPriceToday: totalProductPerformanceToday,
      graphPeriod
    })
  }, [graphPeriod, historicalPerformance, totalProductPerformanceToday])

  return {
    builtData,
    loading: loading || loadingProductPerformance
  }
}

export default usePerformanceGraph
