import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material'
import Button from 'components/Button'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  code: {
    fontFamily: 'monospace',
    fontWeight: 'bold',
    textAlign: 'center'
  }
}))

export interface BackupCodesModalProps {
  isOpen: boolean
  onClose: () => void
  backupCodes: string[]
}

export default function BackupCodesModal({
  isOpen,
  onClose,
  backupCodes
}: BackupCodesModalProps): JSX.Element {
  const classes = useStyles()

  const handleDownloadBackupCodes = () => {
    const element = document.createElement('a')
    const data = new Blob([backupCodes.join('\n')], { type: 'text/plain' })
    element.href = URL.createObjectURL(data)
    element.download = 'goldie-backup-codes.txt'
    document.body.appendChild(element)
    element.click()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby='dialog'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='dialog' sx={{ textAlign: 'center' }}>
        Good as gold! Authenticator app enabled
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Next time you're asked for a one-time password, use your authenticator
          app to generate one.
        </DialogContentText>
        <DialogContentText sx={{ mb: 2 }}>
          You can also choose to optionally download the following backup codes.
          These can be used if you ever lose your device. Ensure you download
          them and make sure you keep them in a vault place.
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {backupCodes.map(code => (
              <Typography className={classes.code} key={code}>
                {code}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              color='primary'
              onClick={handleDownloadBackupCodes}
              fullWidth
            >
              Download
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button color='blue' fullWidth onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
