import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  form: {
    width: '100%',

    '& .MuiFilledInput-root': {
      '&:has(textarea)': {
        padding: theme.spacing(2, 2.25),
        background: 'none'
      },

      '& textarea': {
        padding: theme.spacing(0)
      }
    }
  },
  countUp: {
    color: theme.palette.primary.main
  }
}))
