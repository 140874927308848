import * as React from 'react'
import classNames from 'classnames'
import { Stack, Typography, Grid } from '@mui/material'

/** hooks */
import { ProductPerformanceQuery } from 'generated/graphql'

/** utils */
import { formattedPrice } from 'utils/util'

/** styles */
import useStyles from './styles'

type Props = {
  data?: ProductPerformanceQuery
  currentValue: string
}

export default function GridValue(props: Props) {
  const { data, currentValue } = props
  const classes = useStyles()

  const totalReturn = Number(
    data?.productPerformance?.totalReturn || 0
  ).toFixed(2)
  const totalCost = Number(data?.productPerformance?.totalCost || 0).toFixed(2)
  const totalReturnAsPercentage = formattedPrice(
    data?.productPerformance?.totalReturnAsPercentage || 0
  )
  const ownedAllotmentsCount =
    data?.productPerformance?.ownedAllotmentsCount || 0
  const periodInvestedInDays =
    data?.productPerformance?.periodInvestedInDays || 0

  const getTime = () => {
    switch (true) {
      case periodInvestedInDays < 30:
        return `${periodInvestedInDays} day`
      case periodInvestedInDays < 365:
        return `${Math.floor(periodInvestedInDays / 30)} month`
      default:
        const year = Math.floor(periodInvestedInDays / 365)
        const month = Math.floor((periodInvestedInDays - year * 365) / 30)
        return `${year} year, ${month} month`
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Stack
          className={classNames(classes.gridValueItem, classes.gridValueItem1)}
        >
          <Typography>CURRENT VALUE:</Typography>
          <Typography>${currentValue}</Typography>
        </Stack>
      </Grid>

      <Grid item xs={6}>
        <Stack
          className={classNames(classes.gridValueItem, classes.gridValueItem2)}
        >
          <Typography>PERCENTAGE RETURN:</Typography>
          <Typography>{totalReturnAsPercentage}%</Typography>
        </Stack>
      </Grid>

      <Grid item xs={6}>
        <Stack
          className={classNames(classes.gridValueItem, classes.gridValueItem2)}
        >
          <Typography>TOTAL RETURN:</Typography>
          <Typography>
            {isNaN(Number(totalReturn)) ? 0 : totalReturn}
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={6}>
        <Stack
          className={classNames(classes.gridValueItem, classes.gridValueItem1)}
        >
          <Typography>QUANTITY OWNED:</Typography>
          <Typography>
            {ownedAllotmentsCount ? Number(ownedAllotmentsCount) / 10 : 0}g
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={6}>
        <Stack
          className={classNames(classes.gridValueItem, classes.gridValueItem1)}
        >
          <Typography>AMOUNT INVESTED:</Typography>
          <Typography>${isNaN(Number(totalCost)) ? 0 : totalCost}</Typography>
        </Stack>
      </Grid>

      <Grid item xs={6}>
        <Stack
          className={classNames(classes.gridValueItem, classes.gridValueItem1)}
        >
          <Typography>PERIOD INVESTED:</Typography>
          <Typography>{getTime()}</Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}
