import { Typography } from '@mui/material'

export default function TradingPaused(): JSX.Element {
  return (
    <Typography
      textAlign='center'
      my={24}
      color={theme => theme.palette.disabled.contrastText}
    >
      Trading is currently offline, hang tight, we will be back online soon
    </Typography>
  )
}
