import {
  Box,
  Breakpoint,
  Container,
  CssBaseline,
  SxProps,
  Theme,
  Typography
} from '@mui/material'

import { useAuth } from 'components/AuthProvider'

import React from 'react'
import BaseLayout, { BaseLayoutProps } from '../BaseLayout'
import useStyles from './styles'
import LoadingWrapper from '../LoadingWrapper'

export interface Props {
  BackgroundComponent?: React.ComponentType
  BreadcrumbsComponent?: React.ComponentType
  children: React.ReactNode
  containerMaxWidth?: Breakpoint
  heading?: string | JSX.Element | React.ReactChild
  wrapperContainer?: SxProps<Theme>
  wrapperContent?: SxProps<Theme>
  loading?: boolean
  loadingAnimation?: boolean
  pageTitle?: string
}

export function DefaultLayout({
  BackgroundComponent,
  BreadcrumbsComponent,
  children,
  heading,
  wrapperContent,
  loading,
  loadingAnimation
}: Readonly<Props>): JSX.Element {
  const { user } = useAuth()

  const classes = useStyles()

  return (
    <React.Fragment>
      {BackgroundComponent ? <BackgroundComponent /> : null}

      <CssBaseline />
      <Box
        component='section'
        sx={{
          mt: 6,
          px: 2,
          ...wrapperContent
        }}
      >
        {user && !!BreadcrumbsComponent ? <BreadcrumbsComponent /> : null}
        {heading ? (
          typeof heading === 'string' ? (
            <Typography variant='h1' component='h1' className={classes.heading}>
              {heading}
            </Typography>
          ) : (
            <Box width='100%'>{heading}</Box>
          )
        ) : null}
        <LoadingWrapper
          loading={Boolean(loading)}
          animation={Boolean(loadingAnimation)}
          centred
        >
          <>{children}</>
        </LoadingWrapper>
      </Box>
    </React.Fragment>
  )
}

export default ({
  children,
  ...rest
}: Props & BaseLayoutProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Container
      component='main'
      className={classes.container}
      maxWidth={false}
      sx={rest.wrapperContainer}
    >
      <BaseLayout {...rest}>
        <DefaultLayout {...rest}>{children}</DefaultLayout>
      </BaseLayout>
    </Container>
  )
}
