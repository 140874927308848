import { Box, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useAuth } from 'components/AuthProvider'
import DefaultLayout from 'components/layout/DefaultLayout'
import LoadingWrapper from 'components/layout/LoadingWrapper'
import { POLiTransferStatusPath } from 'components/Routes/Routes'
import { useRequestPoliTransactionVerificationMutation } from 'generated/graphql'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: '3rem',
    margin: theme.spacing(4, 0)
  },
  loading: {
    margin: 'auto',
    color: theme.palette.primary.main
  }
}))

export default function POLiTransferLoading(): JSX.Element {
  const classes = useStyles()

  const { setToast } = useAuth()

  const { search } = useLocation()

  const history = useHistory()

  const transactionToken = new URLSearchParams(search).get('token') as string

  const [requestPoliTransactionVerification, { loading }] =
    useRequestPoliTransactionVerificationMutation()

  useEffect(() => {
    requestPoliTransactionVerification({
      variables: {
        input: {
          transactionToken
        }
      },
      onCompleted: data => {
        history.push({
          pathname: POLiTransferStatusPath(),
          state: {
            status: data.requestPoliTransactionVerification?.transactionStatus
          }
        })
      },
      onError: error =>
        setToast({
          message: error.message,
          open: true,
          duration: 3000,
          type: 'error'
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionToken])

  return (
    <DefaultLayout
      wrapperContainer={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }}
    >
      <Box>
        <Typography variant='h1' component='h1' className={classes.header}>
          POLi TRANSFER LOADING
        </Typography>
        <LoadingWrapper className={classes.loading} loading={loading}>
          <div />
        </LoadingWrapper>
      </Box>
    </DefaultLayout>
  )
}
