import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, Grid, Typography } from '@mui/material'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import {
  buyCelebrationPath,
  dashboardPath,
  verifyPath
} from 'components/Routes'
import SwitchVault from 'components/SwitchVault/SwitchVault'
import CustomTooltip from 'components/Tooltip/Tooltip'
import TradingPaused from 'components/TradingPaused/TradingPaused'
import TransactionButtons from 'components/TransactionButtons/TransactionButtons'
import {
  DEBOUNCE_INTERVAL,
  REVIEW_ORDER_EXPIRED,
  TRADING_IS_PAUSED,
  patternOneDigitsAfterComma
} from 'constants/index'
import { useFormik } from 'formik'
import {
  ReviewOrderQuery,
  Vault,
  namedOperations,
  useCreateMarketTransactionMutation,
  useUpdateReviewOrderForBuyMutation,
  useUpdateReviewOrderWithVaultMutation
} from 'generated/graphql'
import useDebounce from 'hooks/useDebounce'
import { localProductsData } from 'hooks/useProducts'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { roundUpDollarsAndCents } from 'utils/util'
import * as Yup from 'yup'
import FormattedInputs from '../MyVault/ReviewSell/InputNumber'
import { PORTFOLIO_PERFORMANCE_FOR_CURRENT_USER } from '../TradeProduct/queries'
import useStyles from './styles'
import DefaultLayout from 'components/layout/DefaultLayout'
export interface Props {
  reviewOrderData?: ReviewOrderQuery
  reviewOrderId: string
  reviewOrderLoading?: boolean
}

export const FEE_PLAN_TYPES: { [key: string]: string } = {
  INSURANCE: 'Standard Insurance Fee',
  TRANSACTION: 'Standard Transaction Fee'
}

interface FormValues {
  amount: string
  weight: number
  spotPrice?: number
}

const validationSchema = Yup.object().shape({
  amount: Yup.string(),
  weight: Yup.number()
    .required('Weight (grams) is required field')
    .test('is-decimal', 'Specify grams to buy to 1 decimal place', value => {
      if (value !== undefined) {
        return patternOneDigitsAfterComma.test(value.toString())
      }
      return true
    })
})

export default function ReviewPurchase({
  reviewOrderData,
  reviewOrderId,
  reviewOrderLoading
}: Readonly<Props>): JSX.Element {
  const classes = useStyles()
  const history = useHistory()
  const { user } = useAuth()
  const { setToast } = useAuth()

  // States
  const [tradingPaused, setTradingPaused] = useState<boolean>(false)
  const [helperTextAmountInput, setHelperTextAmountInput] = useState<string>('')

  const [disabledButton, setDisabledButton] = useState<boolean>(true)

  const {
    fees,
    totalPriceWithFees,
    totalPriceWithoutFees,
    weight,
    // user,
    pricePerUnit,
    vault,
    product,
    spotPrice
  } = reviewOrderData?.reviewOrder ?? {}

  const fetchLocalProductData = () => {
    if (product?.name) {
      return localProductsData[product.name.toLowerCase()]
    } else {
      return {
        shortDescription: '',
        image: '',
        descriptionParagraphs: ''
      }
    }
  }

  const walletBalanceOfUser = useMemo(() => {
    return Number(user?.wallet?.balance)
  }, [user?.wallet?.balance])

  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  // all auto generate hook functions
  const [updateReviewOrder, { data: updatedReviewOrder }] =
    useUpdateReviewOrderForBuyMutation()

  const [updateReviewOrderWithVault, { loading }] =
    useUpdateReviewOrderWithVaultMutation()

  const [createMarketTransaction, { loading: creatingMarketTransaction }] =
    useCreateMarketTransactionMutation({
      onCompleted: () => {
        setDisabledButton(false)
        setToast({
          open: true,
          message: `Good as ${product?.name}! Market transaction created.`,
          type: 'success',
          duration: 3000
        })
        setTradingPaused(false)
        history.push({
          pathname: buyCelebrationPath({
            routeParams: { productId: product?.id }
          }),
          state: { weight: Number(weight) / 10, productId: product?.id }
        })
      },
      onError: error => {
        setDisabledButton(false)
        if (
          error.graphQLErrors[0].extensions?.['error_status'] ===
          TRADING_IS_PAUSED
        ) {
          setTradingPaused(true)
        }
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        })
        if (
          error.graphQLErrors[0].extensions?.['error_status'] ===
          REVIEW_ORDER_EXPIRED
        ) {
          history.push(dashboardPath())
        }
      },
      // Refetches the current user (so that the correct vault and
      // wallet details are displayed on the Dashboard)
      refetchQueries: [
        namedOperations.Query.getCurrentUser,
        {
          query: PORTFOLIO_PERFORMANCE_FOR_CURRENT_USER,
          variables: { productId: product?.id }
        }
      ],
      variables: {
        input: {
          reviewOrderId: parseInt(reviewOrderId),
          vaultId: vault ? Number(vault?.id) : null
        }
      }
    })

  // useForm hook
  const {
    values,
    errors,
    touched,
    setTouched,
    setValues,
    setErrors,
    setFieldValue,
    handleChange,
    handleBlur
  } = useFormik({
    initialValues: {
      amount: totalPriceWithoutFees
        ? Number(totalPriceWithoutFees).toFixed(2)
        : '',
      weight: weight ? Number(weight) / 10 : 0
    },
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    onSubmit: (values: FormValues) => {},
    enableReinitialize: true
  })

  // I still keep the debounce interval is 4 second
  // but I think it is very slow (in my opinion)
  const debouncedWeight = useDebounce<number>(values.weight, DEBOUNCE_INTERVAL)
  const debouncedAmount = useDebounce<string>(values.amount, DEBOUNCE_INTERVAL)

  const handleChangeWeight = (weight: number, rounded?: boolean) => {
    updateReviewOrder({
      variables: {
        input: {
          reviewOrderId: Number(reviewOrderId),
          weight: parseFloat(Number(weight * 10).toFixed(2))
        }
      },
      onError: error => setErrors({ weight: error.message, amount: undefined }),
      onCompleted: data => {
        const { totalPriceWithoutFees, weight, spotPrice } =
          data.reviewOrder || {}
        setValues({
          amount: totalPriceWithoutFees
            ? roundUpDollarsAndCents(Number(totalPriceWithoutFees)).toString()
            : '0',
          weight: weight ? Number(weight) / 10 : 0,
          spotPrice
        })
        setErrors({ weight: undefined, amount: undefined })
        setTouched({ amount: false, weight: false })
        if (
          Number(values.amount) !==
            Number(Number(totalPriceWithoutFees).toFixed(2)) &&
          rounded
        ) {
          setHelperTextAmountInput(
            `We've rounded $${values.amount} to ${roundUpDollarsAndCents(
              Number(totalPriceWithoutFees)
            ).toString()}`
          )
        }
      }
    })
  }

  // Side Effect to Call API when change weight/amount (using useDebounce)
  useEffect(() => {
    if (!errors.weight && touched.weight) {
      handleChangeWeight(values.weight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedWeight])

  useEffect(() => {
    if (!errors.amount && touched.amount) {
      const weight = calculateDecigramsRoundedDown(Number(values.amount))
      handleChangeWeight(weight, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAmount])

  // render total / fees / price
  const total = useMemo(() => {
    if (updatedReviewOrder) {
      return updatedReviewOrder?.reviewOrder?.totalPriceWithFees
    }
    return totalPriceWithFees
  }, [totalPriceWithFees, updatedReviewOrder])

  const topUpPrice = useMemo(() => {
    let price = 0

    if (updatedReviewOrder) {
      price = roundUpDollarsAndCents(
        Number(updatedReviewOrder?.reviewOrder?.totalPriceWithFees) -
          walletBalanceOfUser
      )
    } else {
      price = roundUpDollarsAndCents(
        Number(reviewOrderData?.reviewOrder?.totalPriceWithFees) -
          walletBalanceOfUser
      )
    }

    return price < 1 ? 1 : price
  }, [
    reviewOrderData?.reviewOrder?.totalPriceWithFees,
    updatedReviewOrder,
    walletBalanceOfUser
  ])

  const isShowTopUp = useMemo(() => {
    const totalPriceWithFees =
      Number(
        updatedReviewOrder
          ? updatedReviewOrder?.reviewOrder?.totalPriceWithFees
          : reviewOrderData?.reviewOrder?.totalPriceWithFees
      ) - walletBalanceOfUser

    setDisabledButton(roundUpDollarsAndCents(totalPriceWithFees) > 0)
    return roundUpDollarsAndCents(totalPriceWithFees) > 0
  }, [
    reviewOrderData?.reviewOrder?.totalPriceWithFees,
    updatedReviewOrder,
    walletBalanceOfUser
  ])

  const transactionFee = useMemo(() => {
    if (updatedReviewOrder) {
      return Number(
        updatedReviewOrder?.reviewOrder?.fees?.find(
          fee => fee.plan.name === FEE_PLAN_TYPES.TRANSACTION
        )?.fee ?? 0
      ).toFixed(2)
    }
    return Number(
      fees?.find(fee => fee.plan.name === FEE_PLAN_TYPES.TRANSACTION)?.fee ?? 0
    ).toFixed(2)
  }, [fees, updatedReviewOrder])

  const handleConfirm = async () => {
    if (!disabledButton) {
      const paymentForm = document.getElementById(
        'credit-or-debit-form'
      ) as HTMLFormElement
      if (paymentForm) {
        paymentForm.requestSubmit()
      } else {
        localStorage.removeItem('url_after_POLi_transfer')
        await createMarketTransaction()
      }
    }
  }

  // change vault function
  const handleChangeVault = async (id: number | null): Promise<Vault> => {
    const { data } = await updateReviewOrderWithVault({
      variables: {
        input: {
          reviewOrderId: Number(reviewOrderId),
          vaultId: id
        }
      }
    })

    return data?.updateReviewOrderWithVault?.vault as Vault
  }

  // all calculate price functions
  const calculateDecigramsRoundedDown = useCallback(
    (amount: number) =>
      amount > 0
        ? parseFloat((Math.floor(amount / Number(spotPrice)) / 10).toFixed(1))
        : 0,
    [spotPrice]
  )

  const calculateDecigrams = useCallback(
    (amount: number) =>
      amount > 0
        ? parseFloat((amount / Number(pricePerUnit) / 10).toFixed(1))
        : 0,
    [pricePerUnit]
  )

  const handleChangeWeightInput = (
    e: Event | ChangeEvent<HTMLInputElement>,
    value?: number | number[]
  ) => {
    setTouched({ amount: false, weight: true })
    handleChange(e)
    const amount =
      Number(value ?? (e.target as HTMLInputElement).value) *
      Number(pricePerUnit) *
      10
    setFieldValue('amount', amount.toFixed(2))
  }

  const handleChangeAmountInput = (e: ChangeEvent<HTMLInputElement>) => {
    setTouched({ amount: true, weight: false })
    handleChange(e)
    const weight = calculateDecigrams(Number(e.target.value))
    setFieldValue('weight', weight)
  }

  const handleIncreaseAmount = useCallback(() => {
    if (!loading) {
      const amount = Number(values.amount) + Number(pricePerUnit)
      const weight = calculateDecigrams(amount)
      setTouched({ amount: false, weight: true })
      setValues({ amount: amount.toFixed(2).toString(), weight })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricePerUnit, values.amount, loading])

  const handleDecreaseAmount = useCallback(() => {
    if (!loading) {
      const amount = Number(values.amount) - Number(pricePerUnit)
      const weight = calculateDecigrams(amount)
      setTouched({ amount: false, weight: true })
      setValues({
        amount: amount > 0 ? amount.toFixed(2).toString() : '0',
        weight
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricePerUnit, values.amount, loading])

  const nextStepButtons = () => {
    const showIsNotVerified =
      user &&
      !user?.profile?.onboarded &&
      !user?.profile?.partialIdentityVerified

    if (showIsNotVerified) {
      return (
        <Grid item mt={5}>
          <Button
            fullWidth
            variant='outlined'
            type='submit'
            onClick={() => {
              history.push(verifyPath())
            }}
          >
            Verify and buy
          </Button>
        </Grid>
      )
    } else if (isShowTopUp) {
      return (
        <Grid item>
          <Grid
            item
            container
            justifyContent='space-between'
            alignItems='flex-start'
            flexWrap='nowrap'
            mt={2}
          >
            <Typography variant='h6' color='primary'>
              Top up
            </Typography>
            <Box textAlign='right'>
              <Typography variant='h6' color='primary'>
                ${Number(topUpPrice).toFixed(2)}
              </Typography>
            </Box>
          </Grid>
          <TransactionButtons
            price={topUpPrice}
            setDisabledButton={setDisabledButton}
            createTransaction={createMarketTransaction}
            loading={creatingMarketTransaction}
            paymentParams={{
              amount: topUpPrice.toString(),
              payment_type: 'top_up_to_wallet'
            }}
          />
          <Grid item>
            <Button
              disabled={disabledButton}
              fullWidth
              type='button'
              id='buy-confirmation-button'
              onClick={handleConfirm}
              style={{ marginBottom: '2rem' }}
            >
              Confirm Purchase
            </Button>
          </Grid>
        </Grid>
      )
    } else if (!disabledButton) {
      return (
        <Grid item mt={5}>
          <Button
            fullWidth
            variant='outlined'
            type='submit'
            onClick={handleConfirm}
          >
            Confirm Purchase
          </Button>
        </Grid>
      )
    } else {
      return <></>
    }
  }

  if (tradingPaused) {
    return <TradingPaused />
  }

  return (
    <DefaultLayout
      pageTitle='Checkout'
      loading={reviewOrderLoading || creatingMarketTransaction}
      loadingAnimation
    >
      <Box px={10} height='100vh'>
        <Box
          className={classes.background}
          style={{
            backgroundImage: `url('${fetchLocalProductData().image}')`
          }}
        />
      </Box>
      <Grid px={2} className={classes.root}>
        <Grid
          item
          mt={4}
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <ArrowBackIcon color='primary' fontSize='large' onClick={goBack} />
          <Typography>Check we have it right</Typography>
        </Grid>

        <Grid item mt={2} alignItems='flex-start'>
          <Typography>CHOOSE VAULT:</Typography>
          <SwitchVault
            handleActive={handleChangeVault}
            loading={loading}
            text=''
          />
        </Grid>

        <Grid
          item
          container
          justifyContent='space-between'
          alignItems='center'
          flexWrap='nowrap'
        >
          <Grid item xs={6}>
            <Typography>{product?.name}</Typography>
            <Typography>{fetchLocalProductData()?.shortDescription}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box onClick={handleIncreaseAmount} textAlign='right'>
              <KeyboardArrowUpIcon fontSize='large' color='primary' />
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='flex-end'
            >
              <Box textAlign='right'>
                <FormattedInputs
                  name='amount'
                  value={values.amount}
                  onChange={handleChangeAmountInput}
                  onBlur={handleBlur}
                />
              </Box>
              <Box textAlign='right'>
                <input
                  className={classes.amountInput}
                  name='weight'
                  value={values.weight}
                  onChange={handleChangeWeightInput}
                  onBlur={handleBlur}
                  type='number'
                />
                <Typography
                  component='span'
                  color={({ palette }) => palette.primary.main}
                >
                  g
                </Typography>
              </Box>
            </Box>
            <Box onClick={handleDecreaseAmount} textAlign='right'>
              <KeyboardArrowDownIcon fontSize='large' color='primary' />
            </Box>
          </Grid>
        </Grid>

        {helperTextAmountInput && (
          <Grid item>
            <Typography align='right' variant='body2' color='primary'>
              {helperTextAmountInput}
            </Typography>
          </Grid>
        )}

        <Grid item my={2} container justifyContent='space-between'>
          <Typography>Wallet balance</Typography>
          <Typography>${walletBalanceOfUser.toFixed(2)}</Typography>
        </Grid>

        <Grid
          item
          container
          justifyContent='space-between'
          alignItems='flex-start'
          flexWrap='nowrap'
        >
          <Typography variant='h5' color='primary'>
            TOTAL
          </Typography>
          <Box textAlign='right'>
            <Typography variant='h5' color='primary'>
              ${Number(total ?? 0).toFixed(2)}
            </Typography>
            <Typography variant='body2' color='primary'>
              Includes 0.89% transaction fee, +${transactionFee}
            </Typography>
            <Box display='flex' justifyContent='flex-end'>
              <CustomTooltip
                Icon={<Typography variant='body2'>What's this?</Typography>}
                title='
                This is the buy price. 
                It is the price the market will sell at currently. 
                It is slightly different to the current mid-price or sell price.
                '
                placement='bottom'
              />
            </Box>
          </Box>
        </Grid>

        {nextStepButtons()}
      </Grid>
    </DefaultLayout>
  )
}
