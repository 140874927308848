import { useCallback, useEffect, useState } from 'react'

import { useAuth } from 'components/AuthProvider'
import { useUpdatePasswordMutation } from 'generated/graphql'

import {
  VisibilityRounded as VisibilityIcon,
  VisibilityOffRounded as VisibilityOffIcon
} from '@mui/icons-material'
import {
  Grid,
  IconButton,
  InputAdornment,
  Theme,
  Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import Button from 'components/Button'
import DefaultLayout from 'components/layout/DefaultLayout'
import PasswordProgress from 'components/PasswordProgress'
import { Field, FieldProps, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

interface FormValues {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

const INITIAL_VALUES: FormValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
}

const VALIDATION_SCHEMA = Yup.object().shape({
  currentPassword: Yup.string().required('Current password is required'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('newPassword'), null], `Confirm password doesn't match`)
})

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  }
}))

export default function ChangePassword(): JSX.Element {
  const classes = useStyles()
  const [passwordChangeRequested, setPasswordChangeRequested] =
    useState<boolean>(false)
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<{
    currentPassword: boolean
    confirmPassword: boolean
  }>({ currentPassword: false, confirmPassword: false })

  const { user, maybeRequestOtp, setToast } = useAuth()

  const handleToggleShowPassword = (
    field: 'currentPassword' | 'confirmPassword'
  ) => setShowPassword(prev => ({ ...prev, [field]: !prev[field] }))

  const [updatePassword, { loading }] = useUpdatePasswordMutation({
    // TODO: Error handling
    onError: error =>
      setToast({
        open: true,
        message: error.message,
        type: 'error',
        duration: 3000
      }),
    onCompleted: () =>
      setToast({
        open: true,
        message: 'Good as gold! Your password has been changed',
        type: 'success',
        duration: 2000
      })
  })

  useEffect(() => {
    if (passwordChangeRequested && user?.otpVerified) {
      setPasswordChangeRequested(false)
      updatePassword({ variables: { input: { currentPassword, newPassword } } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordChangeRequested, user])

  const handleSubmit = async (values: FormValues) => {
    const { currentPassword, newPassword } = values

    setCurrentPassword(currentPassword)
    setNewPassword(newPassword)
    if (user?.otpVerified) {
      updatePassword({ variables: { input: { currentPassword, newPassword } } })
    } else {
      setPasswordChangeRequested(true)
      maybeRequestOtp()
    }
  }

  const renderHeader = useCallback(() => {
    return (
      <Typography component='h1' variant='h1' fontSize='4.5rem' color='primary'>
        Change password
      </Typography>
    )
  }, [])

  return (
    <DefaultLayout
      backgroundColor='default'
      heading={renderHeader()}
      showBackIcon
      loading={loading}
    >
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({ setFieldValue }) => (
          <Form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field name='currentPassword' autoComplete='current-password'>
                  {({ field, form, meta }: FieldProps) => (
                    <TextField
                      field={field}
                      form={form}
                      meta={meta}
                      fullWidth
                      disabled={false}
                      label='Current password'
                      required
                      onChange={e => {
                        setFieldValue('currentPassword', e.target.value)
                      }}
                      InputProps={{
                        style: { background: '#ffffff' },
                        // This is where the toggle 'view password' button is added
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={() =>
                                handleToggleShowPassword('currentPassword')
                              }
                              size='large'
                              tabIndex={-1}
                            >
                              {showPassword.currentPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      sx={{ background: 'none' }}
                      type={showPassword.currentPassword ? 'text' : 'password'}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <PasswordProgress
                  autoComplete='new-password'
                  label='New password'
                  required={true}
                  onChange={(password: string, hasError?: boolean) => {
                    setFieldValue('newPassword', password)
                    if (hasError) setDisableButton(true)
                    else setDisableButton(false)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field name='confirmPassword' autoComplete='new-password'>
                  {({ field, form, meta }: FieldProps) => (
                    <TextField
                      field={field}
                      form={form}
                      meta={meta}
                      disabled={false}
                      fullWidth
                      label='Confirm password'
                      required
                      onChange={e => {
                        setFieldValue('confirmPassword', e.target.value)
                      }}
                      InputProps={{
                        style: { background: '#ffffff' },
                        // This is where the toggle 'view password' button is added
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={() =>
                                handleToggleShowPassword('confirmPassword')
                              }
                              size='large'
                              tabIndex={-1}
                            >
                              {showPassword.confirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      sx={{ background: 'none' }}
                      type={showPassword.confirmPassword ? 'text' : 'password'}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sx={{ mt: 4, textAlign: 'center' }}>
                <Button
                  fullWidth
                  color='primary'
                  disabled={disableButton}
                  type='submit'
                >
                  Change
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </DefaultLayout>
  )
}
