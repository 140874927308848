import CloseIcon from '@mui/icons-material/Close'
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation'
import { Box, Tooltip, tooltipClasses, TooltipProps, Zoom } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { ReactElement, ReactNode, useState } from 'react'

interface CustomTooltipProps {
  placement?: TooltipProps['placement']
  title: NonNullable<React.ReactNode>
  iconSize?: 'inherit' | 'large' | 'medium' | 'small'
  isHover?: boolean
  Icon?:
    | string
    | typeof React.Component
    | ReactElement
    | ReactNode
    | JSX.Element
  showCloseIcon?: boolean
  arrow?: boolean
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.black.main,
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(0.5),

    minHeight: '3rem',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1)
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.white.main,
    marginBottom: theme.spacing(1.5),

    '&::before': {
      border: `1px solid ${theme.palette.primary.main}`
    }
  }
}))

export default function CustomTooltip(props: CustomTooltipProps): JSX.Element {
  const {
    placement = 'top',
    title,
    iconSize = 'large',
    Icon = <NotListedLocationIcon color='primary' fontSize={iconSize} />,
    isHover = false,
    showCloseIcon = true,
    arrow = true
  } = props

  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const renderTooltipTitle = () => {
    return (
      <Box display='flex' justifyContent='space-between' minWidth='18rem'>
        <Typography>{title}</Typography>
        {showCloseIcon && (
          <CloseIcon
            fontSize='small'
            color='primary'
            sx={{ cursor: 'pointer' }}
            onClick={handleClose}
          />
        )}
      </Box>
    )
  }

  return isHover ? (
    <HtmlTooltip
      arrow={arrow}
      title={renderTooltipTitle()}
      placement={placement}
      disableFocusListener
      disableTouchListener
    >
      <Box>{Icon}</Box>
    </HtmlTooltip>
  ) : (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <HtmlTooltip
          arrow
          title={renderTooltipTitle()}
          placement={placement}
          onClose={handleClose}
          open={open}
          TransitionComponent={Zoom}
          PopperProps={{
            disablePortal: true
          }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <Box onClick={handleOpen} sx={{ cursor: 'pointer', display: 'flex' }}>
            {Icon}
          </Box>
        </HtmlTooltip>
      </Box>
    </ClickAwayListener>
  )
}
