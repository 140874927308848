interface Props {
  color?: string
}

export default function Add({ color = '#ED008B' }: Props): JSX.Element {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={34} height={34} fill='none'>
      <circle
        cx={16.617}
        cy={16.611}
        r={11.098}
        stroke={color}
        transform='rotate(45 16.617 16.61)'
      />
      <path stroke={color} d='M24.508 16.646H8.315m8.334 8.081V8.097' />
    </svg>
  )
}
