import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
  accordion: {
    background: theme.palette.background.paper,
    margin: theme.spacing(1, 0, 2),
    '&::before': {
      display: 'none'
    }
  },
  accordionSummary: {
    borderRadius: '16px',

    '& > div': {
      margin: theme.spacing(2, 0)
    },

    '& .Mui-expanded': {
      margin: theme.spacing(2, 0)
    }
  },
  expandIcon: {
    color: theme.palette.black.main,
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  expandIconExpanded: {
    transform: 'rotate(180deg)'
  },
  divider: {
    borderColor: '#E6E6E6',
    margin: theme.spacing(1.25, 0, 1.5)
  },
  activityTotal: {
    textAlign: 'right'
  },
  descriptionList: {
    display: 'block',
    width: '100%',
    padding: theme.spacing(0),

    '& dt, &dd': {
      padding: theme.spacing(0),
      margin: theme.spacing(0)
    },
    '& dt': {
      float: 'left',
      clear: 'left',
      marginRight: theme.spacing(0.5)
    },
    '& dd': {
      float: 'right',
      clear: 'right',
      textAlign: 'right'
      // marginRight: theme.spacing(4)
    }
  },

  activities: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px'
  }
}))
