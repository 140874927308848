import FormField from 'components/formik/FormField'
import { FormikErrors, useFormikContext } from 'formik'
import {
  AmlVerificationAnswerOption,
  AmlVerificationQuestion
} from 'generated/graphql'
import { useEffect } from 'react'
import * as Yup from 'yup'
import { FormValues } from '../OnboardingWizard'

interface CommentFormFieldProps {
  identifier: AmlVerificationQuestion['identifier']
  optionId: AmlVerificationAnswerOption['identifier']
  errors: FormikErrors<FormValues>
}

export default function CommentFormField({
  identifier,
  optionId,
  errors
}: CommentFormFieldProps) {
  const { registerField, isSubmitting } = useFormikContext()

  useEffect(() => {
    registerField(`comments.${identifier}.${optionId}`, {
      validate: function (v) {
        const error = Yup.string()
          .required('Other field is required')
          .validate(v)
        return error
          .then(() => {
            return null
          })
          .catch(error => {
            return error.message
          })
      }
    })

    return () => {
      registerField(`comments.${identifier}.${optionId}`, {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        validate: function () {}
      })
    }
  }, [identifier, optionId, registerField])

  return (
    <FormField
      name={`comments.${identifier}.${optionId}`}
      required
      placeholder='Please elaborate...'
      sx={{ ml: 4, width: '90%' }}
      error={!!errors.comments?.[identifier]?.[optionId]}
      helperText={errors.comments?.[identifier]?.[optionId]}
      disabled={isSubmitting}
    />
  )
}
