import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { BugsnagErrorBoundary } from '@bugsnag/plugin-react'
import '@stripe/stripe-js' // Injects the Stripe script as soon as the StripeProvider is rendered
import { render } from 'react-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import client from 'config/apolloClient'
import reportWebVitals from 'utils/reportWebVitals'
import theme from 'utils/theme'

import { ApolloProvider } from '@apollo/client'
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider
} from '@mui/material/styles'
import AppRoot from 'components/AppRoot'
import { AuthProvider } from 'components/AuthProvider'
import { StrictMode } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
// TODO: Do we actually need the serviceworker? What is it being used for?
// import * as serviceWorker from './serviceWorker'

import './index.css'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient()

Bugsnag.start({
  appVersion: process.env.REACT_APP_VERSION,
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY as string,
  enabledReleaseStages: ['dev', 'production'],
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE
})

const ErrorBoundary: BugsnagErrorBoundary | undefined =
  Bugsnag.getPlugin('react')?.createErrorBoundary(React)

render(
  <StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
    >
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <AuthProvider>
                <AppRoot ErrorBoundary={ErrorBoundary} />
              </AuthProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
// serviceWorker.register()
