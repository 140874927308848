import { RefObject, useState, useEffect, useMemo } from 'react'

export default function useOnScreen(ref: RefObject<HTMLElement>): boolean {
  const [isIntersecting, setIntersecting] = useState<boolean>(false)

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    []
  )

  useEffect(() => {
    const currentRef = ref.current
    if (!currentRef) return

    observer.observe(currentRef)
    return () => {
      observer.unobserve(currentRef)
    }
  }, [observer, ref])

  return isIntersecting
}
