export default function BuyVoucher(): JSX.Element {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='40' height='40' rx='20' fill='#D60078' />
      <path
        d='M27.9697 18H11.9697V26C11.9697 29 12.9697 30 15.9697 30H23.9697C26.9697 30 27.9697 29 27.9697 26V18Z'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M29.5 15V16C29.5 17.1 28.97 18 27.5 18H12.5C10.97 18 10.5 17.1 10.5 16V15C10.5 13.9 10.97 13 12.5 13H27.5C28.97 13 29.5 13.9 29.5 15Z'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.6398 13.0014H14.1198C13.7798 12.6314 13.7898 12.0614 14.1498 11.7014L15.5698 10.2814C15.9398 9.91141 16.5498 9.91141 16.9198 10.2814L19.6398 13.0014Z'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.8696 13.0014H20.3496L23.0696 10.2814C23.4396 9.91141 24.0496 9.91141 24.4196 10.2814L25.8396 11.7014C26.1996 12.0614 26.2096 12.6314 25.8696 13.0014Z'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
