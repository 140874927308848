import { RouteComponentProps } from 'react-router-dom'
import { verifyPersonalDetailsPath } from 'components/Routes'
import Button from 'components/Button'
import { Grid, Typography } from '@mui/material'

export type GetStartedProps = RouteComponentProps

export default function GetStarted({ history }: GetStartedProps): JSX.Element {
  function handleClickNext() {
    history.push(verifyPersonalDetailsPath())
  }

  return (
    <>
      <Typography my={3} variant='h6'>
        &emsp;NZ has strict policies and processes to keep you and your
        investment safe. Part of this means we need to match your ID with
        details held by the New Zealand Government so that you can use the
        platform safely and securely.
      </Typography>

      <Typography my={3} variant='h6'>
        &emsp;We’ll ask you a few personal investor behavior questions so that
        we can comply with our legal obligations. You can update your details at
        any time, see our privacy policy for more information, or email us at{' '}
        <a href='mailto:support@getgoldie.co.nz'>support@getgoldie.co.nz</a> if
        you have any questions.
      </Typography>

      <Grid mt={8} mb={6}>
        <Button
          color='primary'
          variant='outlined'
          fullWidth
          onClick={handleClickNext}
          type='button'
        >
          Start
        </Button>
      </Grid>
    </>
  )
}
