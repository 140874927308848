export default function CreditCard(): JSX.Element {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='40' height='40' rx='20' fill='#FF9457' />
      <path
        d='M10 16.5039H30'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 24.5039H16'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.5 24.5039H22.5'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.44 11.5039H25.55C29.11 11.5039 30 12.3839 30 15.8939V24.1039C30 27.6139 29.11 28.4939 25.56 28.4939H14.44C10.89 28.5039 10 27.6239 10 24.1139V15.8939C10 12.3839 10.89 11.5039 14.44 11.5039Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
