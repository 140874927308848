import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Typography
} from '@mui/material'
import PasswordField from 'components/PasswordField'
import React, { useState } from 'react'
import theme from 'utils/theme'
import zxcvbn from 'zxcvbn'

export interface PasswordProgressProps {
  autoComplete: string
  label: string
  required?: boolean
  onChange: (password: string, hasError?: boolean) => void
}

export default function PasswordProgress(
  props: PasswordProgressProps
): JSX.Element {
  const { autoComplete, label, required = false, onChange } = props

  const [passwordProgress, setPasswordProgress] = useState<{
    strength: number
    suggestions: string[]
    score: number
  }>({
    strength: 0,
    suggestions: [],
    score: 0
  })
  const [error, setError] = useState<string>('')

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputPassword = zxcvbn(e.target.value)
    onChange(e.target.value, inputPassword.score < 3)
    if (inputPassword.score < 3) {
      setError(inputPassword.feedback.suggestions[0])
      setPasswordProgress(prev => ({
        ...prev,
        strength: inputPassword.guesses_log10 * 8,
        suggestions: inputPassword.feedback.suggestions.join(' ').split('. '),
        score: inputPassword.score
      }))
    } else {
      setError('')
      switch (inputPassword.score) {
        case 3:
          return setPasswordProgress(prev => ({
            ...prev,
            strength: 70,
            score: inputPassword.score,
            suggestions: ['Nice 👍']
          }))
        case 4:
          return setPasswordProgress(prev => ({
            ...prev,
            strength: 100,
            score: inputPassword.score,
            suggestions: ['Excellent 🔒']
          }))
      }
    }
  }

  const handleBlurInput = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    setError(prev => (!e.target.value ? `${label} is required` : prev))
  }

  return (
    <React.Fragment>
      <PasswordField
        autoComplete={autoComplete}
        fullWidth={true}
        label={label}
        required={required}
        error={!!error}
        helperText={!passwordProgress.strength && error ? error : ''}
        sx={{ background: 'none' }}
        onChange={handleChangeInput}
        onBlur={handleBlurInput}
      />
      {passwordProgress.strength > 0 && (
        <Box sx={{ marginTop: '8px' }}>
          <LinearProgress
            variant='determinate'
            value={passwordProgress.strength}
            sx={{
              height: 8,
              borderRadius: 5,
              [`& .${linearProgressClasses.bar}`]: {
                background:
                  passwordProgress.score >= 3
                    ? theme.palette.green.main
                    : theme.palette.error.main
              }
            }}
          />

          {passwordProgress.suggestions.map((suggestion: string, index) => (
            <Typography
              key={index}
              sx={{
                color:
                  passwordProgress.score >= 3
                    ? theme.palette.black.main
                    : theme.palette.error.main,
                fontSize: 12,
                fontWeight: 400,
                margin: theme.spacing(0.5, 1.75, 0)
              }}
            >
              {suggestion}
            </Typography>
          ))}
        </Box>
      )}
    </React.Fragment>
  )
}
