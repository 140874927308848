import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    '& button': {
      '&:hover': {
        '& p': {
          color: theme.palette.white.main
        }
      }
    }
  },
  wrapperBtn: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',

    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: 'transparent',
    '& p': {
      color: theme.palette.primary.main
    },

    '&:hover': {
      background: `${theme.palette.primary.main} !important`
    }
  },
  wrapperActivatedBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    '& p': {
      color: theme.palette.white.main
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& p': {
        color: theme.palette.white.main
      }
    }
  },
  titleBtn: {
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: 0.75,
    fontFamily: theme.typography.button.fontFamily
  },
  textFee: {
    fontSize: '0.7rem',
    textTransform: 'none'
  },
  accountGroup: {
    padding: theme.spacing(2, 2.5),
    backgroundColor: theme.palette.background.paper
  },
  form: {
    width: '100%',
    margin: theme.spacing(1, 0, 4),

    '& h2': {
      color: theme.palette.default.contrastText
    }
  }
}))
