import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Variant } from '@mui/material/styles/createTypography'
import React from 'react'
import CountUp from 'react-countup'
import { formattedPrice } from 'utils/util'

const useStyles = makeStyles((theme: Theme) => ({
  positiveChange: {
    color: theme.palette.status.positive
  },
  negativeChange: {
    color: theme.palette.status.negative
  }
}))

interface CountUpPriceProps {
  prefix?: string | JSX.Element
  suffix?: string
  value: number
  className?: string
  showColor?: boolean
  showBracket?: boolean
  duration?: number
  variant?: Variant
  color?: string
}

function CountUpPrice(props: CountUpPriceProps): JSX.Element {
  const {
    prefix,
    suffix,
    value,
    className,
    showColor,
    showBracket,
    duration,
    variant,
    color
  } = props
  const classes = useStyles()

  const showColorCondition = (): string => {
    if (showColor) {
      return Math.sign(value) === -1
        ? classes.negativeChange
        : classes.positiveChange
    }
    return ''
  }

  const showPrefix = (
    amount: number,
    prefixSymbol?: string | JSX.Element
  ): string => {
    if (prefixSymbol && typeof prefixSymbol === 'string') {
      return Math.sign(amount) === -1 ? `-${prefixSymbol}` : prefixSymbol
    }
    return Math.sign(amount) === -1 ? `-` : ''
  }

  const showSuffix = (suffixSymbol?: string): string => {
    return suffixSymbol ?? ''
  }

  const formatPrice = (price: number): string => {
    const formattedValue = formattedPrice(price)
    const prefixString = showPrefix(value, prefix)
    const suffixString = showSuffix(suffix)
    return `${prefixString}${formattedValue}${suffixString}`
  }

  return (
    <Typography
      component='div'
      variant={variant}
      className={showColorCondition()}
      color={color}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      {showBracket && (
        <Typography className={className} color='inherit'>
          (
        </Typography>
      )}
      {prefix && typeof prefix !== 'string' && prefix}
      <CountUp
        start={0}
        decimals={2}
        end={value}
        className={className}
        formattingFn={formatPrice}
        duration={duration ?? 1}
      />
      {showBracket && (
        <Typography className={className} color='inherit'>
          )
        </Typography>
      )}
    </Typography>
  )
}

export default React.memo(
  CountUpPrice,
  (prevProp, nextProp) => prevProp.value === nextProp.value
)
