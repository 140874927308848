import { Box, Typography } from '@mui/material'
import { useAuth } from 'components/AuthProvider'
import { dashboardPath, welcomePath } from 'components/Routes'
import fineGoldImage from 'images/Golden.png'
import catalist from 'images/catalist.png'
import video from 'images/handshake.mp4'
import investments_card from 'images/investments_card.png'
import { default as shadow, default as shadowImage } from 'images/shadow.png'
import slider_image_3 from 'images/slider_image_3.png'
import slider_image_4 from 'images/slider_image_4.png'
import { useHistory } from 'react-router'
import useStyles from './styles'

interface SliderProps {
  id: number
  heading?: string[]
  paragraph: string[]
  image: string
  shadow?: string
  video?: string
}

export const slider: SliderProps[] = [
  {
    id: 1,
    paragraph: [],
    image: investments_card
  },
  {
    id: 2,
    paragraph: [
      'Every KIWI now has access to real gold, at wholesale prices... without the middlemen'
    ],
    image: fineGoldImage,
    shadow: shadowImage
  },
  {
    id: 3,
    paragraph: [
      'WE’RE CHANGING THE FACE OF OWNERSHIP WITH REAL THINGS. JOIN THE REAL REVOLUTION'
    ],
    image: slider_image_3,
    shadow: shadowImage
  },
  {
    id: 4,
    paragraph: [
      'YOUR GOLD IS STORED IN NZ’s MOST SECURE VAULT & OUR INNOVATIVE TECH KEEPS YOUR DATA SAFE'
    ],
    image: slider_image_4
  },
  {
    id: 5,
    paragraph: [],
    image: '',
    shadow: shadow,
    video: video
  }
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function PreviousButton(props: any) {
  const classes = useStyles()
  return (
    <Box component='div' className={classes.previous} onClick={props.onClick} />
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function NextButton(props: any) {
  const classes = useStyles()
  const history = useHistory()

  const { user } = useAuth()

  const handleClick = () => {
    const isVerified =
      user?.profile?.onboarded || user?.profile?.partialIdentityVerified
    if (props.onClick) {
      props.onClick()
    } else {
      history.push(isVerified ? dashboardPath() : welcomePath())
    }
  }
  return <Box component='div' className={classes.next} onClick={handleClick} />
}

export function StackDivVideo({ video }: { video: string }) {
  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-around'
        gap={4}
      >
        <Typography component='span' fontSize='60px' fontFamily='Moore'>
          Goldie
        </Typography>
        <Typography component='span' fontSize='23px'>
          X
        </Typography>
        <img src={catalist} alt='catalist' style={{ height: '23px' }} />
      </Box>
      <Box
        sx={{
          width: '265px',
          height: '382px',
          border: `6px solid black`,
          position: 'relative',
          overflow: 'hidden',
          mt: 4
        }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          width='100%'
          style={{
            background: 'black',
            backgroundSize: 'cover',
            position: 'absolute',
            inset: 0
          }}
        >
          <source src={video} type='video/mp4' />
        </video>
      </Box>
    </>
  )
}
