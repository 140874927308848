import {
  AppBar,
  Box,
  Breakpoint,
  IconButton,
  Link,
  Stack,
  SwipeableDrawer,
  Typography,
  useMediaQuery
} from '@mui/material'
import classNames from 'classnames'
import { useAuth } from 'components/AuthProvider'
import {
  autoInvestPath,
  contactPath,
  kidVaultPath,
  myVaultPath,
  redeemVoucherPath,
  settingsPath,
  tradePath,
  tutorialPath,
  verificationErrorPath,
  verificationPartialSuccessPath,
  verificationSuccessPath,
  verifyFinancialDetailsPath,
  verifyIdDetailsPath,
  verifyPath,
  verifyPersonalDetailsPath,
  verifyingPath,
  voucherFlowPath,
  walletPath,
  welcomePath
} from 'components/Routes/Routes'
import SwitchVault from 'components/SwitchVault'
import VerificationBanner from 'components/VerificationBanner'
import MenuIcon from 'components/icons/MenuIcon'
import LoadingWrapper from 'components/layout/LoadingWrapper'
import useProducts from 'hooks/useProducts'
import useVault from 'hooks/useVault/useVault'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import theme from 'utils/theme'
import { generateUsername } from 'utils/util'
import MenuList from './MenuList'
import useStyles from './styles'
import AddToHomeScreen from 'components/AddToHomeScreen'

interface MenuProps {
  containerMaxWidth?: Breakpoint
  showSecondNav?: boolean
  pageTitle?: string
  children: ReactElement
}

export type NavLinkType = {
  id: string
  path?: string
  href?: string
  text: string
  isVault?: boolean
  matchPath?: (path: string) => boolean
}

export type MenuType = NavLinkType & {
  secondaryMenu?: MenuType[]
  disable?: boolean
}

export default function Menu({
  children,
  containerMaxWidth,
  pageTitle
}: Readonly<MenuProps>) {
  const history = useHistory()
  const classes = useStyles()
  const { user, loading } = useAuth()
  const { activatedVault } = useVault()
  const { products } = useProducts()
  const location = useLocation()

  const goToHomePage = useCallback(() => history.push('/'), [history])

  const isLgView = useMediaQuery(theme.breakpoints.down('lg'))
  const isMdView = useMediaQuery(theme.breakpoints.up('md'))

  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    const header = document.getElementById('app-header')
    if (header) {
      if (open) {
        document.body.setAttribute(
          'style',
          'touch-action: none;-ms-touch-action: none;'
        )
      } else {
        document.body.setAttribute(
          'style',
          'touch-action: auto;-ms-touch-action: auto;'
        )
      }
    }
  }, [open])

  const onClose = useCallback(() => setOpen(false), [])
  const onOpen = useCallback(() => setOpen(true), [])
  const handleToggleMenu = useCallback(() => setOpen(!open), [open])

  const isShowLeftContent = useMemo(
    () => isMdView && !isLgView,
    [isLgView, isMdView]
  )

  const multiUser = useMemo(() => (user?.vaults?.length ?? 0) >= 2, [user])

  const listProduct = useMemo(() => {
    return products
      .map(product => ({
        id: product.id,
        path: tradePath({
          routeParams: {
            productId: product.id
          }
        }),
        text: product.tradable ? product.name : `${product.name} COMING SOON`,
        disable: product.disable
      }))
      .concat([
        {
          id: 'art',
          path: '',
          text: 'Art',
          disable: true
        },
        {
          id: 'collectibles',
          path: '',
          text: 'Collectibles',
          disable: true
        },
        {
          id: 'all',
          path: '',
          text: 'All',
          disable: false
        }
      ])
  }, [products])

  const NAV_LINKS: MenuType[] = useMemo(
    () => [
      {
        id: 'my_vault',
        path: multiUser ? undefined : myVaultPath(),
        text: multiUser ? 'Vaults' : 'My Vault',
        matchPath: path => {
          // match /trade, /dashboard
          return /^\/vault/.test(path)
        },
        secondaryMenu: multiUser
          ? user?.vaults?.map(item => ({
              id: item.id,
              path: myVaultPath(),
              text: `${item.name ?? generateUsername(user)}`,
              isVault: true
            }))
          : undefined
      },
      {
        id: 'trade',
        text: 'Trade',
        matchPath: path => {
          // match /trade, /dashboard
          return /^\/trade|^\/dashboard|^\/review/.test(path)
        },
        secondaryMenu: listProduct
      },
      {
        id: 'auto_invest',
        text: 'Auto Invest',
        path: autoInvestPath(),
        matchPath: path => {
          return /^\/auto_invest/.test(path)
        }
      },
      {
        id: 'gift',
        text: 'Gift',
        matchPath: path => {
          return /^\/account\/gifts/.test(path)
        },
        secondaryMenu: [
          {
            id: 'buy_gift',
            path: voucherFlowPath(),
            text: 'Buy Gift'
          },
          {
            id: 'Redeem Gift',
            path: redeemVoucherPath(),
            text: 'Redeem Gift'
          }
        ]
      },
      {
        id: 'account',
        text: 'Account',
        matchPath: path => {
          return /^\/account/.test(path)
        },
        secondaryMenu: [
          {
            id: 'setting',
            path: settingsPath(),
            text: 'Settings'
          },

          {
            id: 'wallet',
            path: walletPath(),
            text: 'Wallet'
          },
          {
            id: 'kid_vault',
            path: kidVaultPath(),
            text: 'Kids vault'
          },
          {
            id: 'tutorial_course',
            path: tutorialPath(),
            text: 'Tutorials/Courses'
          },
          {
            id: 'contact',
            path: contactPath(),
            text: 'Contact'
          }
        ]
      }
    ],
    [listProduct, multiUser, user]
  )

  const getPageTitleFromPath = () => {
    const nav_link = NAV_LINKS.find(nav_link => {
      return nav_link.matchPath ? nav_link.matchPath(location.pathname) : null
    })

    return nav_link?.text
  }

  const currentPageTitle = pageTitle ?? getPageTitleFromPath() ?? 'Goldie'

  const renderVerificationBanner = useCallback(() => {
    // NOTE: we don't want to render the verification banner when a user is
    // completing the verification/onboarding process
    const excludedPaths = [
      verificationErrorPath(),
      verificationSuccessPath(),
      verifyFinancialDetailsPath(),
      verifyIdDetailsPath(),
      verifyPath(),
      verifyPersonalDetailsPath(),
      verifyPersonalDetailsPath(),
      verifyingPath(),
      verificationPartialSuccessPath(),
      welcomePath()
    ]

    // only show when verified fail
    const isShowVerificationBanner =
      user &&
      !user?.profile?.onboarded &&
      !user?.profile?.partialIdentityVerified &&
      !excludedPaths.includes(location.pathname)

    return isShowVerificationBanner ? <VerificationBanner /> : null
  }, [user, location.pathname])

  const renderCredit = useCallback(() => {
    return (
      <>
        <Typography className={classes.kiaOra}>
          Kia Ora {activatedVault?.name}
        </Typography>
        <Typography className={classes.wallet}>
          Credit: ${Number(user?.wallet?.balance).toFixed(2)}
        </Typography>
      </>
    )
  }, [activatedVault, classes, user])

  const renderMobileMenu = useCallback(() => {
    return (
      <Box>
        <AppBar className={classes.appBar} id='app-header'>
          {!open && (
            <>
              {renderVerificationBanner()}
              <AddToHomeScreen
                isHasVerificationBanner={!!renderVerificationBanner()}
              />
            </>
          )}
          <Stack className={classes.header}>
            <Box flex={1}>
              <Link onClick={goToHomePage} sx={{ textDecoration: 'none' }}>
                <Typography
                  variant='h1'
                  component='h1'
                  color={theme.palette.ironSand.main}
                  fontSize='2.5rem'
                  textAlign='left'
                  className={classes.pageTitle}
                >
                  {currentPageTitle}
                </Typography>
              </Link>
            </Box>
            <Box display='flex' justifyContent='center'>
              <SwitchVault showCreateButton />
            </Box>
            <Box display='flex' justifyContent='flex-end'>
              <IconButton
                onClick={handleToggleMenu}
                className={classes.menuIconWrap}
              >
                <MenuIcon
                  className={classNames(classes.menuIcon, {
                    [classes.menuIconOpen]: open
                  })}
                />
              </IconButton>
            </Box>

            <SwipeableDrawer
              BackdropProps={{ className: classes.menuBackdrop }}
              ModalProps={{
                hideBackdrop: true,
                className: classes.menuModal
              }}
              PaperProps={{ className: classes.menuDrawer }}
              anchor='left'
              onClose={onClose}
              open={open}
              onOpen={onOpen}
              transitionDuration={200}
            >
              <Stack className={classes.wrap}>
                <Stack className={classes.menuLeft}>
                  <MenuList navLinks={NAV_LINKS} onClose={onClose} />
                </Stack>
              </Stack>
            </SwipeableDrawer>
          </Stack>
        </AppBar>
        {children}
      </Box>
    )
  }, [
    NAV_LINKS,
    children,
    classes,
    goToHomePage,
    handleToggleMenu,
    onClose,
    onOpen,
    open,
    renderVerificationBanner,
    currentPageTitle
  ])

  const renderDesktopMenu = useCallback(() => {
    return (
      <>
        {renderVerificationBanner()}
        <AddToHomeScreen
          isHasVerificationBanner={!!renderVerificationBanner()}
        />
        <Box id='menu' className={classes.container}>
          <Box>
            <Box className={classes.rightContent}>
              <Box display='flex' flexDirection='column' height='100%'>
                <Box pt={2} pl={2}>
                  <Link onClick={goToHomePage} sx={{ textDecoration: 'none' }}>
                    <Typography
                      variant='h1'
                      component='h1'
                      color={theme.palette.ironSand.main}
                      fontSize='2.5rem'
                      textAlign='left'
                      className={classes.pageTitle}
                    >
                      Goldie
                    </Typography>
                  </Link>
                </Box>
                <MenuList navLinks={NAV_LINKS} onClose={onClose} />
              </Box>
              {!isShowLeftContent && (
                <Box className={classes.stickyRightContent}>
                  {renderCredit()}
                </Box>
              )}
            </Box>
          </Box>

          <Box
            flex={isLgView && isMdView ? 1 : 3}
            maxWidth={containerMaxWidth}
            position='relative'
          >
            <LoadingWrapper loading={loading}>{children}</LoadingWrapper>
          </Box>

          {isShowLeftContent ? (
            <Box>
              <Box className={classes.leftContent}>{renderCredit()}</Box>
            </Box>
          ) : (
            <Box width={50} />
          )}
        </Box>
      </>
    )
  }, [
    NAV_LINKS,
    children,
    classes,
    containerMaxWidth,
    goToHomePage,
    isLgView,
    isMdView,
    isShowLeftContent,
    onClose,
    renderCredit,
    renderVerificationBanner,
    loading
  ])

  if (!user)
    return (
      <Box maxWidth={containerMaxWidth} m='0 auto'>
        {children}
      </Box>
    )

  return <>{isMdView ? renderDesktopMenu() : renderMobileMenu()}</>
}
