import { Grid, InputAdornment, TextField, Typography } from '@mui/material'
import BalanceCard from 'components/BalanceCard'
import Button from 'components/Button'
import { rechargeConfirmPath } from 'components/Routes'
import DefaultLayout from 'components/layout/DefaultLayout'
import { useFormik } from 'formik'
import { useHistory, useLocation } from 'react-router'
import * as Yup from 'yup'
import useStyles from './styles'
import { patternTwoDigitsAfterComma } from 'constants/index'

interface FormValues {
  amount: number
}

const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .required('Amount to transfer is required')
    .min(1, 'Amount must be greater than or equal to $1')
    .max(50000, 'Amount must be less than or equal to $50000')
    .test(
      'is-decimal',
      'The amount should be a decimal with maximum 2 digits after comma',
      value => {
        if (value !== undefined) {
          return patternTwoDigitsAfterComma.test(value.toString())
        }
        return true
      }
    )
})

export default function Recharge(): JSX.Element {
  const classes = useStyles()

  const { state } = useLocation<{ amount: number; fee: number }>()

  const history = useHistory()

  const { errors, touched, values, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        amount: state?.amount
          ? Math.max(Number(state?.amount.toFixed(2)), 10)
          : 10
      },
      validationSchema,
      onSubmit: (values: FormValues) => {
        history.push({
          pathname: rechargeConfirmPath(),
          state: {
            amount: values.amount
          }
        })
      }
    })

  return (
    <DefaultLayout
      backgroundColor='white'
      wrapperContent={{ mt: 2, px: 3 }}
      noLogo
      headerTitle='Recharge'
      heading={<BalanceCard />}
      showBackIcon
    >
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Grid container gap={1}>
          <Grid item xs={12} mt={3}>
            <Typography fontSize='18px' fontWeight={700} color='#1E1E1E'>
              Amount to transfer
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              fullWidth
              required
              type='number'
              id='amount'
              label='Amount to transfer'
              name='amount'
              className={classes.input}
              value={values.amount}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.amount && touched.amount)}
              helperText={
                Boolean(errors.amount && touched.amount) && errors.amount
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position='start'
                    style={{ marginTop: '10px' }}
                  >
                    $
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item container xs={12} mt={2} gap={2}>
            <Grid item xs={12}>
              <Button color='primary' type='submit' fullWidth>
                Recharge
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </DefaultLayout>
  )
}
