import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { FormEvent } from 'react'

import { dashboardPath } from 'components/Routes'
import { useAuth } from 'components/AuthProvider'
import {
  namedOperations,
  useRequestWithdrawFundsMutation,
  useWalletForCurrentUserQuery
} from 'generated/graphql'

import Button from 'components/Button'
import Currency from 'components/Currency'
import DefaultLayout from 'components/layout/DefaultLayout'
import OnboardingMessage from 'components/OnboardingMessage'
import { Grid, Link, TextField, Typography } from '@mui/material'

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  voucherFunds: {
    marginBottom: theme.spacing(2)
  },
  walletBalanceGroup: {
    margin: theme.spacing(2, 2),
    padding: theme.spacing(2, 2.5),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '1.25rem'
  }
}))

export type WithdrawFundsProps = RouteComponentProps

export default function WithdrawFunds({
  history
}: Readonly<WithdrawFundsProps>): JSX.Element {
  const classes = useStyles()

  const [amountToWithdraw, setAmountToWithdraw] = useState<string>('')
  const [isWithdrawalRequested, setIsWithdrawalRequested] =
    useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const { user, maybeRequestOtp, setToast } = useAuth()

  const { data, refetch } = useWalletForCurrentUserQuery()

  useEffect(() => {
    refetch()
  }, [refetch])

  const voucherBalance = useMemo(() => {
    const { walletForCurrentUser } = data ?? {}
    return walletForCurrentUser?.expirableWalletCreditBalance
      ? parseFloat(walletForCurrentUser.expirableWalletCreditBalance)
      : 0
  }, [data])

  const walletBalance = useMemo(() => {
    const { walletForCurrentUser } = data ?? {}
    return walletForCurrentUser?.walletBalance
      ? parseFloat(walletForCurrentUser.walletBalance)
      : 0
  }, [data])

  const isOnBoarded = useMemo(() => {
    const { profile } = user ?? {}
    return profile?.onboarded
  }, [user])

  const withdrawableAmount = useMemo(() => {
    const { walletForCurrentUser } = data ?? {}
    return walletForCurrentUser?.withdrawableAmount
      ? parseFloat(walletForCurrentUser.withdrawableAmount)
      : 0
  }, [data])

  const withheldFunds = useMemo(() => {
    const { walletForCurrentUser } = data ?? {}
    return walletForCurrentUser?.withheldFunds
      ? parseFloat(walletForCurrentUser.withheldFunds)
      : 0
  }, [data])

  const [requestWithdrawFunds, { loading: requestWithdrawFundsLoading }] =
    useRequestWithdrawFundsMutation({
      onCompleted: () => {
        setToast({
          open: true,
          message: 'Good as gold! Withdrawal requested.',
          type: 'success',
          duration: 3000
        })
        history.push(dashboardPath())
      },
      // TODO: error handling
      onError: error =>
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        }),
      // Refetches the current user so that the correct wallet balance details are displayed on the Dashboard
      refetchQueries: [namedOperations.Query.getCurrentUser]
    })

  // If the user is not `otpVerified` when they attempt to request a withdrawal, we set the `isWithdrawalRequested`
  // flag. Then, once the user becomes `otpVerified` by completing the OTP generation and verification flow,
  // we can reset this flag and attemp the withdrawal request again
  useEffect(() => {
    if (isWithdrawalRequested && user?.otpVerified) {
      setIsWithdrawalRequested(false)

      requestWithdrawFunds({
        variables: {
          input: {
            amountToWithdraw
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWithdrawalRequested, user])

  function handleChangeAmountToWithdraw(event: ChangeEvent<HTMLInputElement>) {
    setAmountToWithdraw(event.target.value)
    if (!event.target.value) setErrorMessage('Amount is required')
    else if (Number(event.target.value) > Number(walletBalance))
      setErrorMessage(`You don't have enough funds`)
    else setErrorMessage('')
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    // TODO: form validation and required fields, (once we have firmed up flow/designs)
    event.preventDefault()

    if (!amountToWithdraw) {
      return setErrorMessage('Amount is required')
    }

    if (user?.otpVerified) {
      requestWithdrawFunds({
        variables: {
          input: {
            amountToWithdraw
          }
        }
      })
    } else {
      setIsWithdrawalRequested(true)
      maybeRequestOtp()
    }
  }

  const noBankAccount = useMemo(() => !user?.profile?.bankAccountNumber, [user])

  const renderHeader = useCallback(() => {
    return (
      <Typography component='h1' variant='h1' fontSize='4.5rem' color='primary'>
        Withdraw funds
      </Typography>
    )
  }, [])

  return (
    <DefaultLayout
      backgroundColor='default'
      heading={renderHeader()}
      showBackIcon
    >
      {isOnBoarded ? (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.walletBalanceGroup}>
              <Typography component='h2' variant='h6'>
                Wallet balance
              </Typography>
              <Typography component='p' variant='h2'>
                <Currency amount={walletBalance} isSuperscript />
              </Typography>
              <Typography component='p'>
                Gifts balance: <Currency amount={voucherBalance} />
              </Typography>
            </Grid>
            {noBankAccount ? (
              <>
                <Grid item xs={12}>
                  <Typography>
                    We only process withdrawals to your verified bank account,
                    to add a verified bank account you can:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    1. make a deposit using the Bank Deposit or POLi method, or
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    2. send us an email to{' '}
                    <Link component='a' href='mailto:support@getgoldie.co.nz'>
                      support@getgoldie.co.nz
                    </Link>{' '}
                    with a copy of your bank statement showing your name and
                    bank account number
                  </Typography>
                </Grid>
              </>
            ) : null}
            <Grid item xs={12}>
              <TextField
                defaultValue={
                  user?.profile?.bankAccountNumber ??
                  'Deposit funds first to verify your bank account'
                }
                disabled
                fullWidth
                id='accountNumber'
                label='Account number'
                name='accountNumber'
                required
                type='text'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                id='amountToWithdraw'
                label='Amount'
                name='amountToWithdraw'
                onChange={handleChangeAmountToWithdraw}
                required
                // TODO: this doesn't actually seem to be a number - can type letters??
                // Also, restrict this to 2dp (and everywhere else)
                type='number'
                value={amountToWithdraw}
                error={!!errorMessage}
                helperText={errorMessage}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              <Typography>
                Withdrawable amount: ${withdrawableAmount}*
              </Typography>
            </Grid>
            <Grid item xs={12} my={2}>
              <Typography variant='body2' color='GrayText'>
                Withheld amount: ${withheldFunds}
              </Typography>
              <Typography variant='body2' color='GrayText'>
                Any promotional or referral credits can not be withdrawn for 12
                months from the date the credit was applied.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                color='primary'
                disabled={
                  requestWithdrawFundsLoading ||
                  !user?.profile?.bankAccountNumber ||
                  Number(amountToWithdraw) <= 0 ||
                  Number(amountToWithdraw) > withdrawableAmount
                }
                fullWidth
                type='submit'
              >
                Withdraw funds
              </Button>
            </Grid>
          </Grid>
        </form>
      ) : (
        <OnboardingMessage />
      )}
    </DefaultLayout>
  )
}
