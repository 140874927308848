import DefaultLayout from 'components/layout/DefaultLayout/DefaultLayout'
import TradingPaused from 'components/TradingPaused/TradingPaused'

export default function PauseTrading(): JSX.Element {
  return (
    <DefaultLayout heading='Trading Paused'>
      <TradingPaused />
    </DefaultLayout>
  )
}
