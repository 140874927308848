import { Divider, Grid, Slide, Typography } from '@mui/material'
import { ReviewPaymentData } from 'api/payments/types'
import Button from 'components/Button/Button'
import Currency from 'components/Currency'
import TransactionButtons from 'components/TransactionButtons/TransactionButtons'
import DefaultLayout from 'components/layout/DefaultLayout/DefaultLayout'
import { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { formattedPrice } from 'utils/util'
import useStyles from './styles'

export interface ITransactionForm {
  voucher_amount: string
  payment_amount: string
  fee: string
}

export default function VoucherConfirm(): JSX.Element {
  const classes = useStyles()

  const { state } = useLocation<ReviewPaymentData>()

  const { amount, voucher_amount, to_email, to_name, note } = state || {}

  const [disabledButton, setDisabledButton] = useState<boolean>(true)

  const [transactionForm, setTransactionForm] =
    useState<ITransactionForm | null>(null)

  const fromWalletBalanceTotal = useMemo(() => {
    const { voucher_amount, payment_amount, fee } = transactionForm ?? {}
    return Number(voucher_amount) - Number(payment_amount) + Number(fee)
  }, [transactionForm])

  const creditCardTotal = useMemo(() => {
    const { payment_amount, fee } = transactionForm ?? {}
    return Number(payment_amount) - Number(fee)
  }, [transactionForm])

  const total = useMemo(() => {
    const { voucher_amount, fee } = transactionForm ?? {}
    return Number(voucher_amount) + Number(fee)
  }, [transactionForm])

  const formInformation = [
    {
      label: 'From Wallet Balance',
      value: fromWalletBalanceTotal,
      unit: '$'
    },
    {
      label: 'Credit Card',
      value: creditCardTotal,
      unit: '$'
    },
    {
      label: 'CC fee of',
      value: transactionForm?.fee,
      unit: '$'
    }
  ]

  const handleConfirm = useCallback(async () => {
    if (!disabledButton) {
      const paymentForm = document.getElementById(
        'credit-or-debit-form'
      ) as HTMLFormElement
      if (paymentForm) {
        paymentForm.requestSubmit()
      }
    }
  }, [disabledButton])

  const handleFillTransactionForm = (review_data: ReviewPaymentData) => {
    setTransactionForm({
      voucher_amount: review_data.voucher_amount,
      payment_amount: review_data.payment_amount,
      fee: review_data.fee
    })
    setDisabledButton(false)
  }

  const renderFooter = useCallback(() => {
    return (
      <Grid
        item
        container
        mt={3}
        gap={1}
        className={
          disabledButton ? classes.disabledStickyFooter : classes.stickyFooter
        }
      >
        <Grid item xs={4} className={classes.total}>
          <Currency
            amount={Number(transactionForm?.payment_amount ?? amount)}
          />
          <Typography>Amount to transfer</Typography>
        </Grid>

        <Grid item flex={1}>
          <Button
            fullWidth
            type='button'
            className={disabledButton ? classes.disabledBtn : classes.button}
            disabled={disabledButton}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    )
  }, [
    amount,
    classes.button,
    classes.disabledBtn,
    classes.disabledStickyFooter,
    classes.stickyFooter,
    classes.total,
    disabledButton,
    handleConfirm,
    transactionForm?.payment_amount
  ])

  return (
    <DefaultLayout
      wrapperContent={{ mt: 4 }}
      showBackIcon
      heading='Voucher Confirm'
      stickyFooter={renderFooter()}
    >
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} overflow='hidden'>
          <Slide direction='up' in={true} timeout={700}>
            <Grid item container flexDirection='column' justifyContent='center'>
              <Typography component='h2' mt={3}>
                Voucher amount
                <b>{` $${amount} `}</b>
              </Typography>

              <Typography component='h2'>
                We will email a gift voucher to
                <b>{` ${to_name} `}</b>
                at
                <b>{` ${to_email} `}</b>
              </Typography>
            </Grid>
          </Slide>
        </Grid>

        <Divider />

        {transactionForm && (
          <>
            <Grid item container spacing={2}>
              {formInformation.map(info => (
                <Grid
                  item
                  container
                  justifyContent='space-between'
                  alignItems='center'
                  key={info.label}
                >
                  <Grid item>
                    <Typography className={classes.label}>
                      {info.label}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {info.unit + formattedPrice(info.value ?? '0')}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Divider />

            <Grid
              item
              container
              xs={12}
              alignItems='center'
              justifyContent='space-between'
            >
              <Grid item>
                <Typography component='p' variant='body1' fontWeight='700'>
                  Total
                </Typography>
              </Grid>
              <Grid item>
                <Typography component='p' variant='body1' fontWeight='700'>
                  ${total}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}

        {!!note && (
          <Grid item xs={12}>
            <Typography>Message: {note}</Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <TransactionButtons
            price={Number(voucher_amount)}
            setDisabledButton={setDisabledButton}
            paymentParams={{
              amount: amount,
              payment_type: 'buy_voucher',
              toEmail: to_email,
              toName: to_name,
              note: note
            }}
            handleCreatePaymentSuccess={handleFillTransactionForm}
            setTransactionForm={setTransactionForm}
          />
        </Grid>
      </Grid>
    </DefaultLayout>
  )
}
