import { Box, Grid, Stack, TextField, Typography } from '@mui/material'
import { ReviewPaymentData } from 'api/payments/types'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import CountUpPrice from 'components/CountUpPrice'
import { usePayments } from 'components/PaymentsProvider'
import { voucherReviewPath } from 'components/Routes/VoucherFlowRoutes'
import { voucherConfirmPath } from 'components/Routes/VoucherFlowRoutes/VoucherFlowRoutes'
import DefaultLayout from 'components/layout/DefaultLayout'
import { useFormik } from 'formik'
import { useWalletForCurrentUserQuery } from 'generated/graphql'
import { useEffect, useMemo, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useHistory, useLocation } from 'react-router-dom'
import { formattedPrice } from 'utils/util'
import * as Yup from 'yup'
import useStyles from './styles'

const MIN_DOLLAR_VALUE = 10
const MAX_DOLLAR_VALUE = 1_000
const MAX_INPUT_LENGTH = 100

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DEFAULT_WALLET_USE_AMOUNT = 1

interface FormValues {
  amount: number
  toName: string
  toEmail: string
  note: string
}

const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .min(MIN_DOLLAR_VALUE, `Vouchers can't be less than $${MIN_DOLLAR_VALUE}`)
    .max(
      MAX_DOLLAR_VALUE,
      `Vouchers can't be greater than $${MAX_DOLLAR_VALUE}`
    )
    .required('Required'),
  toName: Yup.string().max(MAX_INPUT_LENGTH, 'Too long').required('Required'),
  toEmail: Yup.string()
    .max(MAX_INPUT_LENGTH, 'Too long')
    .email('Invalid email')
    .required('Required')
})

export default function Gift(): JSX.Element {
  const classes = useStyles()

  const history = useHistory<Partial<ReviewPaymentData>>()

  const { state } = useLocation<Partial<FormValues>>()

  const { amount, toEmail, toName, note } = state ?? {}

  const { setToast } = useAuth()

  const [disableBtn, setDisableBtn] = useState<boolean>(false)

  const {
    data: walletForCurrentUser,
    loading,
    refetch
  } = useWalletForCurrentUserQuery()

  const walletBalance = Number(
    walletForCurrentUser?.walletForCurrentUser?.walletBalance || 0
  )

  const { createPayment } = usePayments()

  const recaptchaEnabled = JSON.parse(
    process.env.REACT_APP_RECAPTCHA_ENABLED || 'true'
  )
  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    refetch()
  }, [refetch])

  const onSubmit = async (values: FormValues) => {
    setDisableBtn(true)

    localStorage.removeItem('voucher_form')

    if (!executeRecaptcha && recaptchaEnabled) return setDisableBtn(false)

    const recaptchaToken =
      recaptchaEnabled && executeRecaptcha
        ? await executeRecaptcha('payments/create')
        : undefined

    if (values.amount > walletBalance) {
      setToast({
        open: true,
        message: "Your wallet doesn't have enough credit to buy this voucher",
        duration: 4000,
        type: 'info'
      })
      history.push({
        pathname: voucherConfirmPath(),
        state: {
          amount: values.amount.toString(),
          voucher_amount:
            Number(values.amount - walletBalance).toString() ?? '0',
          fee: fee.toString() ?? '0',
          to_email: values.toEmail,
          to_name: values.toName,
          note: values.note
        }
      })
    } else {
      // Get Stripe PaymentIntent/ClientSecret and then move to next step, storing intent in queryParams
      createPayment(
        {
          amount: values.amount.toString(),
          toName: values.toName,
          toEmail: values.toEmail,
          note: values.note,
          payment_type: 'buy_voucher'
        },
        recaptchaToken,
        res => {
          history.push({
            pathname: voucherReviewPath(),
            state: res.review_data
          })
        }
      )
    }
  }

  const formik = useFormik({
    initialValues: {
      amount: Number(amount) || 10,
      toEmail: toEmail ?? '',
      toName: toName ?? '',
      note: note ?? ''
    },
    validationSchema,
    onSubmit
  })

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    formik

  const { fee, total } = useMemo(() => {
    /* 
      Change logic when buy voucher with 3 ways of payments
    */

    /* 
      const balanceWhenBuyVoucher: number = values.amount - walletBalance
      if (balanceWhenBuyVoucher <= 0) {
        return {
          total: values.amount,
          fee: 0
        }
      } 
    */

    /* 
      If the user's voucher purchase amount is greater than the amount in their wallet by about "$1" ==>
      payment stripe should be pay "$1" + "fee"
      then this $1 will be refunded to user's wallet.
      # Because Stripe only allows transactions greater than $1
    */
    // const balanceInWallet =
    //   balanceWhenBuyVoucher < 1
    //     ? balanceWhenBuyVoucher + DEFAULT_WALLET_USE_AMOUNT
    //     : balanceWhenBuyVoucher

    // This logic ís following the Backend
    // const formulaFee: number =
    //   (balanceInWallet +
    //     Number(process.env.REACT_APP_STRIPE_PROCESSING_FEE_CHARGE)) /
    //   (1 - Number(process.env.REACT_APP_STRIPE_PROCESSING_FEE_PERCENTAGE) / 100)

    return {
      total: values.amount,
      fee: 0
    }
  }, [values.amount])

  const renderReviewAndPay = () => {
    return (
      <Grid>
        <Box component='div'></Box>
        <Button
          onClick={() => handleSubmit()}
          showArrow={false}
          fullWidth
          variant='outlined'
          disabled={disableBtn}
        >
          <Box
            display='flex'
            flexGrow='1'
            justifyContent='space-around'
            flexDirection='row'
          >
            <Typography color='primary'>Review & send gift</Typography>
            <CountUpPrice
              value={total}
              prefix='$'
              duration={0.5}
              variant='body1'
              className={classes.countUp}
            />
          </Box>
        </Button>
      </Grid>
    )
  }

  return (
    <DefaultLayout
      loading={loading}
      wrapperContent={{ mt: 2 }}
      heading={
        <Stack gap={3}>
          <Typography
            variant='h1'
            component='h1'
            fontFamily='Moore'
            color='primary.main'
            textAlign='center'
            fontSize='6rem'
          >
            Buy gift
          </Typography>
          <Typography textAlign='left'>
            Your Balance {`$${formattedPrice(walletBalance)}`}
          </Typography>
        </Stack>
      }
      showBackIcon
    >
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container rowSpacing={2}>
          {/*<Grid item xs={12}>*/}
          {/*  <Typography textAlign='center'>*/}
          {/*    Your Balance {`$${formattedPrice(walletBalance)}`}*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}
          <Grid item xs={12} container justifyContent='center'>
            <model-viewer
              src='GoldNugget_002.glb'
              ar={false}
              camera-controls
              disable-zoom
              poster='poster.webp'
              shadow-intensity='1'
              alt='A 3D gold bar'
            ></model-viewer>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name='amount'
              label='Amount ($)'
              type='number'
              fullWidth
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.amount}
              helperText={
                Boolean(errors.amount && touched.amount) && errors.amount
              }
              error={Boolean(errors.amount && touched.amount)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name='toName'
              label='Giftee name'
              fullWidth
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.toName}
              helperText={
                Boolean(errors.toName && touched.toName) && errors.toName
              }
              error={Boolean(errors.toName && touched.toName)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name='toEmail'
              label='Giftee email'
              fullWidth
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.toEmail}
              helperText={
                Boolean(errors.toEmail && touched.toEmail) && errors.toEmail
              }
              error={Boolean(errors.toEmail && touched.toEmail)}
            />
          </Grid>
          <Grid item xs={12} mb={4}>
            <TextField
              label='Message'
              name='note'
              multiline
              fullWidth
              rows={6}
              maxRows={6}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.note}
            />
          </Grid>
        </Grid>
        {renderReviewAndPay()}
      </form>
    </DefaultLayout>
  )
}
