import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  List,
  Typography
} from '@mui/material'
import classNames from 'classnames'
import { useAuth } from 'components/AuthProvider'
import useVault from 'hooks/useVault/useVault'
import { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { MenuType } from './Menu'
import useStyles from './styles'

interface MenuListProps {
  navLinks: MenuType[]
  onClose: () => void
}

export default function MenuList({
  navLinks,
  onClose
}: Readonly<MenuListProps>) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const { logout } = useAuth()
  const { activatedVaultId, handleActiveVault } = useVault()

  const [currentVault, setCurrentVault] = useState<string>(
    activatedVaultId.toString()
  )

  const redirectPath = useCallback(
    (path: string) => {
      history.push(path)
      onClose()
    },
    [history, onClose]
  )

  const handleClickSecondaryMenu = useCallback(
    async (secondaryMenu: MenuType) => {
      if (secondaryMenu.disable) return
      if (secondaryMenu.isVault) {
        setCurrentVault(secondaryMenu?.id)
        await handleActiveVault(secondaryMenu?.id).then(() => onClose())
      }

      if (secondaryMenu.href) {
        window.location.replace(secondaryMenu.href)
        onClose()
      } else {
        return secondaryMenu.path && redirectPath(secondaryMenu.path)
      }
    },
    [handleActiveVault, redirectPath, onClose]
  )

  return (
    <Box className={classes.menuListWrapper}>
      <List>
        {navLinks.map(navLink => {
          const { id, path, text, secondaryMenu, matchPath } = navLink ?? {}

          const isActive = matchPath ? matchPath(location.pathname) : false

          return (
            <Accordion
              key={id}
              disableGutters
              classes={{
                root: classes.listItemLink
              }}
              defaultExpanded={id === 'asset' || id === 'my_vault'}
            >
              <AccordionSummary
                expandIcon={undefined}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className={classes.accordionSummary}
              >
                <Typography
                  className={classes.listItemText}
                  variant='h6Serif'
                  onClick={() => path && redirectPath(path)}
                  color={isActive ? 'primary' : 'black'}
                >
                  {text}
                </Typography>
              </AccordionSummary>
              {secondaryMenu && (
                <AccordionDetails className={classes.accordionDetails}>
                  {secondaryMenu.map(item => (
                    <Typography
                      key={item.id}
                      onClick={() => handleClickSecondaryMenu(item)}
                      className={classNames(classes.listSecondaryItemText, {
                        [classes['listSecondaryItemText-disable']]: item.disable
                      })}
                      variant='h6Serif'
                      color={
                        currentVault === item.id && item.isVault
                          ? 'primary'
                          : 'black'
                      }
                    >
                      {item.text}
                    </Typography>
                  ))}
                </AccordionDetails>
              )}
            </Accordion>
          )
        })}
      </List>

      <Link
        className={classes.listItemLink}
        component='div'
        onClick={logout}
        position='static'
        type='button'
        underline='hover'
        pb={2}
      >
        <Typography
          className={classes.listItemText}
          component='p'
          variant='h6Serif'
        >
          Sign out
        </Typography>
      </Link>
    </Box>
  )
}
