import * as React from 'react'
import { TableBody as TableBodyElem, TableCell, TableRow } from '@mui/material'
import classNames from 'classnames'

/** types */
import type { TableBaseProps } from './models'

/** styles */
import useStyles from './styles'

function TableBody<T>(
  props: Pick<
    TableBaseProps<T>,
    'data' | 'onClickItem' | 'rows' | 'tableRowProps'
  >
) {
  const { onClickItem, data, rows, tableRowProps } = props
  const { hover = true, className, onClick, ...other } = tableRowProps ?? {}

  const classes = useStyles()
  return (
    <TableBodyElem>
      {data?.map((item, indexRow) => {
        let key = `${indexRow}-${Date.now()}`
        if (item && typeof item === 'object') {
          // @ts-ignore
          key = item?.key || item?.id || key
        }

        return (
          <TableRow
            className={classNames(classes.tableRow, className)}
            key={`${key}`}
            onClick={event => {
              onClick?.(event)
              onClickItem?.(item)
            }}
            hover={hover}
            {...other}
          >
            {rows?.map((cell, indexCell) => (
              <TableCell key={`${key}-${cell.id}`}>
                {cell.render(item, indexRow, indexCell)}
              </TableCell>
            ))}
          </TableRow>
        )
      })}
    </TableBodyElem>
  )
}

export default React.memo(TableBody)
