import { Grid, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { GraphPeriodType } from 'components/LineGraph/LineGraph'

interface GraphPeriodProps {
  graphPeriod: GraphPeriodType
  setGraphPeriod: (graphPeriod: GraphPeriodType) => void
  periodGraphClass?: string
  periodGraphActiveClass?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(0, 0),
    borderTop: `1px solid ${theme.palette.black.main}`,
    borderBottom: `1px solid ${theme.palette.black.main}`
  },
  periodGraph: {
    textTransform: 'uppercase',
    color: theme.palette.black.main,
    cursor: 'pointer',
    padding: theme.spacing(0, 0.4, 0.2)
  },
  periodGraphActive: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 0.4, 0.2),
    cursor: 'pointer'
  }
}))

export default function GraphPeriod(props: GraphPeriodProps): JSX.Element {
  const classes = useStyles()

  const {
    graphPeriod,
    setGraphPeriod,
    periodGraphClass,
    periodGraphActiveClass
  } = props

  function removeElementOfLineGraph(): void {
    const tooltipElement = document.getElementById('tooltip')
    const overPeriodElement = document.getElementById('over-period')
    if (tooltipElement) {
      tooltipElement.remove()
    }
    if (overPeriodElement) {
      overPeriodElement.remove()
    }
  }

  const checkActiveGraphPeriod = (period: string) => {
    removeElementOfLineGraph()
    return graphPeriod === period
      ? periodGraphActiveClass ?? classes.periodGraphActive
      : periodGraphClass ?? classes.periodGraph
  }

  return (
    <Grid
      item
      container
      alignItems='center'
      justifyContent='space-between'
      className={classes.wrapper}
    >
      <Typography
        className={checkActiveGraphPeriod('1w')}
        onClick={() => {
          setGraphPeriod('1w')
        }}
      >
        1w
      </Typography>
      <Typography
        className={checkActiveGraphPeriod('1m')}
        onClick={() => {
          setGraphPeriod('1m')
        }}
      >
        1m
      </Typography>
      <Typography
        className={checkActiveGraphPeriod('3m')}
        onClick={() => {
          setGraphPeriod('3m')
        }}
      >
        3m
      </Typography>
      <Typography
        className={checkActiveGraphPeriod('1y')}
        onClick={() => {
          setGraphPeriod('1y')
        }}
      >
        1y
      </Typography>
      <Typography
        className={checkActiveGraphPeriod('5y')}
        onClick={() => {
          setGraphPeriod('5y')
        }}
      >
        5y
      </Typography>
      <Typography
        className={checkActiveGraphPeriod('20y')}
        onClick={() => {
          setGraphPeriod('20y')
        }}
      >
        20y
      </Typography>
    </Grid>
  )
}
