import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  label: {
    fontSize: '12px',
    color: theme.palette.text.disabled
  },
  input: {
    '& input': {
      padding: theme.spacing(2, 2.25),
      fontSize: '20px',
      background: theme.palette.white.main
    }
  }
}))
