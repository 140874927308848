import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import { autoInvestSuccessPath } from 'components/Routes'
import DefaultLayout from 'components/layout/DefaultLayout'
import { patternOneDigitsAfterComma } from 'constants/index'
import { useFormik } from 'formik'
import {
  namedOperations,
  useCreateAutoInvestMutation,
  useGetLatestSpotPriceQuery
} from 'generated/graphql'
import { useMemo } from 'react'
import { NumericFormat } from 'react-number-format'
import { useHistory } from 'react-router'
import theme from 'utils/theme'
import { generateUsername, roundDownDollarsAndCents } from 'utils/util'
import * as Yup from 'yup'

interface FormValues {
  when: string
  what: string
  vault: string
  amount: number
  investBy: string
}

const initialValues: FormValues = {
  when: '',
  what: '',
  vault: '',
  investBy: 'unit',
  amount: 0.1
}

const validationSchema = Yup.object().shape({
  when: Yup.string().required(),
  what: Yup.string().required(),
  vault: Yup.string().required(),
  investBy: Yup.string().required(),
  amount: Yup.number()
    .required('This is field is required')
    .min(0.1)
    .test('is-decimal', 'Specify grams to buy to 1 decimal place', value => {
      if (value !== undefined) {
        return patternOneDigitsAfterComma.test(value.toString())
      }
      return true
    })
})

export default function AddAutoInvest(): JSX.Element {
  const { user, setToast } = useAuth()

  const history = useHistory()

  const listVault = useMemo(
    () =>
      user?.vaults.map(vault =>
        vault.name ? vault : { ...vault, name: generateUsername(user) }
      ),
    [user]
  )

  const listProduct = useMemo(
    () =>
      user?.securitiesOwned
        ?.map(securityOwned => securityOwned.product)
        .filter(product => product?.tradable),
    [user]
  )

  const [createAutoInvest, { loading }] = useCreateAutoInvestMutation()

  const {
    errors,
    touched,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    handleSubmit
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: FormValues) => {
      localStorage.setItem('investBy', JSON.stringify(values.investBy))
      createAutoInvest({
        variables: {
          input: {
            amount:
              values.investBy === 'dollar'
                ? Number(values.amount)
                : values.amount * 10,
            duration: values.when,
            productId: Number(values.what),
            vaultId: Number(values.vault),
            investBy: values.investBy
          }
        },
        refetchQueries: [namedOperations.Query.getCurrentUser],
        awaitRefetchQueries: true,
        onCompleted: () => {
          setToast({
            open: true,
            message: 'Create Auto Investment successful!',
            type: 'success',
            duration: 3000
          })
          history.push(autoInvestSuccessPath())
        }
      })
    }
  })

  const { data: latestSpotPrice } = useGetLatestSpotPriceQuery({
    variables: { productId: Number(values.what) },
    // TODO: error handling
    onError: error =>
      setToast({
        open: true,
        message: error.message,
        type: 'error',
        duration: 3000
      }),
    skip: !values.what
  })

  const handleInvestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selected = (event.target as HTMLInputElement).value ?? 'dollar'
    setFieldValue('investBy', selected)
    setFieldValue('amount', selected === 'dollar' ? 20 : 0.1)
  }

  const handleIncreaseUnit = () => {
    setFieldValue('amount', (values.amount * 10 + 1) / 10)
  }

  const handleDecreaseUnit = () => {
    setFieldValue(
      'amount',
      values.amount > 0.1 ? (values.amount * 10 - 1) / 10 : 0.1
    )
  }

  const renderInput = () => {
    if (values.investBy === 'dollar') {
      return (
        <>
          <TextField
            fullWidth
            required
            type='number'
            id='dollar'
            label='Maximum amount'
            name='amount'
            value={values.amount}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.amount && touched.amount)}
            helperText={
              Boolean(errors.amount && touched.amount) && errors.amount
            }
            InputLabelProps={{
              style: {
                left: 0
              }
            }}
            InputProps={{
              startAdornment: (
                <Typography mt={1.25} ml={1}>
                  $
                </Typography>
              ),
              inputProps: {
                style: {
                  paddingLeft: '2px'
                }
              }
            }}
          />

          <Typography variant='body2'>
            Pro tip: ensure the amount is greater than the minimum product
            purchase amount based on the current price.
          </Typography>
        </>
      )
    }

    return (
      <NumericFormat
        thousandSeparator
        onValueChange={({ value }) =>
          handleChange({ target: { name: 'amount', value } })
        }
        onBlur={handleBlur}
        valueIsNumericString
        value={values.amount}
        fullWidth
        required
        label='Maximum units'
        name='amount'
        suffix=' gram'
        error={Boolean(errors.amount && touched.amount)}
        helperText={
          Boolean(errors.amount && touched.amount)
            ? errors.amount
            : `Currently 0.1 gram is $ ${
                latestSpotPrice
                  ? roundDownDollarsAndCents(
                      Number(latestSpotPrice?.latestSpotPrice.midpointPerDg)
                    ).toFixed(2)
                  : '0.00'
              } & it frequently changes`
        }
        InputLabelProps={{
          style: {
            left: 0
          }
        }}
        FormHelperTextProps={{
          style: {
            color: Boolean(errors.amount && touched.amount)
              ? theme.palette.error.main
              : theme.palette.primary.main
          }
        }}
        InputProps={{
          endAdornment: (
            <Box>
              <IconButton
                size='small'
                sx={{ p: 0 }}
                onClick={handleIncreaseUnit}
              >
                <ArrowDropUpIcon fontSize='small' />
              </IconButton>
              <IconButton
                size='small'
                sx={{ p: 0 }}
                onClick={handleDecreaseUnit}
              >
                <ArrowDropDownIcon fontSize='small' />
              </IconButton>
            </Box>
          ),

          style: {
            lineHeight: '1rem'
          }
        }}
        customInput={TextField}
      />
    )
  }

  return (
    <DefaultLayout heading='' showBackIcon>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container rowGap={3}>
          <Grid item xs={12}>
            <FormControl fullWidth error={!!(errors.when && touched.when)}>
              <InputLabel required id='when' sx={{ left: '0' }}>
                When?
              </InputLabel>
              <Select
                labelId='when'
                label=''
                name='when'
                value={values.when}
                onChange={handleChange}
                error={!!(errors.when && touched.when)}
              >
                <MenuItem value='weekly'>Weekly</MenuItem>
                <MenuItem value='fortnightly'>Fortnightly</MenuItem>
              </Select>
            </FormControl>
            {errors.when && touched.when && (
              <Typography variant='body2' color='error'>
                {errors?.when}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={!!(errors.what && touched.what)}>
              <InputLabel required id='what' sx={{ left: '0' }}>
                What?
              </InputLabel>
              <Select
                labelId='what'
                label=''
                name='what'
                value={values.what}
                onChange={handleChange}
                error={!!(errors.what && touched.what)}
              >
                {listProduct?.map(product => (
                  <MenuItem value={product?.id} key={product?.id}>
                    {product?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.what && touched.what && (
              <Typography variant='body2' color='error'>
                {errors?.what}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={!!(errors.vault && touched.vault)}>
              <InputLabel required id='vault' sx={{ left: '0' }}>
                Vault
              </InputLabel>
              <Select
                labelId='vault'
                label=''
                name='vault'
                value={values.vault}
                onChange={handleChange}
                error={!!(errors.vault && touched.vault)}
              >
                {listVault?.map(vault => {
                  return (
                    <MenuItem value={vault.id} key={vault.id}>
                      {vault?.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            {errors.vault && touched.vault && (
              <Typography variant='body2' color='error'>
                {errors?.vault}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl
              defaultValue={values.investBy ?? null}
              disabled={!values.what}
            >
              <RadioGroup
                defaultValue={values.investBy ?? null}
                value={values.investBy ?? null}
                onChange={handleInvestChange}
                name='investBy'
              >
                <FormControlLabel
                  value='unit'
                  control={<Radio />}
                  label='Invest by units'
                />
                <FormControlLabel
                  value='dollar'
                  control={<Radio />}
                  label='Invest by $'
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {values.what && renderInput()}

          <Grid item xs={12} mt={0}>
            <Button
              fullWidth
              variant='outlined'
              type='submit'
              disabled={loading}
              loading={loading}
            >
              CREATE AUTO INVESTMENT
            </Button>
            <Typography textAlign='center' variant='body2' color='primary'>
              Tip: Auto invest uses money from your wallet, so keep it topped up
            </Typography>
          </Grid>
        </Grid>
      </form>
    </DefaultLayout>
  )
}
