import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ palette }) => ({
  tableBox: {
    display: 'flex',
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    position: 'relative'
  },
  tableContainer: {
    maxWidth: '100%',
    maxHeight: '100%',
    '&::-webkit-scrollbar': {
      width: '7px',
      height: '7px'
    }
  },
  table: {},
  tableHead: {
    '& th': {
      background: 'transparent',
      //padding: spacing(1, 0, 1, 2.5),
      color: palette.primary.main,
      textAlign: 'right',
      borderBottom: `1px solid ${palette.black.main}`,
      '&:first-child': {
        textAlign: 'left',
        paddingLeft: 0
      }
    }
  },
  tableRow: {
    cursor: 'pointer',
    '& td': {
      fontSize: '1rem',
      //padding: spacing(1, 0, 1, 1),
      textAlign: 'right',
      borderBottom: `1px solid ${palette.black.main}`,
      '&:first-child': {
        textAlign: 'left',
        paddingLeft: 0
      }
    }
  },
  tableLoading: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: palette.grey[300],
    zIndex: 1,

    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingTop: 80
  }
}))

export default useStyles
