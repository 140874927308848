import { Route, Switch } from 'react-router-dom'

import buildLink from 'utils/buildLink'

import { PaymentsProvider } from 'components/PaymentsProvider'
import BuyVoucher from 'components/pages/Gift'
import VoucherCheckout from 'components/pages/VoucherCheckout'
import VoucherConfirm from 'components/pages/VoucherConfirm/VoucherConfirm'
import VoucherReview from 'components/pages/VoucherReview'
import { PathArgs } from '../types'

export function voucherPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/gifts/voucher' })
}
export function voucherCheckoutPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/gifts/voucher/checkout' })
}
export function voucherReviewPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/gifts/voucher/review' })
}
export function voucherConfirmPath(args?: PathArgs): string {
  return buildLink({ ...args, path: '/account/gifts/voucher/confirm' })
}

export default function VoucherFlow(): JSX.Element {
  return (
    <PaymentsProvider>
      <Switch>
        <Route exact path={voucherConfirmPath()} component={VoucherConfirm} />
        <Route path={voucherCheckoutPath()} component={VoucherCheckout} />
        <Route path={voucherReviewPath()} component={VoucherReview} />
        <Route path={voucherPath()} component={BuyVoucher} />
      </Switch>
    </PaymentsProvider>
  )
}
