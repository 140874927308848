import { useProductsQuery } from 'generated/graphql'
//import fineGoldImage from 'images/gold-blob.png'
//import fineSilverImage from 'images/silver-blob.png'
import fineGoldImage from 'images/gold-with-shadow-cropped.png'
import fineSilverImage from 'images/silver-with-shadow-cropped.png'

import { useMemo } from 'react'
import { useParams } from 'react-router'

export type ProductInclLocal = {
  disable: boolean
  image: string
  descriptions: string[]
  shortDescription: string
  __typename?: 'Product' | undefined
  id: string
  name: string
  description: string
  sku: string
  tradable: boolean
  sliderScalingFactor?: number
}

export const localProductsData: Record<
  string,
  {
    image: string
    shortDescription: string
    descriptionParagraphs: string[]
    disable: boolean
    sliderScalingFactor?: number
  }
> = {
  gold: {
    image: fineGoldImage,
    shortDescription: '99.99% fine Gold',
    descriptionParagraphs: [
      `Once tethered to the value of currency, investment gold has
      become the benchmark for appreciating wealth. The gold bar you
      see here is the exact bar of gold we have stored at the Goldie
      vault in Wellington.`,
      `First found in 2450 BC by the Ancient Egyptians, it has ever
      since been a modus for commodities exchange and wealth. You
      may have heard the phrase “gold standard”. In the 20th
      century, banks and governments used the price of gold to
      benchmark the currency markets. At the time, this helped
      tether inflation but made it harder to stimulate the economy
      because any monetary stimulus had to stay in lockstep with the
      market price for gold.`,
      `To get a good market price when buying and selling gold, you
      generally need to buy a lot of gold all at once. This is
      called the wholesale market rate. To get the wholesale price
      of gold, you would need to buy 1kg* of gold which at the time
      of writing costs $103,402.27*. This is unattainable to almost
      everyone in New Zealand. If you wish to buy smaller amounts of
      gold, by the time you pay a higher market rate for a smaller
      amount of gold, and pay commission to the broker, it makes it
      much harder to make a return on the investment.`
    ],
    disable: false
  },
  silver: {
    image: fineSilverImage,
    shortDescription: '99.9% pure Silver',
    descriptionParagraphs: [
      `Silver has long been valued as a precious metal. Silver metal
      is used in many bullion coins, sometimes alongside gold`,
      `Silver was one of the seven metals of antiquity that were
      known to prehistoric humans and whose discovery is thus lost
      to history. In particular, the three metals of group 11,
      copper, silver, and gold, occur in the elemental form in
      nature and were probably used as the first primitive forms of
      money as opposed to simple bartering. However, unlike copper,
      silver did not lead to the growth of metallurgy on account of
      its low structural strength, and was more often used
      ornamentally or as money. Since silver is more reactive than
      gold, supplies of native silver were much more limited than
      those of gold. For example, silver was more expensive than
      gold in Egypt until around the fifteenth century BC: the
      Egyptians are thought to have separated gold from silver by
      heating the metals with salt, and then reducing the silver
      chloride produced to the metal.`,
      `Other than in currency and as an investment medium (coins and
      bullion), silver is used in solar panels, water filtration,
      jewellery, ornaments, high-value tableware and utensils (hence
      the term "silverware"), in electrical contacts and conductors,
      in specialized mirrors, window coatings, in catalysis of
      chemical reactions, as a colorant in stained glass, and in
      specialized confectionery. Its compounds are used in
      photographic and X-ray film`
    ],
    disable: false,
    sliderScalingFactor: 10
  }
}

export default function useProducts(forceProductId = null) {
  const { data: products, loading: productsLoading } = useProductsQuery()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params = useParams<any>()
  const productId = Number(forceProductId ?? params?.productId ?? 1)

  const productsInclLocals = products?.products?.map(p => {
    return {
      ...p,
      disable: localProductsData[p.name.toLowerCase()]?.disable,
      image: localProductsData[p.name.toLowerCase()]?.image,
      descriptions:
        localProductsData[p.name.toLowerCase()]?.descriptionParagraphs,
      shortDescription:
        localProductsData[p.name.toLowerCase()]?.shortDescription,
      slidingScalingFactor:
        localProductsData[p.name.toLocaleLowerCase()]?.sliderScalingFactor
    }
  })

  const currentProduct = useMemo(() => {
    if (productsInclLocals) {
      return (
        productsInclLocals.find(item => item.id === productId.toString()) ??
        null
      )
    }
    return null
  }, [productId, productsInclLocals])

  return {
    products: productsInclLocals ?? [],
    loading: productsLoading,
    product: currentProduct,
    productId
  }
}
