import { Box, Grid, Typography } from '@mui/material'
import { useState } from 'react'

import {
  namedOperations,
  useEnableAuthenticatorAppMutation
} from 'generated/graphql'

import Button from 'components/Button'
import VerificationInput from 'components/VerificationInput'
import theme from 'utils/theme'

export interface Props {
  setBackupCodes: (value: string[]) => void
}

export default function TwoStepCode({ setBackupCodes }: Props): JSX.Element {
  const [otp, setOtp] = useState<string>('')
  const [error, setError] = useState<string>('')

  const handleChangeOtpCode = (code: string) => {
    setError('')
    setOtp(code)
  }

  const [enableAuthenticatorApp, { loading: enableAuthenticatorAppLoading }] =
    useEnableAuthenticatorAppMutation({
      // TODO: Error handling
      onError: error => setError(error.message),
      onCompleted: data => {
        if (data?.backupCodes) {
          setBackupCodes(data?.backupCodes)
        }
      },
      refetchQueries: [namedOperations.Query.getCurrentUser]
    })

  const handleEnableAuthenticatorApp = () => {
    enableAuthenticatorApp({ variables: { input: { otp } } })
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          align='left'
          variant='h2'
          fontSize='48px'
          textTransform='uppercase'
        >
          Enter your code
        </Typography>
      </Grid>
      <Grid item xs={12} mb={4}>
        <Typography align='left' fontSize='medium'>
          Enter the code generated by your auth app below
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 6 }}>
        <VerificationInput onChange={handleChangeOtpCode} />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center', marginBottom: 6 }}>
        {error && (
          <Box
            sx={{
              color: theme.palette.error.main,
              fontSize: '16px'
            }}
          >
            {error}
          </Box>
        )}
      </Grid>
      <Grid item xs={12} sx={{ mt: 4, textAlign: 'center' }}>
        <Button
          color='primary'
          fullWidth
          onClick={handleEnableAuthenticatorApp}
          disabled={enableAuthenticatorAppLoading}
        >
          Turn on authenticator app
        </Button>
      </Grid>
    </Grid>
  )
}
