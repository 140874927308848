import {
  Typography,
  List,
  ListItem,
  IconButton,
  ListItemText
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { makeStyles } from '@mui/styles'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import { addAutoInvestPath } from 'components/Routes'
import DefaultLayout from 'components/layout/DefaultLayout'
import {
  AutoInvest as AutoInvestType,
  namedOperations,
  useDeleteAutoInvestMutation
} from 'generated/graphql'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'

import { compact } from 'lodash'

const useStyles = makeStyles(theme => ({
  cardWrapper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderColor: theme.palette.primary.main
  }
}))

interface AutoInvestListItemProps {
  autoInvest: AutoInvestType
}

function AutoInvestListItem({ autoInvest }: AutoInvestListItemProps) {
  const classes = useStyles()
  const [deleteAutoInvest] = useDeleteAutoInvestMutation()

  const handleCancelAutoInvest = useCallback(
    (autoInvestId: number) => {
      deleteAutoInvest({
        variables: {
          input: {
            autoInvestId
          }
        },
        refetchQueries: [namedOperations.Query.getCurrentUser]
      })
    },
    [deleteAutoInvest]
  )

  const autoInvestStringParts = () => {
    const array: string[] = []

    if (autoInvest.product?.name) {
      array.push(`Buy ${autoInvest.product?.name}`)
    }

    if (autoInvest.amount && autoInvest.investBy) {
      array.push(
        autoInvest.investBy === 'dollar'
          ? `$${autoInvest.amount}`
          : `${Number(autoInvest.amount) / 10}g`
      )
    }

    if (autoInvest.duration) {
      array.push(autoInvest.duration)
    }

    return compact(array)
  }

  return (
    <ListItem
      key={autoInvest.id}
      className={classes.cardWrapper}
      secondaryAction={
        <IconButton
          edge='end'
          aria-label='delete'
          onClick={() => handleCancelAutoInvest(Number(autoInvest.id))}
        >
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemText
        primary={autoInvestStringParts().join(', ')}
        secondary={
          autoInvest.nextAutoinvestDate
            ? `Next investment ${autoInvest.nextAutoinvestDate}`
            : null
        }
      />
    </ListItem>
  )
}

export default function AutoInvest(): JSX.Element {
  const history = useHistory()

  const { user } = useAuth()

  const autoInvests = useMemo(() => user?.autoInvests, [user])

  const goToCreateNewAutoInvestment = useCallback(
    () => history.push(addAutoInvestPath()),
    [history]
  )

  return (
    <DefaultLayout heading='Auto invest' showBackIcon>
      <Typography variant='body1' align='center' my={2}>
        Automatically invest on a frequency that suits you.
      </Typography>

      <List>
        {autoInvests?.map(autoInvest => (
          <AutoInvestListItem autoInvest={autoInvest} key={autoInvest.id} />
        ))}
      </List>

      <Button
        fullWidth
        variant='outlined'
        onClick={goToCreateNewAutoInvestment}
        sx={{ mb: 6 }}
      >
        CREATE NEW AUTO INVESTMENT
      </Button>
    </DefaultLayout>
  )
}
