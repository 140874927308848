import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import BankIcon from 'components/icons/Bank'
import LockIcon from 'components/icons/Lock'
import MobileIcon from 'components/icons/Mobile'
import MonitorIcon from 'components/icons/Monitor'
import PersonalIcon from 'components/icons/Personal'
import DefaultLayout from 'components/layout/DefaultLayout'
import {
  bankAccountPath,
  changePasswordPath,
  personalDetailsPath,
  twoStepAuthenticationPath
} from 'components/Routes'
import { deviceSettingsPath, referralsPath } from 'components/Routes/Routes'
import { useHistory } from 'react-router-dom'
import useStyles from './styles'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'

export default function ProfileSettings(): JSX.Element {
  const classes = useStyles()

  const history = useHistory()

  const settings = [
    {
      id: 'personalDetails',
      path: personalDetailsPath(),
      label: 'Personal Details',
      icon: <PersonalIcon />,
      isShow: true
    },
    {
      id: 'bankAccount',
      path: bankAccountPath(),
      label: 'Bank Account',
      icon: <BankIcon />,
      isShow: true
    },
    {
      id: 'changePassword',
      path: changePasswordPath(),
      label: 'Change Password',
      icon: <LockIcon />,
      isShow: true
    },
    {
      id: 'twoStepAuthentication',
      path: twoStepAuthenticationPath(),
      label: 'Two-Step Authentication',
      icon: <MonitorIcon />,
      isShow: true
    },
    {
      id: 'deviceSettings',
      path: deviceSettingsPath(),
      label: 'Device Settings',
      icon: <MobileIcon />,
      isShow: true
    },
    {
      id: 'referralProgramme',
      path: referralsPath(),
      label: 'Referral Programme',
      icon: <GroupAddOutlinedIcon />,
      isShow: JSON.parse(process.env.REACT_APP_ENABLE_REFERRAL_CODE || 'true')
    }
  ]

  const handleClickButton = (path: string) => history.push(path)

  return (
    <DefaultLayout wrapperContent={{ mt: 0, mb: 2 }} showBackIcon>
      <Grid container rowSpacing={2} py={4}>
        {settings.map(setting =>
          setting.isShow ? (
            <Grid item xs={12} key={setting.id}>
              <Box
                className={classes.setting}
                onClick={() => handleClickButton(setting.path)}
              >
                <Box>{setting.icon}</Box>
                <Typography>{setting.label}</Typography>
              </Box>
            </Grid>
          ) : null
        )}
      </Grid>
    </DefaultLayout>
  )
}
