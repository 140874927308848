import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import Button from 'components/Button'

import { verifyPath } from 'components/Routes'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  step: {
    cursor: 'text',
    textDecoration: 'none'
  }
}))

export default function OnboardingMessage(): JSX.Element {
  const classes = useStyles()

  const history = useHistory()

  const handleClickOnboarding = () => {
    history.push(verifyPath())
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card
          sx={{
            border: theme => `1px solid ${theme.palette.primary.main}`,
            my: 1
          }}
        >
          <CardContent>
            <Typography sx={{ mb: 2 }} variant='body1'>
              Before you can buy gold, we're required to check that you are who
              you say you are.
            </Typography>
            <Typography sx={{ mb: 2 }} variant='body1'>
              Easy as 1-2-3!
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography
                component='p'
                sx={{ flex: '0 0 3.5rem', mr: 1 }}
                variant='h1'
              >
                <abbr className={classes.step} title='Step 1'>
                  1
                </abbr>
              </Typography>
              <Typography variant='body1'>
                Introduce yourself - we want to get to know you
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography
                component='p'
                sx={{ flex: '0 0 3.5rem', mr: 1 }}
                variant='h1'
              >
                <abbr className={classes.step} title='Step 2'>
                  2
                </abbr>
              </Typography>
              <Typography variant='body1'>
                Get your ID handy, so we can match your details against NZ
                Government data
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography
                component='p'
                sx={{ flex: '0 0 3.5rem', mr: 1 }}
                variant='h1'
              >
                <abbr className={classes.step} title='Step 3'>
                  3
                </abbr>
              </Typography>
              <Typography variant='body1'>
                You & Goldie - give us an idea of what you do, and how you'll
                invest with us
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Button
          fullWidth
          variant='outlined'
          onClick={handleClickOnboarding}
          sx={{ mt: 1.5, mb: 4 }}
        >
          Get verified now
        </Button>
      </Grid>
    </Grid>
  )
}
