import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
  setting: {
    background: theme.palette.background.paper,
    borderRadius: '16px',
    padding: theme.spacing(2.5, 3),

    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1rem',

    '& > div': {
      width: '1.5rem',
      height: '1.5rem',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    '& > p': {
      lineHeight: '150%',
      fontSize: '1rem',
      fontWeight: '400',
      color: '#292D32'
    }
  }
}))
