import { TextField } from '@mui/material'
import {
  DatePicker as DatePickerMui,
  LocalizationProvider
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useFormikContext } from 'formik'
import { API_DATE_FORMAT, UI_DATE_FORMAT, formatDate } from 'utils/util'

interface DatePickerProps {
  name: string
  label: string
  inputFormat?: 'DD/MM/YYYY' | 'YYYY-MM-DD'
  outputFormat?: 'DD/MM/YYYY' | 'YYYY-MM-DD'
}

export default function DatePicker<T>({
  name,
  label,
  inputFormat,
  outputFormat
}: Readonly<DatePickerProps>): JSX.Element {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    setFieldTouched
  } = useFormikContext<T>()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePickerMui
        inputFormat={UI_DATE_FORMAT}
        // @ts-ignore
        value={dayjs(values[name], inputFormat ?? API_DATE_FORMAT)}
        label={label}
        onChange={date => {
          const formattedDate = date
            ? formatDate({
                date: dayjs(date).format(inputFormat ?? API_DATE_FORMAT),
                inputFormat: inputFormat ?? API_DATE_FORMAT,
                outputFormat: outputFormat ?? API_DATE_FORMAT
              })
            : ''
          setFieldTouched(name, true)
          setFieldValue(name, formattedDate)
        }}
        renderInput={params => (
          <TextField
            {...params}
            onBlur={handleBlur}
            label={label}
            name={name}
            required
            fullWidth
            // @ts-ignore
            error={touched[name] && !!errors[name]}
            // @ts-ignore
            helperText={touched[name] && errors[name]}
            // @ts-ignore
            value={values[name]}
          />
        )}
      />
    </LocalizationProvider>
  )
}
