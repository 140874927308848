import { useCallback, useMemo } from 'react'

import { useAuth } from 'components/AuthProvider'

import { Grid, Typography } from '@mui/material'
import DefaultLayout from 'components/layout/DefaultLayout'
import OnboardingMessage from 'components/OnboardingMessage'

import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import DepositDetail from 'components/DepositDetail'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  depositDetailWrapper: {
    position: 'relative',
    marginTop: theme.spacing(2),

    '&:first-child': {
      marginTop: theme.spacing(0.5)
    },

    '&:not(:last-child)': {
      paddingBottom: theme.spacing(1.5)
    }
  },
  depositDetailValueText: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap'
  },
  depositDetailLabelText: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  }
}))

// TODO: should we link to the Activity page from here (once built) to show past deposits for example?
export default function DepositFunds(): JSX.Element {
  const classes = useStyles()

  const { user } = useAuth()

  const isOnboarded = useMemo(() => user?.profile?.onboarded, [user])

  const renderHeader = useCallback(() => {
    return (
      <Typography component='h1' variant='h1' fontSize='5.5rem' color='primary'>
        Deposit funds
      </Typography>
    )
  }, [])

  return (
    <DefaultLayout backgroundColor='default' heading={renderHeader()}>
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {isOnboarded ? (
              <>
                <Typography variant='body1' sx={{ textAlign: 'center' }}>
                  Deposit funds into your wallet using the details below. Make
                  the payment before 2pm on a business day, and the funds should
                  be available in your wallet within 48 hours.
                </Typography>
                <DepositDetail />
                <Typography
                  sx={{ mb: 2, textTransform: 'uppercase' }}
                  variant='h5'
                >
                  Save us for future deposits!
                </Typography>
                <Typography mb={2}>
                  You will receive an email notification when your deposit has
                  been received. If you don't see this email in the expected
                  timeframe, please check your spam folder.
                </Typography>
              </>
            ) : (
              <OnboardingMessage />
            )}
          </Grid>
        </Grid>
      </div>
    </DefaultLayout>
  )
}
