import { Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  namedOperations,
  useDisableAuthenticatorAppMutation
} from 'generated/graphql'

import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import { personalDetailsPath } from 'components/Routes'
import DefaultLayout from 'components/layout/DefaultLayout'

export default function DisableAuthenticator(): JSX.Element {
  const { user, maybeRequestOtp, setToast } = useAuth()
  const history = useHistory()
  const [
    disableAuthenticatorAppRequested,
    setDisableAuthenticatorAppRequested
  ] = useState(false)

  useEffect(() => {
    if (disableAuthenticatorAppRequested && user?.otpVerified) {
      disableAuthenticatorApp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableAuthenticatorAppRequested, user])

  const [disableAuthenticatorApp, { loading: disableAuthenticatorAppLoading }] =
    useDisableAuthenticatorAppMutation({
      onError: error =>
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        }),
      onCompleted: () => {
        setToast({
          open: true,
          message: 'Good as gold! Authenticator app disabled.',
          type: 'success',
          duration: 3000
        })
        history.push(personalDetailsPath())
      },
      refetchQueries: [namedOperations.Query.getCurrentUser]
    })

  const handleDisableAuthenticatorApp = () => {
    if (user?.otpVerified) {
      disableAuthenticatorApp()
    } else {
      setDisableAuthenticatorAppRequested(true)
      maybeRequestOtp()
    }
  }

  const loading = !user || disableAuthenticatorAppLoading

  return (
    <DefaultLayout
      backgroundColor='default'
      heading='Disable'
      showBackIcon
      loading={loading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            You currently have the authenticator app enabled, click the button
            below to turn it off.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 4, textAlign: 'center' }}>
          <Button color='blue' onClick={handleDisableAuthenticatorApp}>
            Turn off authenticator app
          </Button>
        </Grid>
      </Grid>
    </DefaultLayout>
  )
}
