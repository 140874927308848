/* eslint-disable */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A big decimal value */
  BigDecimal: any
  /** An ISO 8601-encoded date */
  ISO8601Date: any
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any
  /** Represents untyped JSON */
  JSON: any
}

/** Autogenerated input type of ActivateVault */
export type ActivateVaultInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['Int']
}

/** Autogenerated return type of ActivateVault. */
export type ActivateVaultPayload = {
  __typename?: 'ActivateVaultPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  vault?: Maybe<Vault>
}

/** List of a user's activities */
export type Activities = {
  __typename?: 'Activities'
  items: Array<Activity>
  totalCount: Scalars['Int']
  totalPages: Scalars['Int']
}

/** A record of a user's activity */
export type Activity = {
  __typename?: 'Activity'
  createdAt: Scalars['ISO8601DateTime']
  entries: Array<LedgerEntry>
  id: Scalars['Int']
  reference: Scalars['String']
  total: Scalars['BigDecimal']
  vault?: Maybe<Vault>
}

/** An AML verification answer */
export type AmlVerificationAnswer = {
  __typename?: 'AmlVerificationAnswer'
  answer?: Maybe<Scalars['String']>
  chosenAnswerOptionIds?: Maybe<Array<Scalars['ID']>>
  comments?: Maybe<Scalars['String']>
  id: Scalars['ID']
}

/** Attributes to receive an AML verification answer */
export type AmlVerificationAnswerInput = {
  /** The answer text */
  answer?: Maybe<Scalars['String']>
  /** The answer option id(s) */
  chosenAnswerOptionIds?: Maybe<Array<Scalars['ID']>>
  /** The answer comments */
  comments?: Maybe<Scalars['String']>
  /** The question id */
  questionId: Scalars['ID']
}

/** An AML verification answer option */
export type AmlVerificationAnswerOption = {
  __typename?: 'AmlVerificationAnswerOption'
  answer: Scalars['String']
  commentsRequired: Scalars['Boolean']
  displayOrder: Scalars['Int']
  id: Scalars['ID']
  identifier: Scalars['String']
}

/** An AML verification question */
export type AmlVerificationQuestion = {
  __typename?: 'AmlVerificationQuestion'
  answer?: Maybe<AmlVerificationAnswer>
  answerOptions: Array<AmlVerificationAnswerOption>
  displayOrder: Scalars['Int']
  format: Scalars['String']
  id: Scalars['ID']
  identifier: Scalars['String']
  question: Scalars['String']
}

/** An AML verification question section */
export type AmlVerificationQuestionSection = {
  __typename?: 'AmlVerificationQuestionSection'
  displayOrder: Scalars['Int']
  id: Scalars['ID']
  identifier: Scalars['String']
  name: Scalars['String']
  questions: Array<AmlVerificationQuestion>
}

/** Autogenerated input type of AnswerAmlQuestions */
export type AnswerAmlQuestionsInput = {
  answers: Array<AmlVerificationAnswerInput>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** A Auto Invest */
export type AutoInvest = {
  __typename?: 'AutoInvest'
  amount: Scalars['BigDecimal']
  duration: Scalars['String']
  id: Scalars['ID']
  investBy: Scalars['String']
  nextAutoinvestDate?: Maybe<Scalars['ISO8601Date']>
  product: Product
  user?: Maybe<User>
  vault?: Maybe<Vault>
}

/** A buy order */
export type BuyOrder = {
  __typename?: 'BuyOrder'
  buyer: User
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  marketTransaction: MarketTransaction
  pricePerUnit: Scalars['String']
  state: Scalars['String']
  unit: Scalars['String']
  weight: Scalars['Int']
}

/** Autogenerated input type of BuyVoucher */
export type BuyVoucherInput = {
  amount: Scalars['BigDecimal']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  paymentIntentId?: Maybe<Scalars['String']>
  toEmail: Scalars['String']
  toName: Scalars['String']
}

/** Autogenerated return type of BuyVoucher. */
export type BuyVoucherPayload = {
  __typename?: 'BuyVoucherPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

/** Autogenerated input type of CreateAutoInvest */
export type CreateAutoInvestInput = {
  amount: Scalars['BigDecimal']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  duration: Scalars['String']
  investBy: Scalars['String']
  productId: Scalars['Int']
  vaultId: Scalars['Int']
}

/** Autogenerated input type of CreateCustomerPopupInteraction */
export type CreateCustomerPopupInteractionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  popupId: Scalars['Int']
}

/** Autogenerated input type of CreateMarketTransaction */
export type CreateMarketTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  reviewOrderId: Scalars['Int']
  vaultId?: Maybe<Scalars['Int']>
}

/** Autogenerated input type of CreateOrUpdateVault */
export type CreateOrUpdateVaultInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  name: Scalars['String']
  relationship?: Maybe<Scalars['String']>
}

/** Autogenerated input type of CreatePoliDirectDebit */
export type CreatePoliDirectDebitInput = {
  amount: Scalars['BigDecimal']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** Autogenerated return type of CreatePoliDirectDebit. */
export type CreatePoliDirectDebitPayload = {
  __typename?: 'CreatePoliDirectDebitPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  navigationUrl?: Maybe<Scalars['String']>
}

/** Autogenerated input type of CreateReviewOrderForBuy */
export type CreateReviewOrderForBuyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  productId: Scalars['Int']
  weight?: Maybe<Scalars['Int']>
}

/** Autogenerated input type of CreateReviewOrderForSell */
export type CreateReviewOrderForSellInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  productId: Scalars['Int']
  weight?: Maybe<Scalars['Int']>
}

/** The Popup shows to the user in front end */
export type CustomerPopup = {
  __typename?: 'CustomerPopup'
  bodyText: Scalars['String']
  buttonLink: Scalars['String']
  buttonText: Scalars['String']
  displayOpt?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  title: Scalars['String']
}

/** Autogenerated input type of DeleteAutoInvest */
export type DeleteAutoInvestInput = {
  autoInvestId: Scalars['Int']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** Autogenerated input type of DisableAuthenticatorApp */
export type DisableAuthenticatorAppInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** Autogenerated input type of EnableAuthenticatorApp */
export type EnableAuthenticatorAppInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  otp: Scalars['String']
}

/** A user's credit wallet entry */
export type ExpirableWalletCreditEntry = LedgerEntry & {
  __typename?: 'ExpirableWalletCreditEntry'
  /** The timestamp when entry was created */
  createdAt: Scalars['ISO8601DateTime']
  /** The amount credited */
  credit: Scalars['BigDecimal']
  /** The amount debited */
  debit: Scalars['BigDecimal']
  /** The owner of the entry */
  owner: User
  /** The source of the transaction */
  source: Source
}

/** A fee plan */
export type FeePlan = {
  __typename?: 'FeePlan'
  description: Scalars['String']
  name: Scalars['String']
}

/** Autogenerated input type of GenerateOtp */
export type GenerateOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** The holding balance of a user for a specific spot price */
export type HistoricalHoldingBalance = {
  __typename?: 'HistoricalHoldingBalance'
  spotDate: Scalars['ISO8601Date']
  totalHoldingBalance: Scalars['BigDecimal']
}

/** A historical spot price */
export type HistoricalSpotPrice = {
  __typename?: 'HistoricalSpotPrice'
  id: Scalars['ID']
  midpointPerG: Scalars['BigDecimal']
  spotDate: Scalars['ISO8601Date']
}

/** Input for pagination */
export type InputPagination = {
  limit?: Maybe<Scalars['Int']>
  page?: Maybe<Scalars['Int']>
}

export type LedgerEntry = {
  /** The timestamp when entry was created */
  createdAt: Scalars['ISO8601DateTime']
  /** The amount credited */
  credit: Scalars['BigDecimal']
  /** The amount debited */
  debit: Scalars['BigDecimal']
  /** The owner of the entry */
  owner: User
  /** The source of the transaction */
  source: Source
}

/** A market order */
export type MarketOrder = {
  __typename?: 'MarketOrder'
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  orderType: Scalars['String']
  pricePerUnit: Scalars['String']
  quantity: Scalars['Int']
  unit: Scalars['String']
}

/** A market transaction */
export type MarketTransaction = {
  __typename?: 'MarketTransaction'
  buyOrder: BuyOrder
  sellOrder: SellOrder
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Activate Kid Vault User */
  activateVault?: Maybe<ActivateVaultPayload>
  /** Record answers to AML questions */
  answerAmlQuestions?: Maybe<Array<AmlVerificationQuestionSection>>
  /** Buy voucher by user to gift */
  buyVoucher?: Maybe<BuyVoucherPayload>
  /** Create a auto investment */
  createAutoInvest?: Maybe<AutoInvest>
  /** Create customer popup interaction record to track display customer popup or not. */
  createCustomerPopupInteraction?: Maybe<Scalars['Boolean']>
  /** Create a market order for the currently logged in user */
  createMarketTransaction?: Maybe<MarketTransaction>
  /** Create or Update Vault of user */
  createOrUpdateVault?: Maybe<Vault>
  /** Initiate Transaction */
  createPoliDirectDebit?: Maybe<CreatePoliDirectDebitPayload>
  /** Create a review order for the currently logged in user to buy gold */
  createReviewOrderForBuy?: Maybe<ReviewOrder>
  /** Create a review order for the currently logged in user to sell gold */
  createReviewOrderForSell?: Maybe<ReviewOrder>
  /** Delete a auto investment */
  deleteAutoInvest?: Maybe<Scalars['Boolean']>
  /** Disables authenticator app-based OTP for current user */
  disableAuthenticatorApp?: Maybe<Scalars['String']>
  /** Enables authenticator app-based OTP for current user. Returns an array of otp backup codes */
  enableAuthenticatorApp?: Maybe<Array<Scalars['String']>>
  /** Generate OTP for current user */
  generateOtp?: Maybe<Scalars['Boolean']>
  /** Redeems a voucher against the currently logged in user */
  redeemVoucher?: Maybe<Voucher>
  /** Allows the user to request identity verification for themselves */
  requestIdentityVerification?: Maybe<Profile>
  /** request get Poli transaction to verify status */
  requestPoliTransactionVerification?: Maybe<RequestPoliTransactionVerificationPayload>
  /** Create a requests to withdraw funds from the current_user's wallet */
  requestWithdrawFunds?: Maybe<WithdrawalRequest>
  /** Updates the password for the currently logged in user */
  updatePassword?: Maybe<Scalars['Boolean']>
  /** Update the profile of the currently logged in user on Profile Setting */
  updateProfile?: Maybe<Profile>
  /** Update the profile of the currently logged in user during onboarding */
  updateProfileForOnboarding?: Maybe<Profile>
  /** Update a review order with an weight */
  updateReviewOrderWithVault?: Maybe<ReviewOrder>
  /** Update a review order with an weight */
  updateReviewOrderWithWeight?: Maybe<ReviewOrder>
  /** Verify OTP for current user */
  verifyOtp?: Maybe<Scalars['Boolean']>
  /** handle and verify stripe transaction with stripe_payment_intent_identifier */
  verifyStripeTransaction?: Maybe<VerifyStripeTransactionPayload>
}

export type MutationActivateVaultArgs = {
  input: ActivateVaultInput
}

export type MutationAnswerAmlQuestionsArgs = {
  input: AnswerAmlQuestionsInput
}

export type MutationBuyVoucherArgs = {
  input: BuyVoucherInput
}

export type MutationCreateAutoInvestArgs = {
  input: CreateAutoInvestInput
}

export type MutationCreateCustomerPopupInteractionArgs = {
  input: CreateCustomerPopupInteractionInput
}

export type MutationCreateMarketTransactionArgs = {
  input: CreateMarketTransactionInput
}

export type MutationCreateOrUpdateVaultArgs = {
  input: CreateOrUpdateVaultInput
}

export type MutationCreatePoliDirectDebitArgs = {
  input: CreatePoliDirectDebitInput
}

export type MutationCreateReviewOrderForBuyArgs = {
  input: CreateReviewOrderForBuyInput
}

export type MutationCreateReviewOrderForSellArgs = {
  input: CreateReviewOrderForSellInput
}

export type MutationDeleteAutoInvestArgs = {
  input: DeleteAutoInvestInput
}

export type MutationDisableAuthenticatorAppArgs = {
  input: DisableAuthenticatorAppInput
}

export type MutationEnableAuthenticatorAppArgs = {
  input: EnableAuthenticatorAppInput
}

export type MutationGenerateOtpArgs = {
  input: GenerateOtpInput
}

export type MutationRedeemVoucherArgs = {
  input: RedeemVoucherInput
}

export type MutationRequestIdentityVerificationArgs = {
  input: RequestIdentityVerificationInput
}

export type MutationRequestPoliTransactionVerificationArgs = {
  input: RequestPoliTransactionVerificationInput
}

export type MutationRequestWithdrawFundsArgs = {
  input: RequestWithdrawFundsInput
}

export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput
}

export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput
}

export type MutationUpdateProfileForOnboardingArgs = {
  input: UpdateProfileForOnboardingInput
}

export type MutationUpdateReviewOrderWithVaultArgs = {
  input: UpdateReviewOrderWithVaultInput
}

export type MutationUpdateReviewOrderWithWeightArgs = {
  input: UpdateReviewOrderWithWeightInput
}

export type MutationVerifyOtpArgs = {
  input: VerifyOtpInput
}

export type MutationVerifyStripeTransactionArgs = {
  input: VerifyStripeTransactionInput
}

/** A product */
export type Product = {
  __typename?: 'Product'
  description: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  sku: Scalars['String']
  tradable: Scalars['Boolean']
}

/** Valuation and performance of a product */
export type ProductPerformance = {
  __typename?: 'ProductPerformance'
  currentValue: Scalars['BigDecimal']
  netProfit: Scalars['String']
  ownedAllotmentsCount: Scalars['Int']
  periodInvestedInDays: Scalars['Int']
  product: Product
  spotPrice: SpotPrice
  totalCost: Scalars['BigDecimal']
  totalReturn: Scalars['BigDecimal']
  totalReturnAsPercentage: Scalars['BigDecimal']
  unit: Scalars['String']
}

/** The profile of a user */
export type Profile = {
  __typename?: 'Profile'
  addressLine1?: Maybe<Scalars['String']>
  addressLine2?: Maybe<Scalars['String']>
  amlVerificationQuestionsAnswered: Scalars['Boolean']
  amlVerified: Scalars['Boolean']
  bankAccountName?: Maybe<Scalars['String']>
  bankAccountNumber?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  complete: Scalars['Boolean']
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>
  driversLicenceNumber?: Maybe<Scalars['String']>
  driversLicenceVersion?: Maybe<Scalars['String']>
  email: Scalars['String']
  ethnicity?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  identifier: Scalars['String']
  identityVerificationFailureReasons: Array<Scalars['JSON']>
  identityVerificationRequestInProgress: Scalars['Boolean']
  identityVerified: Scalars['Boolean']
  lastName?: Maybe<Scalars['String']>
  locked: Scalars['Boolean']
  middleName?: Maybe<Scalars['String']>
  nzMobilePhoneNumber?: Maybe<Scalars['String']>
  onboarded: Scalars['Boolean']
  partialIdentityVerified: Scalars['Boolean']
  passportExpiry?: Maybe<Scalars['String']>
  passportNumber?: Maybe<Scalars['String']>
  postcode?: Maybe<Scalars['String']>
  preferredName?: Maybe<Scalars['String']>
  reference?: Maybe<Scalars['String']>
  referralCode?: Maybe<Scalars['String']>
  suburb?: Maybe<Scalars['String']>
  user: User
  verificationMethod?: Maybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  /** Retrieve the activity for the currently logged in user */
  activityForCurrentUser?: Maybe<Activities>
  /** Retrieve the AML verification question and related info */
  amlVerificationQuestions: Array<AmlVerificationQuestionSection>
  /**
   * Retrieve the authenticator provisioning uri of the currently logged in user.
   * Will only work if the user does not have an authenticator app already enabled.
   */
  authenticatorAppProvisioningUri: Scalars['String']
  /** Retrieve a buy order for the current user given an id */
  buyOrderForCurrentUser: BuyOrder
  /** Retrieve the profile of the currently logged in user */
  currentUser: User
  /** Retrieve list popups to show the users */
  customerPopup: Array<CustomerPopup>
  /** Retrieve a user's holding balance over time */
  historicalHoldingBalances: Array<HistoricalHoldingBalance>
  /** Retrieves historical spot prices */
  historicalSpotPrices: Array<HistoricalSpotPrice>
  /** Retrieve latest spot price */
  latestSpotPrice: SpotPrice
  /** Retrieve a buy order for the current user given an id */
  marketOrdersForProduct: Array<MarketOrder>
  /** Returns the performance for a given product owned by the current user */
  productPerformance: ProductPerformance
  /** get product performance summary */
  productPerformanceSummaries: Array<ProductPerformance>
  /** get available products. */
  products: Array<Product>
  /** Retrieve the profile for the current user */
  profileForCurrentUser: Profile
  /** Retrieve a review order for the current user given an id */
  reviewOrderForCurrentUser: ReviewOrder
  /** Retrieve the securities owned for the currently logged in user */
  securitiesOwnedForCurrentUser: SecuritiesOwned
  /** Retrieve a sell order for the current user given an id */
  sellOrderForCurrentUser: SellOrder
  /** get detail vault of user. */
  vaultDetail?: Maybe<Vault>
  /** Retrieve the wallet for the currently logged in user */
  walletForCurrentUser: Wallet
}

export type QueryActivityForCurrentUserArgs = {
  inputPagination?: Maybe<InputPagination>
}

export type QueryBuyOrderForCurrentUserArgs = {
  buyOrderId: Scalars['Int']
}

export type QueryHistoricalHoldingBalancesArgs = {
  productIds?: Maybe<Array<Scalars['Int']>>
  timeframe?: Maybe<Scalars['String']>
  vaultIds?: Maybe<Array<Scalars['Int']>>
}

export type QueryHistoricalSpotPricesArgs = {
  productId: Scalars['Int']
  timeframe?: Maybe<Scalars['String']>
}

export type QueryLatestSpotPriceArgs = {
  productId: Scalars['Int']
}

export type QueryMarketOrdersForProductArgs = {
  productId: Scalars['Int']
  vaultIds?: Maybe<Array<Scalars['Int']>>
}

export type QueryProductPerformanceArgs = {
  productId: Scalars['Int']
}

export type QueryReviewOrderForCurrentUserArgs = {
  reviewOrderId: Scalars['Int']
}

export type QuerySecuritiesOwnedForCurrentUserArgs = {
  productId: Scalars['Int']
}

export type QuerySellOrderForCurrentUserArgs = {
  sellOrderId: Scalars['Int']
}

export type QueryVaultDetailArgs = {
  vaultId: Scalars['Int']
}

/** Autogenerated input type of RedeemVoucher */
export type RedeemVoucherInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  redemptionCode: Scalars['String']
}

/** Autogenerated input type of RequestIdentityVerification */
export type RequestIdentityVerificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** Autogenerated input type of RequestPoliTransactionVerification */
export type RequestPoliTransactionVerificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  transactionToken: Scalars['String']
}

/** Autogenerated return type of RequestPoliTransactionVerification. */
export type RequestPoliTransactionVerificationPayload = {
  __typename?: 'RequestPoliTransactionVerificationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
  transactionStatus?: Maybe<Scalars['String']>
}

/** Autogenerated input type of RequestWithdrawFunds */
export type RequestWithdrawFundsInput = {
  amountToWithdraw: Scalars['BigDecimal']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** A review order */
export type ReviewOrder = {
  __typename?: 'ReviewOrder'
  allotmentsOwned: Scalars['Int']
  fees: Array<ReviewOrderFee>
  id: Scalars['ID']
  lockedUntil: Scalars['ISO8601DateTime']
  pricePerUnit: Scalars['BigDecimal']
  product: Product
  spotPrice: Scalars['BigDecimal']
  totalFees: Scalars['BigDecimal']
  totalPriceWithFees: Scalars['BigDecimal']
  totalPriceWithoutFees: Scalars['BigDecimal']
  type: Scalars['String']
  unit: Scalars['String']
  user: User
  userHasBalance: Scalars['Boolean']
  vault?: Maybe<Vault>
  weight: Scalars['String']
}

/** A calculated fee amount */
export type ReviewOrderFee = {
  __typename?: 'ReviewOrderFee'
  fee: Scalars['BigDecimal']
  plan: FeePlan
  reviewOrder: ReviewOrder
}

/** A summary of the allotments owned by product */
export type SecuritiesOwned = {
  __typename?: 'SecuritiesOwned'
  allotmentsOwned: Scalars['Int']
  hasHistory: Scalars['Boolean']
  product: Product
  securities: Array<SecurityOwned>
  user: User
  userId: Scalars['ID']
}

/** A security */
export type Security = {
  __typename?: 'Security'
  id: Scalars['ID']
  product: Product
  serialNumber: Scalars['String']
  unit: Scalars['String']
}

/** A summary of the allotments owned by product */
export type SecurityOwned = {
  __typename?: 'SecurityOwned'
  ownedAllotmentsCount: Scalars['Int']
  security: Security
}

/** A sell order */
export type SellOrder = {
  __typename?: 'SellOrder'
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  marketTransaction: MarketTransaction
  pricePerUnit: Scalars['String']
  seller: User
  state: Scalars['String']
  unit: Scalars['String']
  weight: Scalars['Int']
}

export type Source = {
  __typename?: 'Source'
  sourceCategorisation: Scalars['String']
  sourceInfo: Scalars['String']
  sourceNote?: Maybe<Scalars['String']>
}

/** A spot price */
export type SpotPrice = {
  __typename?: 'SpotPrice'
  askPricePerDg: Scalars['BigDecimal']
  askPricePerOz: Scalars['BigDecimal']
  bidPricePerDg: Scalars['BigDecimal']
  bidPricePerOz: Scalars['BigDecimal']
  exchangeRateTimestamp: Scalars['ISO8601DateTime']
  exchangeRateUsdNzd: Scalars['BigDecimal']
  id: Scalars['ID']
  midpointPerDg: Scalars['BigDecimal']
  spotTime: Scalars['ISO8601DateTime']
}

/** Autogenerated input type of UpdatePassword */
export type UpdatePasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  currentPassword: Scalars['String']
  newPassword: Scalars['String']
}

/** Autogenerated input type of UpdateProfileForOnboarding */
export type UpdateProfileForOnboardingInput = {
  addressLine1?: Maybe<Scalars['String']>
  addressLine2?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>
  ethnicity?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  middleName?: Maybe<Scalars['String']>
  nzMobilePhoneNumber?: Maybe<Scalars['String']>
  postcode?: Maybe<Scalars['String']>
  preferredName?: Maybe<Scalars['String']>
  suburb?: Maybe<Scalars['String']>
  verificationData?: Maybe<VerificationData>
  verificationMethod?: Maybe<Scalars['String']>
}

/** Autogenerated input type of UpdateProfile */
export type UpdateProfileInput = {
  addressLine1?: Maybe<Scalars['String']>
  addressLine2?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  nzMobilePhoneNumber?: Maybe<Scalars['String']>
  postcode?: Maybe<Scalars['String']>
  preferredName?: Maybe<Scalars['String']>
  suburb?: Maybe<Scalars['String']>
}

/** Autogenerated input type of UpdateReviewOrderWithVault */
export type UpdateReviewOrderWithVaultInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  reviewOrderId: Scalars['Int']
  vaultId?: Maybe<Scalars['Int']>
}

/** Autogenerated input type of UpdateReviewOrderWithWeight */
export type UpdateReviewOrderWithWeightInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  reviewOrderId: Scalars['Int']
  weight: Scalars['Int']
}

/** A user */
export type User = {
  __typename?: 'User'
  activatedVault?: Maybe<Vault>
  authenticatorAppEnabled: Scalars['Boolean']
  autoInvests: Array<AutoInvest>
  email: Scalars['String']
  firstTimeTopUpWithPoli: Scalars['Boolean']
  id: Scalars['ID']
  otpVerified: Scalars['Boolean']
  profile?: Maybe<Profile>
  securitiesOwned: Array<SecuritiesOwned>
  vaults: Array<Vault>
  wallet: Wallet
}

/** User's Vaults */
export type Vault = {
  __typename?: 'Vault'
  balance: Scalars['String']
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>
  email?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isDefault: Scalars['Boolean']
  name?: Maybe<Scalars['String']>
  relationship?: Maybe<Scalars['String']>
  securitiesOwned: Array<SecuritiesOwned>
}

export type VerificationData = {
  driversLicenceNumber?: Maybe<Scalars['String']>
  driversLicenceVersion?: Maybe<Scalars['String']>
  passportExpiry?: Maybe<Scalars['ISO8601Date']>
  passportNumber?: Maybe<Scalars['String']>
}

/** Autogenerated input type of VerifyOtp */
export type VerifyOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  otp: Scalars['String']
}

/** Autogenerated input type of VerifyStripeTransaction */
export type VerifyStripeTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  paymentIntentIdentifier: Scalars['String']
}

/** Autogenerated return type of VerifyStripeTransaction. */
export type VerifyStripeTransactionPayload = {
  __typename?: 'VerifyStripeTransactionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
  transactionStatus?: Maybe<Scalars['String']>
}

/** A voucher */
export type Voucher = {
  __typename?: 'Voucher'
  amount: Scalars['BigDecimal']
  expiresAt: Scalars['ISO8601DateTime']
  fromEmail: Scalars['String']
  fromName: Scalars['String']
  id: Scalars['ID']
  note?: Maybe<Scalars['String']>
  redeemedAt?: Maybe<Scalars['ISO8601DateTime']>
  status: Scalars['String']
  toEmail: Scalars['String']
  toName: Scalars['String']
}

/** A user's wallet */
export type Wallet = {
  __typename?: 'Wallet'
  /** The balance of the user's wallet (incl. vouchers) in dollars and cents e.g. '10.50' */
  balance: Scalars['String']
  /** The balance of the user's expirable wallet credit only in dollars and cents e.g. '10.50' */
  expirableWalletCreditBalance: Scalars['String']
  /** Credit from special promotions and referral bonuses in dollars and cents e.g. '10.50' */
  rewardCredit: Scalars['String']
  user: User
  userId: Scalars['ID']
  /** Credit from the redemption of gifts in dollars and cents e.g. '10.50' */
  voucherCredit: Scalars['String']
  /** The balance of the user's wallet only in dollars and cents e.g. '10.50' */
  walletBalance: Scalars['String']
  /** The withdrawable amount of the user's wallet credit only in dollars and cents e.g. '10.50' */
  withdrawableAmount: Scalars['String']
  /** The amount of funds that have been withheld and may not be withdrawn yet. dollars and cents e.g. '10.50' */
  withheldFunds: Scalars['String']
}

/** A user's wallet entry */
export type WalletEntry = LedgerEntry & {
  __typename?: 'WalletEntry'
  /** The timestamp when entry was created */
  createdAt: Scalars['ISO8601DateTime']
  /** The amount credited */
  credit: Scalars['BigDecimal']
  /** The amount debited */
  debit: Scalars['BigDecimal']
  /** The owner of the entry */
  owner: User
  /** The source of the transaction */
  source: Source
}

/** A request to withdraw funds from a user's wallet */
export type WithdrawalRequest = {
  __typename?: 'WithdrawalRequest'
  amountToWithdraw: Scalars['BigDecimal']
  bankAccount: Scalars['String']
  id: Scalars['ID']
  processedAt?: Maybe<Scalars['ISO8601DateTime']>
  profile: Profile
  status: Scalars['String']
}

export type VerifyOtpMutationVariables = Exact<{
  input: VerifyOtpInput
}>

export type VerifyOtpMutation = {
  __typename?: 'Mutation'
  verifyOtp?: Maybe<boolean>
}

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentUserQuery = {
  __typename?: 'Query'
  currentUser: {
    __typename?: 'User'
    email: string
    id: string
    otpVerified: boolean
    authenticatorAppEnabled: boolean
    firstTimeTopUpWithPoli: boolean
    profile?: Maybe<{
      __typename?: 'Profile'
      bankAccountName?: Maybe<string>
      bankAccountNumber?: Maybe<string>
      firstName?: Maybe<string>
      identifier: string
      onboarded: boolean
      preferredName?: Maybe<string>
      reference?: Maybe<string>
      addressLine1?: Maybe<string>
      addressLine2?: Maybe<string>
      suburb?: Maybe<string>
      city?: Maybe<string>
      postcode?: Maybe<string>
      lastName?: Maybe<string>
      partialIdentityVerified: boolean
      dateOfBirth?: Maybe<any>
      referralCode?: Maybe<string>
      driversLicenceNumber?: Maybe<string>
      driversLicenceVersion?: Maybe<string>
      passportExpiry?: Maybe<string>
      passportNumber?: Maybe<string>
      verificationMethod?: Maybe<string>
      ethnicity?: Maybe<string>
    }>
    securitiesOwned: Array<{
      __typename?: 'SecuritiesOwned'
      allotmentsOwned: number
      hasHistory: boolean
      product: {
        __typename?: 'Product'
        name: string
        description: string
        id: string
        tradable: boolean
      }
      securities: Array<{
        __typename?: 'SecurityOwned'
        ownedAllotmentsCount: number
      }>
    }>
    wallet: {
      __typename?: 'Wallet'
      balance: string
      userId: string
      expirableWalletCreditBalance: string
      walletBalance: string
    }
    vaults: Array<{
      __typename?: 'Vault'
      dateOfBirth?: Maybe<any>
      email?: Maybe<string>
      id: string
      name?: Maybe<string>
      relationship?: Maybe<string>
      balance: string
      securitiesOwned: Array<{
        __typename?: 'SecuritiesOwned'
        allotmentsOwned: number
        hasHistory: boolean
        product: {
          __typename?: 'Product'
          name: string
          description: string
          id: string
          tradable: boolean
        }
        securities: Array<{
          __typename?: 'SecurityOwned'
          ownedAllotmentsCount: number
        }>
      }>
    }>
    activatedVault?: Maybe<{
      __typename?: 'Vault'
      dateOfBirth?: Maybe<any>
      email?: Maybe<string>
      id: string
      name?: Maybe<string>
      relationship?: Maybe<string>
      balance: string
      securitiesOwned: Array<{
        __typename?: 'SecuritiesOwned'
        allotmentsOwned: number
        hasHistory: boolean
        product: {
          __typename?: 'Product'
          name: string
          description: string
          id: string
          tradable: boolean
        }
        securities: Array<{
          __typename?: 'SecurityOwned'
          ownedAllotmentsCount: number
        }>
      }>
    }>
    autoInvests: Array<{
      __typename?: 'AutoInvest'
      amount: any
      duration: string
      nextAutoinvestDate?: Maybe<any>
      id: string
      investBy: string
      product: { __typename?: 'Product'; name: string }
    }>
  }
}

export type GenerateOtpMutationVariables = Exact<{ [key: string]: never }>

export type GenerateOtpMutation = {
  __typename?: 'Mutation'
  generateOtp?: Maybe<boolean>
}

export type ProductsQueryVariables = Exact<{ [key: string]: never }>

export type ProductsQuery = {
  __typename?: 'Query'
  products: Array<{
    __typename?: 'Product'
    id: string
    name: string
    description: string
    sku: string
    tradable: boolean
  }>
}

export type GetHistoricalSpotPricesQueryVariables = Exact<{
  timeframe?: Maybe<Scalars['String']>
  productId: Scalars['Int']
}>

export type GetHistoricalSpotPricesQuery = {
  __typename?: 'Query'
  historicalSpotPrices: Array<{
    __typename?: 'HistoricalSpotPrice'
    id: string
    midpointPerG: any
    spotDate: any
  }>
}

export type GetHistoricalPerformanceQueryVariables = Exact<{
  timeframe?: Maybe<Scalars['String']>
  productIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
  vaultIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
}>

export type GetHistoricalPerformanceQuery = {
  __typename?: 'Query'
  historicalHoldingBalances: Array<{
    __typename?: 'HistoricalHoldingBalance'
    spotDate: any
    totalHoldingBalance: any
  }>
}

export type CustomerPopupQueryVariables = Exact<{ [key: string]: never }>

export type CustomerPopupQuery = {
  __typename?: 'Query'
  customerPopup: Array<{
    __typename?: 'CustomerPopup'
    bodyText: string
    buttonLink: string
    buttonText: string
    displayOpt?: Maybe<boolean>
    id: string
    title: string
  }>
}

export type CreateCustomerPopupInteractionMutationVariables = Exact<{
  input: CreateCustomerPopupInteractionInput
}>

export type CreateCustomerPopupInteractionMutation = {
  __typename?: 'Mutation'
  createCustomerPopupInteraction?: Maybe<boolean>
}

export type VerifyStripeTransactionMutationVariables = Exact<{
  input: VerifyStripeTransactionInput
}>

export type VerifyStripeTransactionMutation = {
  __typename?: 'Mutation'
  verifyStripeTransaction?: Maybe<{
    __typename?: 'VerifyStripeTransactionPayload'
    success?: Maybe<boolean>
    transactionStatus?: Maybe<string>
  }>
}

export type ActivityForCurrentUserQueryVariables = Exact<{
  limit: Scalars['Int']
  page: Scalars['Int']
}>

export type ActivityForCurrentUserQuery = {
  __typename?: 'Query'
  activityForCurrentUser?: Maybe<{
    __typename?: 'Activities'
    totalCount: number
    totalPages: number
    items: Array<{
      __typename?: 'Activity'
      id: number
      total: any
      reference: string
      createdAt: any
      entries: Array<
        | {
            __typename?: 'ExpirableWalletCreditEntry'
            debit: any
            credit: any
            createdAt: any
            source: {
              __typename?: 'Source'
              sourceCategorisation: string
              sourceInfo: string
              sourceNote?: Maybe<string>
            }
          }
        | {
            __typename?: 'WalletEntry'
            debit: any
            credit: any
            createdAt: any
            source: {
              __typename?: 'Source'
              sourceCategorisation: string
              sourceInfo: string
              sourceNote?: Maybe<string>
            }
          }
      >
      vault?: Maybe<{
        __typename?: 'Vault'
        balance: string
        dateOfBirth?: Maybe<any>
        email?: Maybe<string>
        id: string
        name?: Maybe<string>
        relationship?: Maybe<string>
        securitiesOwned: Array<{
          __typename?: 'SecuritiesOwned'
          allotmentsOwned: number
          hasHistory: boolean
          product: { __typename?: 'Product'; name: string; id: string }
          securities: Array<{
            __typename?: 'SecurityOwned'
            ownedAllotmentsCount: number
          }>
        }>
      }>
    }>
  }>
}

export type CreateAutoInvestMutationVariables = Exact<{
  input: CreateAutoInvestInput
}>

export type CreateAutoInvestMutation = {
  __typename?: 'Mutation'
  createAutoInvest?: Maybe<{
    __typename?: 'AutoInvest'
    amount: any
    duration: string
    id: string
  }>
}

export type DeleteAutoInvestMutationVariables = Exact<{
  input: DeleteAutoInvestInput
}>

export type DeleteAutoInvestMutation = {
  __typename?: 'Mutation'
  deleteAutoInvest?: Maybe<boolean>
}

export type ReviewOrderForBuyQueryVariables = Exact<{
  reviewOrderId: Scalars['Int']
}>

export type ReviewOrderForBuyQuery = {
  __typename?: 'Query'
  reviewOrder: {
    __typename?: 'ReviewOrder'
    id: string
    spotPrice: any
    totalFees: any
    totalPriceWithFees: any
    totalPriceWithoutFees: any
    userHasBalance: boolean
    allotmentsOwned: number
    pricePerUnit: any
    weight: string
    user: {
      __typename?: 'User'
      id: string
      wallet: {
        __typename?: 'Wallet'
        balance: string
        userId: string
        walletBalance: string
      }
    }
    product: {
      __typename?: 'Product'
      id: string
      name: string
      tradable: boolean
    }
  }
}

export type UpdateReviewOrderForBuyMutationVariables = Exact<{
  input: UpdateReviewOrderWithWeightInput
}>

export type UpdateReviewOrderForBuyMutation = {
  __typename?: 'Mutation'
  reviewOrder?: Maybe<{
    __typename?: 'ReviewOrder'
    allotmentsOwned: number
    id: string
    lockedUntil: any
    pricePerUnit: any
    spotPrice: any
    totalFees: any
    totalPriceWithFees: any
    totalPriceWithoutFees: any
    type: string
    unit: string
    weight: string
    userHasBalance: boolean
    fees: Array<{
      __typename?: 'ReviewOrderFee'
      fee: any
      plan: { __typename?: 'FeePlan'; description: string; name: string }
    }>
  }>
}

export type UpdatePasswordMutationVariables = Exact<{
  input: UpdatePasswordInput
}>

export type UpdatePasswordMutation = {
  __typename?: 'Mutation'
  updatePassword?: Maybe<boolean>
}

export type CreateReviewOrderForBuyMutationVariables = Exact<{
  input: CreateReviewOrderForBuyInput
}>

export type CreateReviewOrderForBuyMutation = {
  __typename?: 'Mutation'
  reviewOrder?: Maybe<{
    __typename?: 'ReviewOrder'
    id: string
    spotPrice: any
    totalFees: any
    totalPriceWithFees: any
    totalPriceWithoutFees: any
    userHasBalance: boolean
    weight: string
    user: {
      __typename?: 'User'
      id: string
      wallet: { __typename?: 'Wallet'; balance: string; userId: string }
    }
  }>
}

export type CreateReviewOrderForSellMutationVariables = Exact<{
  input: CreateReviewOrderForSellInput
}>

export type CreateReviewOrderForSellMutation = {
  __typename?: 'Mutation'
  reviewOrder?: Maybe<{
    __typename?: 'ReviewOrder'
    allotmentsOwned: number
    id: string
    spotPrice: any
    totalFees: any
    totalPriceWithFees: any
    totalPriceWithoutFees: any
    userHasBalance: boolean
    weight: string
  }>
}

export type BuyVoucherMutationVariables = Exact<{
  input: BuyVoucherInput
}>

export type BuyVoucherMutation = {
  __typename?: 'Mutation'
  buyVoucher?: Maybe<{
    __typename?: 'BuyVoucherPayload'
    clientMutationId?: Maybe<string>
    message?: Maybe<string>
    success?: Maybe<boolean>
  }>
}

export type WalletForCurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type WalletForCurrentUserQuery = {
  __typename?: 'Query'
  walletForCurrentUser: {
    __typename?: 'Wallet'
    balance: string
    walletBalance: string
    expirableWalletCreditBalance: string
    withdrawableAmount: string
    withheldFunds: string
    rewardCredit: string
    voucherCredit: string
  }
}

export type CreateOrUpdateVaultMutationVariables = Exact<{
  input: CreateOrUpdateVaultInput
}>

export type CreateOrUpdateVaultMutation = {
  __typename?: 'Mutation'
  createOrUpdateVault?: Maybe<{
    __typename?: 'Vault'
    dateOfBirth?: Maybe<any>
    email?: Maybe<string>
    id: string
    name?: Maybe<string>
    relationship?: Maybe<string>
  }>
}

export type VaultDetailQueryVariables = Exact<{
  vaultId: Scalars['Int']
}>

export type VaultDetailQuery = {
  __typename?: 'Query'
  vaultDetail?: Maybe<{
    __typename?: 'Vault'
    dateOfBirth?: Maybe<any>
    email?: Maybe<string>
    id: string
    name?: Maybe<string>
    relationship?: Maybe<string>
  }>
}

export type ReviewOrderForSellQueryVariables = Exact<{
  reviewOrderId: Scalars['Int']
}>

export type ReviewOrderForSellQuery = {
  __typename?: 'Query'
  reviewOrder: {
    __typename?: 'ReviewOrder'
    allotmentsOwned: number
    id: string
    spotPrice: any
    totalFees: any
    totalPriceWithFees: any
    totalPriceWithoutFees: any
    userHasBalance: boolean
    weight: string
    user: {
      __typename?: 'User'
      id: string
      wallet: { __typename?: 'Wallet'; balance: string; userId: string }
    }
    fees: Array<{
      __typename?: 'ReviewOrderFee'
      fee: any
      plan: { __typename?: 'FeePlan'; name: string }
    }>
    vault?: Maybe<{
      __typename?: 'Vault'
      id: string
      email?: Maybe<string>
      name?: Maybe<string>
      balance: string
      securitiesOwned: Array<{
        __typename?: 'SecuritiesOwned'
        allotmentsOwned: number
        hasHistory: boolean
        product: { __typename?: 'Product'; name: string; id: string }
        securities: Array<{
          __typename?: 'SecurityOwned'
          ownedAllotmentsCount: number
        }>
      }>
    }>
    product: { __typename?: 'Product'; id: string; name: string }
  }
}

export type UpdateReviewOrderForSellMutationVariables = Exact<{
  input: UpdateReviewOrderWithWeightInput
}>

export type UpdateReviewOrderForSellMutation = {
  __typename?: 'Mutation'
  reviewOrder?: Maybe<{
    __typename?: 'ReviewOrder'
    allotmentsOwned: number
    id: string
    spotPrice: any
    totalFees: any
    totalPriceWithFees: any
    totalPriceWithoutFees: any
    userHasBalance: boolean
    weight: string
    fees: Array<{
      __typename?: 'ReviewOrderFee'
      fee: any
      plan: { __typename?: 'FeePlan'; name: string }
    }>
  }>
}

export type MarketOrdersForProductQueryVariables = Exact<{
  productId: Scalars['Int']
}>

export type MarketOrdersForProductQuery = {
  __typename?: 'Query'
  marketOrdersForProduct: Array<{
    __typename?: 'MarketOrder'
    id: string
    createdAt: any
    orderType: string
    quantity: number
    unit: string
    pricePerUnit: string
  }>
}

export type ProductPerformanceSummariesQueryVariables = Exact<{
  [key: string]: never
}>

export type ProductPerformanceSummariesQuery = {
  __typename?: 'Query'
  productPerformanceSummaries: Array<{
    __typename?: 'ProductPerformance'
    currentValue: any
    totalCost: any
    totalReturn: any
    totalReturnAsPercentage: any
    product: {
      __typename?: 'Product'
      id: string
      name: string
      description: string
      sku: string
      tradable: boolean
    }
  }>
}

export type GetProfileForCurrentUserQueryVariables = Exact<{
  [key: string]: never
}>

export type GetProfileForCurrentUserQuery = {
  __typename?: 'Query'
  profileForCurrentUser: {
    __typename?: 'Profile'
    addressLine1?: Maybe<string>
    addressLine2?: Maybe<string>
    city?: Maybe<string>
    complete: boolean
    dateOfBirth?: Maybe<any>
    driversLicenceNumber?: Maybe<string>
    driversLicenceVersion?: Maybe<string>
    firstName?: Maybe<string>
    identifier: string
    lastName?: Maybe<string>
    middleName?: Maybe<string>
    nzMobilePhoneNumber?: Maybe<string>
    postcode?: Maybe<string>
    preferredName?: Maybe<string>
    suburb?: Maybe<string>
    passportNumber?: Maybe<string>
    passportExpiry?: Maybe<string>
    verificationMethod?: Maybe<string>
    ethnicity?: Maybe<string>
  }
}

export type UpdateProfileForOnboardingMutationVariables = Exact<{
  input: UpdateProfileForOnboardingInput
}>

export type UpdateProfileForOnboardingMutation = {
  __typename?: 'Mutation'
  profileForCurrentUser?: Maybe<{
    __typename?: 'Profile'
    identifier: string
    preferredName?: Maybe<string>
    ethnicity?: Maybe<string>
  }>
}

export type GetAmlVerificationQuestionsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAmlVerificationQuestionsQuery = {
  __typename?: 'Query'
  questionSections: Array<{
    __typename?: 'AmlVerificationQuestionSection'
    displayOrder: number
    id: string
    identifier: string
    name: string
    questions: Array<{
      __typename?: 'AmlVerificationQuestion'
      displayOrder: number
      format: string
      id: string
      identifier: string
      question: string
      answer?: Maybe<{
        __typename?: 'AmlVerificationAnswer'
        answer?: Maybe<string>
        chosenAnswerOptionIds?: Maybe<Array<string>>
        comments?: Maybe<string>
        id: string
      }>
      answerOptions: Array<{
        __typename?: 'AmlVerificationAnswerOption'
        answer: string
        commentsRequired: boolean
        displayOrder: number
        id: string
        identifier: string
      }>
    }>
  }>
}

export type GetIdentityVerificationStatusQueryVariables = Exact<{
  [key: string]: never
}>

export type GetIdentityVerificationStatusQuery = {
  __typename?: 'Query'
  identityVerificationStatus: {
    __typename?: 'Profile'
    identifier: string
    identityVerificationFailureReasons: Array<any>
    identityVerificationRequestInProgress: boolean
    identityVerified: boolean
  }
}

export type AnswerAmlQuestionsMutationVariables = Exact<{
  input: AnswerAmlQuestionsInput
}>

export type AnswerAmlQuestionsMutation = {
  __typename?: 'Mutation'
  questionSections?: Maybe<
    Array<{ __typename?: 'AmlVerificationQuestionSection'; id: string }>
  >
}

export type RequestIdentityVerificationMutationVariables = Exact<{
  input: RequestIdentityVerificationInput
}>

export type RequestIdentityVerificationMutation = {
  __typename?: 'Mutation'
  profile?: Maybe<{
    __typename?: 'Profile'
    identifier: string
    identityVerificationFailureReasons: Array<any>
    identityVerificationRequestInProgress: boolean
    identityVerified: boolean
  }>
}

export type RequestPoliTransactionVerificationMutationVariables = Exact<{
  input: RequestPoliTransactionVerificationInput
}>

export type RequestPoliTransactionVerificationMutation = {
  __typename?: 'Mutation'
  requestPoliTransactionVerification?: Maybe<{
    __typename?: 'RequestPoliTransactionVerificationPayload'
    success?: Maybe<boolean>
    message?: Maybe<string>
    transactionStatus?: Maybe<string>
  }>
}

export type ProfileForCurrentUserQueryVariables = Exact<{
  [key: string]: never
}>

export type ProfileForCurrentUserQuery = {
  __typename?: 'Query'
  profile: {
    __typename?: 'Profile'
    identifier: string
    dateOfBirth?: Maybe<any>
    email: string
    firstName?: Maybe<string>
    middleName?: Maybe<string>
    lastName?: Maybe<string>
    preferredName?: Maybe<string>
    driversLicenceNumber?: Maybe<string>
    driversLicenceVersion?: Maybe<string>
    nzMobilePhoneNumber?: Maybe<string>
    addressLine1?: Maybe<string>
    addressLine2?: Maybe<string>
    suburb?: Maybe<string>
    city?: Maybe<string>
    postcode?: Maybe<string>
    onboarded: boolean
    reference?: Maybe<string>
  }
}

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput
}>

export type UpdateProfileMutation = {
  __typename?: 'Mutation'
  profile?: Maybe<{
    __typename?: 'Profile'
    addressLine1?: Maybe<string>
    addressLine2?: Maybe<string>
    amlVerificationQuestionsAnswered: boolean
    amlVerified: boolean
    bankAccountName?: Maybe<string>
    bankAccountNumber?: Maybe<string>
    city?: Maybe<string>
    complete: boolean
    dateOfBirth?: Maybe<any>
    driversLicenceNumber?: Maybe<string>
    driversLicenceVersion?: Maybe<string>
    email: string
    firstName?: Maybe<string>
    identifier: string
    identityVerified: boolean
    identityVerificationFailureReasons: Array<any>
    identityVerificationRequestInProgress: boolean
    lastName?: Maybe<string>
    locked: boolean
    middleName?: Maybe<string>
    nzMobilePhoneNumber?: Maybe<string>
    onboarded: boolean
    postcode?: Maybe<string>
    preferredName?: Maybe<string>
    reference?: Maybe<string>
    suburb?: Maybe<string>
  }>
}

export type CreatePoliDirectDebitMutationVariables = Exact<{
  input: CreatePoliDirectDebitInput
}>

export type CreatePoliDirectDebitMutation = {
  __typename?: 'Mutation'
  createPoliDirectDebit?: Maybe<{
    __typename?: 'CreatePoliDirectDebitPayload'
    navigationUrl?: Maybe<string>
  }>
}

export type RedeemVoucherMutationVariables = Exact<{
  input: RedeemVoucherInput
}>

export type RedeemVoucherMutation = {
  __typename?: 'Mutation'
  voucher?: Maybe<{ __typename?: 'Voucher'; id: string }>
}

export type ReviewOrderQueryVariables = Exact<{
  reviewOrderId: Scalars['Int']
}>

export type ReviewOrderQuery = {
  __typename?: 'Query'
  reviewOrder: {
    __typename?: 'ReviewOrder'
    id: string
    totalFees: any
    totalPriceWithFees: any
    totalPriceWithoutFees: any
    weight: string
    pricePerUnit: any
    spotPrice: any
    fees: Array<{
      __typename?: 'ReviewOrderFee'
      fee: any
      plan: { __typename?: 'FeePlan'; name: string }
    }>
    user: {
      __typename?: 'User'
      wallet: { __typename?: 'Wallet'; balance: string; walletBalance: string }
    }
    vault?: Maybe<{
      __typename?: 'Vault'
      id: string
      name?: Maybe<string>
      balance: string
      dateOfBirth?: Maybe<any>
      email?: Maybe<string>
      relationship?: Maybe<string>
      securitiesOwned: Array<{
        __typename?: 'SecuritiesOwned'
        allotmentsOwned: number
        hasHistory: boolean
        product: { __typename?: 'Product'; name: string; id: string }
        securities: Array<{
          __typename?: 'SecurityOwned'
          ownedAllotmentsCount: number
        }>
      }>
    }>
    product: { __typename?: 'Product'; id: string; name: string }
  }
}

export type CreateMarketTransactionMutationVariables = Exact<{
  input: CreateMarketTransactionInput
}>

export type CreateMarketTransactionMutation = {
  __typename?: 'Mutation'
  marketTransaction?: Maybe<{
    __typename?: 'MarketTransaction'
    buyOrder: { __typename?: 'BuyOrder'; id: string }
    sellOrder: { __typename?: 'SellOrder'; id: string }
  }>
}

export type UpdateReviewOrderWithVaultMutationVariables = Exact<{
  input: UpdateReviewOrderWithVaultInput
}>

export type UpdateReviewOrderWithVaultMutation = {
  __typename?: 'Mutation'
  updateReviewOrderWithVault?: Maybe<{
    __typename?: 'ReviewOrder'
    id: string
    totalFees: any
    totalPriceWithFees: any
    totalPriceWithoutFees: any
    weight: string
    pricePerUnit: any
    spotPrice: any
    fees: Array<{
      __typename?: 'ReviewOrderFee'
      fee: any
      plan: { __typename?: 'FeePlan'; name: string }
    }>
    user: {
      __typename?: 'User'
      wallet: { __typename?: 'Wallet'; balance: string; walletBalance: string }
    }
    vault?: Maybe<{
      __typename?: 'Vault'
      balance: string
      dateOfBirth?: Maybe<any>
      email?: Maybe<string>
      id: string
      name?: Maybe<string>
      relationship?: Maybe<string>
      securitiesOwned: Array<{
        __typename?: 'SecuritiesOwned'
        allotmentsOwned: number
        hasHistory: boolean
        product: { __typename?: 'Product'; name: string; id: string }
        securities: Array<{
          __typename?: 'SecurityOwned'
          ownedAllotmentsCount: number
        }>
      }>
    }>
  }>
}

export type ProductPerformanceQueryVariables = Exact<{
  productId: Scalars['Int']
}>

export type ProductPerformanceQuery = {
  __typename?: 'Query'
  productPerformance: {
    __typename?: 'ProductPerformance'
    currentValue: any
    netProfit: string
    ownedAllotmentsCount: number
    periodInvestedInDays: number
    totalCost: any
    totalReturn: any
    totalReturnAsPercentage: any
    unit: string
    spotPrice: {
      __typename?: 'SpotPrice'
      bidPricePerDg: any
      bidPricePerOz: any
      spotTime: any
      askPricePerDg: any
      askPricePerOz: any
    }
  }
}

export type SecuritiesOwnedForCurrentUserQueryVariables = Exact<{
  productId: Scalars['Int']
}>

export type SecuritiesOwnedForCurrentUserQuery = {
  __typename?: 'Query'
  securitiesOwnedForCurrentUser: {
    __typename?: 'SecuritiesOwned'
    allotmentsOwned: number
    securities: Array<{
      __typename?: 'SecurityOwned'
      ownedAllotmentsCount: number
      security: {
        __typename?: 'Security'
        id: string
        serialNumber: string
        unit: string
        product: {
          __typename?: 'Product'
          id: string
          name: string
          sku: string
          description: string
          tradable: boolean
        }
      }
    }>
    user: {
      __typename?: 'User'
      profile?: Maybe<{
        __typename?: 'Profile'
        addressLine1?: Maybe<string>
        addressLine2?: Maybe<string>
        amlVerificationQuestionsAnswered: boolean
        amlVerified: boolean
        bankAccountName?: Maybe<string>
        bankAccountNumber?: Maybe<string>
        city?: Maybe<string>
        complete: boolean
        dateOfBirth?: Maybe<any>
        driversLicenceNumber?: Maybe<string>
        driversLicenceVersion?: Maybe<string>
        email: string
        firstName?: Maybe<string>
        identifier: string
        identityVerificationFailureReasons: Array<any>
        identityVerificationRequestInProgress: boolean
        identityVerified: boolean
        lastName?: Maybe<string>
        locked: boolean
        middleName?: Maybe<string>
        nzMobilePhoneNumber?: Maybe<string>
        onboarded: boolean
        postcode?: Maybe<string>
        preferredName?: Maybe<string>
        reference?: Maybe<string>
        suburb?: Maybe<string>
      }>
    }
  }
}

export type ActivateVaultMutationVariables = Exact<{
  input: ActivateVaultInput
}>

export type ActivateVaultMutation = {
  __typename?: 'Mutation'
  activateVault?: Maybe<{
    __typename?: 'ActivateVaultPayload'
    vault?: Maybe<{
      __typename?: 'Vault'
      dateOfBirth?: Maybe<any>
      email?: Maybe<string>
      id: string
      name?: Maybe<string>
      relationship?: Maybe<string>
      balance: string
    }>
  }>
}

export type DisableAuthenticatorAppMutationVariables = Exact<{
  [key: string]: never
}>

export type DisableAuthenticatorAppMutation = {
  __typename?: 'Mutation'
  disableAuthenticatorApp?: Maybe<string>
}

export type AuthenticatorAppProvisioningUriQueryVariables = Exact<{
  [key: string]: never
}>

export type AuthenticatorAppProvisioningUriQuery = {
  __typename?: 'Query'
  authenticatorAppProvisioningUri: string
}

export type EnableAuthenticatorAppMutationVariables = Exact<{
  input: EnableAuthenticatorAppInput
}>

export type EnableAuthenticatorAppMutation = {
  __typename?: 'Mutation'
  backupCodes?: Maybe<Array<string>>
}

export type RequestWithdrawFundsMutationVariables = Exact<{
  input: RequestWithdrawFundsInput
}>

export type RequestWithdrawFundsMutation = {
  __typename?: 'Mutation'
  withdrawalRequest?: Maybe<{ __typename?: 'WithdrawalRequest'; id: string }>
}

export type GetLatestSpotPriceQueryVariables = Exact<{
  productId: Scalars['Int']
}>

export type GetLatestSpotPriceQuery = {
  __typename?: 'Query'
  latestSpotPrice: {
    __typename?: 'SpotPrice'
    id: string
    midpointPerDg: any
    askPricePerDg: any
    bidPricePerDg: any
    spotTime: any
  }
}

export const VerifyOtpDocument = gql`
  mutation verifyOtp($input: VerifyOtpInput!) {
    verifyOtp: verifyOtp(input: $input)
  }
`
export type VerifyOtpMutationFn = Apollo.MutationFunction<
  VerifyOtpMutation,
  VerifyOtpMutationVariables
>

/**
 * __useVerifyOtpMutation__
 *
 * To run a mutation, you first call `useVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOtpMutation, { data, loading, error }] = useVerifyOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyOtpMutation,
    VerifyOtpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyOtpMutation, VerifyOtpMutationVariables>(
    VerifyOtpDocument,
    options
  )
}
export type VerifyOtpMutationHookResult = ReturnType<
  typeof useVerifyOtpMutation
>
export type VerifyOtpMutationResult = Apollo.MutationResult<VerifyOtpMutation>
export type VerifyOtpMutationOptions = Apollo.BaseMutationOptions<
  VerifyOtpMutation,
  VerifyOtpMutationVariables
>
export const GetCurrentUserDocument = gql`
  query getCurrentUser {
    currentUser {
      email
      id
      otpVerified
      authenticatorAppEnabled
      profile {
        bankAccountName
        bankAccountNumber
        firstName
        identifier
        onboarded
        preferredName
        reference
        addressLine1
        addressLine2
        suburb
        city
        postcode
        lastName
        partialIdentityVerified
        dateOfBirth
        referralCode
        driversLicenceNumber
        driversLicenceVersion
        passportExpiry
        passportNumber
        verificationMethod
        ethnicity
      }
      securitiesOwned {
        product {
          name
          description
          id
          tradable
        }
        securities {
          ownedAllotmentsCount
        }
        allotmentsOwned
        hasHistory
      }
      wallet {
        balance
        userId
        expirableWalletCreditBalance
        walletBalance
      }
      vaults {
        dateOfBirth
        email
        id
        name
        relationship
        balance
        securitiesOwned {
          product {
            name
            description
            id
            tradable
          }
          securities {
            ownedAllotmentsCount
          }
          allotmentsOwned
          hasHistory
        }
      }
      activatedVault {
        dateOfBirth
        email
        id
        name
        relationship
        balance
        securitiesOwned {
          product {
            name
            description
            id
            tradable
          }
          securities {
            ownedAllotmentsCount
          }
          allotmentsOwned
          hasHistory
        }
      }
      firstTimeTopUpWithPoli
      autoInvests {
        amount
        duration
        nextAutoinvestDate
        id
        product {
          name
        }
        investBy
      }
    }
  }
`

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  )
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  )
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>
export const GenerateOtpDocument = gql`
  mutation generateOtp {
    generateOtp: generateOtp(input: {})
  }
`
export type GenerateOtpMutationFn = Apollo.MutationFunction<
  GenerateOtpMutation,
  GenerateOtpMutationVariables
>

/**
 * __useGenerateOtpMutation__
 *
 * To run a mutation, you first call `useGenerateOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateOtpMutation, { data, loading, error }] = useGenerateOtpMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateOtpMutation,
    GenerateOtpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GenerateOtpMutation, GenerateOtpMutationVariables>(
    GenerateOtpDocument,
    options
  )
}
export type GenerateOtpMutationHookResult = ReturnType<
  typeof useGenerateOtpMutation
>
export type GenerateOtpMutationResult =
  Apollo.MutationResult<GenerateOtpMutation>
export type GenerateOtpMutationOptions = Apollo.BaseMutationOptions<
  GenerateOtpMutation,
  GenerateOtpMutationVariables
>
export const ProductsDocument = gql`
  query products {
    products {
      id
      name
      description
      sku
      tradable
    }
  }
`

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    options
  )
}
export function useProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsQuery,
    ProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    options
  )
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>
export type ProductsLazyQueryHookResult = ReturnType<
  typeof useProductsLazyQuery
>
export type ProductsQueryResult = Apollo.QueryResult<
  ProductsQuery,
  ProductsQueryVariables
>
export const GetHistoricalSpotPricesDocument = gql`
  query getHistoricalSpotPrices($timeframe: String, $productId: Int!) {
    historicalSpotPrices(timeframe: $timeframe, productId: $productId) {
      id
      midpointPerG
      spotDate
    }
  }
`

/**
 * __useGetHistoricalSpotPricesQuery__
 *
 * To run a query within a React component, call `useGetHistoricalSpotPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHistoricalSpotPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHistoricalSpotPricesQuery({
 *   variables: {
 *      timeframe: // value for 'timeframe'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetHistoricalSpotPricesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHistoricalSpotPricesQuery,
    GetHistoricalSpotPricesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetHistoricalSpotPricesQuery,
    GetHistoricalSpotPricesQueryVariables
  >(GetHistoricalSpotPricesDocument, options)
}
export function useGetHistoricalSpotPricesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHistoricalSpotPricesQuery,
    GetHistoricalSpotPricesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetHistoricalSpotPricesQuery,
    GetHistoricalSpotPricesQueryVariables
  >(GetHistoricalSpotPricesDocument, options)
}
export type GetHistoricalSpotPricesQueryHookResult = ReturnType<
  typeof useGetHistoricalSpotPricesQuery
>
export type GetHistoricalSpotPricesLazyQueryHookResult = ReturnType<
  typeof useGetHistoricalSpotPricesLazyQuery
>
export type GetHistoricalSpotPricesQueryResult = Apollo.QueryResult<
  GetHistoricalSpotPricesQuery,
  GetHistoricalSpotPricesQueryVariables
>
export const GetHistoricalPerformanceDocument = gql`
  query getHistoricalPerformance(
    $timeframe: String
    $productIds: [Int!]
    $vaultIds: [Int!]
  ) {
    historicalHoldingBalances(
      timeframe: $timeframe
      productIds: $productIds
      vaultIds: $vaultIds
    ) {
      spotDate
      totalHoldingBalance
    }
  }
`

/**
 * __useGetHistoricalPerformanceQuery__
 *
 * To run a query within a React component, call `useGetHistoricalPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHistoricalPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHistoricalPerformanceQuery({
 *   variables: {
 *      timeframe: // value for 'timeframe'
 *      productIds: // value for 'productIds'
 *      vaultIds: // value for 'vaultIds'
 *   },
 * });
 */
export function useGetHistoricalPerformanceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHistoricalPerformanceQuery,
    GetHistoricalPerformanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetHistoricalPerformanceQuery,
    GetHistoricalPerformanceQueryVariables
  >(GetHistoricalPerformanceDocument, options)
}
export function useGetHistoricalPerformanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHistoricalPerformanceQuery,
    GetHistoricalPerformanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetHistoricalPerformanceQuery,
    GetHistoricalPerformanceQueryVariables
  >(GetHistoricalPerformanceDocument, options)
}
export type GetHistoricalPerformanceQueryHookResult = ReturnType<
  typeof useGetHistoricalPerformanceQuery
>
export type GetHistoricalPerformanceLazyQueryHookResult = ReturnType<
  typeof useGetHistoricalPerformanceLazyQuery
>
export type GetHistoricalPerformanceQueryResult = Apollo.QueryResult<
  GetHistoricalPerformanceQuery,
  GetHistoricalPerformanceQueryVariables
>
export const CustomerPopupDocument = gql`
  query customerPopup {
    customerPopup {
      bodyText
      buttonLink
      buttonText
      displayOpt
      id
      title
    }
  }
`

/**
 * __useCustomerPopupQuery__
 *
 * To run a query within a React component, call `useCustomerPopupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerPopupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerPopupQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerPopupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomerPopupQuery,
    CustomerPopupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CustomerPopupQuery, CustomerPopupQueryVariables>(
    CustomerPopupDocument,
    options
  )
}
export function useCustomerPopupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerPopupQuery,
    CustomerPopupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CustomerPopupQuery, CustomerPopupQueryVariables>(
    CustomerPopupDocument,
    options
  )
}
export type CustomerPopupQueryHookResult = ReturnType<
  typeof useCustomerPopupQuery
>
export type CustomerPopupLazyQueryHookResult = ReturnType<
  typeof useCustomerPopupLazyQuery
>
export type CustomerPopupQueryResult = Apollo.QueryResult<
  CustomerPopupQuery,
  CustomerPopupQueryVariables
>
export const CreateCustomerPopupInteractionDocument = gql`
  mutation createCustomerPopupInteraction(
    $input: CreateCustomerPopupInteractionInput!
  ) {
    createCustomerPopupInteraction(input: $input)
  }
`
export type CreateCustomerPopupInteractionMutationFn = Apollo.MutationFunction<
  CreateCustomerPopupInteractionMutation,
  CreateCustomerPopupInteractionMutationVariables
>

/**
 * __useCreateCustomerPopupInteractionMutation__
 *
 * To run a mutation, you first call `useCreateCustomerPopupInteractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerPopupInteractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerPopupInteractionMutation, { data, loading, error }] = useCreateCustomerPopupInteractionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerPopupInteractionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomerPopupInteractionMutation,
    CreateCustomerPopupInteractionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCustomerPopupInteractionMutation,
    CreateCustomerPopupInteractionMutationVariables
  >(CreateCustomerPopupInteractionDocument, options)
}
export type CreateCustomerPopupInteractionMutationHookResult = ReturnType<
  typeof useCreateCustomerPopupInteractionMutation
>
export type CreateCustomerPopupInteractionMutationResult =
  Apollo.MutationResult<CreateCustomerPopupInteractionMutation>
export type CreateCustomerPopupInteractionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateCustomerPopupInteractionMutation,
    CreateCustomerPopupInteractionMutationVariables
  >
export const VerifyStripeTransactionDocument = gql`
  mutation verifyStripeTransaction($input: VerifyStripeTransactionInput!) {
    verifyStripeTransaction(input: $input) {
      success
      transactionStatus
    }
  }
`
export type VerifyStripeTransactionMutationFn = Apollo.MutationFunction<
  VerifyStripeTransactionMutation,
  VerifyStripeTransactionMutationVariables
>

/**
 * __useVerifyStripeTransactionMutation__
 *
 * To run a mutation, you first call `useVerifyStripeTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyStripeTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyStripeTransactionMutation, { data, loading, error }] = useVerifyStripeTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyStripeTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyStripeTransactionMutation,
    VerifyStripeTransactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    VerifyStripeTransactionMutation,
    VerifyStripeTransactionMutationVariables
  >(VerifyStripeTransactionDocument, options)
}
export type VerifyStripeTransactionMutationHookResult = ReturnType<
  typeof useVerifyStripeTransactionMutation
>
export type VerifyStripeTransactionMutationResult =
  Apollo.MutationResult<VerifyStripeTransactionMutation>
export type VerifyStripeTransactionMutationOptions = Apollo.BaseMutationOptions<
  VerifyStripeTransactionMutation,
  VerifyStripeTransactionMutationVariables
>
export const ActivityForCurrentUserDocument = gql`
  query activityForCurrentUser($limit: Int!, $page: Int!) {
    activityForCurrentUser(inputPagination: { limit: $limit, page: $page }) {
      totalCount
      totalPages
      items {
        id
        total
        reference
        createdAt
        entries {
          debit
          credit
          source {
            sourceCategorisation
            sourceInfo
            sourceNote
          }
          createdAt
        }
        vault {
          balance
          dateOfBirth
          email
          id
          name
          relationship
          securitiesOwned {
            product {
              name
              id
            }
            securities {
              ownedAllotmentsCount
            }
            allotmentsOwned
            hasHistory
          }
        }
      }
    }
  }
`

/**
 * __useActivityForCurrentUserQuery__
 *
 * To run a query within a React component, call `useActivityForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityForCurrentUserQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useActivityForCurrentUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActivityForCurrentUserQuery,
    ActivityForCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ActivityForCurrentUserQuery,
    ActivityForCurrentUserQueryVariables
  >(ActivityForCurrentUserDocument, options)
}
export function useActivityForCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityForCurrentUserQuery,
    ActivityForCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ActivityForCurrentUserQuery,
    ActivityForCurrentUserQueryVariables
  >(ActivityForCurrentUserDocument, options)
}
export type ActivityForCurrentUserQueryHookResult = ReturnType<
  typeof useActivityForCurrentUserQuery
>
export type ActivityForCurrentUserLazyQueryHookResult = ReturnType<
  typeof useActivityForCurrentUserLazyQuery
>
export type ActivityForCurrentUserQueryResult = Apollo.QueryResult<
  ActivityForCurrentUserQuery,
  ActivityForCurrentUserQueryVariables
>
export const CreateAutoInvestDocument = gql`
  mutation createAutoInvest($input: CreateAutoInvestInput!) {
    createAutoInvest(input: $input) {
      amount
      duration
      id
    }
  }
`
export type CreateAutoInvestMutationFn = Apollo.MutationFunction<
  CreateAutoInvestMutation,
  CreateAutoInvestMutationVariables
>

/**
 * __useCreateAutoInvestMutation__
 *
 * To run a mutation, you first call `useCreateAutoInvestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAutoInvestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAutoInvestMutation, { data, loading, error }] = useCreateAutoInvestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAutoInvestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAutoInvestMutation,
    CreateAutoInvestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateAutoInvestMutation,
    CreateAutoInvestMutationVariables
  >(CreateAutoInvestDocument, options)
}
export type CreateAutoInvestMutationHookResult = ReturnType<
  typeof useCreateAutoInvestMutation
>
export type CreateAutoInvestMutationResult =
  Apollo.MutationResult<CreateAutoInvestMutation>
export type CreateAutoInvestMutationOptions = Apollo.BaseMutationOptions<
  CreateAutoInvestMutation,
  CreateAutoInvestMutationVariables
>
export const DeleteAutoInvestDocument = gql`
  mutation deleteAutoInvest($input: DeleteAutoInvestInput!) {
    deleteAutoInvest(input: $input)
  }
`
export type DeleteAutoInvestMutationFn = Apollo.MutationFunction<
  DeleteAutoInvestMutation,
  DeleteAutoInvestMutationVariables
>

/**
 * __useDeleteAutoInvestMutation__
 *
 * To run a mutation, you first call `useDeleteAutoInvestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAutoInvestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAutoInvestMutation, { data, loading, error }] = useDeleteAutoInvestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAutoInvestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAutoInvestMutation,
    DeleteAutoInvestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteAutoInvestMutation,
    DeleteAutoInvestMutationVariables
  >(DeleteAutoInvestDocument, options)
}
export type DeleteAutoInvestMutationHookResult = ReturnType<
  typeof useDeleteAutoInvestMutation
>
export type DeleteAutoInvestMutationResult =
  Apollo.MutationResult<DeleteAutoInvestMutation>
export type DeleteAutoInvestMutationOptions = Apollo.BaseMutationOptions<
  DeleteAutoInvestMutation,
  DeleteAutoInvestMutationVariables
>
export const ReviewOrderForBuyDocument = gql`
  query reviewOrderForBuy($reviewOrderId: Int!) {
    reviewOrder: reviewOrderForCurrentUser(reviewOrderId: $reviewOrderId) {
      id
      spotPrice
      totalFees
      totalPriceWithFees
      totalPriceWithoutFees
      userHasBalance
      allotmentsOwned
      pricePerUnit
      user {
        id
        wallet {
          balance
          userId
          walletBalance
        }
      }
      weight
      product {
        id
        name
        tradable
      }
    }
  }
`

/**
 * __useReviewOrderForBuyQuery__
 *
 * To run a query within a React component, call `useReviewOrderForBuyQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewOrderForBuyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewOrderForBuyQuery({
 *   variables: {
 *      reviewOrderId: // value for 'reviewOrderId'
 *   },
 * });
 */
export function useReviewOrderForBuyQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReviewOrderForBuyQuery,
    ReviewOrderForBuyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReviewOrderForBuyQuery,
    ReviewOrderForBuyQueryVariables
  >(ReviewOrderForBuyDocument, options)
}
export function useReviewOrderForBuyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReviewOrderForBuyQuery,
    ReviewOrderForBuyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReviewOrderForBuyQuery,
    ReviewOrderForBuyQueryVariables
  >(ReviewOrderForBuyDocument, options)
}
export type ReviewOrderForBuyQueryHookResult = ReturnType<
  typeof useReviewOrderForBuyQuery
>
export type ReviewOrderForBuyLazyQueryHookResult = ReturnType<
  typeof useReviewOrderForBuyLazyQuery
>
export type ReviewOrderForBuyQueryResult = Apollo.QueryResult<
  ReviewOrderForBuyQuery,
  ReviewOrderForBuyQueryVariables
>
export const UpdateReviewOrderForBuyDocument = gql`
  mutation updateReviewOrderForBuy($input: UpdateReviewOrderWithWeightInput!) {
    reviewOrder: updateReviewOrderWithWeight(input: $input) {
      allotmentsOwned
      fees {
        fee
        plan {
          description
          name
        }
      }
      id
      lockedUntil
      pricePerUnit
      spotPrice
      totalFees
      totalPriceWithFees
      totalPriceWithoutFees
      type
      unit
      weight
      userHasBalance
    }
  }
`
export type UpdateReviewOrderForBuyMutationFn = Apollo.MutationFunction<
  UpdateReviewOrderForBuyMutation,
  UpdateReviewOrderForBuyMutationVariables
>

/**
 * __useUpdateReviewOrderForBuyMutation__
 *
 * To run a mutation, you first call `useUpdateReviewOrderForBuyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReviewOrderForBuyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReviewOrderForBuyMutation, { data, loading, error }] = useUpdateReviewOrderForBuyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReviewOrderForBuyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReviewOrderForBuyMutation,
    UpdateReviewOrderForBuyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateReviewOrderForBuyMutation,
    UpdateReviewOrderForBuyMutationVariables
  >(UpdateReviewOrderForBuyDocument, options)
}
export type UpdateReviewOrderForBuyMutationHookResult = ReturnType<
  typeof useUpdateReviewOrderForBuyMutation
>
export type UpdateReviewOrderForBuyMutationResult =
  Apollo.MutationResult<UpdateReviewOrderForBuyMutation>
export type UpdateReviewOrderForBuyMutationOptions = Apollo.BaseMutationOptions<
  UpdateReviewOrderForBuyMutation,
  UpdateReviewOrderForBuyMutationVariables
>
export const UpdatePasswordDocument = gql`
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword: updatePassword(input: $input)
  }
`
export type UpdatePasswordMutationFn = Apollo.MutationFunction<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePasswordMutation,
    UpdatePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePasswordMutation,
    UpdatePasswordMutationVariables
  >(UpdatePasswordDocument, options)
}
export type UpdatePasswordMutationHookResult = ReturnType<
  typeof useUpdatePasswordMutation
>
export type UpdatePasswordMutationResult =
  Apollo.MutationResult<UpdatePasswordMutation>
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>
export const CreateReviewOrderForBuyDocument = gql`
  mutation createReviewOrderForBuy($input: CreateReviewOrderForBuyInput!) {
    reviewOrder: createReviewOrderForBuy(input: $input) {
      id
      spotPrice
      totalFees
      totalPriceWithFees
      totalPriceWithoutFees
      userHasBalance
      user {
        id
        wallet {
          balance
          userId
        }
      }
      weight
    }
  }
`
export type CreateReviewOrderForBuyMutationFn = Apollo.MutationFunction<
  CreateReviewOrderForBuyMutation,
  CreateReviewOrderForBuyMutationVariables
>

/**
 * __useCreateReviewOrderForBuyMutation__
 *
 * To run a mutation, you first call `useCreateReviewOrderForBuyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReviewOrderForBuyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReviewOrderForBuyMutation, { data, loading, error }] = useCreateReviewOrderForBuyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReviewOrderForBuyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReviewOrderForBuyMutation,
    CreateReviewOrderForBuyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateReviewOrderForBuyMutation,
    CreateReviewOrderForBuyMutationVariables
  >(CreateReviewOrderForBuyDocument, options)
}
export type CreateReviewOrderForBuyMutationHookResult = ReturnType<
  typeof useCreateReviewOrderForBuyMutation
>
export type CreateReviewOrderForBuyMutationResult =
  Apollo.MutationResult<CreateReviewOrderForBuyMutation>
export type CreateReviewOrderForBuyMutationOptions = Apollo.BaseMutationOptions<
  CreateReviewOrderForBuyMutation,
  CreateReviewOrderForBuyMutationVariables
>
export const CreateReviewOrderForSellDocument = gql`
  mutation createReviewOrderForSell($input: CreateReviewOrderForSellInput!) {
    reviewOrder: createReviewOrderForSell(input: $input) {
      allotmentsOwned
      id
      spotPrice
      totalFees
      totalPriceWithFees
      totalPriceWithoutFees
      userHasBalance
      weight
    }
  }
`
export type CreateReviewOrderForSellMutationFn = Apollo.MutationFunction<
  CreateReviewOrderForSellMutation,
  CreateReviewOrderForSellMutationVariables
>

/**
 * __useCreateReviewOrderForSellMutation__
 *
 * To run a mutation, you first call `useCreateReviewOrderForSellMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReviewOrderForSellMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReviewOrderForSellMutation, { data, loading, error }] = useCreateReviewOrderForSellMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReviewOrderForSellMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReviewOrderForSellMutation,
    CreateReviewOrderForSellMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateReviewOrderForSellMutation,
    CreateReviewOrderForSellMutationVariables
  >(CreateReviewOrderForSellDocument, options)
}
export type CreateReviewOrderForSellMutationHookResult = ReturnType<
  typeof useCreateReviewOrderForSellMutation
>
export type CreateReviewOrderForSellMutationResult =
  Apollo.MutationResult<CreateReviewOrderForSellMutation>
export type CreateReviewOrderForSellMutationOptions =
  Apollo.BaseMutationOptions<
    CreateReviewOrderForSellMutation,
    CreateReviewOrderForSellMutationVariables
  >
export const BuyVoucherDocument = gql`
  mutation buyVoucher($input: BuyVoucherInput!) {
    buyVoucher(input: $input) {
      clientMutationId
      message
      success
    }
  }
`
export type BuyVoucherMutationFn = Apollo.MutationFunction<
  BuyVoucherMutation,
  BuyVoucherMutationVariables
>

/**
 * __useBuyVoucherMutation__
 *
 * To run a mutation, you first call `useBuyVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyVoucherMutation, { data, loading, error }] = useBuyVoucherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuyVoucherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuyVoucherMutation,
    BuyVoucherMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BuyVoucherMutation, BuyVoucherMutationVariables>(
    BuyVoucherDocument,
    options
  )
}
export type BuyVoucherMutationHookResult = ReturnType<
  typeof useBuyVoucherMutation
>
export type BuyVoucherMutationResult = Apollo.MutationResult<BuyVoucherMutation>
export type BuyVoucherMutationOptions = Apollo.BaseMutationOptions<
  BuyVoucherMutation,
  BuyVoucherMutationVariables
>
export const WalletForCurrentUserDocument = gql`
  query walletForCurrentUser {
    walletForCurrentUser {
      balance
      walletBalance
      expirableWalletCreditBalance
      withdrawableAmount
      withheldFunds
      rewardCredit
      voucherCredit
    }
  }
`

/**
 * __useWalletForCurrentUserQuery__
 *
 * To run a query within a React component, call `useWalletForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletForCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useWalletForCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WalletForCurrentUserQuery,
    WalletForCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    WalletForCurrentUserQuery,
    WalletForCurrentUserQueryVariables
  >(WalletForCurrentUserDocument, options)
}
export function useWalletForCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WalletForCurrentUserQuery,
    WalletForCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    WalletForCurrentUserQuery,
    WalletForCurrentUserQueryVariables
  >(WalletForCurrentUserDocument, options)
}
export type WalletForCurrentUserQueryHookResult = ReturnType<
  typeof useWalletForCurrentUserQuery
>
export type WalletForCurrentUserLazyQueryHookResult = ReturnType<
  typeof useWalletForCurrentUserLazyQuery
>
export type WalletForCurrentUserQueryResult = Apollo.QueryResult<
  WalletForCurrentUserQuery,
  WalletForCurrentUserQueryVariables
>
export const CreateOrUpdateVaultDocument = gql`
  mutation CreateOrUpdateVault($input: CreateOrUpdateVaultInput!) {
    createOrUpdateVault(input: $input) {
      dateOfBirth
      email
      id
      name
      relationship
    }
  }
`
export type CreateOrUpdateVaultMutationFn = Apollo.MutationFunction<
  CreateOrUpdateVaultMutation,
  CreateOrUpdateVaultMutationVariables
>

/**
 * __useCreateOrUpdateVaultMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateVaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateVaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateVaultMutation, { data, loading, error }] = useCreateOrUpdateVaultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateVaultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateVaultMutation,
    CreateOrUpdateVaultMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateOrUpdateVaultMutation,
    CreateOrUpdateVaultMutationVariables
  >(CreateOrUpdateVaultDocument, options)
}
export type CreateOrUpdateVaultMutationHookResult = ReturnType<
  typeof useCreateOrUpdateVaultMutation
>
export type CreateOrUpdateVaultMutationResult =
  Apollo.MutationResult<CreateOrUpdateVaultMutation>
export type CreateOrUpdateVaultMutationOptions = Apollo.BaseMutationOptions<
  CreateOrUpdateVaultMutation,
  CreateOrUpdateVaultMutationVariables
>
export const VaultDetailDocument = gql`
  query VaultDetail($vaultId: Int!) {
    vaultDetail(vaultId: $vaultId) {
      dateOfBirth
      email
      id
      name
      relationship
    }
  }
`

/**
 * __useVaultDetailQuery__
 *
 * To run a query within a React component, call `useVaultDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useVaultDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVaultDetailQuery({
 *   variables: {
 *      vaultId: // value for 'vaultId'
 *   },
 * });
 */
export function useVaultDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    VaultDetailQuery,
    VaultDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VaultDetailQuery, VaultDetailQueryVariables>(
    VaultDetailDocument,
    options
  )
}
export function useVaultDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VaultDetailQuery,
    VaultDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VaultDetailQuery, VaultDetailQueryVariables>(
    VaultDetailDocument,
    options
  )
}
export type VaultDetailQueryHookResult = ReturnType<typeof useVaultDetailQuery>
export type VaultDetailLazyQueryHookResult = ReturnType<
  typeof useVaultDetailLazyQuery
>
export type VaultDetailQueryResult = Apollo.QueryResult<
  VaultDetailQuery,
  VaultDetailQueryVariables
>
export const ReviewOrderForSellDocument = gql`
  query reviewOrderForSell($reviewOrderId: Int!) {
    reviewOrder: reviewOrderForCurrentUser(reviewOrderId: $reviewOrderId) {
      allotmentsOwned
      id
      spotPrice
      totalFees
      totalPriceWithFees
      totalPriceWithoutFees
      userHasBalance
      allotmentsOwned
      user {
        id
        wallet {
          balance
          userId
        }
      }
      weight
      fees {
        fee
        plan {
          name
        }
      }
      vault {
        id
        email
        name
        balance
        securitiesOwned {
          product {
            name
            id
          }
          securities {
            ownedAllotmentsCount
          }
          allotmentsOwned
          hasHistory
        }
      }
      product {
        id
        name
      }
    }
  }
`

/**
 * __useReviewOrderForSellQuery__
 *
 * To run a query within a React component, call `useReviewOrderForSellQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewOrderForSellQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewOrderForSellQuery({
 *   variables: {
 *      reviewOrderId: // value for 'reviewOrderId'
 *   },
 * });
 */
export function useReviewOrderForSellQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReviewOrderForSellQuery,
    ReviewOrderForSellQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReviewOrderForSellQuery,
    ReviewOrderForSellQueryVariables
  >(ReviewOrderForSellDocument, options)
}
export function useReviewOrderForSellLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReviewOrderForSellQuery,
    ReviewOrderForSellQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReviewOrderForSellQuery,
    ReviewOrderForSellQueryVariables
  >(ReviewOrderForSellDocument, options)
}
export type ReviewOrderForSellQueryHookResult = ReturnType<
  typeof useReviewOrderForSellQuery
>
export type ReviewOrderForSellLazyQueryHookResult = ReturnType<
  typeof useReviewOrderForSellLazyQuery
>
export type ReviewOrderForSellQueryResult = Apollo.QueryResult<
  ReviewOrderForSellQuery,
  ReviewOrderForSellQueryVariables
>
export const UpdateReviewOrderForSellDocument = gql`
  mutation updateReviewOrderForSell($input: UpdateReviewOrderWithWeightInput!) {
    reviewOrder: updateReviewOrderWithWeight(input: $input) {
      allotmentsOwned
      fees {
        fee
        plan {
          name
        }
      }
      id
      spotPrice
      totalFees
      totalPriceWithFees
      totalPriceWithoutFees
      userHasBalance
      weight
    }
  }
`
export type UpdateReviewOrderForSellMutationFn = Apollo.MutationFunction<
  UpdateReviewOrderForSellMutation,
  UpdateReviewOrderForSellMutationVariables
>

/**
 * __useUpdateReviewOrderForSellMutation__
 *
 * To run a mutation, you first call `useUpdateReviewOrderForSellMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReviewOrderForSellMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReviewOrderForSellMutation, { data, loading, error }] = useUpdateReviewOrderForSellMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReviewOrderForSellMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReviewOrderForSellMutation,
    UpdateReviewOrderForSellMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateReviewOrderForSellMutation,
    UpdateReviewOrderForSellMutationVariables
  >(UpdateReviewOrderForSellDocument, options)
}
export type UpdateReviewOrderForSellMutationHookResult = ReturnType<
  typeof useUpdateReviewOrderForSellMutation
>
export type UpdateReviewOrderForSellMutationResult =
  Apollo.MutationResult<UpdateReviewOrderForSellMutation>
export type UpdateReviewOrderForSellMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateReviewOrderForSellMutation,
    UpdateReviewOrderForSellMutationVariables
  >
export const MarketOrdersForProductDocument = gql`
  query marketOrdersForProduct($productId: Int!) {
    marketOrdersForProduct(productId: $productId) {
      id
      createdAt
      orderType
      quantity
      unit
      pricePerUnit
    }
  }
`

/**
 * __useMarketOrdersForProductQuery__
 *
 * To run a query within a React component, call `useMarketOrdersForProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketOrdersForProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketOrdersForProductQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useMarketOrdersForProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    MarketOrdersForProductQuery,
    MarketOrdersForProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    MarketOrdersForProductQuery,
    MarketOrdersForProductQueryVariables
  >(MarketOrdersForProductDocument, options)
}
export function useMarketOrdersForProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketOrdersForProductQuery,
    MarketOrdersForProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    MarketOrdersForProductQuery,
    MarketOrdersForProductQueryVariables
  >(MarketOrdersForProductDocument, options)
}
export type MarketOrdersForProductQueryHookResult = ReturnType<
  typeof useMarketOrdersForProductQuery
>
export type MarketOrdersForProductLazyQueryHookResult = ReturnType<
  typeof useMarketOrdersForProductLazyQuery
>
export type MarketOrdersForProductQueryResult = Apollo.QueryResult<
  MarketOrdersForProductQuery,
  MarketOrdersForProductQueryVariables
>
export const ProductPerformanceSummariesDocument = gql`
  query productPerformanceSummaries {
    productPerformanceSummaries {
      currentValue
      product {
        id
        name
        description
        sku
        tradable
      }
      totalCost
      totalReturn
      totalReturnAsPercentage
    }
  }
`

/**
 * __useProductPerformanceSummariesQuery__
 *
 * To run a query within a React component, call `useProductPerformanceSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductPerformanceSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductPerformanceSummariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductPerformanceSummariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductPerformanceSummariesQuery,
    ProductPerformanceSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ProductPerformanceSummariesQuery,
    ProductPerformanceSummariesQueryVariables
  >(ProductPerformanceSummariesDocument, options)
}
export function useProductPerformanceSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductPerformanceSummariesQuery,
    ProductPerformanceSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ProductPerformanceSummariesQuery,
    ProductPerformanceSummariesQueryVariables
  >(ProductPerformanceSummariesDocument, options)
}
export type ProductPerformanceSummariesQueryHookResult = ReturnType<
  typeof useProductPerformanceSummariesQuery
>
export type ProductPerformanceSummariesLazyQueryHookResult = ReturnType<
  typeof useProductPerformanceSummariesLazyQuery
>
export type ProductPerformanceSummariesQueryResult = Apollo.QueryResult<
  ProductPerformanceSummariesQuery,
  ProductPerformanceSummariesQueryVariables
>
export const GetProfileForCurrentUserDocument = gql`
  query getProfileForCurrentUser {
    profileForCurrentUser: profileForCurrentUser {
      addressLine1
      addressLine2
      city
      complete
      dateOfBirth
      driversLicenceNumber
      driversLicenceVersion
      firstName
      identifier
      lastName
      middleName
      nzMobilePhoneNumber
      postcode
      preferredName
      suburb
      passportNumber
      passportExpiry
      verificationMethod
      ethnicity
    }
  }
`

/**
 * __useGetProfileForCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetProfileForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileForCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileForCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProfileForCurrentUserQuery,
    GetProfileForCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetProfileForCurrentUserQuery,
    GetProfileForCurrentUserQueryVariables
  >(GetProfileForCurrentUserDocument, options)
}
export function useGetProfileForCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfileForCurrentUserQuery,
    GetProfileForCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetProfileForCurrentUserQuery,
    GetProfileForCurrentUserQueryVariables
  >(GetProfileForCurrentUserDocument, options)
}
export type GetProfileForCurrentUserQueryHookResult = ReturnType<
  typeof useGetProfileForCurrentUserQuery
>
export type GetProfileForCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetProfileForCurrentUserLazyQuery
>
export type GetProfileForCurrentUserQueryResult = Apollo.QueryResult<
  GetProfileForCurrentUserQuery,
  GetProfileForCurrentUserQueryVariables
>
export const UpdateProfileForOnboardingDocument = gql`
  mutation updateProfileForOnboarding(
    $input: UpdateProfileForOnboardingInput!
  ) {
    profileForCurrentUser: updateProfileForOnboarding(input: $input) {
      identifier
      preferredName
      ethnicity
    }
  }
`
export type UpdateProfileForOnboardingMutationFn = Apollo.MutationFunction<
  UpdateProfileForOnboardingMutation,
  UpdateProfileForOnboardingMutationVariables
>

/**
 * __useUpdateProfileForOnboardingMutation__
 *
 * To run a mutation, you first call `useUpdateProfileForOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileForOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileForOnboardingMutation, { data, loading, error }] = useUpdateProfileForOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileForOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileForOnboardingMutation,
    UpdateProfileForOnboardingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateProfileForOnboardingMutation,
    UpdateProfileForOnboardingMutationVariables
  >(UpdateProfileForOnboardingDocument, options)
}
export type UpdateProfileForOnboardingMutationHookResult = ReturnType<
  typeof useUpdateProfileForOnboardingMutation
>
export type UpdateProfileForOnboardingMutationResult =
  Apollo.MutationResult<UpdateProfileForOnboardingMutation>
export type UpdateProfileForOnboardingMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateProfileForOnboardingMutation,
    UpdateProfileForOnboardingMutationVariables
  >
export const GetAmlVerificationQuestionsDocument = gql`
  query getAmlVerificationQuestions {
    questionSections: amlVerificationQuestions {
      displayOrder
      id
      identifier
      name
      questions {
        displayOrder
        answer {
          answer
          chosenAnswerOptionIds
          comments
          id
        }
        answerOptions {
          answer
          commentsRequired
          displayOrder
          id
          identifier
        }
        format
        id
        identifier
        question
      }
    }
  }
`

/**
 * __useGetAmlVerificationQuestionsQuery__
 *
 * To run a query within a React component, call `useGetAmlVerificationQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAmlVerificationQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAmlVerificationQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAmlVerificationQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAmlVerificationQuestionsQuery,
    GetAmlVerificationQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAmlVerificationQuestionsQuery,
    GetAmlVerificationQuestionsQueryVariables
  >(GetAmlVerificationQuestionsDocument, options)
}
export function useGetAmlVerificationQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAmlVerificationQuestionsQuery,
    GetAmlVerificationQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAmlVerificationQuestionsQuery,
    GetAmlVerificationQuestionsQueryVariables
  >(GetAmlVerificationQuestionsDocument, options)
}
export type GetAmlVerificationQuestionsQueryHookResult = ReturnType<
  typeof useGetAmlVerificationQuestionsQuery
>
export type GetAmlVerificationQuestionsLazyQueryHookResult = ReturnType<
  typeof useGetAmlVerificationQuestionsLazyQuery
>
export type GetAmlVerificationQuestionsQueryResult = Apollo.QueryResult<
  GetAmlVerificationQuestionsQuery,
  GetAmlVerificationQuestionsQueryVariables
>
export const GetIdentityVerificationStatusDocument = gql`
  query getIdentityVerificationStatus {
    identityVerificationStatus: profileForCurrentUser {
      identifier
      identityVerificationFailureReasons
      identityVerificationRequestInProgress
      identityVerified
    }
  }
`

/**
 * __useGetIdentityVerificationStatusQuery__
 *
 * To run a query within a React component, call `useGetIdentityVerificationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentityVerificationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentityVerificationStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIdentityVerificationStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetIdentityVerificationStatusQuery,
    GetIdentityVerificationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetIdentityVerificationStatusQuery,
    GetIdentityVerificationStatusQueryVariables
  >(GetIdentityVerificationStatusDocument, options)
}
export function useGetIdentityVerificationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIdentityVerificationStatusQuery,
    GetIdentityVerificationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetIdentityVerificationStatusQuery,
    GetIdentityVerificationStatusQueryVariables
  >(GetIdentityVerificationStatusDocument, options)
}
export type GetIdentityVerificationStatusQueryHookResult = ReturnType<
  typeof useGetIdentityVerificationStatusQuery
>
export type GetIdentityVerificationStatusLazyQueryHookResult = ReturnType<
  typeof useGetIdentityVerificationStatusLazyQuery
>
export type GetIdentityVerificationStatusQueryResult = Apollo.QueryResult<
  GetIdentityVerificationStatusQuery,
  GetIdentityVerificationStatusQueryVariables
>
export const AnswerAmlQuestionsDocument = gql`
  mutation answerAmlQuestions($input: AnswerAmlQuestionsInput!) {
    questionSections: answerAmlQuestions(input: $input) {
      id
    }
  }
`
export type AnswerAmlQuestionsMutationFn = Apollo.MutationFunction<
  AnswerAmlQuestionsMutation,
  AnswerAmlQuestionsMutationVariables
>

/**
 * __useAnswerAmlQuestionsMutation__
 *
 * To run a mutation, you first call `useAnswerAmlQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerAmlQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerAmlQuestionsMutation, { data, loading, error }] = useAnswerAmlQuestionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnswerAmlQuestionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnswerAmlQuestionsMutation,
    AnswerAmlQuestionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AnswerAmlQuestionsMutation,
    AnswerAmlQuestionsMutationVariables
  >(AnswerAmlQuestionsDocument, options)
}
export type AnswerAmlQuestionsMutationHookResult = ReturnType<
  typeof useAnswerAmlQuestionsMutation
>
export type AnswerAmlQuestionsMutationResult =
  Apollo.MutationResult<AnswerAmlQuestionsMutation>
export type AnswerAmlQuestionsMutationOptions = Apollo.BaseMutationOptions<
  AnswerAmlQuestionsMutation,
  AnswerAmlQuestionsMutationVariables
>
export const RequestIdentityVerificationDocument = gql`
  mutation requestIdentityVerification(
    $input: RequestIdentityVerificationInput!
  ) {
    profile: requestIdentityVerification(input: $input) {
      identifier
      identityVerificationFailureReasons
      identityVerificationRequestInProgress
      identityVerified
    }
  }
`
export type RequestIdentityVerificationMutationFn = Apollo.MutationFunction<
  RequestIdentityVerificationMutation,
  RequestIdentityVerificationMutationVariables
>

/**
 * __useRequestIdentityVerificationMutation__
 *
 * To run a mutation, you first call `useRequestIdentityVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestIdentityVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestIdentityVerificationMutation, { data, loading, error }] = useRequestIdentityVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestIdentityVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestIdentityVerificationMutation,
    RequestIdentityVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestIdentityVerificationMutation,
    RequestIdentityVerificationMutationVariables
  >(RequestIdentityVerificationDocument, options)
}
export type RequestIdentityVerificationMutationHookResult = ReturnType<
  typeof useRequestIdentityVerificationMutation
>
export type RequestIdentityVerificationMutationResult =
  Apollo.MutationResult<RequestIdentityVerificationMutation>
export type RequestIdentityVerificationMutationOptions =
  Apollo.BaseMutationOptions<
    RequestIdentityVerificationMutation,
    RequestIdentityVerificationMutationVariables
  >
export const RequestPoliTransactionVerificationDocument = gql`
  mutation requestPoliTransactionVerification(
    $input: RequestPoliTransactionVerificationInput!
  ) {
    requestPoliTransactionVerification(input: $input) {
      success
      message
      transactionStatus
    }
  }
`
export type RequestPoliTransactionVerificationMutationFn =
  Apollo.MutationFunction<
    RequestPoliTransactionVerificationMutation,
    RequestPoliTransactionVerificationMutationVariables
  >

/**
 * __useRequestPoliTransactionVerificationMutation__
 *
 * To run a mutation, you first call `useRequestPoliTransactionVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPoliTransactionVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPoliTransactionVerificationMutation, { data, loading, error }] = useRequestPoliTransactionVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPoliTransactionVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPoliTransactionVerificationMutation,
    RequestPoliTransactionVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestPoliTransactionVerificationMutation,
    RequestPoliTransactionVerificationMutationVariables
  >(RequestPoliTransactionVerificationDocument, options)
}
export type RequestPoliTransactionVerificationMutationHookResult = ReturnType<
  typeof useRequestPoliTransactionVerificationMutation
>
export type RequestPoliTransactionVerificationMutationResult =
  Apollo.MutationResult<RequestPoliTransactionVerificationMutation>
export type RequestPoliTransactionVerificationMutationOptions =
  Apollo.BaseMutationOptions<
    RequestPoliTransactionVerificationMutation,
    RequestPoliTransactionVerificationMutationVariables
  >
export const ProfileForCurrentUserDocument = gql`
  query profileForCurrentUser {
    profile: profileForCurrentUser {
      identifier
      dateOfBirth
      email
      firstName
      middleName
      lastName
      preferredName
      driversLicenceNumber
      driversLicenceVersion
      nzMobilePhoneNumber
      addressLine1
      addressLine2
      suburb
      city
      postcode
      onboarded
      reference
    }
  }
`

/**
 * __useProfileForCurrentUserQuery__
 *
 * To run a query within a React component, call `useProfileForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileForCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileForCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfileForCurrentUserQuery,
    ProfileForCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ProfileForCurrentUserQuery,
    ProfileForCurrentUserQueryVariables
  >(ProfileForCurrentUserDocument, options)
}
export function useProfileForCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileForCurrentUserQuery,
    ProfileForCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ProfileForCurrentUserQuery,
    ProfileForCurrentUserQueryVariables
  >(ProfileForCurrentUserDocument, options)
}
export type ProfileForCurrentUserQueryHookResult = ReturnType<
  typeof useProfileForCurrentUserQuery
>
export type ProfileForCurrentUserLazyQueryHookResult = ReturnType<
  typeof useProfileForCurrentUserLazyQuery
>
export type ProfileForCurrentUserQueryResult = Apollo.QueryResult<
  ProfileForCurrentUserQuery,
  ProfileForCurrentUserQueryVariables
>
export const UpdateProfileDocument = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    profile: updateProfile(input: $input) {
      addressLine1
      addressLine2
      amlVerificationQuestionsAnswered
      amlVerified
      bankAccountName
      bankAccountNumber
      city
      complete
      dateOfBirth
      driversLicenceNumber
      driversLicenceVersion
      email
      firstName
      identifier
      identityVerified
      identityVerificationFailureReasons
      identityVerificationRequestInProgress
      lastName
      locked
      middleName
      nzMobilePhoneNumber
      onboarded
      postcode
      preferredName
      reference
      suburb
    }
  }
`
export type UpdateProfileMutationFn = Apollo.MutationFunction<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UpdateProfileDocument, options)
}
export type UpdateProfileMutationHookResult = ReturnType<
  typeof useUpdateProfileMutation
>
export type UpdateProfileMutationResult =
  Apollo.MutationResult<UpdateProfileMutation>
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>
export const CreatePoliDirectDebitDocument = gql`
  mutation createPoliDirectDebit($input: CreatePoliDirectDebitInput!) {
    createPoliDirectDebit(input: $input) {
      navigationUrl
    }
  }
`
export type CreatePoliDirectDebitMutationFn = Apollo.MutationFunction<
  CreatePoliDirectDebitMutation,
  CreatePoliDirectDebitMutationVariables
>

/**
 * __useCreatePoliDirectDebitMutation__
 *
 * To run a mutation, you first call `useCreatePoliDirectDebitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePoliDirectDebitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPoliDirectDebitMutation, { data, loading, error }] = useCreatePoliDirectDebitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePoliDirectDebitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePoliDirectDebitMutation,
    CreatePoliDirectDebitMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePoliDirectDebitMutation,
    CreatePoliDirectDebitMutationVariables
  >(CreatePoliDirectDebitDocument, options)
}
export type CreatePoliDirectDebitMutationHookResult = ReturnType<
  typeof useCreatePoliDirectDebitMutation
>
export type CreatePoliDirectDebitMutationResult =
  Apollo.MutationResult<CreatePoliDirectDebitMutation>
export type CreatePoliDirectDebitMutationOptions = Apollo.BaseMutationOptions<
  CreatePoliDirectDebitMutation,
  CreatePoliDirectDebitMutationVariables
>
export const RedeemVoucherDocument = gql`
  mutation redeemVoucher($input: RedeemVoucherInput!) {
    voucher: redeemVoucher(input: $input) {
      id
    }
  }
`
export type RedeemVoucherMutationFn = Apollo.MutationFunction<
  RedeemVoucherMutation,
  RedeemVoucherMutationVariables
>

/**
 * __useRedeemVoucherMutation__
 *
 * To run a mutation, you first call `useRedeemVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemVoucherMutation, { data, loading, error }] = useRedeemVoucherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRedeemVoucherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RedeemVoucherMutation,
    RedeemVoucherMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RedeemVoucherMutation,
    RedeemVoucherMutationVariables
  >(RedeemVoucherDocument, options)
}
export type RedeemVoucherMutationHookResult = ReturnType<
  typeof useRedeemVoucherMutation
>
export type RedeemVoucherMutationResult =
  Apollo.MutationResult<RedeemVoucherMutation>
export type RedeemVoucherMutationOptions = Apollo.BaseMutationOptions<
  RedeemVoucherMutation,
  RedeemVoucherMutationVariables
>
export const ReviewOrderDocument = gql`
  query reviewOrder($reviewOrderId: Int!) {
    reviewOrder: reviewOrderForCurrentUser(reviewOrderId: $reviewOrderId) {
      fees {
        fee
        plan {
          name
        }
      }
      id
      totalFees
      totalPriceWithFees
      totalPriceWithoutFees
      weight
      user {
        wallet {
          balance
          walletBalance
        }
      }
      pricePerUnit
      vault {
        id
        name
        balance
        dateOfBirth
        email
        relationship
        securitiesOwned {
          product {
            name
            id
          }
          securities {
            ownedAllotmentsCount
          }
          allotmentsOwned
          hasHistory
        }
      }
      product {
        id
        name
      }
      spotPrice
    }
  }
`

/**
 * __useReviewOrderQuery__
 *
 * To run a query within a React component, call `useReviewOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewOrderQuery({
 *   variables: {
 *      reviewOrderId: // value for 'reviewOrderId'
 *   },
 * });
 */
export function useReviewOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReviewOrderQuery,
    ReviewOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReviewOrderQuery, ReviewOrderQueryVariables>(
    ReviewOrderDocument,
    options
  )
}
export function useReviewOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReviewOrderQuery,
    ReviewOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReviewOrderQuery, ReviewOrderQueryVariables>(
    ReviewOrderDocument,
    options
  )
}
export type ReviewOrderQueryHookResult = ReturnType<typeof useReviewOrderQuery>
export type ReviewOrderLazyQueryHookResult = ReturnType<
  typeof useReviewOrderLazyQuery
>
export type ReviewOrderQueryResult = Apollo.QueryResult<
  ReviewOrderQuery,
  ReviewOrderQueryVariables
>
export const CreateMarketTransactionDocument = gql`
  mutation createMarketTransaction($input: CreateMarketTransactionInput!) {
    marketTransaction: createMarketTransaction(input: $input) {
      buyOrder {
        id
      }
      sellOrder {
        id
      }
    }
  }
`
export type CreateMarketTransactionMutationFn = Apollo.MutationFunction<
  CreateMarketTransactionMutation,
  CreateMarketTransactionMutationVariables
>

/**
 * __useCreateMarketTransactionMutation__
 *
 * To run a mutation, you first call `useCreateMarketTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarketTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarketTransactionMutation, { data, loading, error }] = useCreateMarketTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMarketTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMarketTransactionMutation,
    CreateMarketTransactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateMarketTransactionMutation,
    CreateMarketTransactionMutationVariables
  >(CreateMarketTransactionDocument, options)
}
export type CreateMarketTransactionMutationHookResult = ReturnType<
  typeof useCreateMarketTransactionMutation
>
export type CreateMarketTransactionMutationResult =
  Apollo.MutationResult<CreateMarketTransactionMutation>
export type CreateMarketTransactionMutationOptions = Apollo.BaseMutationOptions<
  CreateMarketTransactionMutation,
  CreateMarketTransactionMutationVariables
>
export const UpdateReviewOrderWithVaultDocument = gql`
  mutation UpdateReviewOrderWithVault(
    $input: UpdateReviewOrderWithVaultInput!
  ) {
    updateReviewOrderWithVault(input: $input) {
      fees {
        fee
        plan {
          name
        }
      }
      id
      totalFees
      totalPriceWithFees
      totalPriceWithoutFees
      weight
      user {
        wallet {
          balance
          walletBalance
        }
      }
      pricePerUnit
      vault {
        balance
        dateOfBirth
        email
        id
        name
        relationship
        securitiesOwned {
          product {
            name
            id
          }
          securities {
            ownedAllotmentsCount
          }
          allotmentsOwned
          hasHistory
        }
      }
      spotPrice
    }
  }
`
export type UpdateReviewOrderWithVaultMutationFn = Apollo.MutationFunction<
  UpdateReviewOrderWithVaultMutation,
  UpdateReviewOrderWithVaultMutationVariables
>

/**
 * __useUpdateReviewOrderWithVaultMutation__
 *
 * To run a mutation, you first call `useUpdateReviewOrderWithVaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReviewOrderWithVaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReviewOrderWithVaultMutation, { data, loading, error }] = useUpdateReviewOrderWithVaultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReviewOrderWithVaultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReviewOrderWithVaultMutation,
    UpdateReviewOrderWithVaultMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateReviewOrderWithVaultMutation,
    UpdateReviewOrderWithVaultMutationVariables
  >(UpdateReviewOrderWithVaultDocument, options)
}
export type UpdateReviewOrderWithVaultMutationHookResult = ReturnType<
  typeof useUpdateReviewOrderWithVaultMutation
>
export type UpdateReviewOrderWithVaultMutationResult =
  Apollo.MutationResult<UpdateReviewOrderWithVaultMutation>
export type UpdateReviewOrderWithVaultMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateReviewOrderWithVaultMutation,
    UpdateReviewOrderWithVaultMutationVariables
  >
export const ProductPerformanceDocument = gql`
  query productPerformance($productId: Int!) {
    productPerformance(productId: $productId) {
      currentValue
      netProfit
      ownedAllotmentsCount
      periodInvestedInDays
      totalCost
      totalReturn
      totalReturnAsPercentage
      unit
      spotPrice {
        bidPricePerDg
        bidPricePerOz
        spotTime
        askPricePerDg
        askPricePerOz
      }
    }
  }
`

/**
 * __useProductPerformanceQuery__
 *
 * To run a query within a React component, call `useProductPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductPerformanceQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useProductPerformanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductPerformanceQuery,
    ProductPerformanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ProductPerformanceQuery,
    ProductPerformanceQueryVariables
  >(ProductPerformanceDocument, options)
}
export function useProductPerformanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductPerformanceQuery,
    ProductPerformanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ProductPerformanceQuery,
    ProductPerformanceQueryVariables
  >(ProductPerformanceDocument, options)
}
export type ProductPerformanceQueryHookResult = ReturnType<
  typeof useProductPerformanceQuery
>
export type ProductPerformanceLazyQueryHookResult = ReturnType<
  typeof useProductPerformanceLazyQuery
>
export type ProductPerformanceQueryResult = Apollo.QueryResult<
  ProductPerformanceQuery,
  ProductPerformanceQueryVariables
>
export const SecuritiesOwnedForCurrentUserDocument = gql`
  query securitiesOwnedForCurrentUser($productId: Int!) {
    securitiesOwnedForCurrentUser(productId: $productId) {
      allotmentsOwned
      securities {
        ownedAllotmentsCount
        security {
          id
          product {
            id
            name
            sku
            description
            tradable
          }
          serialNumber
          unit
        }
      }
      user {
        profile {
          addressLine1
          addressLine2
          amlVerificationQuestionsAnswered
          amlVerified
          bankAccountName
          bankAccountNumber
          city
          complete
          dateOfBirth
          driversLicenceNumber
          driversLicenceVersion
          email
          firstName
          identifier
          identityVerificationFailureReasons
          identityVerificationRequestInProgress
          identityVerified
          lastName
          locked
          middleName
          nzMobilePhoneNumber
          onboarded
          postcode
          preferredName
          reference
          suburb
        }
      }
    }
  }
`

/**
 * __useSecuritiesOwnedForCurrentUserQuery__
 *
 * To run a query within a React component, call `useSecuritiesOwnedForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecuritiesOwnedForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecuritiesOwnedForCurrentUserQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useSecuritiesOwnedForCurrentUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    SecuritiesOwnedForCurrentUserQuery,
    SecuritiesOwnedForCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SecuritiesOwnedForCurrentUserQuery,
    SecuritiesOwnedForCurrentUserQueryVariables
  >(SecuritiesOwnedForCurrentUserDocument, options)
}
export function useSecuritiesOwnedForCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SecuritiesOwnedForCurrentUserQuery,
    SecuritiesOwnedForCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SecuritiesOwnedForCurrentUserQuery,
    SecuritiesOwnedForCurrentUserQueryVariables
  >(SecuritiesOwnedForCurrentUserDocument, options)
}
export type SecuritiesOwnedForCurrentUserQueryHookResult = ReturnType<
  typeof useSecuritiesOwnedForCurrentUserQuery
>
export type SecuritiesOwnedForCurrentUserLazyQueryHookResult = ReturnType<
  typeof useSecuritiesOwnedForCurrentUserLazyQuery
>
export type SecuritiesOwnedForCurrentUserQueryResult = Apollo.QueryResult<
  SecuritiesOwnedForCurrentUserQuery,
  SecuritiesOwnedForCurrentUserQueryVariables
>
export const ActivateVaultDocument = gql`
  mutation ActivateVault($input: ActivateVaultInput!) {
    activateVault(input: $input) {
      vault {
        dateOfBirth
        email
        id
        name
        relationship
        balance
      }
    }
  }
`
export type ActivateVaultMutationFn = Apollo.MutationFunction<
  ActivateVaultMutation,
  ActivateVaultMutationVariables
>

/**
 * __useActivateVaultMutation__
 *
 * To run a mutation, you first call `useActivateVaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateVaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateVaultMutation, { data, loading, error }] = useActivateVaultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateVaultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateVaultMutation,
    ActivateVaultMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ActivateVaultMutation,
    ActivateVaultMutationVariables
  >(ActivateVaultDocument, options)
}
export type ActivateVaultMutationHookResult = ReturnType<
  typeof useActivateVaultMutation
>
export type ActivateVaultMutationResult =
  Apollo.MutationResult<ActivateVaultMutation>
export type ActivateVaultMutationOptions = Apollo.BaseMutationOptions<
  ActivateVaultMutation,
  ActivateVaultMutationVariables
>
export const DisableAuthenticatorAppDocument = gql`
  mutation disableAuthenticatorApp {
    disableAuthenticatorApp(input: {})
  }
`
export type DisableAuthenticatorAppMutationFn = Apollo.MutationFunction<
  DisableAuthenticatorAppMutation,
  DisableAuthenticatorAppMutationVariables
>

/**
 * __useDisableAuthenticatorAppMutation__
 *
 * To run a mutation, you first call `useDisableAuthenticatorAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAuthenticatorAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAuthenticatorAppMutation, { data, loading, error }] = useDisableAuthenticatorAppMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisableAuthenticatorAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableAuthenticatorAppMutation,
    DisableAuthenticatorAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DisableAuthenticatorAppMutation,
    DisableAuthenticatorAppMutationVariables
  >(DisableAuthenticatorAppDocument, options)
}
export type DisableAuthenticatorAppMutationHookResult = ReturnType<
  typeof useDisableAuthenticatorAppMutation
>
export type DisableAuthenticatorAppMutationResult =
  Apollo.MutationResult<DisableAuthenticatorAppMutation>
export type DisableAuthenticatorAppMutationOptions = Apollo.BaseMutationOptions<
  DisableAuthenticatorAppMutation,
  DisableAuthenticatorAppMutationVariables
>
export const AuthenticatorAppProvisioningUriDocument = gql`
  query authenticatorAppProvisioningUri {
    authenticatorAppProvisioningUri
  }
`

/**
 * __useAuthenticatorAppProvisioningUriQuery__
 *
 * To run a query within a React component, call `useAuthenticatorAppProvisioningUriQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthenticatorAppProvisioningUriQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticatorAppProvisioningUriQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthenticatorAppProvisioningUriQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AuthenticatorAppProvisioningUriQuery,
    AuthenticatorAppProvisioningUriQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AuthenticatorAppProvisioningUriQuery,
    AuthenticatorAppProvisioningUriQueryVariables
  >(AuthenticatorAppProvisioningUriDocument, options)
}
export function useAuthenticatorAppProvisioningUriLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthenticatorAppProvisioningUriQuery,
    AuthenticatorAppProvisioningUriQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AuthenticatorAppProvisioningUriQuery,
    AuthenticatorAppProvisioningUriQueryVariables
  >(AuthenticatorAppProvisioningUriDocument, options)
}
export type AuthenticatorAppProvisioningUriQueryHookResult = ReturnType<
  typeof useAuthenticatorAppProvisioningUriQuery
>
export type AuthenticatorAppProvisioningUriLazyQueryHookResult = ReturnType<
  typeof useAuthenticatorAppProvisioningUriLazyQuery
>
export type AuthenticatorAppProvisioningUriQueryResult = Apollo.QueryResult<
  AuthenticatorAppProvisioningUriQuery,
  AuthenticatorAppProvisioningUriQueryVariables
>
export const EnableAuthenticatorAppDocument = gql`
  mutation enableAuthenticatorApp($input: EnableAuthenticatorAppInput!) {
    backupCodes: enableAuthenticatorApp(input: $input)
  }
`
export type EnableAuthenticatorAppMutationFn = Apollo.MutationFunction<
  EnableAuthenticatorAppMutation,
  EnableAuthenticatorAppMutationVariables
>

/**
 * __useEnableAuthenticatorAppMutation__
 *
 * To run a mutation, you first call `useEnableAuthenticatorAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableAuthenticatorAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableAuthenticatorAppMutation, { data, loading, error }] = useEnableAuthenticatorAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableAuthenticatorAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnableAuthenticatorAppMutation,
    EnableAuthenticatorAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EnableAuthenticatorAppMutation,
    EnableAuthenticatorAppMutationVariables
  >(EnableAuthenticatorAppDocument, options)
}
export type EnableAuthenticatorAppMutationHookResult = ReturnType<
  typeof useEnableAuthenticatorAppMutation
>
export type EnableAuthenticatorAppMutationResult =
  Apollo.MutationResult<EnableAuthenticatorAppMutation>
export type EnableAuthenticatorAppMutationOptions = Apollo.BaseMutationOptions<
  EnableAuthenticatorAppMutation,
  EnableAuthenticatorAppMutationVariables
>
export const RequestWithdrawFundsDocument = gql`
  mutation requestWithdrawFunds($input: RequestWithdrawFundsInput!) {
    withdrawalRequest: requestWithdrawFunds(input: $input) {
      id
    }
  }
`
export type RequestWithdrawFundsMutationFn = Apollo.MutationFunction<
  RequestWithdrawFundsMutation,
  RequestWithdrawFundsMutationVariables
>

/**
 * __useRequestWithdrawFundsMutation__
 *
 * To run a mutation, you first call `useRequestWithdrawFundsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestWithdrawFundsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestWithdrawFundsMutation, { data, loading, error }] = useRequestWithdrawFundsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestWithdrawFundsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestWithdrawFundsMutation,
    RequestWithdrawFundsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestWithdrawFundsMutation,
    RequestWithdrawFundsMutationVariables
  >(RequestWithdrawFundsDocument, options)
}
export type RequestWithdrawFundsMutationHookResult = ReturnType<
  typeof useRequestWithdrawFundsMutation
>
export type RequestWithdrawFundsMutationResult =
  Apollo.MutationResult<RequestWithdrawFundsMutation>
export type RequestWithdrawFundsMutationOptions = Apollo.BaseMutationOptions<
  RequestWithdrawFundsMutation,
  RequestWithdrawFundsMutationVariables
>
export const GetLatestSpotPriceDocument = gql`
  query getLatestSpotPrice($productId: Int!) {
    latestSpotPrice(productId: $productId) {
      id
      midpointPerDg
      askPricePerDg
      bidPricePerDg
      spotTime
    }
  }
`

/**
 * __useGetLatestSpotPriceQuery__
 *
 * To run a query within a React component, call `useGetLatestSpotPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestSpotPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestSpotPriceQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetLatestSpotPriceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLatestSpotPriceQuery,
    GetLatestSpotPriceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetLatestSpotPriceQuery,
    GetLatestSpotPriceQueryVariables
  >(GetLatestSpotPriceDocument, options)
}
export function useGetLatestSpotPriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestSpotPriceQuery,
    GetLatestSpotPriceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetLatestSpotPriceQuery,
    GetLatestSpotPriceQueryVariables
  >(GetLatestSpotPriceDocument, options)
}
export type GetLatestSpotPriceQueryHookResult = ReturnType<
  typeof useGetLatestSpotPriceQuery
>
export type GetLatestSpotPriceLazyQueryHookResult = ReturnType<
  typeof useGetLatestSpotPriceLazyQuery
>
export type GetLatestSpotPriceQueryResult = Apollo.QueryResult<
  GetLatestSpotPriceQuery,
  GetLatestSpotPriceQueryVariables
>
export const namedOperations = {
  Query: {
    getCurrentUser: 'getCurrentUser',
    products: 'products',
    getHistoricalSpotPrices: 'getHistoricalSpotPrices',
    getHistoricalPerformance: 'getHistoricalPerformance',
    customerPopup: 'customerPopup',
    activityForCurrentUser: 'activityForCurrentUser',
    reviewOrderForBuy: 'reviewOrderForBuy',
    walletForCurrentUser: 'walletForCurrentUser',
    VaultDetail: 'VaultDetail',
    reviewOrderForSell: 'reviewOrderForSell',
    marketOrdersForProduct: 'marketOrdersForProduct',
    productPerformanceSummaries: 'productPerformanceSummaries',
    getProfileForCurrentUser: 'getProfileForCurrentUser',
    getAmlVerificationQuestions: 'getAmlVerificationQuestions',
    getIdentityVerificationStatus: 'getIdentityVerificationStatus',
    profileForCurrentUser: 'profileForCurrentUser',
    reviewOrder: 'reviewOrder',
    productPerformance: 'productPerformance',
    securitiesOwnedForCurrentUser: 'securitiesOwnedForCurrentUser',
    authenticatorAppProvisioningUri: 'authenticatorAppProvisioningUri',
    getLatestSpotPrice: 'getLatestSpotPrice'
  },
  Mutation: {
    verifyOtp: 'verifyOtp',
    generateOtp: 'generateOtp',
    createCustomerPopupInteraction: 'createCustomerPopupInteraction',
    verifyStripeTransaction: 'verifyStripeTransaction',
    createAutoInvest: 'createAutoInvest',
    deleteAutoInvest: 'deleteAutoInvest',
    updateReviewOrderForBuy: 'updateReviewOrderForBuy',
    updatePassword: 'updatePassword',
    createReviewOrderForBuy: 'createReviewOrderForBuy',
    createReviewOrderForSell: 'createReviewOrderForSell',
    buyVoucher: 'buyVoucher',
    CreateOrUpdateVault: 'CreateOrUpdateVault',
    updateReviewOrderForSell: 'updateReviewOrderForSell',
    updateProfileForOnboarding: 'updateProfileForOnboarding',
    answerAmlQuestions: 'answerAmlQuestions',
    requestIdentityVerification: 'requestIdentityVerification',
    requestPoliTransactionVerification: 'requestPoliTransactionVerification',
    updateProfile: 'updateProfile',
    createPoliDirectDebit: 'createPoliDirectDebit',
    redeemVoucher: 'redeemVoucher',
    createMarketTransaction: 'createMarketTransaction',
    UpdateReviewOrderWithVault: 'UpdateReviewOrderWithVault',
    ActivateVault: 'ActivateVault',
    disableAuthenticatorApp: 'disableAuthenticatorApp',
    enableAuthenticatorApp: 'enableAuthenticatorApp',
    requestWithdrawFunds: 'requestWithdrawFunds'
  }
}
