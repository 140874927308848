import { TextFieldProps } from '@mui/material'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumericFormatAmount = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          })
        }}
        thousandSeparator
        valueIsNumericString
        prefix='$ '
      />
    )
  }
)

const NumericFormatWeight = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props

    return (
      <NumericFormat
        {...other}
        onChange={undefined}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          })
        }}
        max={2}
        style={{ borderRadius: 0 }}
        thousandSeparator
        valueIsNumericString
        allowNegative={false}
        suffix=' g'
      />
    )
  }
)

export default function FormattedInput(props: TextFieldProps) {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: (props?.name === 'amount'
          ? NumericFormatAmount
          : // eslint-disable-next-line @typescript-eslint/no-explicit-any
            NumericFormatWeight) as any
      }}
      sx={{
        border: 'none',
        '& fieldset': { display: 'none' },
        '& input': {
          p: 0,
          color: 'primary.main',
          bgcolor: 'transparent',
          textAlign: 'right'
        }
      }}
      variant='outlined'
    />
  )
}
