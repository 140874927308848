import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
  depositDetailWrapper: {
    position: 'relative',
    marginTop: theme.spacing(2),

    '&:first-child': {
      marginTop: theme.spacing(0.5)
    },

    '&:not(:last-child)': {
      paddingBottom: theme.spacing(1.5)
    }
  },
  depositDetailLabelText: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  depositDetailValueText: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap'
  },
  descriptionDeposit: {
    color: theme.palette.primary.main,
    fontSize: '12px'
  }
}))
