import { RouteComponentProps } from 'react-router-dom'

import { dashboardPath } from 'components/Routes'
import { namedOperations, useRedeemVoucherMutation } from 'generated/graphql'
// import goldBarImage from 'images/gold-bar.png'

import { Grid, TextField } from '@mui/material'
import Button from 'components/Button'
// import ImageWithShadow from 'components/ImageWithShadow'
import DefaultLayout from 'components/layout/DefaultLayout'

import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { useAuth } from 'components/AuthProvider'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  }
}))

const validationSchema = Yup.object().shape({
  redemptionCode: Yup.string().required('Redemption code is required')
})

interface FormValues {
  redemptionCode: string
}

const initialValues: FormValues = {
  redemptionCode: ''
}

export type RedeemVoucherProps = RouteComponentProps

export default function RedeemVoucher({
  history
}: RedeemVoucherProps): JSX.Element {
  const classes = useStyles()

  const { setToast } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: FormValues) => {
      redeemVoucher({
        variables: {
          input: {
            redemptionCode: values.redemptionCode
          }
        }
      })
    }
  })

  const { handleSubmit, setErrors, errors, handleChange, handleBlur } = formik

  const [redeemVoucher, { loading: redeemVoucherLoading }] =
    useRedeemVoucherMutation({
      onCompleted: () => {
        setToast({
          open: true,
          message: 'Good as gold! Gift redeemed.',
          type: 'success',
          duration: 3000
        })
        history.push(dashboardPath())
      },
      // TODO: error handling
      onError: error => setErrors({ redemptionCode: error.message }),
      // Refetches the current user so that the correct wallet balance details are displayed on the Dashboard
      refetchQueries: [namedOperations.Query.getCurrentUser]
    })

  return (
    <DefaultLayout heading='Redeem Gift' showBackIcon>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              fullWidth
              id='redemptionCode'
              label='Redemption code'
              name='redemptionCode'
              required
              type='text'
              error={!!errors.redemptionCode}
              helperText={errors.redemptionCode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              color='primary'
              fullWidth
              variant='outlined'
              disabled={redeemVoucherLoading}
              type='submit'
            >
              Redeem
            </Button>
          </Grid>
        </Grid>
      </form>
    </DefaultLayout>
  )
}
