/* eslint-disable @typescript-eslint/no-explicit-any */
export function gaEvent(name: string, params: unknown) {
  if ((window as any)?.gtag) {
    return (window as any)?.gtag('event', name, params)
  }
}

export function fbEvent(name: string) {
  if ((window as any)?.fbq) {
    return (window as any)?.fbq('track', name)
  }
}
