import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  perGram: {
    color: theme.palette.status.positive
  },
  form: {
    width: '100%'
  },
  button: {
    background: theme.palette.primary.contrastText,
    borderRadius: '1rem',
    width: '3.5rem',
    height: '3.5rem',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  increments: {
    color: theme.palette.disabled.contrastText,
    fontSize: '12px',
    lineHeight: '150%'
  },
  incrementsError: {
    color: theme.palette.error.main,
    fontSize: '12px',
    lineHeight: '150%'
  },
  'review-&-pay': {
    background: theme.palette.primary.main,
    borderTopRightRadius: '2rem',
    borderTopLeftRadius: '2rem',
    padding: theme.spacing(2, 2, 3),

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1.5rem',

    '& > div': {
      '& > div:first-child': {
        color: theme.palette.white.main,
        fontWeight: '700',
        lineHeight: '140%'
      },

      '& > div:last-child': {
        display: 'flex',
        gap: '4px',

        '& > p': {
          color: theme.palette.white.main,
          fontSize: '0.75rem',
          fontWeight: '400',
          lineHeight: '140%'
        },

        '& > div > span': {
          color: theme.palette.white.main,
          fontSize: '0.75rem',
          fontWeight: '400',
          lineHeight: '140%'
        }
      }
    }
  },
  helperTextAmount: {
    position: 'absolute',
    top: '-35%',
    fontSize: '10px',
    color: theme.palette.black.main
  },
  amountInput: {
    background: 'transparent !important',

    '& > input': {
      paddingLeft: theme.spacing(1)
    }
  }
}))
