// Adapted from: https://dev.to/finiam/predictable-react-authentication-with-the-context-api-g10
import Bugsnag from '@bugsnag/js'
import client from 'config/apolloClient'
import { Location } from 'history'
import queryString from 'query-string'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'

import * as sessionsApi from 'api/sessions'
import * as usersApi from 'api/users'
import {
  confirmEmailPath,
  loginPath,
  tutorialPath,
  verifyOtpPath
} from 'components/Routes'
import {
  GenerateOtpMutationFn,
  Profile,
  SecuritiesOwned,
  User,
  Wallet,
  useGenerateOtpMutation,
  useGetCurrentUserQuery
} from 'generated/graphql'
import history from 'utils/history'
import { checkTypeOfValue, sentenceCase } from 'utils/util'

import { Alert, AlertColor, Snackbar } from '@mui/material'
import { AuthorizationStatus, VerifyOtpParams } from 'api/sessions/sessions'
import LoadingWrapper from 'components/layout/LoadingWrapper'
import {
  getUserTheme,
  saveTheme
} from 'components/pages/DeviceSettings/DeviceSettings'
import { fbEvent, gaEvent } from 'utils/ga'
import AuthModal from './AuthModal'

export interface UserType
  extends Omit<User, 'profile' | 'securitiesOwned' | 'wallet'> {
  profile?: Partial<Profile> | null
  securitiesOwned?: [Partial<SecuritiesOwned>] | null
  wallet?: Partial<Wallet> | null
}

export interface IToast {
  open: boolean
  message: string
  type?: AlertColor
  duration?: number
}

export interface SignUpPayload {
  email: string
  password: string
  signUpToken: string
  recaptchaToken?: string
  referrerCode?: string
  marketingOptedInAt: boolean
}

export interface LogInPayload {
  email: string
  password: string
  keepMeSignedIn: string
  recaptchaToken?: string
}

interface AuthContextType {
  user?: UserType
  loading: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any
  login: ({
    email,
    password,
    keepMeSignedIn,
    recaptchaToken
  }: LogInPayload) => void
  resend: (email: string, recaptchaToken?: string) => void
  requestReset: (email: string, recaptchaToken?: string) => void
  reset: (token: string, password: string, recaptchaToken?: string) => void
  signUp: ({
    email,
    password,
    signUpToken,
    recaptchaToken,
    referrerCode,
    marketingOptedInAt
  }: SignUpPayload) => void
  confirmEmail: (
    email: string,
    confirmationToken: string,
    recaptchaToken?: string
  ) => void
  generateOtp: GenerateOtpMutationFn
  maybeRequestOtp: () => void
  logout: () => void
  refreshCurrentUser: () => Promise<UserType>
  setToast: (toast: IToast) => void
  verifyOtp: (params: VerifyOtpParams) => void
  setLoading: (loading: boolean) => void
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export function AuthProvider({
  children
}: Readonly<{
  children: JSX.Element
}>): JSX.Element {
  const [user, setUser] = useState<UserType | undefined>()
  const [error, setError] = useState<unknown>()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true)

  const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false)

  const [location, setLocation] = useState<Location<unknown>>(history.location)

  const [toast, setToast] = useState<IToast>({
    open: false,
    message: '',
    type: 'info',
    duration: 1500
  })

  // Check if there is a currently active session when the provider is mounted for the first time.
  // If there is an error, it means there is no session.
  // Finally, just signal the component that the initial load is over.
  const {
    data: currentUserData,
    loading: currentUserLoading,
    refetch: getCurrentUser
  } = useGetCurrentUserQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setLoadingInitial(false)
    },
    onError: () => {
      setLoadingInitial(false)
    }
  })

  const { mutate: confirm } = useMutation(usersApi.confirmEmail)

  const [generateOtp] = useGenerateOtpMutation({
    // TODO: error handling
    onError: error =>
      setToast(prev => ({
        ...prev,
        open: true,
        message: error.message,
        type: 'error',
        duration: 3000
      })),
    onCompleted: () => setIsAuthModalOpen(true)
  })

  useEffect(() => {
    // We use the variable `mounted` here to make sure we aren't updating state in the `history.listen()` callback
    // Fix as per: https://www.debuggr.io/react-update-unmounted-component/#the-fix
    let mounted = true

    history.listen(location => {
      if (mounted) setLocation(location)
    })

    return () => {
      mounted = false
    }
  }, [])

  // If we change page, reset the error state.
  useEffect(() => {
    if (error) setError(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    if (currentUserData) {
      setUser(currentUserData.currentUser as UserType)

      const { email, id } = currentUserData.currentUser
      Bugsnag.setUser(id, email)
    }
  }, [currentUserData])

  useEffect(() => {
    setLoading(currentUserLoading)
  }, [currentUserLoading])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function showToastError(error: any) {
    const message: string =
      error.response.data.errors &&
      sentenceCase(
        `${Object.entries(error.response.data.errors)
          .map(([, val]) => `${val}`)
          .join(', ')}.`
      )
    setToast(prev => ({
      ...prev,
      open: true,
      message,
      type: 'error',
      duration: 3000
    }))
  }

  // Flags the component loading state and posts the login data to the server.
  // On success we redirect to the dashboard path.
  // Finally, signal to the component that the loading state is over.
  async function login({
    email,
    password,
    keepMeSignedIn,
    recaptchaToken
  }: LogInPayload) {
    setLoading(true)
    setError('')

    sessionsApi
      .login({
        email,
        password,
        remember_me: !!keepMeSignedIn,
        recaptcha_token: recaptchaToken
      })
      .then(async res => {
        // call google analyst event when user login success
        gaEvent('login', {
          name: 'a user logs in'
        })

        // This ensures that when we login with account enabled two-step authenticator
        // then the app will redirect to verify OTP screen
        if (res.status === AuthorizationStatus.VerifyOtp) {
          history.push(verifyOtpPath())
          return
        }
        // This ensures that when we redirect to the dashboard path, that we
        // don't get sent back to the login path due to there being no user
        return await getCurrentUser()
      })
      .then(res => {
        if (res) {
          // set theme by user id
          const userId = res.data.currentUser.id
          const userTheme = getUserTheme(userId)
          saveTheme(userTheme)
        }
        return res
      })
      .then(res => {
        if (res) {
          const queryParams = queryString.parse(location.search)
          const path = queryParams.path
          history.push(path as string)
        }
      })
      .catch(error => {
        // This ensures that when we login with account haven't yet confirm email
        // then the app will redirect to confirm email screen
        if (!error.response.data.status) {
          history.push({
            pathname: confirmEmailPath(),
            search: queryString.stringify({ email }),
            state: {
              resendCode: true
            }
          })
        }
        setError(
          error.response.data.errors
            ? sentenceCase(`${error.response.data.errors.join(', ')}.`)
            : error?.response?.data?.error
        )
      })
      .finally(() => setLoading(false))
  }

  // Flags the component loading state and posts the sign up data to the server.
  // On success we redirect to the login path.
  // Finally, signal to the component that the loading state is over.
  function signUp({
    email,
    password,
    signUpToken,
    recaptchaToken,
    referrerCode,
    marketingOptedInAt
  }: SignUpPayload) {
    setLoading(true)
    setError('')

    usersApi
      .signUp({
        email,
        password,
        signup_token: signUpToken,
        recaptcha_token: recaptchaToken,
        referrer_code: referrerCode,
        marketing_opt_in: marketingOptedInAt
      })
      .then(() => {
        // call google analyst event when user sign up success
        gaEvent('sign_up', {
          name: 'a user signs up to measure the popularity of each sign-up method'
        })

        fbEvent('CompleteRegistration')

        saveTheme('device')
        history.push({
          pathname: confirmEmailPath(),
          search: queryString.stringify({ email })
        })
      })
      .catch(error => {
        setError(
          sentenceCase(
            `${Object.entries(error.response.data.errors)
              .map(([key, val]) => `${key} ${val}`)
              .join(', ')}.`
          )
        )
      })
      .finally(() => setLoading(false))
  }

  function confirmEmail(
    email: string,
    confirmationToken: string,
    recaptchaToken?: string
  ) {
    setLoading(true)
    setError('')

    confirm(
      {
        email,
        confirmation_token: confirmationToken,
        recaptcha_token: recaptchaToken
      },
      {
        onError: error =>
          setError(
            checkTypeOfValue(error) === 'string'
              ? error
              : sentenceCase(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  `${Object.entries((error as any)?.response?.data?.errors)
                    .map(([, val]) => `${val}`)
                    .join(', ')}.`
                )
          ),
        onSuccess: async () => {
          await getCurrentUser().then(() => history.push(tutorialPath()))
        },
        onSettled: () => setLoading(false)
      }
    )
  }

  async function resend(email: string, recaptchaToken?: string) {
    setLoading(true)

    usersApi
      .resendConfirmationEmail({ email, recaptcha_token: recaptchaToken })
      .then(() => {
        history.push({
          pathname: confirmEmailPath(),
          search: queryString.stringify({ email })
        })
      })
      .catch(error => {
        showToastError(error)
      })
      .finally(() => setLoading(false))
  }

  function requestReset(email: string, recaptchaToken?: string) {
    setLoading(true)

    usersApi
      .requestResetPassword({ email, recaptcha_token: recaptchaToken })
      .then(() => {
        history.push(loginPath())
        setToast({
          open: true,
          message: 'Please check your email to reset your password',
          type: 'info',
          duration: 3000
        })
      })
      .catch(error => {
        showToastError(error)
      })
      .finally(() => setLoading(false))
  }

  function reset(token: string, password: string, recaptchaToken?: string) {
    setLoading(true)
    usersApi
      .resetPassword({ token, password, recaptcha_token: recaptchaToken })
      .then(() => {
        history.push(loginPath())
      })
      .catch(error => {
        showToastError(error)
      })
      .finally(() => setLoading(false))
  }

  // Call the logout endpoint and then remove the user from the state.
  function logout() {
    sessionsApi
      .logout()
      .then(() => {
        setUser(undefined)
        client.clearStore()
        history.push(loginPath())
      })
      .then(() => {
        // clear theme
        saveTheme('device')
      })
  }

  async function verifyOtp(params: VerifyOtpParams) {
    setLoading(true)
    setError('')

    sessionsApi
      .verifyOtp(params)
      .then(async () => {
        // This ensures that when we redirect to the dashboard path, that we
        // don't get sent back to the login path due to there being no user
        return await getCurrentUser()
      })
      .then(res => {
        // set theme by user id
        const userId = res.data.currentUser.id
        const userTheme = getUserTheme(userId)
        saveTheme(userTheme)
      })
      .then(() => {
        const queryParams = queryString.parse(location.search)
        const path = queryParams.path
        history.push(path as string)
      })
      .catch(error => {
        setError(error.response.data.errors ?? '')
      })
      .finally(() => setLoading(false))
  }

  function handleCloseAuthModal(_event?: never, reason?: string): void {
    // If `onClose` is called from an interaction initiated by the Material UI Dialog component, there may be an
    // associated `reason`. Do not close the AuthModal if the reason is one of "escapeKeyDown" or "backdropClick".
    if (reason !== 'escapeKeyDown' && reason !== 'backdropClick') {
      setIsAuthModalOpen(false)
    }
  }

  function maybeRequestOtp(): void {
    // If the user's authenticatorAppEnabled flag is `false`, we will request a OTP via email using the `generateOtp` mutation
    // If the user's authenticatorAppEnabled flag is `true`, we do not need to request an OTP as the user will use their authenticator
    // app to generate one - we just need to render the modal for them to enter their code
    if (!user?.authenticatorAppEnabled) {
      generateOtp()
    } else {
      setIsAuthModalOpen(true)
    }
  }

  async function refreshCurrentUser(): Promise<UserType> {
    setLoading(true)
    try {
      return (await getCurrentUser()).data.currentUser as UserType
    } finally {
      setLoading(false)
    }
  }

  // Make the provider update only when it should.
  // We only want to force re-renders if the user, loading or error states change.
  //
  // Whenever the `value` passed into a provider changes, the whole tree under the provider re-renders,
  // and that can be very costly! Even in this case, where you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoisedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      setLoading,
      login,
      resend,
      requestReset,
      reset,
      signUp,
      confirmEmail,
      generateOtp,
      maybeRequestOtp,
      logout,
      refreshCurrentUser,
      setToast,
      verifyOtp
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, loading, error]
  )

  // We only want to render the underlying app after we assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoisedValue}>
      <LoadingWrapper loading={loadingInitial}>{children}</LoadingWrapper>
      <AuthModal isOpen={isAuthModalOpen} onClose={handleCloseAuthModal} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={toast.open}
        onClose={() => setToast(prev => ({ ...prev, open: false }))}
        autoHideDuration={toast.duration}
        key={'top' + 'right'}
      >
        <Alert severity={toast.type} sx={{ width: '100%' }} variant='filled'>
          {toast.message}
        </Alert>
      </Snackbar>
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextType => useContext(AuthContext)
