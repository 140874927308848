import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import Slider, { Settings } from 'react-slick'
import { gaEvent } from 'utils/ga'
import { NextButton, PreviousButton, StackDivVideo, slider } from './Arrows'
import ProgressBar from './ProgressBar'
import useStyles from './styles'
import { useAuth } from 'components/AuthProvider'
import { dashboardPath, welcomePath } from 'components/Routes'
import { Container } from '@mui/material'
import { CONTAINER_WIDTH } from 'constants/index'

const TIME_TO_NEXT_SLIDE = 5000

interface SlideProps {
  id: number
  image: string
  paragraph: string[]
  heading?: string[] | undefined
  shadow?: string | undefined
  video?: string | undefined
}

function Slide({
  id,
  image,
  paragraph,
  heading,
  shadow,
  video
}: SlideProps): JSX.Element {
  const classes = useStyles()
  const bgImageRef = useRef<HTMLDivElement>(null)

  const [shadowWidth, setShadowWidth] = useState(0)
  const [bgImageSampleDimentions, setBgImageSampleDimentions] = useState({
    height: 0,
    width: 0
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onLoadBgImageSample = (x: any) => {
    if (x.currentTarget) {
      setBgImageSampleDimentions({
        height: x.currentTarget.height,
        width: x.currentTarget.width
      })
    }
  }

  useEffect(() => {
    if (bgImageRef.current) {
      const divWidth = bgImageRef.current.clientWidth
      const divHeight = bgImageRef.current.clientHeight

      if (bgImageSampleDimentions.width && divWidth) {
        // const widthRatio = divWidth / bgImageSampleDimentions.width
        // const heightRatio = divHeight / bgImageSampleDimentions.height

        if (bgImageSampleDimentions.width > bgImageSampleDimentions.height) {
          setShadowWidth(
            (bgImageSampleDimentions.height / bgImageSampleDimentions.width) *
              divWidth
          )
        } else {
          setShadowWidth(
            (bgImageSampleDimentions.width / bgImageSampleDimentions.height) *
              divHeight
          )
        }
      }
    }
  }, [bgImageSampleDimentions, image])

  useEffect(() => {
    const bgImageSample = new Image()
    bgImageSample.onload = onLoadBgImageSample
    bgImageSample.src = image
  }, [image])

  return (
    <Box component='div' key={id} className={classes.displayPart}>
      {heading?.map((heading: string, index) => (
        <Box px={4} key={index}>
          <Typography component='h1'>{heading}</Typography>
        </Box>
      ))}
      {paragraph?.map((paragraph, index) => (
        <Box px={4} key={index} mb={2}>
          <Typography component='p' lineHeight={1}>
            {paragraph}
          </Typography>
        </Box>
      ))}

      {!!image && (
        <Box
          ref={bgImageRef}
          className='test'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '90%',
            height: '50vh',
            backgroundImage: `url('${image}')`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% center',
            position: 'relative'
          }}
        >
          {!!shadow && shadowWidth !== 0 && (
            <Box
              sx={{
                alignSelf: 'auto',
                position: 'absolute',
                backgroundImage: `url('${shadow}')`,
                bottom: '-15vh',
                width: shadowWidth * 1.1,
                height: '15vh',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '50% center'
              }}
            />
          )}
        </Box>
      )}

      {!!video && (
        <>
          <StackDivVideo video={video} />
          <Box
            sx={{
              alignSelf: 'auto',
              backgroundImage: `url('${shadow}')`,
              width: '100%',
              height: '69px',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '50% center'
            }}
          />

          <Box display='flex' flexDirection='column' gap={1} mb={6}>
            <Typography
              component='a'
              href='https://www.catalist.co.nz/marketplace'
              sx={{
                textAlign: 'center',
                color: theme => theme.palette.primary.main,
                textDecoration: 'underline'
              }}
              target='_blank'
            >
              MORE INFO
            </Typography>

            <Typography component='span' className={classes.paragraph}>
              Connecting investors To real things, offering low-cost trading,
              liquidity & transparency.
            </Typography>
            <Typography component='span' className={classes.paragraph}>
              All trades are settled by Catalist on the Catalist Marketplace'
            </Typography>
          </Box>
        </>
      )}
    </Box>
  )
}

export default function Tutorial(): JSX.Element {
  const classes = useStyles()

  const history = useHistory()

  const [step, setStep] = useState<number>(0)

  const { user } = useAuth()

  const settings: Settings = {
    dots: false,
    infinite: false,
    autoplay: true,
    autoplaySpeed: TIME_TO_NEXT_SLIDE,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PreviousButton />,
    nextArrow: <NextButton />,
    swipe: false,
    initialSlide: 0,
    pauseOnHover: false,
    pauseOnFocus: false,
    beforeChange: (_current: number, next: number) => setStep(next)
  }

  const finishTutorial = useCallback(
    () => {
      const isVerified =
        user?.profile?.onboarded || user?.profile?.partialIdentityVerified
      return isVerified
        ? history.push(dashboardPath())
        : history.push(welcomePath())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  )

  const clickSkip = () => {
    /*
    track clicks on the 'skip' button (GA4)
    Because GA4 will automatically create `click` events for events that
    lead to an external site, we should name our event something different
    https://support.google.com/analytics/answer/9234069?hl=en
    */
    gaEvent('press', {
      name: 'skip_tutorial'
    })

    finishTutorial()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (step === slider.length - 1) {
        finishTutorial()
      }
    }, TIME_TO_NEXT_SLIDE)

    return () => clearTimeout(timer)
  }, [step, history, finishTutorial])

  return (
    <Container className={classes.root} maxWidth={CONTAINER_WIDTH}>
      <ProgressBar step={step} length={slider.length} />

      <Slider {...settings}>
        {slider.map((slider, index) => (
          <Slide {...slider} key={index} />
        ))}
      </Slider>

      <Typography className={classes.skip} onClick={clickSkip}>
        Skip
      </Typography>
    </Container>
  )
}
