import { useMemo, useRef } from 'react'
import * as Yup from 'yup'

import {
  verifyFinancialDetailsPath,
  verifyGetStartedPath,
  verifyIdDetailsPath,
  verifyPath,
  verifyPersonalDetailsPath
} from 'components/Routes'
import {
  AmlVerificationAnswerOption,
  AmlVerificationQuestion,
  useGetAmlVerificationQuestionsQuery,
  useGetProfileForCurrentUserQuery
} from 'generated/graphql'
import {
  API_DATE_FORMAT,
  UI_DATE_FORMAT,
  formatDate,
  formatFieldToLabel
} from 'utils/util'
import formValidation, {
  VALIDATION_VERIFY_STEP_ONE,
  VALIDATION_VERIFY_STEP_TWO
} from './formValidation'

import { useAuth } from 'components/AuthProvider'
import DefaultLayout from 'components/layout/DefaultLayout'
import { Form, Formik } from 'formik'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { AnyObject } from 'yup/lib/types'
import Breadcrumbs from './Breadcrumbs/Breadcrumbs'
import FinancialDetailsForm from './FinancialDetailsForm'
import GetStarted from './GetStarted/GetStarted'
import IdDetailsForm from './IdDetailsForm'
import PersonalDetailsForm from './PersonalDetailsForm'

export interface FormValues {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
  firstName: string
  middleName: string
  lastName: string
  preferredName: string
  dateOfBirth: string
  addressLine1: string
  addressLine2: string
  suburb: string
  city: string
  postcode: string
  nzMobilePhoneNumber: string
  driversLicenceNumber?: string
  driversLicenceVersion?: string
  comments: { [key: string]: { [key: string]: string } }
  passportNumber?: string
  passportExpiry?: string
  verificationMethod?: string
  ethnicity: string
}

interface QuestionFieldsProps {
  name: string
  format: string
}

// TODO: dynamic form validation schema for AML questions

export type OnboardingWizardProps = RouteComponentProps

export default function OnboardingWizard({
  location
}: OnboardingWizardProps): JSX.Element {
  const financialDetailsFormRef = useRef()

  const { setToast } = useAuth()

  const {
    data: profileForCurrentUserData,
    loading: profileForCurrentUserLoading
  } = useGetProfileForCurrentUserQuery({
    // TODO: error handling
    onError: error =>
      setToast({
        open: true,
        message: error.message,
        type: 'error',
        duration: 3000
      }),
    fetchPolicy: 'no-cache'
  })

  const { data: questionSectionsData, loading: questionSectionsLoading } =
    useGetAmlVerificationQuestionsQuery({
      // TODO: error handling
      onError: error =>
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        })
    })

  const initialValues = useMemo(
    () => {
      // TODO: should this be `let values: FormValues = {}`, and if so, should all the fields be optional???
      // Or should each field be instantiated with empty values below?
      let values = {}

      if (profileForCurrentUserData && questionSectionsData) {
        const {
          addressLine1,
          addressLine2,
          city,
          dateOfBirth,
          driversLicenceNumber,
          driversLicenceVersion,
          firstName,
          lastName,
          middleName,
          nzMobilePhoneNumber,
          postcode,
          preferredName,
          suburb,
          passportExpiry,
          passportNumber,
          verificationMethod,
          ethnicity
        } = profileForCurrentUserData?.profileForCurrentUser || {}

        values = {
          ...values,
          addressLine1: addressLine1 || '',
          addressLine2: addressLine2 || '',
          city: city || '',
          dateOfBirth: dateOfBirth
            ? formatDate({
                date: dateOfBirth,
                inputFormat: API_DATE_FORMAT,
                outputFormat: UI_DATE_FORMAT
              })
            : '',
          driversLicenceNumber: driversLicenceNumber || '',
          driversLicenceVersion: driversLicenceVersion || '',
          firstName: firstName || '',
          lastName: lastName || '',
          middleName: middleName || '',
          nzMobilePhoneNumber: nzMobilePhoneNumber || '',
          postcode: postcode || '',
          preferredName: preferredName || '',
          suburb: suburb || '',
          passportExpiry: passportExpiry || '',
          passportNumber: passportNumber || '',
          verificationMethod: verificationMethod,
          ethnicity: ethnicity || ''
        } as FormValues

        const { questionSections } = questionSectionsData

        let comments = {}

        questionSections?.forEach(section => {
          const questions: Array<AmlVerificationQuestion> =
            section.questions || []

          const sectionValues = questions.reduce(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (values: any, question: AmlVerificationQuestion) => {
              const answer = question.answer

              let initialValue: string | Array<string>

              switch (question.format) {
                case 'multi_select':
                  initialValue = answer?.chosenAnswerOptionIds || []
                  break
                case 'single_select':
                  initialValue = answer?.chosenAnswerOptionIds?.length
                    ? answer.chosenAnswerOptionIds[0]
                    : ''
                  break
                case 'free_text':
                  initialValue = answer?.answer || ''
                  break
                default:
                  initialValue = ''
              }

              // Initialises multi-select form values with an empty array instead of an empty string
              // const initialValue = question.format === 'multi_select' ? [] : ''

              // NOTE: this builds up an object for any fields that require a comment
              // It's a nested object, keyed first by the question identifier, and then the option identifier
              const commentValues = question.answerOptions.reduce(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (values: any, option: AmlVerificationAnswerOption) => {
                  if (option.commentsRequired) {
                    return {
                      ...values,
                      [question.identifier]: {
                        [option.id]: answer?.comments || ''
                      }
                    }
                  } else {
                    return values
                  }
                },
                {}
              )

              comments = { ...comments, ...commentValues }

              return {
                ...values,
                [question.identifier]: initialValue
              }
            },
            {}
          )

          values = { ...values, ...sectionValues, comments }
        })
      }

      return values as FormValues
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profileForCurrentUserData, questionSectionsData]
  )

  const validationSchema = useMemo(() => {
    /* We validate Financial Details Form in Frontend because in BackEnd doesn't response error message for each question field */
    const questionFields = questionSectionsData?.questionSections
      ? questionSectionsData.questionSections.reduce(
          (questionResults: QuestionFieldsProps[], currentQuestion) => {
            const questionResult = currentQuestion?.questions.map(question => {
              return {
                name: question.identifier,
                format: question.format
              }
            })
            return [...questionResults, ...questionResult]
          },
          []
        )
      : []

    const VALIDATION_VERIFY_STEP_THREE = questionFields.length
      ? questionFields.reduce((questionFields: AnyObject, questionField) => {
          switch (questionField.format) {
            case 'multi_select':
              return {
                ...questionFields,
                [questionField.name]: Yup.array().min(
                  1,
                  `You must choose at least one answer`
                )
              }
            case 'single_select':
              return {
                ...questionFields,
                [questionField.name]: Yup.string().required(
                  `${formatFieldToLabel(questionField.name)} is required`
                )
              }
            default:
              return { ...questionFields }
          }
        }, {})
      : {}

    switch (location.pathname) {
      // Validation Personal Details form (Step 1/3)
      case verifyPersonalDetailsPath():
        return Yup.object().shape(VALIDATION_VERIFY_STEP_ONE)
      // Validation ID Details form (Step 2/3)
      case verifyIdDetailsPath():
        return Yup.object().shape(VALIDATION_VERIFY_STEP_TWO)
      // Validation Financial Details form (Step 3/3)
      case verifyFinancialDetailsPath():
        return Yup.object().shape(VALIDATION_VERIFY_STEP_THREE)
      default:
        return Yup.object().shape(formValidation)
    }
  }, [location.pathname, questionSectionsData])

  const pageHeading = useMemo(() => {
    switch (location.pathname) {
      case verifyGetStartedPath():
        return <Breadcrumbs step={0} heading='get started' />
      case verifyPersonalDetailsPath():
        return <Breadcrumbs step={1} heading='introduce yourself' />
      case verifyIdDetailsPath():
        return <Breadcrumbs step={2} heading='id check' />
      case verifyFinancialDetailsPath():
        return <Breadcrumbs step={3} heading='more details' />
      default:
        return ''
    }
  }, [location.pathname])

  return (
    <DefaultLayout
      heading={pageHeading}
      wrapperContainer={{ position: 'initial' }}
      wrapperContent={{ mt: 4 }}
      loading={profileForCurrentUserLoading || questionSectionsLoading}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={() => {
          // @ts-ignore
          financialDetailsFormRef.current.handleClickVerify()
        }}
        validationSchema={validationSchema}
      >
        <Form>
          <Switch>
            <Redirect from={verifyPath()} exact to={verifyGetStartedPath()} />
            <Route path={verifyGetStartedPath()} component={GetStarted} />
            <Route
              path={verifyPersonalDetailsPath()}
              component={PersonalDetailsForm}
            />
            <Route path={verifyIdDetailsPath()} component={IdDetailsForm} />
            <Route
              path={verifyFinancialDetailsPath()}
              render={props => (
                <FinancialDetailsForm
                  ref={financialDetailsFormRef}
                  {...props}
                />
              )}
            />
          </Switch>
        </Form>
      </Formik>
    </DefaultLayout>
  )
}
