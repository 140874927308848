import { Fragment, useState } from 'react'

import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography
} from '@mui/material'
import Currency from 'components/Currency'
import DateTime from 'components/DateTime'
import BuyIcon from 'components/icons/Buy'
import BuyVoucherIcon from 'components/icons/BuyVoucher'
import DepositIcon from 'components/icons/Deposit'
import PoliDepositIcon from 'components/icons/PoliDeposit'
import SellIcon from 'components/icons/Sell'
import useStyles from './styles'
import InsuranceFeeIcon from 'components/icons/InsuranceFee'
import WithdrawalIcon from 'components/icons/Withdrawal'
import CreditCardIcon from 'components/icons/CreditCard/CreditCard'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import { generateUsername } from 'utils/util'
import { useAuth } from 'components/AuthProvider'

interface LedgerEntry {
  createdAt: string
  credit: number
  debit: number
  source: {
    sourceCategorisation: string
    sourceInfo: string
    sourceNote?: string | null
  }
  __typename?: string
}

export interface Vault {
  dateOfBirth: string
  email: string
  id: string
  name: string
  relationship: string
  balance: string
  securitiesOwned: [SecuritiesOwned]
}

export interface SecuritiesOwned {
  allotmentsOwned: number
  hasHistory: boolean
  securities: [Security]
  product: Product
}

export interface Security {
  ownedAllotmentsCount: number
}

export interface Product {
  name: string
  id: string
}

// TODO: See if we can utilise existing graphql types
export interface ActivityItemProps {
  activity?: {
    id: number
    createdAt: string
    entries: LedgerEntry[]
    reference: string
    total: string
  }
  vault?: Partial<Vault>
}

enum ActivityTitle {
  Buy = 'Buy',
  Sell = 'Sell',
  BuyVoucher = 'Buy Voucher',
  PoliDeposit = 'Poli Deposit',
  ReceiveVoucher = 'Receive voucher',
  Deposit = 'Deposit',
  InsuranceFee = 'Insurance fee',
  Withdrawal = 'Withdrawal',
  TopUp = 'Top-up with credit card',
  Reward = 'Reward'
}

export default function ActivityItem({
  activity,
  vault
}: ActivityItemProps): JSX.Element | null {
  const classes = useStyles()

  const { user } = useAuth()

  const [expanded, setExpanded] = useState<boolean>(false)

  const handleToggleExpanded = () => {
    setExpanded(!expanded)
  }

  const renderIcon = () => {
    const title = activity?.entries[0].source
      .sourceCategorisation as ActivityTitle

    switch (title) {
      case ActivityTitle.Buy:
        return <BuyIcon />
      case ActivityTitle.BuyVoucher:
      case ActivityTitle.ReceiveVoucher:
        return <BuyVoucherIcon />
      case ActivityTitle.Sell:
        return <SellIcon />
      case ActivityTitle.PoliDeposit:
        return <PoliDepositIcon />
      case ActivityTitle.Deposit:
        return <DepositIcon />
      case ActivityTitle.InsuranceFee:
        return <InsuranceFeeIcon />
      case ActivityTitle.Withdrawal:
        return <WithdrawalIcon />
      case ActivityTitle.TopUp:
        return <CreditCardIcon />
      default:
        if (/reward/i.test(title)) {
          return (
            <Box
              width={40}
              height={40}
              display='flex'
              justifyContent='center'
              alignItems='center'
              borderRadius='50%'
              border='1px solid black'
            >
              <EmojiEventsIcon width={40} height={40} />
            </Box>
          )
        }

        return <></>
    }
  }

  const renderEntries = (entries: LedgerEntry[]): JSX.Element | null => {
    // Only render entries if we have more than one LedgerEntry associated to the Activity (i.e. it doesn't make sense to show "Deposit" twice)
    return entries.length > 0 ? (
      <>
        {entries.map(entry => {
          // TODO: Expose a better key for each entry
          return (
            <Fragment
              key={`${activity?.reference}-${entry.createdAt}-${entry.source.sourceCategorisation}`}
            >
              <Grid item xs={6}>
                <Typography component='p' fontSize='18px'>
                  {entry.__typename === 'ExpirableWalletCreditEntry'
                    ? 'Special funds'
                    : entry.source.sourceCategorisation}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  component='p'
                  fontSize='18px'
                  fontWeight='700'
                  textAlign='right'
                  textTransform='uppercase'
                >
                  <Currency
                    amount={Math.abs(Number(entry.credit - entry.debit))}
                  />
                </Typography>
              </Grid>
              {entry.source.sourceNote && (
                <>
                  <Grid item xs={6}>
                    <Typography component='p' fontSize='18px'>
                      Note
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography component='p' fontSize='18px' align='right'>
                      {entry.source.sourceNote}
                    </Typography>
                  </Grid>
                </>
              )}
            </Fragment>
          )
        })}
      </>
    ) : null
  }

  const buildExpandIconClasses = (): string => {
    let className = classes.expandIcon

    if (expanded) {
      className += ` ${classes.expandIconExpanded}`
    }

    return className
  }

  return activity ? (
    <Accordion
      className={classes.accordion}
      expanded={expanded}
      onChange={handleToggleExpanded}
    >
      <AccordionSummary
        aria-controls={`${activity.reference}-content`}
        className={classes.accordionSummary}
        id={`${activity.reference}-header`}
      >
        <Grid xs={12} item className={classes.activities}>
          {renderIcon()}
          <Box flex={1}>
            <Typography fontSize='18px' color='#1E1E1E'>
              <Currency amount={Math.abs(Number(activity.total))} />
            </Typography>
            <Typography fontSize='12px' color='#757575'>
              <DateTime date={activity.createdAt} format='DD MMMM YYYY' />
            </Typography>
          </Box>
          <ArrowDropDownRoundedIcon className={buildExpandIconClasses()} />
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={0}>
          {!!vault && (
            <>
              <Grid item xs={6}>
                <Typography component='p' fontSize='18px'>
                  Vault
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  component='p'
                  fontSize='18px'
                  fontWeight='700'
                  textAlign='right'
                >
                  {vault.name ?? generateUsername(user)}
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={6}>
            <Typography component='p' fontSize='18px'>
              Reference
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              component='p'
              fontSize='18px'
              fontWeight='700'
              textAlign='right'
              textTransform='uppercase'
            >
              {activity.reference}
            </Typography>
          </Grid>

          {renderEntries(activity.entries)}
        </Grid>
      </AccordionDetails>
    </Accordion>
  ) : null
}
