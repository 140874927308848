/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, SelectChangeEvent } from '@mui/material'
import { useAuth } from 'components/AuthProvider'
import DefaultLayout from 'components/layout/DefaultLayout'
import { useEffect, useState } from 'react'

export type DeviceSettingThemeType = 'dark' | 'light' | 'device'

export interface IDeviceSettings {
  device_settings: {
    theme: DeviceSettingThemeType
  }
}

const themeOptions = [
  {
    label: 'Dark',
    value: 'dark'
  },
  {
    label: 'Light',
    value: 'light'
  },
  {
    label: 'Device',
    value: 'device'
  }
]

export const saveTheme = (theme: DeviceSettingThemeType) => {
  const deviceTheme = window.matchMedia('(prefers-color-scheme: dark)')
  if (theme === 'device') {
    if (deviceTheme.matches) {
      localStorage.setItem('theme', 'dark')
    } else {
      localStorage.setItem('theme', 'light')
    }
  } else {
    localStorage.setItem('theme', theme)
  }
}

export const getUserTheme = (userId: string): DeviceSettingThemeType => {
  const deviceSettings = JSON.parse(
    localStorage.getItem(userId) as string
  ) as IDeviceSettings
  return deviceSettings?.device_settings?.theme ?? 'device'
}

export default function DeviceSettings(): JSX.Element {
  const { user } = useAuth()
  const userId = user?.id as string

  const [theme, setTheme] = useState<DeviceSettingThemeType>(
    getUserTheme(userId)
  )

  const handleChange = (event: SelectChangeEvent<DeviceSettingThemeType>) => {
    setTheme(event.target.value as DeviceSettingThemeType)
  }

  useEffect(() => {
    localStorage.setItem(userId, JSON.stringify({ device_settings: { theme } }))
    saveTheme(theme)
  }, [theme, userId])

  /*
  // Removed until we support changing themes
   <InputLabel>Nothing here yet</InputLabel>
   <Select value={theme} onChange={handleChange} fullWidth>
   {themeOptions.map(option => (
   <MenuItem key={option.value} value={option.value}>
   {option.label}
   </MenuItem>
   ))}
   </Select>
  */

  return (
    <DefaultLayout
      backgroundColor='default'
      heading='Device settings'
      showBackIcon
    >
      <Grid container alignItems='center'>
        <Grid item xs={2}></Grid>
        <Grid item xs={10}></Grid>
      </Grid>
    </DefaultLayout>
  )
}
