import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    background: theme.palette.white.main,
    position: 'relative',

    [theme.breakpoints.up('lg')]: {
      margin: '0 auto'
    },

    '& .slick-slider': {
      height: '100%',

      '& .slick-list': {
        height: '100%',

        '& .slick-track': {
          height: '100%'
        }
      }
    },

    '& .slick-slide': {
      '& >div': {
        height: '100%'
      }
    },

    '& #stack-1,#stack-2,#stack-3,#stack-4': {
      width: '275px',
      height: '380px',
      border: '1px solid',
      borderColor: theme.palette.black.light,

      '& > video': {
        height: '380px'
      }
    },

    '& #stack-1': {
      position: 'absolute',
      top: '15px',
      right: '15px',
      background: '#C09300'
    },
    '& #stack-2': {
      position: 'absolute',
      top: '10px',
      right: '10px',
      background: '#B297D4'
    },
    '& #stack-3': {
      position: 'absolute',
      top: '5px',
      right: '5px',
      background: '#EBAE04'
    },
    '& #stack-4': {
      position: 'absolute',
      top: '0px',
      right: '0px'
    }
  },

  progressBar: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,

    display: 'flex',
    gap: theme.spacing(0.25),

    [theme.breakpoints.up('lg')]: {
      maxWidth: '600px',
      margin: '0 auto'
    },

    background: 'transparent',
    zIndex: 2000,
    padding: theme.spacing(0.5),

    '& > p': {
      position: 'absolute',
      top: '100%',
      left: '0.75rem',

      color: theme.palette.primary.main,
      fontSize: '1rem'
    }
  },

  linearProgress: {
    borderRadius: '50px',
    height: theme.spacing(0.25),
    background: theme.palette.disabled.main,

    '& > .MuiLinearProgress-bar': {
      background: theme.palette.primary.main
    }
  },

  displayPart: {
    display: 'flex !important',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',

    height: '100%',

    '&:has(h1)': {
      marginTop: theme.spacing(10)
    },

    '& h1': {
      color: theme.palette.white.main,
      textTransform: 'uppercase',
      fontWeight: 700,
      fontSize: '4rem',
      lineHeight: 1
    },

    '& p': {
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: '1.5rem'
    }
  },

  previous: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '80%',
    width: '30%',

    cursor: 'pointer',
    zIndex: 1,

    '& > p': {
      color: theme.palette.white.main,
      fontSize: '1rem'
    }
  },

  next: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '80%',
    width: '30%',

    cursor: 'pointer',
    zIndex: 1,

    '& > p': {
      color: theme.palette.white.main,
      fontSize: '1rem',
      fontWeight: 700
    }
  },

  image: {
    display: 'block',
    width: '50%',
    maxHeight: '80%',
    margin: '0 auto',
    [theme.breakpoints.only('xs')]: {
      width: '70%'
    }
  },

  shadow: {
    display: 'block',
    width: '65%',
    margin: '0 auto',
    [theme.breakpoints.only('xs')]: {
      width: '85%'
    }
  },

  skip: {
    position: 'absolute',
    bottom: '2%',
    width: '100%',
    textAlign: 'center',

    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '1rem'
  },

  paragraph: {
    textAlign: 'center',
    textTransform: 'none',
    fontSize: '10px',
    fontWeight: 500,
    color: theme.palette.primary.main
  }
}))
