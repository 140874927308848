import { GENERATE_OTP, GET_CURRENT_USER, PRODUCTS } from './queries'

const CURRENT_USER = {
  email: 'test@test.com',
  id: '1',
  otpVerified: false,
  authenticatorAppEnabled: false,
  profile: {
    bankAccountName: 'Main',
    bankAccountNumber: '12-3140-0577999-01',
    firstName: 'Glen',
    identifier: '123-456-789',
    onboarded: true,
    preferredName: 'Glennie',
    reference: '094C265C',
    addressLine1: 'Level 2',
    addressLine2: '1 Ghuznee Street',
    suburb: 'Te Aro',
    city: 'Wellington',
    postcode: '6011',
    lastName: 'Mr',
    partialIdentityVerified: true,
    dateOfBirth: '1978-01-10',
    referralCode: 'GLEN2971',
    driversLicenceNumber: 'DP012345',
    driversLicenceVersion: '391',
    passportNumber: null,
    passportExpiry: null,
    verificationMethod: 'drivers_licence',
    ethnicity: 'NZ European'
  },
  reference: 'TEST123',
  securitiesOwned: [
    {
      product: {
        name: 'Gold',
        id: '1',
        description: '1KG bar of 99.99% pure gold',
        __typename: 'Product'
      },
      securities: [],
      allotmentsOwned: 0,
      hasHistory: true,
      __typename: 'SecuritiesOwned'
    },
    {
      product: {
        name: 'Silver',
        id: '2',
        description: '1KG bar of 99.99% pure silver',
        __typename: 'Product'
      },
      securities: [],
      allotmentsOwned: 0,
      hasHistory: true,
      __typename: 'SecuritiesOwned'
    }
  ],
  wallet: {
    balance: '4973.73',
    userId: '1',
    walletBalance: '4973.73',
    expirableWalletCreditBalance: '0.0'
  },
  vaults: [],
  activatedVault: null,
  firstTimeTopUpWithPoli: false,
  autoInvests: {
    amount: '100.0',
    duration: 'weekly',
    nextAutoinvestDate: '2024-02-10',
    id: 1,
    product: {
      name: 'Gold'
    },
    investBy: 'dollar'
  }
}

export const initialUserMock = {
  request: {
    query: GET_CURRENT_USER
  },
  result: {
    data: {
      currentUser: CURRENT_USER
    }
  }
}

export const currentUserMock = {
  request: {
    query: GET_CURRENT_USER
  },
  result: {
    data: {
      currentUser: CURRENT_USER
    }
  }
}

export const otpVerifiedUserMock = {
  request: {
    query: GET_CURRENT_USER
  },
  result: {
    data: {
      currentUser: { ...CURRENT_USER, otpVerified: true }
    }
  }
}

export const authAppEnabledUserMock = {
  request: {
    query: GET_CURRENT_USER
  },
  result: {
    data: {
      currentUser: { ...CURRENT_USER, authenticatorAppEnabled: true }
    }
  }
}

export const authAppAndOtpVerifiedUserMock = {
  request: {
    query: GET_CURRENT_USER
  },
  result: {
    data: {
      currentUser: {
        ...CURRENT_USER,
        authenticatorAppEnabled: true,
        otpVerified: true
      }
    }
  }
}

export const generateOtpMock = {
  request: {
    query: GENERATE_OTP
  },
  result: {
    data: {
      generateOtp: true
    }
  }
}

export const productsMock = {
  request: {
    query: PRODUCTS
  },
  result: {
    data: {
      products: [
        {
          id: '1',
          name: 'Gold',
          description: '1KG bar of 99.99% pure gold',
          sku: 'GOLD001'
        },
        {
          id: '2',
          name: 'Silver',
          description: '1KG bar of 99.9% pure silver',
          sku: 'SILV001'
        }
      ]
    }
  }
}
