/* eslint-disable no-console */
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material'
import Drawer from '@mui/material/Drawer'
import Modal from '@mui/material/Modal'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import GraphPeriod from 'components/GraphPeriod'
import PerformanceGraph, { GraphPeriodType } from 'components/PerformanceGraph'
import { tradePath, verifyPath } from 'components/Routes'
import Add from 'components/icons/Add'
import DefaultLayout from 'components/layout/DefaultLayout'
import {
  // Product,
  ProductPerformance,
  useGetLatestSpotPriceQuery,
  useProductPerformanceQuery,
  useProductPerformanceSummariesQuery
} from 'generated/graphql'
import useProducts, { ProductInclLocal } from 'hooks/useProducts'
import CC_POP_VIDEO from 'images/cc_pop_video.mov'
import queryString from 'query-string'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import theme from 'utils/theme'
import { formatDateToString } from 'utils/util'
import MyVaultProduct from './MyVaultProduct'
import TableBase from './MyVaultProduct/TableBase'
import { TableBaseProps } from './MyVaultProduct/TableBase/models'
import ReviewSell from './ReviewSell'
import useStyles from './styles'
import CountUpPrice from 'components/CountUpPrice'
import TradeSubNavMenu from './TradeSubNavMenu'

interface VaultProductProps {
  productInclLocal: ProductInclLocal
  productPerformance: Partial<ProductPerformance>
  notVerified?: boolean
}

interface NoVaultProductProps {
  disabledTrade?: boolean
  tradePath?: string
  displayName: string
  reverseDirection?: boolean
  notVerified?: boolean
  productInclLocal: ProductInclLocal
}

type TableDataType = {
  asset?: string
  currentValue?: number
  returnPercentage?: number
  returnDollars?: number
}

function VaultProduct({
  productInclLocal,
  productPerformance,
  notVerified
}: Readonly<VaultProductProps>) {
  const styles = useStyles()
  const history = useHistory()
  const { type } = queryString.parse(history.location.search)

  const [openPopup, setOpenPopup] = useState(false)

  const [activatedProduct, setActivatedProduct] = useState<{
    open: boolean
    product?: ProductInclLocal
  }>({ open: false, product: undefined })

  const [tradeSubNavMenuForProduct, setTradeSubNavMenuForProduct] = useState<
    ProductInclLocal | undefined | null
  >(null)

  const [openSellModal, setOpenSellModal] = useState<boolean>(false)

  useEffect(() => {
    if (type === 'sell') {
      setActivatedProduct({ open: false, product: undefined })
      setOpenSellModal(true)
    } else {
      setOpenSellModal(false)
    }
  }, [type])

  const { data } = useProductPerformanceQuery({
    variables: { productId: Number(productInclLocal.id) },
    skip: !productInclLocal.id
  })

  const renderPercentageReturn = (percentageReturn: number) => {
    if (!percentageReturn) {
      return (
        <Typography variant='body2' color='primary'>
          -
        </Typography>
      )
    }

    if (percentageReturn >= 0) {
      return (
        <Typography
          variant='body2'
          component='div'
          display='flex'
          justifyContent='center'
          alignItems='center'
          color='primary'
        >
          <KeyboardArrowUpIcon sx={{ fontSize: '11px' }} />
          <Typography color='primary' variant='body2'>
            {Number(percentageReturn).toFixed(2)}%
          </Typography>
        </Typography>
      )
    } else if (percentageReturn < 0) {
      return (
        <Typography
          variant='body2'
          component='div'
          display='flex'
          justifyContent='center'
          alignItems='center'
          color='primary'
        >
          <KeyboardArrowDownIcon sx={{ fontSize: '11px' }} />
          <Typography color='primary' variant='body2'>
            {Number(Math.abs(percentageReturn)).toFixed(2)}%
          </Typography>
        </Typography>
      )
    } else {
      return null
    }
  }

  const handleClickPlayIcon = () => {
    setOpenPopup(true)
  }

  const handleClosePopup = () => {
    setOpenPopup(false)
  }

  const openInsightProduct = React.useCallback(
    (productInclLocal: ProductInclLocal) => {
      window.scrollTo(0, 0)
      setActivatedProduct({ open: true, product: productInclLocal })
    },
    []
  )

  const closeInsightProduct = React.useCallback(() => {
    setActivatedProduct({ open: false, product: undefined })
  }, [])

  const buttonForTradeSubNav = () => {
    if (notVerified) {
      return (
        <Button
          variant='outlined'
          sx={{
            height: '2rem',
            padding: 1,
            borderRadius: '1.5rem',
            '& p, span': {
              color: 'primary.main'
            },
            '&:hover': {
              borderColor: 'black !important',
              backgroundColor: 'black !important',
              '& p, span': {
                color: 'white !important'
              }
            }
          }}
          onClick={() => {
            history.push(verifyPath())
          }}
        >
          <Typography variant='body2'>GET VERIFIED</Typography>
        </Button>
      )
    } else {
      return (
        <Button
          variant='outlined'
          sx={{
            height: '2rem',
            padding: 1,
            borderRadius: '1.5rem',
            '& p, span': {
              color: 'primary.main'
            },
            '&:hover': {
              borderColor: 'black !important',
              backgroundColor: 'black !important',
              '& p, span': {
                color: 'white !important'
              }
            }
          }}
          onClick={() => setTradeSubNavMenuForProduct(productInclLocal)}
        >
          <Typography variant='body2'>TRADE</Typography>
        </Button>
      )
    }
  }

  return (
    <>
      <Grid
        data-testid='dashboard-container'
        container
        className={styles.aboveTheFoldContainer}
        direction={productInclLocal.name === 'Silver' ? 'row-reverse' : 'row'}
      >
        <Box
          flex={4}
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
        >
          <img
            src={productInclLocal.image}
            alt={productInclLocal.name}
            className={styles.productImage}
          />
        </Box>
        <Box
          flex={6}
          display='flex'
          flexDirection={
            productInclLocal.name === 'Silver' ? 'row-reverse' : 'row'
          }
          alignItems='center'
        >
          <Box mt={1}>
            <Add />
          </Box>
          <Box>
            <Box
              display='flex'
              alignItems='center'
              flexDirection={
                productInclLocal.name === 'Silver' ? 'row-reverse' : 'row'
              }
              gap={0.5}
            >
              <Box
                textTransform='uppercase'
                whiteSpace='nowrap'
                display='flex'
                textAlign={
                  productInclLocal.name === 'Silver' ? 'right' : 'left'
                }
              >
                <Typography>{productInclLocal.name} BULLION</Typography>
                {renderPercentageReturn(
                  productPerformance.totalReturnAsPercentage
                )}
              </Box>
            </Box>

            <Box
              display='flex'
              gap={1}
              justifyContent={
                productInclLocal.name === 'Silver' ? 'flex-end' : 'flex-start'
              }
            >
              <Typography variant='body2' whiteSpace='nowrap'>
                Own:{' '}
                <Typography component='span' variant='body2' color='primary'>
                  {data?.productPerformance.ownedAllotmentsCount
                    ? data?.productPerformance.ownedAllotmentsCount / 10
                    : 0}
                  g
                </Typography>
              </Typography>
              <Typography variant='body2' whiteSpace='nowrap'>
                Value:{' '}
                <Typography component='span' variant='body2' color='primary'>
                  $
                  {Number(data?.productPerformance.currentValue ?? 0).toFixed(
                    2
                  )}
                </Typography>
              </Typography>
            </Box>
            <Box display='flex' gap={1} mt={0.5} alignItems='center'>
              {buttonForTradeSubNav()}
              <Button
                sx={{ height: '2rem', padding: 1, borderRadius: '1.5rem' }}
                onClick={() => openInsightProduct(productInclLocal)}
              >
                <Typography variant='body2' sx={{ color: 'white !important' }}>
                  INSIGHT
                </Typography>
              </Button>
              <OndemandVideoIcon
                sx={{
                  '&:hover': { color: 'primary.main', cursor: 'pointer' }
                }}
                onClick={handleClickPlayIcon}
              />
            </Box>
          </Box>
        </Box>
      </Grid>

      <Modal keepMounted open={openPopup} onClose={handleClosePopup}>
        <Box className={styles.videoModalWrapper}>
          <Box position='relative'>
            <IconButton
              aria-label='Close drawer'
              edge='start'
              onClick={handleClosePopup}
              sx={{ position: 'absolute', top: 0, right: 0, zIndex: 9 }}
            >
              <CloseIcon fontSize='large' color='white' />
            </IconButton>
            <video
              autoPlay
              loop
              muted
              playsInline
              width='100%'
              style={{ opacity: openPopup ? 1 : 0 }}
            >
              <source src={CC_POP_VIDEO} type='video/mp4' />
            </video>
          </Box>
        </Box>
      </Modal>

      {(activatedProduct.open || openSellModal) && (
        <Drawer
          anchor='bottom'
          open={activatedProduct.open || openSellModal}
          onClose={closeInsightProduct}
          className={styles.drawer}
          ModalProps={{
            container: document.getElementById('content'),
            style: { position: 'absolute', inset: 0 }
          }}
          variant='persistent'
        >
          {openSellModal ? (
            <ReviewSell />
          ) : (
            <MyVaultProduct
              product={activatedProduct?.product}
              onClose={closeInsightProduct}
            />
          )}
        </Drawer>
      )}

      {tradeSubNavMenuForProduct && (
        <Drawer
          anchor='bottom'
          open={!!tradeSubNavMenuForProduct}
          onClose={() => setTradeSubNavMenuForProduct(null)}
          className={styles.drawer}
          ModalProps={{
            container: document.getElementById('content'),
            style: { position: 'absolute', inset: 0 }
          }}
          variant='persistent'
        >
          <TradeSubNavMenu
            product={productInclLocal}
            onClose={() => setTradeSubNavMenuForProduct(null)}
          />
        </Drawer>
      )}
    </>
  )
}

function NoVaultProduct({
  displayName,
  disabledTrade,
  tradePath,
  reverseDirection,
  notVerified,
  productInclLocal
}: Readonly<NoVaultProductProps>): JSX.Element {
  const styles = useStyles()
  const history = useHistory()

  const buttonForBuy = () => {
    if (disabledTrade) {
      return (
        <Button
          variant='outlined'
          sx={{ height: '2rem', padding: 1, borderRadius: '1.5rem' }}
          disabled
        >
          <Typography variant='body2' color='primary'>
            COMING SOON
          </Typography>
        </Button>
      )
    } else if (notVerified) {
      return (
        <Button
          variant='outlined'
          sx={{ height: '2rem', padding: 1, borderRadius: '1.5rem' }}
          onClick={() => {
            history.push(verifyPath())
          }}
        >
          <Typography variant='body2' color='primary'>
            VERIFY & BUY
          </Typography>
        </Button>
      )
    } else {
      return (
        <Button
          variant='outlined'
          sx={{ height: '2rem', padding: 1, borderRadius: '1.5rem' }}
          onClick={() => {
            if (tradePath) {
              history.push(tradePath)
            }
          }}
        >
          <Typography variant='body2' color='primary'>
            BUY
          </Typography>
        </Button>
      )
    }
  }

  return (
    <Grid
      data-testid='dashboard-container'
      container
      className={styles.aboveTheFoldContainer}
      direction={reverseDirection ? 'row-reverse' : 'row'}
    >
      <Box
        flex={4}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <img
          src={productInclLocal.image}
          alt={productInclLocal.name}
          className={styles.productImageNoVaultProduct}
        />
      </Box>
      <Box
        flex={6}
        display='flex'
        flexDirection={reverseDirection ? 'row-reverse' : 'row'}
        alignItems='center'
      >
        <Box mt={1}>
          <Add />
        </Box>
        <Box>
          <Box
            display='flex'
            alignItems='center'
            flexDirection={reverseDirection ? 'row-reverse' : 'row'}
            gap={0.5}
          >
            <Typography
              textTransform='uppercase'
              whiteSpace='nowrap'
              textAlign={reverseDirection ? 'right' : 'left'}
            >
              {displayName}
            </Typography>
          </Box>

          <Box
            display='flex'
            gap={1}
            justifyContent={reverseDirection ? 'flex-end' : 'flex-start'}
          >
            <Typography variant='body2'>
              Own:{' '}
              <Typography component='span' variant='body1' color='primary'>
                0.0g
              </Typography>
            </Typography>
            <Typography variant='body2'>
              Value:{' '}
              <Typography component='span' variant='body1' color='primary'>
                $0.00
              </Typography>
            </Typography>
          </Box>
          <Box display='flex' gap={1} mt={0.5}>
            {buttonForBuy()}
            <OndemandVideoIcon />
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}

export default function MyVault(): JSX.Element {
  const styles = useStyles()

  const [isOpenTable, setIsOpenTable] = useState<boolean>(false)
  const [graphPeriod, setGraphPeriod] = useState<GraphPeriodType>('1w')
  const [headerHeight, setHeaderHeight] = useState<number>(0)

  const isDesktopView = useMediaQuery(theme.breakpoints.up('md'))

  const { products } = useProducts()
  const { user } = useAuth()

  const notVerified =
    user && !user?.profile?.onboarded && !user?.profile?.partialIdentityVerified

  React.useEffect(() => {
    const header = document.getElementById('app-header')
    if (header) {
      setHeaderHeight(header.offsetHeight)
    }
  }, [])

  const { data: performanceData, loading } =
    useProductPerformanceSummariesQuery()

  let collectionValue = 0
  let totalCost = 0

  const emptyStatePerformanceTableData: Array<TableDataType> = [
    {
      asset: 'Gold',
      currentValue: Number('0'),
      returnPercentage: Number('0'),
      returnDollars: Number('0')
    }
  ]

  const { data: latestSpotPrice } = useGetLatestSpotPriceQuery({
    variables: { productId: Number(1) }
  })

  const performanceTableData =
    performanceData?.productPerformanceSummaries?.map(item => {
      const currentValue = Number(item?.currentValue).toFixed(2)
      const returnPercentage = Number(item?.totalReturnAsPercentage).toFixed(1)
      const returnDollars = Number(Number(item?.totalReturn).toFixed(2))
      collectionValue += Number(item?.currentValue) || 0
      totalCost += Number(item?.totalCost) || 0

      return {
        asset: item?.product?.name,
        currentValue,
        returnPercentage,
        returnDollars
      }
    })

  const updatedTime = useMemo(() => {
    const { spotTime } = latestSpotPrice?.latestSpotPrice ?? {}
    return formatDateToString(new Date(spotTime as Date))
  }, [latestSpotPrice])

  const totalReturn = collectionValue - totalCost
  const totalReturnPercentage =
    totalCost > 0 ? (totalReturn / totalCost) * 100 : 0

  const tableRows = React.useRef<TableBaseProps<TableDataType>['rows']>([
    {
      id: 'asset',
      label: 'ASSET',
      render: data => data.asset || ''
    },
    {
      id: 'currentValue',
      label: 'CURRENT VALUE',
      render: data => {
        const currentValue = Number(data.currentValue)

        if (currentValue != null) {
          if (currentValue === 0) {
            return (
              <Grid container flexDirection='row' justifyContent='flex-end'>
                <Typography>${currentValue}</Typography>
              </Grid>
            )
          } else if (currentValue >= 0) {
            return (
              <Grid container flexDirection='row' justifyContent='flex-end'>
                <Typography>+${currentValue}</Typography>
              </Grid>
            )
          } else {
            return (
              <Grid container flexDirection='row' justifyContent='flex-end'>
                <Typography>-${Math.abs(currentValue)}</Typography>
              </Grid>
            )
          }
        } else {
          return ''
        }
      }
    },
    {
      id: 'returnPercentage',
      label: '%',
      render: data => {
        const returnPercentage = Number(data.returnPercentage)
        if (returnPercentage != null) {
          if (returnPercentage >= 0) {
            return (
              <Grid container flexDirection='row' justifyContent='flex-end'>
                <KeyboardArrowUpIcon fontSize='small' color='primary' />
                <Typography>{returnPercentage}%</Typography>
              </Grid>
            )
          } else {
            return (
              <Grid container flexDirection='row' justifyContent='flex-end'>
                <KeyboardArrowDownIcon fontSize='small' color='primary' />
                <Typography>{Math.abs(returnPercentage)}%</Typography>
              </Grid>
            )
          }
        } else {
          return ''
        }
      }
    },
    {
      id: 'returnDollars',
      label: 'RETURN $',
      render: data => {
        const returnDollars = Number(data.returnDollars)

        if (returnDollars != null) {
          if (returnDollars === 0) {
            return (
              <Grid container flexDirection='row' justifyContent='flex-end'>
                <Typography>${returnDollars}</Typography>
              </Grid>
            )
          } else if (returnDollars >= 0) {
            return (
              <Grid container flexDirection='row' justifyContent='flex-end'>
                <Typography>+${returnDollars}</Typography>
              </Grid>
            )
          } else {
            return (
              <Grid container flexDirection='row' justifyContent='flex-end'>
                <Typography>-${Math.abs(returnDollars)}</Typography>
              </Grid>
            )
          }
        } else {
          return ''
        }
      }
    }
  ]).current

  const noHistoryProducts = useMemo(() => {
    return products.filter(product => {
      return !performanceData?.productPerformanceSummaries.find(
        item => item.product.id === product.id
      )
    })
  }, [performanceData?.productPerformanceSummaries, products])

  return (
    <DefaultLayout
      wrapperContent={{ mt: 0, mb: 2 }}
      wrapperContainer={{ p: 0 }}
      loading={loading}
      pageTitle='My Vault'
    >
      <Grid container direction='column' minHeight='100vh'>
        <Accordion
          expanded={isOpenTable}
          className={styles.accordionRoot}
          sx={{ top: isDesktopView ? 0 : headerHeight }}
        >
          <AccordionSummary className={styles.accordionSummary}>
            <Grid item container direction='column'>
              <Grid
                item
                container
                justifyContent='space-between'
                alignItems='center'
                direction='row'
              >
                <Box>
                  <Typography>COLLECTION VALUE</Typography>
                  <CountUpPrice value={collectionValue} prefix='$' />
                </Box>
                <Box>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='flex-end'
                  >
                    {totalReturn > 0 && totalCost > 0 ? (
                      <KeyboardArrowUpIcon color='primary' />
                    ) : (
                      <KeyboardArrowDownIcon color='primary' />
                    )}
                    <CountUpPrice
                      value={totalReturnPercentage}
                      suffix='%'
                      color='primary'
                    />
                  </Stack>
                  <CountUpPrice
                    value={Math.abs(totalReturn)}
                    prefix={totalReturn >= 0 ? '+$' : '-$'}
                    color='primary'
                  />
                </Box>
              </Grid>
              {!isOpenTable && (
                <AccordionActions>
                  <Button
                    variant='text'
                    onClick={() => {
                      setIsOpenTable(true)
                    }}
                    sx={{ height: '1rem' }}
                  >
                    <KeyboardArrowDownIcon
                      color='primary'
                      className={styles.arrowIcon}
                    />
                  </Button>
                </AccordionActions>
              )}
            </Grid>
          </AccordionSummary>
          {isOpenTable && (
            <AccordionDetails className={styles.accordionDetails}>
              <TableBase
                className={styles.tableWrapper}
                rows={tableRows}
                data={
                  performanceTableData?.length === 0
                    ? emptyStatePerformanceTableData
                    : performanceTableData
                }
              />
              <Box height='200px' mt={0} mx='-1rem' position='relative'>
                <PerformanceGraph graphPeriod={graphPeriod} myVaultGraph />
              </Box>
              <Box>
                <GraphPeriod
                  graphPeriod={graphPeriod}
                  setGraphPeriod={setGraphPeriod}
                />
              </Box>
              <Typography
                textAlign='right'
                fontSize='0.75rem'
                textTransform='uppercase'
                px={1}
              >
                UPDATED {updatedTime.formattedHours}:
                {updatedTime.formattedMinutes} {updatedTime.meridiem}{' '}
                {updatedTime.formattedDate}
              </Typography>
              <AccordionActions>
                <Button
                  variant='text'
                  onClick={() => {
                    setIsOpenTable(false)
                  }}
                  sx={{ height: '1rem' }}
                >
                  <KeyboardArrowUpIcon
                    color='primary'
                    className={styles.arrowIcon}
                  />
                </Button>
              </AccordionActions>
            </AccordionDetails>
          )}
        </Accordion>

        {performanceData && performanceData?.productPerformanceSummaries && (
          <>
            {performanceData.productPerformanceSummaries.map(performance => {
              if (performance.product && performance.product.id) {
                const productInclLocal = products.find(
                  p => p.id === performance.product.id
                )
                return productInclLocal ? (
                  <VaultProduct
                    key={`VaultProduct-${performance.product.id}`}
                    productInclLocal={productInclLocal}
                    productPerformance={performance}
                    notVerified={notVerified}
                  />
                ) : null
              } else {
                return null
              }
            })}
          </>
        )}
        {noHistoryProducts.map((product, index) => {
          return (
            <NoVaultProduct
              key={`NoVaultProduct${product.id}`}
              tradePath={tradePath({
                routeParams: {
                  productId: product.id
                }
              })}
              disabledTrade={product.disable}
              displayName={`${product.name} BULLION`}
              reverseDirection={index % 2 === 0}
              notVerified={notVerified}
              productInclLocal={product}
            />
          )
        })}
      </Grid>
    </DefaultLayout>
  )
}
