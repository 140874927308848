import * as paymentsApi from 'api/payments'
import { PaymentsParams, PaymentsResponse } from 'api/payments/types'
import { createContext, useContext, useMemo, useState } from 'react'

interface PaymentsContextType {
  payment: PaymentsResponse | undefined
  createPayment: (
    params: PaymentsParams,
    recaptchaToken: string | undefined,
    callback?: (PaymentsResponse: PaymentsResponse) => void
  ) => void
  error: string
  loading: boolean
}

const PaymentsContext = createContext<PaymentsContextType>(
  {} as PaymentsContextType
)

export function PaymentsProvider({
  children
}: {
  children: JSX.Element
}): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')
  const [payment, setPayment] = useState<PaymentsResponse>()

  function createPayment(
    params: PaymentsParams,
    recaptchaToken: string | undefined,
    callback?: (PaymentsResponse: PaymentsResponse) => void
  ) {
    setLoading(true)
    paymentsApi
      .payments(params, recaptchaToken)
      .then(res => {
        setPayment(res)
        callback && callback(res)
      })
      .catch(error => {
        setError(error?.response?.data?.errors[0] ?? '')
      })
      .finally(() => setLoading(false))
  }

  const memoisedValue = useMemo(
    () => ({
      payment,
      error,
      loading,
      createPayment
    }),
    [payment, error, loading]
  )

  return (
    <PaymentsContext.Provider value={memoisedValue}>
      {children}
    </PaymentsContext.Provider>
  )
}

export const usePayments = (): PaymentsContextType =>
  useContext(PaymentsContext)
