import dayjs, { Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(advancedFormat)

const defaultLocale = 'en-nz'
const defaultFormat = 'DD/MM/YYYY'

type date = string | Date | Dayjs

export interface DateTimeProps {
  date: date
  format?: string
}

function getISODate({ date }: { date: date }): string {
  // returns 2017-09-02T00:00:00.000Z
  return dayjs(date).toISOString()
}

function getHumanDate({ date, format }: DateTimeProps): string {
  // returns 02/09/2017
  dayjs.locale(defaultLocale)
  return dayjs(date).format(format)
}

export function dateTimeAsString({
  date,
  format = defaultFormat
}: DateTimeProps): string {
  return getHumanDate({ date, format })
}

export default function DateTime({
  date,
  format = defaultFormat
}: DateTimeProps): JSX.Element | null {
  return date ? (
    <time dateTime={getISODate({ date })}>
      {getHumanDate({ date, format })}
    </time>
  ) : null
}
