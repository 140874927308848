import { Grid, Link, Typography } from '@mui/material'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import { settingsPath } from 'components/Routes'
import FormField from 'components/formik/FormField'
import DefaultLayout from 'components/layout/DefaultLayout'
import { Form, Formik } from 'formik'
import {
  Maybe,
  useProfileForCurrentUserQuery,
  useUpdateProfileMutation
} from 'generated/graphql'
import { useCallback, useMemo, useState } from 'react'
import { Address, Country, WidgetInput } from 'react-addressfinder'
import { useHistory } from 'react-router-dom'
import {
  API_DATE_FORMAT,
  UI_DATE_FORMAT,
  formatAddress,
  formatDate
} from 'utils/util'
import * as Yup from 'yup'
import { formFieldData } from './formFieldData'
import useStyles from './styles'
export interface FormValues {
  address: string
  addressLine1: string
  addressLine2: string
  city: string
  suburb: string
  postcode: string
  dateOfBirth: string
  email: string
  legalName: string
  nzMobilePhoneNumber: string
  preferredName: string
  enterManually: boolean
}

interface IAddress {
  addressLine1?: Maybe<string>
  addressLine2?: Maybe<string>
  suburb?: Maybe<string>
  city?: Maybe<string>
  postcode?: Maybe<string>
}

const validationSchema = Yup.object().shape({
  legalName: Yup.string().required('Legal name is required'),
  address: Yup.string().when('enterManually', {
    is: (enterManually: boolean) => !enterManually,
    then: Yup.string().required('Address is required')
  }),
  addressLine1: Yup.string().when('enterManually', {
    is: (enterManually: boolean) => enterManually,
    then: Yup.string().required('AddressLine1 is required')
  }),
  city: Yup.string().when('enterManually', {
    is: (enterManually: boolean) => enterManually,
    then: Yup.string().required('City is required')
  }),
  postcode: Yup.string().when('enterManually', {
    is: (enterManually: boolean) => enterManually,
    then: Yup.string().required('Postcode is required')
  }),
  nzMobilePhoneNumber: Yup.string().required('Mobile phone number is required')
})

export default function PersonalDetails(): JSX.Element {
  const classes = useStyles()

  const { setToast } = useAuth()

  const [enterManually, setEnterManually] = useState<boolean>(false)

  const history = useHistory()

  const { data, loading } = useProfileForCurrentUserQuery({
    onError: error =>
      setToast({
        open: true,
        message: error.message,
        type: 'error',
        duration: 3000
      })
  })

  const [updateProfile, { loading: updateProfileLoading }] =
    useUpdateProfileMutation({
      onCompleted: () => {
        setToast({
          open: true,
          message: 'Update Personal Details Successfully',
          type: 'success',
          duration: 1500
        })
        // after 1.5s update profile successfully, will redirect to "profile settings" page
        setTimeout(() => {
          history.push(settingsPath())
        }, 1500)
      },
      onError: error =>
        setToast({ open: true, message: error.message, type: 'error' })
    })

  const { profile } = data || {}

  function generateAddress(address: IAddress) {
    const { addressLine1, addressLine2, suburb, city, postcode } = address
    return [addressLine1, addressLine2, suburb, city, postcode]
      .filter(a => !!a)
      .join(', ')
  }

  const initialValues: FormValues = useMemo(
    () => {
      // TODO: should this be `let values: FormValues = {}`, and if so, should all the fields be optional||?
      // Or should each field be instantiated with empty values below?
      let values: FormValues = {
        address: '',
        addressLine1: profile?.addressLine1 || '',
        addressLine2: profile?.addressLine2 || '',
        suburb: profile?.suburb || '',
        city: profile?.city || '',
        postcode: profile?.postcode || '',
        dateOfBirth: profile?.dateOfBirth || '',
        email: profile?.email || '',
        legalName: '',
        nzMobilePhoneNumber: profile?.nzMobilePhoneNumber || '',
        preferredName: profile?.preferredName || '',
        enterManually: false
      }

      if (profile) {
        const {
          addressLine1,
          addressLine2,
          city,
          dateOfBirth,
          firstName,
          lastName,
          middleName,
          postcode,
          suburb
        } = profile

        const legalName = [firstName, middleName, lastName]
          .filter(n => !!n)
          .join(' ')

        const address = generateAddress({
          addressLine1,
          addressLine2,
          city,
          postcode,
          suburb
        })

        values = {
          ...values,
          address: address || '',
          dateOfBirth: dateOfBirth
            ? formatDate({
                date: dateOfBirth,
                inputFormat: API_DATE_FORMAT,
                outputFormat: UI_DATE_FORMAT
              })
            : '',
          legalName: legalName || ''
        } as FormValues
      }

      return values
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profile]
  )

  function formattedAddressValue(values: FormValues): string {
    const address = {
      city: values.city,
      country: Country.NZ,
      line1: values.addressLine1,
      line2: values.addressLine2,
      postcode: values.postcode,
      suburb: values.suburb
    }

    return address.line1 && address.city && address.postcode
      ? formatAddress(address)
      : ''
  }

  function handleSelectAddress(
    _fullAddress: string,
    address: Address,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) {
    const { city, line1, line2, postcode, suburb } = address

    setFieldValue('addressLine1', line1)
    setFieldValue('addressLine2', line2)
    setFieldValue('suburb', suburb)
    setFieldValue('city', city)
    setFieldValue('postcode', postcode)
    setFieldValue(
      'address',
      [line1, line2, suburb, city, postcode].filter(a => !!a).join(', ')
    )
  }

  const changeChangeAddress = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setFieldValue('addressLine1', '')
    setFieldValue('addressLine2', '')
    setFieldValue('suburb', '')
    setFieldValue('city', '')
    setFieldValue('postcode', '')
    setFieldValue('address', '')
  }

  const onToggleEnterManually = (
    setFieldValue: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean
    ) => void,
    values: FormValues
  ) => {
    setEnterManually(!enterManually)
    setFieldValue('enterManually', !enterManually)

    if (!enterManually) {
      const address = generateAddress(values)
      setFieldValue('address', address)
    }
  }

  const handleSubmit = (values: FormValues) => {
    updateProfile({
      variables: {
        input: {
          preferredName: values.preferredName,
          nzMobilePhoneNumber: values.nzMobilePhoneNumber,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2 ?? '',
          city: values.city,
          suburb: values.suburb ?? '',
          postcode: values.postcode
        }
      }
    })
  }

  const renderHeader = useCallback(() => {
    return (
      <Typography component='h1' variant='h1' fontSize='4.5rem' color='primary'>
        Personal details
      </Typography>
    )
  }, [])

  return (
    <DefaultLayout
      heading={renderHeader()}
      wrapperContainer={{ mb: 4 }}
      showBackIcon
      loading={loading}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormField
                  {...formFieldData.legalName}
                  error={!!(errors.legalName && touched.legalName)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField {...formFieldData.preferredName} />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  {...formFieldData.email}
                  error={!!(errors.email && touched.email)}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker<FormValues>
                  name='dateOfBirth'
                  label='Date of birth'
                  inputFormat={UI_DATE_FORMAT}
                  outputFormat={UI_DATE_FORMAT}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  {...formFieldData.nzMobilePhoneNumber}
                  error={
                    !!(
                      errors.nzMobilePhoneNumber && touched.nzMobilePhoneNumber
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} container spacing={2}>
                {enterManually ? (
                  <>
                    <Grid item xs={12}>
                      <Link
                        color='textPrimary'
                        component='button'
                        variant='body1'
                        onClick={() =>
                          onToggleEnterManually(setFieldValue, values)
                        }
                        type='button'
                      >
                        Use address autocompletion
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <FormField
                        {...formFieldData.addressLine1}
                        error={!!(errors.addressLine1 && touched.addressLine1)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormField {...formFieldData.addressLine2} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormField {...formFieldData.suburb} />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <FormField
                        {...formFieldData.city}
                        error={!!(errors.city && touched.city)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormField
                        {...formFieldData.postcode}
                        error={!!(errors.postcode && touched.postcode)}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <WidgetInput
                        addressFinderKey={
                          process.env.REACT_APP_ADDRESSFINDER_KEY as string
                        }
                        country={Country.NZ}
                        id='addressFinderWidget'
                        inputClassName={
                          errors.address
                            ? classes.addressFinderInputError
                            : classes.addressFinderInput
                        }
                        name='addressFinderWidget'
                        onSelected={(fullAddress, address) =>
                          handleSelectAddress(
                            fullAddress,
                            address,
                            setFieldValue
                          )
                        }
                        onChange={() => changeChangeAddress(setFieldValue)}
                        placeholder='Start typing your address...'
                        defaultValue={formattedAddressValue(values)}
                      />
                      {errors.address && (
                        <Typography className={classes.error}>
                          {errors.address}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Link
                        color='textPrimary'
                        component='button'
                        onClick={() =>
                          onToggleEnterManually(setFieldValue, values)
                        }
                        type='button'
                      >
                        Not working? Enter manually
                      </Link>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mt: 4, textAlign: 'center' }}>
                <Button
                  color='primary'
                  type='submit'
                  fullWidth
                  disabled={updateProfileLoading}
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>
                  If you need to change any other details, please get in touch
                  with us at{' '}
                  <Link
                    color='textPrimary'
                    href='mailto:support@getgoldie.co.nz'
                  >
                    support@getgoldie.co.nz
                  </Link>
                  .
                </Typography>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </DefaultLayout>
  )
}
