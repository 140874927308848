import { Box, Breakpoint } from '@mui/material'
import { CONTAINER_WIDTH } from 'constants/index'
import React from 'react'
import { BackgroundColorTypes, ColorTypes } from 'types'
import Copyright from '../Copyright/Copyright'
import Footer from '../Footer/Footer'
import Menu from './Menu'
import useStyles from './styles'

export interface Props {
  color?: ColorTypes
  backgroundColor?: BackgroundColorTypes
  children: React.ReactNode
  showFooter?: boolean
  center?: boolean
  showBackIcon?: boolean
  stickyFooter?: JSX.Element
  noLogo?: boolean
  headerTitle?: string
  showKiaOra?: boolean
  showSecondNav?: boolean
  containerMaxWidth?: Breakpoint
  showMenu?: boolean
  pageTitle?: string
}

export default function BaseLayout({
  color = 'ironSand',
  backgroundColor = 'default',
  children,
  showFooter = false,
  center = false,
  stickyFooter,
  showSecondNav = true,
  containerMaxWidth = CONTAINER_WIDTH,
  pageTitle
}: Readonly<Props>): JSX.Element {
  const classes = useStyles({ color, backgroundColor })

  return (
    <Menu
      pageTitle={pageTitle}
      containerMaxWidth={containerMaxWidth}
      showSecondNav={showSecondNav}
    >
      <>
        <Box className={classes.container}>
          <Box
            component='section'
            className={classes.pageBackground}
            id='content'
            data-testid='content'
          >
            <Box className={center ? classes.growCenter : classes.grow}>
              {children}
            </Box>
            {showFooter && (
              <Box maxWidth='lg' className={classes.noGrow}>
                <Footer />
                <Box mt={2} alignItems='center' justifyContent='center'>
                  <Copyright />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {stickyFooter && (
          <Box className={classes.stickyFooter} component='footer'>
            {stickyFooter}
          </Box>
        )}
      </>
    </Menu>
  )
}
