import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
  fancyButton: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '16px',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(2.5, 3),

    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1rem',

    '& > div': {
      width: '1.5rem',
      height: '1.5rem',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    '& > h4': {
      lineHeight: '150%',
      fontWeight: '400'
    },

    '&:hover': {
      background: theme.palette.white.main,
      color: theme.palette.primary.main
    }
  }
}))
