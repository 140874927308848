import { Link, Stack, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Link as RouterLink } from 'react-router-dom'

import {
  catalistTermsAndConditionsPath,
  privacyPolicyPath,
  InformationMemorandumPath,
  termsAndConditionsPath
} from 'components/Routes'

const useStyles = makeStyles((theme: Theme) => ({
  stack: {
    position: 'relative',
    zIndex: 1
  },
  link: {
    // @ts-ignore
    color: theme.palette.gold.main,
    flex: 1,
    whiteSpace: 'nowrap'
  }
}))

export default function Footer(): JSX.Element {
  const classes = useStyles()

  return (
    <Stack className={classes.stack}>
      <Link
        color='textPrimary'
        component={RouterLink}
        textAlign='left'
        to={termsAndConditionsPath()}
        underline='hover'
        variant='body1'
        className={classes.link}
      >
        Terms and Conditions
      </Link>
      <Link
        color='textPrimary'
        component={RouterLink}
        textAlign='left'
        to={catalistTermsAndConditionsPath()}
        underline='hover'
        variant='body1'
        className={classes.link}
      >
        Catalist Terms and Conditions
      </Link>
      <Link
        color='textPrimary'
        component={RouterLink}
        textAlign='left'
        to={privacyPolicyPath()}
        underline='hover'
        variant='body1'
        className={classes.link}
      >
        Privacy Policy
      </Link>
      <Link
        color='textPrimary'
        component={RouterLink}
        textAlign='left'
        to={InformationMemorandumPath()}
        underline='hover'
        variant='body1'
        className={classes.link}
      >
        Information Memorandum
      </Link>
    </Stack>
  )
}
