import { dashboardPath } from 'components/Routes'

import { Grid, Typography } from '@mui/material'
import Button from 'components/Button'
import DefaultLayout from 'components/layout/DefaultLayout'
import { useHistory } from 'react-router-dom'

export default function VerificationPartialSuccess(): JSX.Element {
  const history = useHistory()

  return (
    <DefaultLayout
      backgroundColor='white'
      heading='Partial verification success'
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='body1'>
            Woops we had a little trouble verifying your identity, we'll be in
            touch to sort it out but in the mean time you can start digging for
            gold.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            color='primary'
            fullWidth
            onClick={() => history.push(dashboardPath())}
          >
            Go to your Dashboard
          </Button>
        </Grid>
      </Grid>
    </DefaultLayout>
  )
}
