import { makeStyles } from '@mui/styles'

interface Props {
  text: string | JSX.Element
  prefix?: string
  suffix?: string
}

// By flipping the `text` and `superscript` we can use the flexbox align baseline.
// This aligns the flipped text along the top of the text.
// By then flipping the whole component back, we have upright text aligned along its top.
// Setting the height helps the superscript text to align with other text in the doc flow.
const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'baseline',
    transform: 'scaleY(-1)',
    height: '1em'
  },
  superscript: {
    fontSize: '0.5em',
    lineHeight: '1em',
    transform: 'scaleY(-1)'
  },
  text: {
    fontSize: '1em',
    lineHeight: '1em',
    transform: 'scaleY(-1)'
  }
}))

export default function Superscript({
  prefix,
  suffix,
  text
}: Props): JSX.Element {
  const classes = useStyles()

  return (
    <span className={classes.wrapper}>
      {prefix && <span className={classes.superscript}>{prefix}</span>}
      <span className={classes.text}>{text}</span>
      {suffix && <span className={classes.superscript}>{suffix}</span>}
    </span>
  )
}
