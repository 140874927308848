import { Grid, List, ListItem, Typography } from '@mui/material'
import Button from 'components/Button'

import DefaultLayout from 'components/layout/DefaultLayout'
import { verifyPath } from 'components/Routes'
import addressIcon from 'images/address.png'
import cardIcon from 'images/card.png'
import ageIcon from 'images/eighteen-plus.png'
import emailIcon from 'images/email.png'
import { useHistory } from 'react-router'
import useStyles from './styles'
import GoldieLogo from 'components/icons/GoldieLogo/GoldieLogo'
import theme from 'utils/theme'

export const list = [
  {
    avatar: ageIcon,
    text: 'To be 18 years or older'
  },
  {
    avatar: emailIcon,
    text: 'An email address'
  },
  {
    avatar: addressIcon,
    text: 'A New Zealand residential address'
  },
  {
    avatar: cardIcon,
    text: `A valid New Zealand driver's licence`
  }
]

export default function Welcome(): JSX.Element {
  const classes = useStyles()

  const history = useHistory()

  const handleClickButton = () => {
    history.push(verifyPath())
  }

  return (
    <DefaultLayout
      backgroundColor='white'
      wrapperContent={{ mt: 0, mb: 2, mx: 1.5 }}
    >
      <Grid
        container
        justifyContent={'center'}
        spacing={2}
        className={classes.wrapper}
      >
        <Grid item xs={12} sm={8} md={6}>
          <GoldieLogo fill={theme.palette.primary.main} />
        </Grid>
        <Grid item xs={12}>
          <Typography component='h2' variant='h1'>
            Welcome to Goldie and your vault.
          </Typography>
          <Typography variant='body1' marginY={2}>
            We're pumped to have you here and can't wait to get you set up.
          </Typography>
          <Typography component='p'>Here's what you'll need:</Typography>
        </Grid>
        <Grid item xs={12}>
          <List className={classes.list}>
            {list.map((item, index) => (
              <ListItem alignItems='center' key={index}>
                <img src={item.avatar} alt='18+' />
                <Typography component='p'>{item.text}</Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Typography component='p' fontStyle='italic' fontSize='13px'>
            This application will take you less than 5 minutes
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            data-testid='diggin-btn'
            variant='outlined'
            type='button'
            onClick={handleClickButton}
          >
            Let's get started
          </Button>
        </Grid>
      </Grid>
    </DefaultLayout>
  )
}
