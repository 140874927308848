import { FormikContextType, useFormikContext } from 'formik'
import { useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { verifyIdDetailsPath } from 'components/Routes'
import { useUpdateProfileForOnboardingMutation } from 'generated/graphql'
import {
  API_DATE_FORMAT,
  UI_DATE_FORMAT,
  formatAddress,
  formatDate
} from 'utils/util'
import { FormValues } from '../'
import { formFieldData } from '../formFieldData'

import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import { Address, Country, WidgetInput } from 'react-addressfinder'
import FormField from '../../../formik/FormField'

import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button/Button'
import DatePicker from 'components/DatePicker'
import * as _ from 'lodash'
import 'react-addressfinder/dist/widget.css'
import theme from 'utils/theme'
import useStyles from './styles'
import { ETHNICITIES } from 'constants/index'

export type PersonalDetailsFormProps = RouteComponentProps

export default function PersonalDetailsForm({
  history
}: Readonly<PersonalDetailsFormProps>): JSX.Element {
  const classes = useStyles()

  const { setToast } = useAuth()

  const {
    setFieldValue,
    values,
    errors,
    touched,
    handleChange,
    handleBlur
  }: FormikContextType<FormValues> = useFormikContext()

  const [addressFinderWidgetError, setAddressFinderWidgetError] =
    useState<string>('')
  const [enterManually, setEnterManually] = useState<boolean>(false)

  const [updateProfile, { loading: updateProfileLoading }] =
    useUpdateProfileForOnboardingMutation({
      // TODO: error handling
      onError: error =>
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        })
    })

  const disableButton = useMemo(() => {
    return (
      !values.firstName ||
      !values.lastName ||
      !values.dateOfBirth ||
      !values.addressLine1 ||
      !values.city ||
      !values.postcode ||
      !values.nzMobilePhoneNumber ||
      !_.isEmpty(errors)
    )
  }, [errors, values])

  function handleChangeAddress(_fullAddress: string, address: Address) {
    const { city, line1, line2, postcode, suburb } = address

    setFieldValue('addressLine1', line1 || '')
    setFieldValue('addressLine2', line2 || '')
    setFieldValue('suburb', suburb || '')
    setFieldValue('city', city || '')
    setFieldValue('postcode', postcode || '')
  }

  const handleClearAddress = () => {
    setFieldValue('addressLine1', '')
    setFieldValue('addressLine2', '')
    setFieldValue('suburb', '')
    setFieldValue('city', '')
    setFieldValue('postcode', '')
  }

  const validateAddressFinderWidget = (value: string) => {
    if (!enterManually && !value) {
      setAddressFinderWidgetError('This field is required')
    } else {
      setAddressFinderWidgetError('')
    }
  }

  function formattedAddressValue(): string {
    const address = {
      city: values.city,
      country: Country.NZ,
      line1: values.addressLine1,
      line2: values.addressLine2,
      postcode: values.postcode,
      suburb: values.suburb
    }

    return address.line1 && address.city && address.postcode
      ? formatAddress(address)
      : ''
  }

  function handleClickNext() {
    if (_.isEmpty(errors)) {
      updateProfile({ variables: { input: getFormValues() } }).then(
        response => {
          if (!response.errors) {
            history.push(verifyIdDetailsPath())
          } else {
            // TODO: error handling
          }
        }
      )
    }
  }

  // function handleClickSaveExit() {
  //   if (dirty) {
  //     updateProfile({ variables: { input: getFormValues() } }).then(
  //       response => {
  //         if (!response.errors) {
  //           history.push(dashboardPath())
  //         } else {
  //           // TODO: error handling
  //         }
  //       }
  //     )
  //   } else {
  //     history.push(dashboardPath())
  //   }
  // }

  function getFormValues() {
    const {
      addressLine1,
      addressLine2,
      city,
      dateOfBirth,
      firstName,
      lastName,
      middleName,
      nzMobilePhoneNumber,
      postcode,
      preferredName,
      suburb,
      ethnicity
    } = values

    return {
      addressLine1,
      addressLine2,
      city,
      dateOfBirth: dateOfBirth
        ? formatDate({
            date: dateOfBirth,
            inputFormat: UI_DATE_FORMAT,
            outputFormat: API_DATE_FORMAT
          })
        : null,
      firstName,
      lastName,
      middleName,
      nzMobilePhoneNumber,
      postcode,
      preferredName,
      suburb,
      ethnicity
    }
  }

  return (
    <>
      <Typography my={2.5} variant='h6'>
        &emsp;So that we can verify you, please make sure your details match
        what’s on your ID.
      </Typography>
      <Grid container spacing={2} paddingX={3}>
        <Grid item xs={12} sm={6}>
          <FormField
            {...formFieldData.firstName}
            error={!!(errors.firstName && touched.firstName)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            {...formFieldData.middleName}
            error={!!(errors.middleName && touched.middleName)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            {...formFieldData.lastName}
            error={!!(errors.lastName && touched.lastName)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            {...formFieldData.preferredName}
            error={!!(errors.preferredName && touched.preferredName)}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker<FormValues>
            name='dateOfBirth'
            label='Date of birth'
            inputFormat={UI_DATE_FORMAT}
            outputFormat={UI_DATE_FORMAT}
          />
        </Grid>
        {!enterManually && (
          <>
            <Grid item xs={12}>
              <WidgetInput
                addressFinderKey={
                  process.env.REACT_APP_ADDRESSFINDER_KEY as string
                }
                country={Country.NZ}
                id='addressFinderWidget'
                inputClassName={
                  addressFinderWidgetError
                    ? classes.addressFinderInputError
                    : classes.addressFinderInput
                }
                name='addressFinderWidget'
                onSelected={handleChangeAddress}
                placeholder='Start typing your address...'
                defaultValue={formattedAddressValue()}
                onChange={handleClearAddress}
                onBlur={e => validateAddressFinderWidget(e.target.value)}
              />
              {addressFinderWidgetError && (
                <Box className={classes.error}>{addressFinderWidgetError}</Box>
              )}
            </Grid>
            <Grid item xs={12} paddingTop='0.5rem !important'>
              <Link
                color={theme.palette.primary.main}
                component='p'
                type='button'
                textAlign='center'
                style={{ cursor: 'pointer' }}
                onClick={() => setEnterManually(true)}
              >
                Enter address manually
              </Link>
            </Grid>
          </>
        )}

        {enterManually && (
          <>
            <Grid item xs={12}>
              <Link
                color={theme.palette.primary.main}
                component='p'
                type='button'
                textAlign='center'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setEnterManually(false)
                  if (values.addressLine1 && values.city && values.postcode)
                    setAddressFinderWidgetError('')
                }}
              >
                Use address autocompletion
              </Link>
            </Grid>
            <Grid item xs={12}>
              <FormField
                {...formFieldData.addressLine1}
                error={!!(errors.addressLine1 && touched.addressLine1)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                {...formFieldData.addressLine2}
                error={!!(errors.addressLine2 && touched.addressLine2)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                {...formFieldData.suburb}
                error={!!(errors.suburb && touched.suburb)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormField
                {...formFieldData.city}
                error={!!(errors.city && touched.city)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                {...formFieldData.postcode}
                error={!!(errors.postcode && touched.postcode)}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormField
            {...formFieldData.nzMobilePhoneNumber}
            error={
              !!(errors.nzMobilePhoneNumber && touched.nzMobilePhoneNumber)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth required data-testid='ethnicity'>
            <InputLabel
              id={formFieldData.ethnicity.name}
              error={!!(errors.ethnicity && touched.ethnicity)}
            >
              {formFieldData.ethnicity.label}
            </InputLabel>
            <Select
              label=''
              name='ethnicity'
              value={values.ethnicity}
              onChange={handleChange}
              onBlur={handleBlur}
              onClickCapture={handleBlur}
              required
              error={!!(errors.ethnicity && touched.ethnicity)}
            >
              {ETHNICITIES.map(ethnicity => (
                <MenuItem key={ethnicity} value={ethnicity}>
                  {ethnicity}
                </MenuItem>
              ))}
            </Select>
            {errors.ethnicity && (
              <FormHelperText error>{errors.ethnicity}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid mt={8} mb={6}>
        <Button
          disabled={updateProfileLoading || disableButton}
          fullWidth
          onClick={handleClickNext}
          color='primary'
          type='button'
        >
          Next
        </Button>
      </Grid>
    </>
  )
}
