import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  header: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: '3rem',
    margin: theme.spacing(4, 0)
  },
  select: {
    borderRadius: '0.5em',
    '& > div': {
      padding: theme.spacing(1.25, 3)
    }
  }
}))
