import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { useAuth } from 'components/AuthProvider'
import { buyPath, dashboardPath, sellPath } from 'components/Routes'
import DefaultLayout from 'components/layout/DefaultLayout'
import { TRADING_IS_PAUSED } from 'constants/index'
import {
  useCreateReviewOrderForBuyMutation,
  useCreateReviewOrderForSellMutation
} from 'generated/graphql'
import PauseTrading from '../PauseTrading/PauseTrading'
//import useProducts from 'hooks/useProducts'

export type CreateReviewOrderProps = RouteComponentProps

export default function CreateReviewOrder({
  history,
  location
}: CreateReviewOrderProps): JSX.Element {
  const { type, productId: productIdStr } = queryString.parse(location.search)
  const productId = Number(productIdStr)

  const { setToast } = useAuth()

  const [tradingPaused, setTradingPaused] = useState<boolean>(false)

  const [createReviewOrderForBuy, { data: createReviewOrderForBuyData }] =
    useCreateReviewOrderForBuyMutation({
      variables: {
        input: {
          productId
        }
      },
      onCompleted: () => {
        setTradingPaused(false)
      },
      onError: error => {
        if (
          error.graphQLErrors[0].extensions?.['error_status'] ===
          TRADING_IS_PAUSED
        ) {
          setTradingPaused(true)
        }
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        })
      }
    })

  const { id: reviewOrderForBuyId } =
    createReviewOrderForBuyData?.reviewOrder || {}

  const [createReviewOrderForSell, { data: createReviewOrderForSellData }] =
    useCreateReviewOrderForSellMutation({
      variables: {
        input: {
          productId
        }
      },
      onError: error => {
        if (
          error.graphQLErrors[0].extensions?.['error_status'] ===
          TRADING_IS_PAUSED
        ) {
          setTradingPaused(true)
        }
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        })
      }
    })

  const { id: reviewOrderForSellId } =
    createReviewOrderForSellData?.reviewOrder || {}

  useEffect(() => {
    if (!type) {
      history.push(dashboardPath())
    } else if (type === 'buy' && productId) {
      createReviewOrderForBuy()
    } else if (type === 'sell' && productId) {
      createReviewOrderForSell()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, productId])

  useEffect(() => {
    if (reviewOrderForBuyId) {
      history.replace(
        buyPath({
          routeParams: { reviewOrderId: reviewOrderForBuyId },
          queryParams: { productId }
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewOrderForBuyId])

  useEffect(() => {
    if (reviewOrderForSellId) {
      history.replace(
        sellPath({
          routeParams: { reviewOrderId: reviewOrderForSellId },
          queryParams: { productId }
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewOrderForSellId])

  if (tradingPaused) {
    return <PauseTrading />
  }

  return (
    <DefaultLayout heading={type === 'buy' ? 'Buy' : 'Sell'} loading>
      {/* NOTE: as loading wrapper is always loading, this will never be seen */}
      <p>Creating review order...</p>
    </DefaultLayout>
  )
}
