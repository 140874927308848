import { useState } from 'react'

import {
  VisibilityOffRounded as VisibilityOffIcon,
  VisibilityRounded as VisibilityIcon
} from '@mui/icons-material'
import {
  BaseTextFieldProps,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps
} from '@mui/material'

export type PasswordFieldProps = BaseTextFieldProps & TextFieldProps

export default function PasswordField(props: PasswordFieldProps): JSX.Element {
  const [showPassword, setShowPassword] = useState(false)

  const handleToggleShowPassword = () => setShowPassword(!showPassword)

  return (
    <TextField
      {...props}
      InputProps={{
        style: { background: '#ffffff' },
        // This is where the toggle 'view password' button is added
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleToggleShowPassword}
              size='large'
              tabIndex={-1}
            >
              {showPassword ? (
                <VisibilityIcon color='primary' />
              ) : (
                <VisibilityOffIcon color='primary' />
              )}
            </IconButton>
          </InputAdornment>
        )
      }}
      sx={props.sx ?? { backgroundColor: '#ffffff' }}
      type={showPassword ? 'text' : 'password'}
    />
  )
}
