import { Box, Grid, Typography } from '@mui/material'
import Icon from 'images/hand_gold.png'
import useStyles from './styles'

export default function NoPerformance(): JSX.Element {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <Box className={classes.wrapper}>
        <Typography variant='h4' className={classes.noPerformance}>
          No performance
        </Typography>
        <Typography variant='h6' className={classes.buyGold}>
          Buy gold to start
        </Typography>
        <img src={Icon} alt='no_performance_icon' width={100} />
      </Box>
    </Grid>
  )
}
