import { Grid, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { twoStepScanPath } from 'components/Routes'

import Button from 'components/Button'
import AuthyPath from 'images/Authy.png'
import DuoPath from 'images/Duo.png'
import GoogleAuthenticatorPath from 'images/GoogleAuthenticator.png'
import MicrosoftAuthenticatorPath from 'images/MicrosoftAuthenticator.png'

export default function TwoStepDownload(): JSX.Element {
  const history = useHistory()

  function handleClick() {
    history.push(twoStepScanPath())
  }

  const images = [
    {
      src: AuthyPath,
      alt: 'authy'
    },
    {
      src: GoogleAuthenticatorPath,
      alt: 'google_authenticator'
    },
    {
      src: MicrosoftAuthenticatorPath,
      alt: 'microsoft_authenticator'
    },
    {
      src: DuoPath,
      alt: 'duo'
    }
  ]

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          align='left'
          variant='h2'
          fontSize='48px'
          textTransform='uppercase'
        >
          Download app
        </Typography>
      </Grid>
      <Grid item xs={12} mb={4}>
        <Typography align='left' fontSize='medium'>
          There are lots of options available, e.g. Google Authenticator, Authy.
        </Typography>
      </Grid>
      <Grid item container spacing={2}>
        {images.map(image => (
          <Grid item xs={6} key={image.alt}>
            <img
              src={image.src}
              alt={image.alt}
              style={{ objectFit: 'cover', width: '100%' }}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} sx={{ mt: 4, textAlign: 'center' }}>
        <Button color='primary' fullWidth onClick={handleClick}>
          Next
        </Button>
      </Grid>
    </Grid>
  )
}
