import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import GraphPeriod from 'components/GraphPeriod'
import NoPerformance from 'components/NoPerformance'
import PerformanceGraph, { GraphPeriodType } from 'components/PerformanceGraph'
import { tradePath, myVaultPath } from 'components/Routes'
import {
  Product,
  useCreateReviewOrderForSellMutation,
  useGetLatestSpotPriceQuery,
  useProductPerformanceQuery
} from 'generated/graphql'
import { memo, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  formatDateToString,
  formattedPrice,
  roundDownDollarsAndCents
} from 'utils/util'
import GridValue from './GridValue'
import TableTransactionHistory from './TableTransactionHistory'
const drawerBleeding = 80

interface MyVaultProductProps {
  product?: Product
  onClose: () => void
}

function MyVaultProduct({
  product,
  onClose
}: Readonly<MyVaultProductProps>): JSX.Element {
  const { user, setToast } = useAuth()
  const productId = product?.id

  const history = useHistory()

  const [graphPeriod, setGraphPeriod] = useState<GraphPeriodType>('1w')

  const { data: latestSpotPrice } = useGetLatestSpotPriceQuery({
    variables: { productId: Number(productId) },
    skip: !productId
  })

  const { data: productPerformance } = useProductPerformanceQuery({
    variables: { productId: Number(productId) },
    skip: !productId
  })

  const [createReviewOrderForSell] = useCreateReviewOrderForSellMutation({
    onError: error => {
      setToast({
        open: true,
        message: error.message,
        type: 'error',
        duration: 3000
      })
    }
  })

  const getUpdatedPriceTime = useCallback(() => {
    const { spotTime } = latestSpotPrice?.latestSpotPrice ?? {}
    return formatDateToString(new Date(spotTime as Date))
  }, [latestSpotPrice])

  const isShowPerformanceGraph = useCallback(() => {
    const activatedVault = user?.activatedVault
    const hasHistory = user?.securitiesOwned?.find(
      s => s?.product?.id === product?.id
    )?.hasHistory as boolean
    return activatedVault
      ? Boolean(
          activatedVault?.securitiesOwned?.find(
            s => s.product.id === product?.id
          )
        )
      : hasHistory
  }, [product?.id, user?.activatedVault, user?.securitiesOwned])

  const getMidpointPerG = useCallback(() => {
    const { midpointPerDg } = latestSpotPrice?.latestSpotPrice ?? {}
    return midpointPerDg
      ? roundDownDollarsAndCents(Number(midpointPerDg) * 10).toFixed(2)
      : '0.00'
  }, [latestSpotPrice])

  const getCurrentValue = useCallback(() => {
    const { currentValue } = productPerformance?.productPerformance ?? {}

    const amount = Number(currentValue).toFixed(2)
    return amount ? formattedPrice(amount) : '0.00'
  }, [productPerformance])

  const getWeightInGrams = useCallback(() => {
    const { ownedAllotmentsCount } =
      productPerformance?.productPerformance ?? {}

    return ownedAllotmentsCount ? Number(ownedAllotmentsCount) / 10 : 0
  }, [productPerformance])

  const { formattedDate, formattedHours, formattedMinutes, meridiem } =
    getUpdatedPriceTime() ?? {}

  // const handleClickReviewToSell = useCallback(() => {
  //   history.push(
  //     createReviewOrderPath({
  //       queryParams: { type: 'sell', productId: productId }
  //     })
  //   )
  // }, [history, productId])

  const handleClickBuyMore = useCallback(() => {
    history.push(tradePath({ routeParams: { productId } }))
  }, [history, productId])

  const handleClickSell = useCallback(() => {
    createReviewOrderForSell({
      variables: {
        input: {
          weight: 1,
          productId: Number(productId)
        }
      },
      onCompleted: ({ reviewOrder }) => {
        const { id } = reviewOrder ?? {}
        if (id) {
          history.push(
            myVaultPath({
              queryParams: { type: 'sell', id }
            })
          )
        }
      }
    }).then()
  }, [history, productId, createReviewOrderForSell])

  return (
    <Box>
      <Box textAlign='right'>
        <IconButton aria-label='Close drawer' edge='start' onClick={onClose}>
          <CloseIcon fontSize='large' />
        </IconButton>
      </Box>
      <Stack height={drawerBleeding} id='bullion'>
        <Box paddingX={2} display='flex' justifyContent='space-between'>
          <Box>
            <Typography color='primary' fontSize='1rem'>
              {product?.name.toUpperCase()} BULLION
            </Typography>
            <Typography color='primary' fontSize='1rem'>
              99.99% FINE {product?.name.toUpperCase()}
            </Typography>
          </Box>
          <Box textAlign='right'>
            <Typography color='primary' fontSize='0.75rem'>
              PER GRAM: ${getMidpointPerG()}
            </Typography>
            <Typography color='primary' fontSize='0.75rem'>
              YOU OWN: ${getCurrentValue()}
            </Typography>
          </Box>
        </Box>
      </Stack>

      <Stack px={2} pb={4}>
        <Typography color='common.black' fontSize='1rem'>
          You own {getWeightInGrams()} grams.
        </Typography>
        <Typography color='common.black' fontSize='1rem'>
          Current value is ${getCurrentValue()}
        </Typography>
      </Stack>

      <Stack px={2}>
        {/* TODO: make this a component */}
        <GridValue data={productPerformance} currentValue={getCurrentValue()} />
      </Stack>

      <Stack px={2} mt={2}>
        <Typography color='common.black' fontSize='0.75rem'>
          All amounts shown in NZD
        </Typography>
      </Stack>

      <Stack height={200} pl={2} mb={4} ml='-1rem' position='relative'>
        {isShowPerformanceGraph() ? (
          <PerformanceGraph
            setToast={setToast}
            user={user}
            graphPeriod={graphPeriod}
            productId={Number(product?.id)}
          />
        ) : (
          <NoPerformance />
        )}
      </Stack>

      <Stack>
        <GraphPeriod
          setGraphPeriod={setGraphPeriod}
          graphPeriod={graphPeriod}
        />
      </Stack>

      <Stack
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        px={2}
        mt={2}
      >
        <Typography variant='body2' color='primary'>
          ${getMidpointPerG()} PER GRAM
        </Typography>
        <Typography variant='body2' color='primary'>
          LIVE AT {formattedHours}:{formattedMinutes} {meridiem} {formattedDate}
        </Typography>
      </Stack>

      <Stack px={2} py={3} gap={1}>
        <Button variant='outlined' fullWidth onClick={handleClickSell}>
          Sell
        </Button>

        <Button variant='outlined' fullWidth onClick={handleClickBuyMore}>
          Buy More
        </Button>
      </Stack>

      <Stack px={2} py={3} gap={1}>
        <Typography color='primary' fontSize='1rem'>
          TRANSACTION HISTORY
        </Typography>
        <TableTransactionHistory productId={productId as string} />
      </Stack>
    </Box>
  )
}

export default memo(MyVaultProduct)
