import {
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  Typography
} from '@mui/material'
import { Field, FormikErrors, FormikTouched, useFormikContext } from 'formik'
import { CheckboxWithLabel } from 'formik-mui'
import { AmlVerificationQuestion } from 'generated/graphql'
import { Fragment } from 'react'
import theme from 'utils/theme'
import { FormValues } from '../OnboardingWizard'
import CommentFormField from './CommentFormField'
import { isOptionSelected } from './FinancialDetailsForm'

interface QuestionProps extends AmlVerificationQuestion {
  values: FormValues
  errors: FormikErrors<FormValues>
  touched: FormikTouched<FormValues>
}

export default function MultiSelectQuestion({
  answerOptions,
  identifier,
  question,
  values,
  errors,
  touched
}: QuestionProps) {
  const { isSubmitting } = useFormikContext()
  return (
    <Grid item xs={12}>
      <FormControl component='fieldset' variant='outlined'>
        <FormLabel component='legend' sx={{ fontStyle: 'italic' }}>
          {question}
        </FormLabel>
        <FormGroup>
          {answerOptions.map(option => {
            return (
              <Fragment key={option.id}>
                <Field
                  Label={{ label: option.answer }}
                  component={CheckboxWithLabel}
                  disabled={isSubmitting}
                  name={identifier}
                  type='checkbox'
                  value={option.id}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                />
                {option.commentsRequired &&
                  isOptionSelected(values, identifier, option.id) && (
                    <CommentFormField
                      identifier={identifier}
                      optionId={option.id}
                      errors={errors}
                    />
                  )}
              </Fragment>
            )
          })}
        </FormGroup>
      </FormControl>
      {errors[identifier] && touched[identifier] && (
        <Typography
          style={{
            fontSize: '0.75rem',
            color: theme.palette.error.main,
            margin: theme.spacing(0.5, 0, 0, 1.75)
          }}
        >
          {errors[identifier]}
        </Typography>
      )}
    </Grid>
  )
}
