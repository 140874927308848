import { Chart, ScatterDataPoint, TooltipItem, TooltipModel } from 'chart.js'
import { NumberValue, timeParse } from 'd3'
import dayjs from 'dayjs'
import {
  GetHistoricalSpotPricesQuery as GetHistoricalSpotPricesQueryResponse,
  HistoricalSpotPrice
} from 'generated/graphql'
import theme from 'utils/theme'
import { formattedPrice } from 'utils/util'

import { GraphPeriodType } from './LineGraph'
import { DataPoint } from './types'

export const X_LABEL_HEIGHT = 45

export function getRandomDefaultData(graphPeriod: GraphPeriodType) {
  switch (graphPeriod) {
    case '1w':
      return 7
    case '1m':
      return 30
    case '3m':
      return 90
    case '1y':
      return 365
    case '5y':
      return 1825
    default:
      return 7300
  }
}

export function getTickMarks(graphPeriod: GraphPeriodType) {
  switch (graphPeriod) {
    case '1w':
      return 7
    case '1m':
      return 30
    case '3m':
      return 90
    case '1y':
      return 12
    default:
      return 6
  }
}

export function defaultTickFormatter(value: NumberValue): string {
  return `$${Number(value).toFixed()}/g`
}

function getPositionLeft(
  position: number,
  chart: Chart<'line', (number | ScatterDataPoint | null)[], unknown>
) {
  if (position <= 30) {
    return 30
  } else if (position >= Number(chart.width - 80)) {
    return chart.width - 80
  }
  return position
}

function getOrCreateTooltip(chart: Chart<'line'>) {
  let tooltipEl = chart?.canvas?.parentNode?.querySelector(
    '#tooltip'
  ) as HTMLDivElement

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.setAttribute('id', 'tooltip')
    tooltipEl.style.minWidth = '120px'
    tooltipEl.style.background = 'transparent'
    tooltipEl.style.color = theme.palette.primary.main
    tooltipEl.style.fontSize = '10px'
    tooltipEl.style.opacity = '0'
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transform = 'translate(-50%, 0)'
    tooltipEl.style.transition = 'all .0001s'
    tooltipEl.style.padding = '0px 16px'
    tooltipEl.className = 'tooltip'

    const tooltipWrapper = document.createElement('div')
    tooltipWrapper.style.margin = '0px'

    tooltipEl.appendChild(tooltipWrapper)
    chart?.canvas?.parentNode?.appendChild(tooltipEl)
  }

  return tooltipEl
}

function getOverPeriod(chart: Chart<'line'>) {
  let overPeriodEl = chart?.canvas?.parentNode?.querySelector(
    '#over-period'
  ) as HTMLDivElement

  if (!overPeriodEl) {
    overPeriodEl = document.createElement('div')
    overPeriodEl.setAttribute('id', 'over-period')
    overPeriodEl.style.width = '100%'
    overPeriodEl.style.margin = '2rem 0'

    chart?.canvas?.parentNode?.appendChild(overPeriodEl)
  }

  return overPeriodEl
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function renderOverPeriod({
  priceEl,
  changeEl,
  chart
}: {
  priceEl: HTMLDivElement
  changeEl: HTMLDivElement
  chart: Chart<'line'>
}) {
  const overPeriodEl = getOverPeriod(chart)
  // Remove old over period children
  while (overPeriodEl.firstChild) {
    overPeriodEl.firstChild.remove()
  }

  priceEl.style.fontFamily = 'none'
  priceEl.style.fontSize = '10px'
  priceEl.style.lineHeight = '1'

  const changeValue = changeEl.textContent

  priceEl.style.color =
    Math.sign(Number(changeValue) * 10) === -1
      ? theme.palette.status.negative
      : theme.palette.status.positive

  changeEl.style.fontSize = '10px'
  changeEl.style.lineHeight = '1'
  changeEl.textContent = `(${changeValue}%)`

  overPeriodEl.style.display = 'flex'
  overPeriodEl.style.justifyContent = 'center'
  overPeriodEl.style.alignContent = 'center'
  overPeriodEl.style.gap = '8px'

  const overPeriodValueEl = document.createElement('div')

  overPeriodValueEl.style.flex = '1'
  overPeriodValueEl.style.display = 'flex'
  overPeriodValueEl.style.justifyContent = 'flex-end'
  overPeriodValueEl.style.alignContent = 'center'
  overPeriodValueEl.style.gap = '4px'
  overPeriodValueEl.style.fontFamily = [
    'Helvetica Neue',
    'Arial',
    'sans-serif'
  ].join(',')

  const overPeriodTextEl = document.createElement('div')
  overPeriodTextEl.style.flex = '1'
  overPeriodTextEl.textContent = 'over period'
  overPeriodTextEl.style.fontFamily = [
    'Helvetica Neue',
    'Arial',
    'sans-serif'
  ].join(',')
  overPeriodTextEl.style.fontSize = '10px'
  overPeriodTextEl.style.color = '#C4C4C4'

  // Add data in over period
  overPeriodValueEl.appendChild(priceEl)
  overPeriodValueEl.appendChild(changeEl)
  overPeriodEl.appendChild(overPeriodValueEl)
  overPeriodEl.appendChild(overPeriodTextEl)
}

function renderPriceEl(
  priceEl: HTMLDivElement,
  dataPoint: TooltipItem<'line'>
) {
  priceEl.style.lineHeight = '130%'
  priceEl.style.color = theme.palette.primary.main
  priceEl.style.fontFamily = ['Neue Haas Unica', 'sans-serif'].join(',')
  priceEl.style.fontSize = '10px'
  const text = document.createTextNode(
    `NZD ${formattedPrice(dataPoint.raw as number)}`
  )
  priceEl.appendChild(text)
}

function renderDateEl(date: HTMLDivElement, body: string) {
  date.style.whiteSpace = 'nowrap'
  date.style.color = theme.palette.primary.main
  date.style.textTransform = 'uppercase'
  date.style.textAlign = 'center'
  date.style.lineHeight = '130%'
  date.style.fontFamily = ['Neue Haas Unica', 'sans-serif'].join(',')
  date.style.fontSize = '10px'

  const text = document.createTextNode(body)
  date.appendChild(text)
}

function renderChangeEl({
  changeEl,
  dp,
  dataPoint
}: {
  dp: DataPoint
  dataPoint: TooltipItem<'line'>
  changeEl: HTMLDivElement
}) {
  const condition =
    dp.value === dataPoint.raw &&
    dayjs(dp.date).format('DD MMM YYYY') === dataPoint.label
  if (condition) {
    changeEl.style.color =
      Math.sign(Number(dp.change) * 10) === -1
        ? theme.palette.status.negative
        : theme.palette.status.positive
    changeEl.style.fontSize = '10px'
    changeEl.style.lineHeight = '200%'

    const changeValue = document.createTextNode(
      `$${formattedPrice(dp.change * 10)}`
    )

    changeEl.appendChild(changeValue)
  }
}

export function beforeDrawLine(chart: Chart<'line'>) {
  // @ts-ignore
  if (chart?.tooltip?._active && chart?.tooltip?._active?.length) {
    const ctx = chart.ctx
    ctx.save()
    // @ts-ignore
    const activePoint = chart.tooltip?._active[0]

    ctx.beginPath()
    ctx.moveTo(activePoint?.element.x, chart.chartArea.top - X_LABEL_HEIGHT)
    ctx.lineTo(activePoint?.element.x, chart.chartArea.bottom)
    ctx.lineWidth = 2
    ctx.strokeStyle = theme.palette.primary.main
    ctx.stroke()
    ctx.restore()
  }
}

export function externalTooltipHandler(
  args: {
    chart: Chart<'line'>
    tooltip: TooltipModel<'line'>
    replay?: boolean
  },
  defaultDataPoint: DataPoint[],
  showChangeValue?: boolean
) {
  const { chart, tooltip, replay } = args
  if (replay) {
    return
  }

  const tooltipEl = getOrCreateTooltip(chart)

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0'
    return
  }

  if (tooltip.body) {
    const dataPoints: TooltipItem<'line'>[] = tooltip.dataPoints || []

    const dateList = tooltip.dataPoints.map(b => b.label)

    const priceEl = document.createElement('div')
    const changeEl = document.createElement('div')

    const topContent = document.createElement('div')
    topContent.style.fontSize = '10px'
    topContent.style.display = 'flex'
    topContent.style.gap = '4px'
    topContent.style.alignItems = 'end'
    topContent.style.justifyContent = 'center'

    // show price of gold in tooltip
    dataPoints.forEach(dataPoint => {
      renderPriceEl(priceEl, dataPoint)

      // show change of gold per gram in tooltip
      defaultDataPoint.forEach(dp => {
        renderChangeEl({ changeEl, dp, dataPoint })
      })
    })

    const date = document.createElement('div')
    date.style.lineHeight = '1'

    // show date in tooltip
    dateList.forEach(body => {
      renderDateEl(date, body)
    })

    const tooltipSection = tooltipEl.querySelector('div') as HTMLTableElement

    // Remove old children
    while (tooltipSection.firstChild) {
      tooltipSection.firstChild.remove()
    }

    // Add new children
    topContent.appendChild(priceEl)
    showChangeValue && topContent.appendChild(changeEl)
    tooltipSection.appendChild(topContent)
    tooltipSection.appendChild(date)
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

  tooltipEl.style.opacity = '1'

  tooltipEl.style.left =
    getPositionLeft(positionX + Number(tooltip?.caretX ?? 0), chart) + 'px'
  tooltipEl.style.top = positionY - 30 + 'px'
}

export interface HistoricalSpotPriceGraphProps {
  productId: number
  defaultGraphPeriod?: string
  title?: string
}

const parseTime = timeParse('%Y-%m-%d') // e.g. 2016-04-30

export const buildDataHistoricalSpotPrices = (
  data: GetHistoricalSpotPricesQueryResponse,
  latestSpotPriceData: number
): Array<DataPoint> => {
  const historicalSpotPrices = [...data.historicalSpotPrices].map(
    (spotPrice, index) => {
      if (index === data.historicalSpotPrices.length - 1) {
        return {
          ...spotPrice,
          midpointPerG: latestSpotPriceData * 10
        }
      }
      return spotPrice
    }
  )

  return historicalSpotPrices.reduce(
    (acc: Array<DataPoint>, val: HistoricalSpotPrice) => {
      const date = parseTime(val.spotDate)

      const newData = [...acc]
      if (date)
        newData.push({
          date,
          value: Number(val.midpointPerG),
          change: Number(0)
        })

      return newData
    },
    []
  )
}
