import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    '& h1': {
      fontSize: '3rem',
      textTransform: 'uppercase',
      color: theme.palette.primary.main
    },

    '& h2': {
      color: theme.palette.disabled.contrastText,
      textAlign: 'center'
    }
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(2),

    '& h2': {
      color: theme.palette.default.contrastText
    }
  },
  label: {
    color: theme.palette.disabled.contrastText,
    lineHeight: '140%'
  }
}))
