import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  addressFinderInput: {
    width: '100%',
    display: 'inline-block',
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: '0.5em',
    fontSize: '0.875rem',
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    height: '1.1876em',
    padding: theme.spacing(2.5, 3),
    boxSizing: 'border-box',

    '&:hover': {
      borderColor: '#212121'
    },

    '&:focus': {
      borderColor: theme.palette.blue.main,
      borderWidth: '2px',
      outline: 'none'
    }
  },
  addressFinderInputError: {
    width: '100%',
    display: 'inline-block',
    border: '1px solid',
    borderColor: theme.palette.error.main,
    borderRadius: '0.5em',
    fontSize: '0.875rem',
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    height: '1.1876em',
    padding: theme.spacing(2.5, 3),
    boxSizing: 'border-box',

    '&:focus': {
      borderColor: theme.palette.error.main,
      borderWidth: '2px',
      outline: 'none'
    }
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '12px',
    fontWeight: 400,
    margin: theme.spacing(0.5, 1.75, 0)
  }
}))
