import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '0px 0px 32px 32px',
    padding: theme.spacing(3, 0, 2),
    margin: theme.spacing(0),
    listStyle: 'none'
  },
  menu: {
    background: theme.palette.background.paper,
    borderRadius: '16px',
    padding: theme.spacing(2.5, 3),
    cursor: 'pointer',

    color: theme.typography.body1.color,

    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1rem',

    '& > div': {
      width: '1.5rem',
      height: '1.5rem',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    '& > p': {
      lineHeight: '150%',
      fontSize: '1rem',
      fontWeight: '400',
      color: '#292D32'
    }
  }
}))
