import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  sell_page: {
    width: '100%',
    height: '100vh',
    backgroundColor: 'transparent'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} 0`
  },
  choose_vault: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(0)} ${theme.spacing(2)}`,
    '& > button[class*="buttonMyVault"]': {
      height: 'unset'
    }
  },
  btnConfirm: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(4),
    width: `calc(100% - ${theme.spacing(4)})`,
    borderWidth: 2
  }
}))
