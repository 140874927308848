import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, Divider, Stack, Typography, useMediaQuery } from '@mui/material'
import SliderMui from '@mui/material/Slider'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import GraphPeriod from 'components/GraphPeriod'
import LineGraph, { GraphPeriodType } from 'components/LineGraph/LineGraph'
import { DataPoint } from 'components/LineGraph/types'
import { buildDataHistoricalSpotPrices } from 'components/LineGraph/utils'
import Location from 'components/Location'
import PopUp from 'components/PopUp'
import { reviewPath } from 'components/Routes'
import { referralsPath, tradePath } from 'components/Routes/Routes'
import DefaultLayout from 'components/layout/DefaultLayout'
import LoadingWrapper from 'components/layout/LoadingWrapper'
import {
  CURRENT_PURCHASE_FEE,
  TRADING_IS_PAUSED,
  patternOneDigitsAfterComma
} from 'constants/index'
import { NumberValue } from 'd3'
import {
  useCreateReviewOrderForBuyMutation,
  useGetHistoricalSpotPricesQuery
} from 'generated/graphql'
import useProducts from 'hooks/useProducts'
import useSpotPrice from 'hooks/useSpotPrice'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import theme from 'utils/theme'
import {
  addPrecisely,
  formatDateToString,
  formattedPrice,
  minusPrecisely
} from 'utils/util'
import PauseTrading from '../PauseTrading'
import InputNumber from './InputNumber'
import useStyles from './styles'
import CountUpPrice from 'components/CountUpPrice'

export default function TradeProduct(): JSX.Element {
  const classes = useStyles()
  const history = useHistory()
  const { user, setToast } = useAuth()

  const isDesktopView = useMediaQuery(theme.breakpoints.up('md'))

  const { product, products, productId } = useProducts()

  const [graphPeriod, setGraphPeriod] = useState<GraphPeriodType>('1w')
  const [tradingPaused, setTradingPaused] = useState<boolean>(false)

  const [headerHeight, setHeaderHeight] = useState<number>(0)
  const [productBullionHeight, setProductBullionHeight] = useState<number>(0)

  const [weightSlider, setWeightSlider] = useState<number>(0)
  const [amount, setAmount] = useState<string>('0')

  const [error, setError] = useState<
    { message: string; error: boolean } | undefined
  >()
  const [step, setStep] = useState<number>(0.1)

  const calculateAmount = useCallback(
    (weight: number, midpointPerDg: string | number) => {
      return (weight * Number(midpointPerDg) * 10).toFixed(2).toString()
    },
    []
  )

  const setWeight = useCallback(
    (weight: number, midpointPerDg: string | number) => {
      // Minimum amount to display on slider 0.1g
      // Max weight we can have is 500
      const weightSliderWeight = Math.max(Math.min(weight, 500), Number(0.1))
      setWeightSlider(weightSliderWeight)
      setAmount(calculateAmount(weightSliderWeight, midpointPerDg))
    },
    [calculateAmount]
  )

  const [createReviewOrderForBuy, { loading: creatingReviewOrderForBuy }] =
    useCreateReviewOrderForBuyMutation({
      onError: error => {
        if (
          error.graphQLErrors[0].extensions?.['error_status'] ===
          TRADING_IS_PAUSED
        ) {
          setTradingPaused(true)
        }
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        })
      }
    })

  const onSubmit = useCallback(() => {
    if (productId && !error?.error) {
      createReviewOrderForBuy({
        variables: {
          input: {
            weight: weightSlider * 10,
            productId
          }
        },
        onCompleted: ({ reviewOrder }) => {
          const { id } = reviewOrder ?? {}
          if (id) {
            history.push(
              reviewPath({
                queryParams: { type: 'buy' },
                routeParams: { reviewOrderId: id }
              })
            )
          }
        }
      })
    }
  }, [createReviewOrderForBuy, error, history, productId, weightSlider])

  const { data: historicalSpotPriceData, loading: historicalSpotPriceLoading } =
    useGetHistoricalSpotPricesQuery({
      variables: {
        timeframe: graphPeriod,
        productId
      },
      // TODO: error handling
      onError: error =>
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        }),
      skip: !productId
    })

  const [, { data: latestSpotPriceData, loading: gettingLatestSpotPrice }] =
    useSpotPrice({
      callInterval: 90000, // 1.5 minutes,
      productId,
      onCompleted: ({ latestSpotPrice }) => {
        const walletAmount = Number(user?.wallet?.balance)
        const amount =
          walletAmount &&
          latestSpotPrice?.midpointPerDg &&
          walletAmount >= Number(latestSpotPrice?.midpointPerDg)
            ? walletAmount
            : Number(latestSpotPrice?.midpointPerDg)

        const possibleWeight =
          amount > 0
            ? Math.floor(
                amount /
                  (Number(latestSpotPrice?.askPricePerDg) *
                    (1.0 + CURRENT_PURCHASE_FEE))
              ) / 10
            : Number(0.1)

        // Init weight to greater of the max grams they could purchase OR 1.0 grams
        const initWeight = Math.max(Number(1.0), possibleWeight)

        setWeight(initWeight, latestSpotPrice.midpointPerDg)

        setStep(() => {
          if (initWeight < 20) return 0.1
          else if (initWeight >= 20 && initWeight < 50) return 0.2
          else if (initWeight >= 50 && initWeight < 100) return 0.5
          return 1
        })
      }
    })

  const loading = useMemo(() => {
    return (
      creatingReviewOrderForBuy ||
      gettingLatestSpotPrice ||
      historicalSpotPriceLoading
    )
  }, [
    creatingReviewOrderForBuy,
    gettingLatestSpotPrice,
    historicalSpotPriceLoading
  ])

  const midpointPerDg = useMemo(() => {
    const { midpointPerDg } = latestSpotPriceData?.latestSpotPrice ?? {}
    return midpointPerDg ? Number(midpointPerDg) : 0
  }, [latestSpotPriceData])

  const updatedTime = useMemo(() => {
    const { spotTime } = latestSpotPriceData?.latestSpotPrice ?? {}
    return formatDateToString(new Date(spotTime as Date))
  }, [latestSpotPriceData])

  // Andrew's comment: In here, where we have latestSpotPriceData, we _should_ be able to replace the last element of the `builtData` with the data from latestSpotPrice.
  // because historicalSpotPriceData only includes an older spotPrice generated once per day.
  const builtData: DataPoint[] = useMemo(
    () =>
      historicalSpotPriceData
        ? buildDataHistoricalSpotPrices(historicalSpotPriceData, midpointPerDg)
        : [],
    [historicalSpotPriceData, midpointPerDg]
  )

  const calculateDecigrams = useCallback(
    (amount: number) =>
      amount > 0
        ? parseFloat((amount / Number(midpointPerDg) / 10).toFixed(1))
        : 0.1,
    [midpointPerDg]
  )

  const handleChangeWeightInput = (e: ChangeEvent<HTMLInputElement>) => {
    const weight = Number(e.target.value)

    if (Number(e.target.value) === weightSlider) {
      return
    }
    setWeight(weight, midpointPerDg)
  }

  const handleChangeAmountInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === amount) {
      return
    }
    const weight = calculateDecigrams(Number(e.target.value))
    setWeight(weight, midpointPerDg)
  }

  const handleOnBlurAmountInput = () => {
    const actualAmount = (
      calculateDecigrams(Number(amount)) *
      Number(midpointPerDg) *
      10
    )
      .toFixed(2)
      .toString()

    if (amount !== actualAmount) {
      setAmount(actualAmount)
      setError({
        message: `We've rounded $${amount} to $${actualAmount}`,
        error: false
      })
    }
  }

  const handleIncreaseWeight = useCallback(() => {
    if (!loading) {
      const weight = addPrecisely(weightSlider, step)
      setWeight(weight, midpointPerDg)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [midpointPerDg, amount, loading, step, weightSlider])

  const handleDecreaseWeight = useCallback(() => {
    if (!loading) {
      const weight =
        weightSlider > 0.1 ? minusPrecisely(weightSlider, step) : 0.1
      setWeight(weight, midpointPerDg)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [midpointPerDg, amount, loading, step, weightSlider])

  const goToReferralPage = useCallback(() => {
    history.push(referralsPath())
  }, [history])

  useEffect(() => {
    const header = document.getElementById('app-header')
    const productBullion = document.getElementById('button-wrapper')
    if (header) {
      setHeaderHeight(header.offsetHeight)
    }
    if (productBullion) {
      setProductBullionHeight(productBullion.offsetHeight)
    }

    window?.addEventListener('scroll', () => {
      const header = document.getElementById('app-header')
      const productBullion = document.getElementById('button-wrapper')
      const slideWrapper = document.getElementById('slide-wrapper')
      if (slideWrapper && header && productBullion) {
        if (
          slideWrapper.getBoundingClientRect().top >=
          Number(header.offsetHeight) + Number(productBullion.offsetHeight)
        ) {
          setHeaderHeight(header.offsetHeight)
          header.style.top = '0'
        } else {
          setHeaderHeight(0)
          header.style.top = `-${header.offsetHeight}px`
        }
      }
    })
  }, [])

  useEffect(() => {
    if (
      weightSlider &&
      !patternOneDigitsAfterComma.test(weightSlider.toString())
    ) {
      setError({
        message: 'Specify grams to buy to 1 decimal place',
        error: true
      })
    } else if (weightSlider > 500) {
      setError({
        message: 'You must provide a weight between 0.1g and 500g',
        error: true
      })
    } else {
      setError(undefined)
    }
    setStep(() => {
      if (weightSlider < 2) return 0.1
      else if (weightSlider >= 2 && weightSlider < 5) return 0.2
      else if (weightSlider >= 5 && weightSlider < 10) return 0.5
      return 1
    })
  }, [weightSlider])

  const sliderScalingFactor = useMemo(() => {
    if (product?.slidingScalingFactor) {
      return product.slidingScalingFactor
    }

    return 1
  }, [product])

  if (tradingPaused) {
    return <PauseTrading />
  }

  return (
    <DefaultLayout
      wrapperContent={{ mt: 0, mb: 2, px: 0 }}
      wrapperContainer={{ p: 0, position: 'relative' }}
      showKiaOra
      showFooter
      pageTitle='Goldie'
    >
      <PopUp />

      <Box
        className={classes.buyButtonWrapper}
        component='section'
        id='button-wrapper'
        top={isDesktopView ? 0 : headerHeight}
      >
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          paddingX={2}
        >
          <Box sx={{ height: '38px' }}>
            <Typography variant='body1' textTransform='uppercase'>
              {product?.name} BULLION
            </Typography>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <Typography>LIVE: </Typography>
              <CountUpPrice
                value={Number(midpointPerDg ?? 0) * 10}
                suffix='/gram'
                prefix='$'
                className={classes.livePrice}
              />
            </Box>
          </Box>

          <Box>
            {product?.tradable ? (
              <Button
                onClick={onSubmit}
                sx={{ borderRadius: '2rem', height: '38px', fontSize: '1rem' }}
              >
                Buy: ${formattedPrice(amount)}
              </Button>
            ) : (
              <Button
                disabled={true}
                sx={{ borderRadius: '2rem', height: '38px', fontSize: '1rem' }}
              >
                Coming Soon!
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Box height='100%' position='relative'>
        <Box
          position='sticky'
          top={
            isDesktopView
              ? productBullionHeight
              : productBullionHeight + headerHeight
          }
          left={0}
          right={0}
          bottom={0}
          overflow='hidden'
          // height={`calc(100vh - ${
          //   isDesktopView ? 0 : headerHeight
          // }px - ${productBullionHeight}px)`}
          height={`calc(100vh - ${isDesktopView ? 0 : headerHeight}px - ${
            productBullionHeight + (isDesktopView ? 60 : 60)
          }px)`}
          minHeight={280}
          display='flex'
          flexDirection='column'
          pt={0.25}
          sx={{
            transition: 'height 0.5s'
          }}
          gap={2}
        >
          <Box display='flex' flexDirection='column'>
            <Stack
              position='relative'
              justifyContent='center'
              alignItems='flex-end'
              //marginLeft={2}
              px={2}
              height={50}
              minHeight={50}
            >
              <Box height={1}>
                <Typography variant='body2' textAlign='right' color='error'>
                  {error?.message}
                </Typography>
              </Box>
              <InputNumber
                name='amount'
                value={amount}
                onChange={handleChangeAmountInput}
                onBlur={handleOnBlurAmountInput}
              />
              <InputNumber
                name='weight'
                value={weightSlider}
                onChange={handleChangeWeightInput}
              />
            </Stack>
          </Box>

          <Box
            display='flex'
            flexDirection='row'
            alignItems='flex-start'
            justifyContent='space-between'
            maxHeight='calc(100% - 70px - 110px)' // magical px values correspond to boxes above and below
            position='relative'
          >
            {product && (
              <img
                src={product?.image}
                alt='{product.name}-bullion'
                className={classes.productImage}
              />
            )}
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              position='absolute'
              top='0'
              right='0'
              bottom='0'
              mr={2}
              gap={2}
            >
              <Box onClick={handleIncreaseWeight} textAlign='center'>
                <KeyboardArrowUpIcon fontSize='large' color='primary' />
              </Box>
              <SliderMui
                aria-label='weight'
                id='weight'
                size='small'
                orientation='vertical'
                valueLabelDisplay='off'
                defaultValue={0}
                step={step}
                max={50 * sliderScalingFactor}
                sx={{ height: '100%' }}
                name='weight'
                value={weightSlider}
                onChange={(_event, value) => {
                  const e = {
                    target: { value: `${value || ''}` }
                  } as ChangeEvent<HTMLInputElement>

                  handleChangeWeightInput(e)
                }}
                className={classes.slider}
                disabled={loading}
              />
              <Box onClick={handleDecreaseWeight} textAlign='center'>
                <KeyboardArrowDownIcon fontSize='large' color='primary' />
              </Box>
            </Box>
          </Box>
          <Box
            display='flex'
            minHeight='95px'
            justifyContent='center'
            gap={1}
            alignItems='center'
            px={2}
            overflow='auto'
            mb={1}
          >
            {products &&
              products.map(p => {
                return (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    gap={1}
                    onClick={() => {
                      history.push(
                        tradePath({ routeParams: { productId: p.id } })
                      )
                    }}
                  >
                    <img
                      src={p?.image}
                      alt='{product.name}-bullion'
                      className={classes.productSwitcherImage}
                    />
                    <Box
                      className={
                        Number(p?.id) === productId
                          ? classes.activeDot
                          : classes.dot
                      }
                    />
                  </Box>
                )
              })}
          </Box>
          <Box display='flex' minHeight='0' flex='1'>
            {/*filler box -- fills up remaining free space*/}
          </Box>
        </Box>

        <Box
          sx={{
            background: 'transparent',
            backdropFilter: 'blur(20px)',
            borderTop: ({ palette }) => `1px solid ${palette.black.main}`
          }}
          mt={1}
          pt={1}
          id='slide-wrapper'
        >
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            px={2}
          >
            <Box>
              <Button
                variant='outlined'
                sx={{
                  borderRadius: '2rem',
                  padding: '0 0.5rem',
                  height: '38px'
                }}
                onClick={goToReferralPage}
              >
                <Typography variant='body1' color='primary'>
                  SHARE $20
                </Typography>
              </Button>
            </Box>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <Typography>CREDIT: </Typography>
              <CountUpPrice value={Number(user?.wallet?.balance)} prefix='$' />
            </Box>
          </Box>
          <Divider className={classes.divider} />
          <Stack height={300} mb={10} position='relative'>
            <Typography
              className={classes.aboutDesc}
              paddingLeft={2}
              textTransform='uppercase'
            >
              {product?.name} PERFORMANCE
            </Typography>
            <LoadingWrapper loading={historicalSpotPriceLoading}>
              <LineGraph
                data={builtData}
                graphPeriod={graphPeriod}
                beginAtZero={false}
                colors={{
                  line: theme.palette.black.main,
                  tooltipCircle: theme.palette.primary.main,
                  axis: theme.palette.black.main
                }}
                tickFormatter={(value: NumberValue) =>
                  `$${Number(value).toFixed(2)}`
                }
                showChangeValue={false}
                showXAxis
                loading={historicalSpotPriceLoading}
                showBackgroundColor={false}
                isCustomTooltip={false}
              />
            </LoadingWrapper>
          </Stack>
          <Stack>
            <GraphPeriod
              setGraphPeriod={setGraphPeriod}
              graphPeriod={graphPeriod}
            />
          </Stack>
          <Stack>
            <Typography
              textAlign='right'
              fontSize='0.75rem'
              textTransform='uppercase'
              px={1}
            >
              UPDATED {updatedTime.formattedHours}:
              {updatedTime.formattedMinutes} {updatedTime.meridiem}{' '}
              {updatedTime.formattedDate}
            </Typography>
          </Stack>
          <Stack
            px={2}
            my={2}
            borderTop={({ palette }) => `1px solid ${palette.black.main}`}
          >
            <Typography className={classes.aboutDesc} color='primary'>
              ABOUT
            </Typography>
            {product?.descriptions.map((description, index) => (
              <Typography
                className={classes.aboutDescSecond}
                key={index}
                fontSize={index === 0 ? 'x-large' : undefined}
              >
                {description}
              </Typography>
            ))}
          </Stack>

          <Stack
            px={2}
            borderTop={({ palette }) => `1px solid ${palette.black.main}`}
          >
            <Typography className={classes.aboutDesc}>LIVE LOCATION</Typography>
            <Location />
          </Stack>
        </Box>
      </Box>
    </DefaultLayout>
  )
}
