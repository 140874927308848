import { FormValues } from '.'

import { FormFieldProps } from 'components/formik/FormField'

type FormFieldDataObj = {
  [Property in keyof Omit<FormValues, 'comments'>]: FormFieldProps
}

export const formFieldData: FormFieldDataObj = {
  address: {
    label: 'Address',
    name: 'address',
    required: true
  },
  addressLine1: {
    label: 'addressLine1',
    name: 'addressLine1',
    required: true
  },
  addressLine2: {
    label: 'addressLine2',
    name: 'addressLine2',
    required: false
  },
  city: {
    label: 'city',
    name: 'city',
    required: true
  },
  suburb: {
    label: 'suburb',
    name: 'suburb',
    required: false
  },
  postcode: {
    label: 'postcode',
    name: 'postcode',
    required: true
  },
  dateOfBirth: {
    label: 'Date of birth',
    name: 'dateOfBirth',
    disabled: true,
    required: true
  },
  email: {
    label: 'Email',
    name: 'email',
    disabled: true,
    required: true
  },
  legalName: {
    label: 'Legal name',
    name: 'legalName',
    disabled: true,
    required: true
  },
  preferredName: {
    label: 'Preferred name',
    name: 'preferredName',
    required: false
  },
  nzMobilePhoneNumber: {
    label: 'NZ mobile phone number',
    name: 'nzMobilePhoneNumber',
    required: true,
    placeholder: 'e.g. 0211234567'
  },
  enterManually: {
    label: 'enterManually',
    name: 'enterManually',
    required: false
  }
}
