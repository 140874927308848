import { useEffect, useState } from 'react'

import {
  GetLatestSpotPriceQuery,
  useGetLatestSpotPriceQuery
} from 'generated/graphql'
import { useAuth } from 'components/AuthProvider'

export interface UseSpotPriceProps {
  callInterval?: number // milliseconds
  onCompleted?: (params: GetLatestSpotPriceQuery) => void
  productId: number
}

type UseSpotPriceReturnType = [
  () => void,
  {
    data: GetLatestSpotPriceQuery | undefined
    loading: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any
    getProgress: () => number
  }
]

export default function useSpotPrice(
  props: UseSpotPriceProps
): UseSpotPriceReturnType {
  const { callInterval, onCompleted, productId } = props || {}

  const { setToast } = useAuth()

  const [lastUpdated, setLastUpdated] = useState<number>(Date.now())

  const { data, loading, error, refetch } = useGetLatestSpotPriceQuery({
    variables: { productId },
    // TODO: error handling
    onError: error =>
      setToast({
        open: true,
        message: error.message,
        type: 'error',
        duration: 3000
      }),
    onCompleted
  })

  function getProgress(): number {
    if (callInterval === undefined) return 100

    const millisecondsEllapsed = Date.now() - lastUpdated
    return ((callInterval - millisecondsEllapsed) / callInterval) * 100
  }

  useEffect(() => {
    if (callInterval) {
      // The state variable `lastUpdated` can be used to calculate how much time is left until the
      // spot price API is called again, hopefully to update the spot price value - if it has changed.
      // What's happening? We start at "100%", and based on the elapsed time, we calculate the percentage remaining
      // this means after the callInterval has passed, we get to "0%", and the query is fetched again.
      const timer = setInterval(() => {
        setLastUpdated(Date.now())
        refetch()
      }, callInterval)

      return () => clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callInterval])

  return [
    refetch,
    {
      data,
      loading,
      error,
      getProgress
    }
  ]
}
