import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  loginBtn: {
    margin: theme.spacing(3.5, 0, 0),
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  checkbox: {
    margin: theme.spacing(0),
    '& span': {
      padding: theme.spacing(0),
      fontSize: '11px'
    },
    '& .MuiFormControlLabel-label': {
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(0.5)
    }
  },
  error: {
    color: '#ff0000'
  },
  forgotPw: {
    fontSize: '16px',
    display: 'block',
    textAlign: 'center',
    width: '100%',
    color: theme.palette.primary.main
  }
}))
