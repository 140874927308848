export default function Withdraw(): JSX.Element {
  return (
    <svg
      width='22'
      height='20'
      viewBox='0 0 22 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 6.50391H21'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4 10H6'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.5 10H12.5'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.56 16.4939C20.11 16.4939 21 15.6139 21 12.1039V5.89391C21 2.38391 20.11 1.50391 16.55 1.50391H5.44C1.89 1.50391 1 2.38391 1 5.89391V12.1139C1 15.6239 1.89 16.5039 5.44 16.4939'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 15V19L13 17'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 19L9 17'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
