import Decimal from 'decimal.js'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs'
import { Address } from 'react-addressfinder'
import { UserType } from 'components/AuthProvider'

export function camelCase(str: string): string {
  return str.replace(/([-_][a-z])/gi, ($1: string) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

export function formatAddress(address: Address): string {
  return [
    address.line1,
    address.line2,
    address.suburb,
    address.city,
    address.postcode
  ]
    .filter(Boolean)
    .join(', ')
}

export function formattedPrice(price: number | string): string {
  const parsedPrice: number =
    typeof price === 'string' ? parseFloat(price.replace(/,/g, '')) : price

  return parsedPrice
    ? new Decimal(Math.abs(parsedPrice))
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    : new Decimal(0).toFixed(2)
}

export function sentenceCase(str: string): string {
  // A naive implementation only for a single sentence. Only used for labels so this should be ok.
  const lc = str.toLowerCase()
  return (lc[0].toUpperCase() + lc.slice(1)).split('_').join(' ')
}

export function trimUnderscore(str: string): string {
  return str ? str.replace(/_/g, ' ').trim() : ''
}

export const API_DATE_FORMAT = 'YYYY-MM-DD'
export const UI_DATE_FORMAT = 'DD/MM/YYYY'

export function formatDate({
  date,
  inputFormat,
  outputFormat
}: {
  date: string
  inputFormat: typeof API_DATE_FORMAT | typeof UI_DATE_FORMAT
  outputFormat: typeof API_DATE_FORMAT | typeof UI_DATE_FORMAT
}): string {
  dayjs.extend(customParseFormat)
  return dayjs(date, inputFormat).format(outputFormat)
}

export function checkOneDecimalNumber(number: number): boolean {
  const decimalNumber = number.toString().split('.')[1]
  return decimalNumber ? number.toString().split('.')[1].length !== 1 : false
}

export const getCurrentDate = () => {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const yyyy = today.getFullYear()
  return dd + '/' + mm + '/' + yyyy
}

export const formatFieldToLabel = (field: string): string => {
  return field
    .split('_')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const convertUnderscoreFieldToCamelField = (field: string): string => {
  const upperCaseField = field
    .split('_')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('')
  return upperCaseField.charAt(0).toLowerCase() + upperCaseField.slice(1)
}

export const convertCamelCaseToWords = (word: string): string => {
  return word.split(/(?=[A-Z])/).join(' ')
}

export const checkTypeOfValue = (value: unknown) =>
  Object.prototype.toString.call(value).slice(8, -1).toLowerCase()

export const roundUpDollarsAndCents = (number: number): number => {
  return Math.ceil(number * 100) / 100
}

export const roundDownDollarsAndCents = (number: number): number => {
  return Math.floor(number * 100) / 100
}

export const roundUpDollars = (number: number): number => {
  return Math.ceil(number)
}

export const generateUsername = (user?: UserType) => {
  if (user?.profile?.preferredName && user?.profile?.preferredName !== '') {
    return user.profile.preferredName
  } else if (user?.profile?.firstName && user?.profile?.firstName !== '') {
    return user.profile.firstName
  } else {
    return 'customer'
  }
}

function stringToColor(string: string) {
  let hash = 0
  let i
  let color = '#'

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  return color
}

export function stringAvatar(name: string) {
  return name
    ? {
        sx: {
          bgcolor: stringToColor(name),
          width: '48px',
          height: '48px',
          fontSize: '24px',
          textTransform: 'uppercase'
        },
        children: `${name.split('')[0][0]}`
      }
    : {
        sx: {
          width: '48px',
          height: '48px'
        }
      }
}

export function formatDateToString(date: Date) {
  let hours: number = date.getHours()
  const minutes: number = date.getMinutes()
  const meridiem: string = hours >= 12 ? 'PM' : 'AM'

  // Add leading zeros if necessary
  const formattedDate: string = dayjs(date).format('DD MMM YYYY')
  hours = hours % 12
  hours = hours ? hours : 12 // Convert midnight (0) to 12 AM
  const formattedHours: string = hours.toString().padStart(2, '0')
  const formattedMinutes: string =
    minutes < 10 ? '0' + minutes : minutes.toString()

  return {
    formattedDate,
    formattedHours,
    formattedMinutes,
    meridiem
  }
}

export function addPrecisely(a: number, b: number): number {
  const decimalA = new Decimal(a)
  const decimalB = new Decimal(b)
  const sum = decimalA.plus(decimalB)
  return sum.toNumber()
}

export function minusPrecisely(a: number, b: number): number {
  const decimalA = new Decimal(a)
  const decimalB = new Decimal(b)
  const sum = decimalA.minus(decimalB)
  return sum.toNumber()
}
