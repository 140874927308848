import { gql } from '@apollo/client'

export const CREATE_REVIEW_ORDER_FOR_BUY = gql`
  mutation createReviewOrderForBuy($input: CreateReviewOrderForBuyInput!) {
    reviewOrder: createReviewOrderForBuy(input: $input) {
      id
      spotPrice
      totalFees
      totalPriceWithFees
      totalPriceWithoutFees
      userHasBalance
      user {
        id
        wallet {
          balance
          userId
        }
      }
      weight
    }
  }
`

export const CREATE_REVIEW_ORDER_FOR_SELL = gql`
  mutation createReviewOrderForSell($input: CreateReviewOrderForSellInput!) {
    reviewOrder: createReviewOrderForSell(input: $input) {
      allotmentsOwned
      id
      spotPrice
      totalFees
      totalPriceWithFees
      totalPriceWithoutFees
      userHasBalance
      weight
    }
  }
`
