import { Box, Grid, Typography } from '@mui/material'
import { useEffect } from 'react'
import QRCode from 'react-qr-code'
import { useHistory } from 'react-router-dom'

import { useAuthenticatorAppProvisioningUriLazyQuery } from 'generated/graphql'

import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import LoadingWrapper from 'components/layout/LoadingWrapper'
import { twoStepCodePath } from 'components/Routes'

export default function TwoStepScan(): JSX.Element {
  const { user, setToast } = useAuth()
  const history = useHistory()

  useEffect(() => {
    if (!user?.authenticatorAppEnabled) {
      getAuthenticatorAppProvisioningUri()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const [
    getAuthenticatorAppProvisioningUri,
    { data: authenticatorAppProvisioningUriData }
  ] = useAuthenticatorAppProvisioningUriLazyQuery({
    // TODO: Error handling
    onError: error =>
      setToast({
        open: true,
        message: error.message,
        type: 'error',
        duration: 3000
      })
  })

  function handleClick() {
    history.push(twoStepCodePath())
  }

  const { authenticatorAppProvisioningUri } =
    authenticatorAppProvisioningUriData || {}

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          align='left'
          variant='h2'
          fontSize='48px'
          textTransform='uppercase'
        >
          Scan this QR code
        </Typography>
      </Grid>
      <Grid item xs={12} mb={4}>
        <Typography align='left' fontSize='medium'>
          With your authenticator app, scan this QR code
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent='center'
        sx={{ marginTop: 6, marginBottom: 6 }}
      >
        <Box
          sx={{
            width: '256px',
            height: '256px',
            position: 'relative'
          }}
        >
          <LoadingWrapper loading={!authenticatorAppProvisioningUri} size={160}>
            {authenticatorAppProvisioningUri ? (
              <QRCode id='qrCode' value={authenticatorAppProvisioningUri} />
            ) : null}
          </LoadingWrapper>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ mt: 4, textAlign: 'center' }}>
        <Button color='primary' fullWidth onClick={handleClick}>
          Next
        </Button>
      </Grid>
    </Grid>
  )
}
