import { makeStyles } from '@mui/styles'
import { TextField, TextFieldProps } from 'formik-mui'

type InputProps = TextFieldProps

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: '3.5rem'
  }
}))

export default function Input(props: InputProps): JSX.Element {
  const classes = useStyles()

  return <TextField className={classes.root} {...props} />
}
