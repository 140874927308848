import { useHistory } from 'react-router-dom'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import {
  verifyFinancialDetailsPath,
  verifyGetStartedPath,
  verifyIdDetailsPath,
  verifyPersonalDetailsPath
} from 'components/Routes'

import { NavigateNextRounded as NavigateNextIcon } from '@mui/icons-material'
import { Breadcrumbs as MuiBreadcrumbs, Typography, Link } from '@mui/material'

import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > ol': {
      justifyContent: 'space-between'
    }
  },
  breadcrumb: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: '1.125rem',
    opacity: '0.4'
  },
  activatedBreadcrumb: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: '1.125rem'
  },
  heading: {
    textTransform: 'uppercase',
    margin: theme.spacing(4, 0, 0)
  }
}))

interface BreadcrumbsProps {
  step: number
  heading: string
}

export default function Breadcrumbs({
  step,
  heading
}: BreadcrumbsProps): JSX.Element {
  const history = useHistory()

  const customBreadcrumbs: BreadcrumbsRoute[] = [
    {
      path: verifyGetStartedPath(),
      breadcrumb: 'SignUp',
      props: {
        step: 0
      }
    },
    {
      path: verifyPersonalDetailsPath(),
      breadcrumb: 'Details',
      props: {
        step: 1
      }
    },
    {
      path: verifyIdDetailsPath(),
      breadcrumb: 'Verify',
      props: {
        step: 2
      }
    },
    {
      path: verifyFinancialDetailsPath(),
      breadcrumb: 'Profile',
      props: {
        step: 3
      }
    }
  ]

  const classes = useStyles()

  return (
    <>
      <MuiBreadcrumbs
        aria-label='breadcrumb'
        separator={<NavigateNextIcon fontSize='small' color='primary' />}
        className={classes.root}
      >
        {customBreadcrumbs.map(({ breadcrumb, path, props }) => {
          const activated = step >= Number(props?.step)

          return (
            <Link
              key={path}
              className={
                activated ? classes.activatedBreadcrumb : classes.breadcrumb
              }
              variant='body1'
              onClick={activated ? () => history.push(path) : () => null}
            >
              {breadcrumb}
            </Link>
          )
        })}
      </MuiBreadcrumbs>
      <Typography variant='h5' className={classes.heading}>
        {heading}
      </Typography>
    </>
  )
}
