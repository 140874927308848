import { SxProps } from '@mui/system'

import { Field } from 'formik'
import { TextField } from 'formik-mui'

export interface FormFieldProps {
  autoComplete?: HTMLInputElement['autocomplete']
  disabled?: boolean
  fullWidth?: boolean
  inputRef?: (inputElement: HTMLElement) => void | undefined
  label?: string
  name: string
  placeholder?: HTMLInputElement['placeholder']
  required?: HTMLInputElement['required']
  sx?: SxProps
  type?: HTMLInputElement['type']
  error?: boolean
  helperText?: string
  showOptionalText?: boolean
}

const buildLabel = (
  label: string,
  required: boolean,
  showOptionalText?: boolean
) => {
  return required
    ? `${label} *`
    : showOptionalText
    ? `${label} (optional)`
    : label
}

export default function FormField({
  autoComplete,
  disabled = false,
  fullWidth = true,
  inputRef,
  label,
  name,
  placeholder,
  required = false,
  sx,
  type = 'text',
  error,
  helperText,
  showOptionalText = true
}: FormFieldProps): JSX.Element {
  return (
    <Field
      autoComplete={autoComplete}
      component={TextField}
      disabled={disabled}
      fullWidth={fullWidth}
      id={name}
      inputRef={inputRef}
      label={label ? buildLabel(label, required, showOptionalText) : null}
      name={name}
      placeholder={placeholder}
      sx={sx}
      type={type}
      error={error}
      helperText={helperText}
    />
  )
}
