import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  Typography
} from '@mui/material'
import { Field, FormikErrors, FormikTouched, useFormikContext } from 'formik'
import { RadioGroup } from 'formik-mui'
import { AmlVerificationQuestion } from 'generated/graphql'
import { Fragment, useState } from 'react'
import theme from 'utils/theme'
import { FormValues } from '../OnboardingWizard'
import CommentFormField from './CommentFormField'
import { isOptionSelected } from './FinancialDetailsForm'
import FreeTextQuestion from './FreeTextQuestion'

interface QuestionProps extends AmlVerificationQuestion {
  values: FormValues
  errors: FormikErrors<FormValues>
  touched: FormikTouched<FormValues>
  questions: AmlVerificationQuestion[]
}

enum EmploymentStatus {
  Employed = 'Employed',
  SelfEmployed = 'Self-employed'
}

export default function SingleSelectQuestion({
  answerOptions,
  identifier,
  question,
  values,
  errors,
  touched,
  format,
  questions
}: QuestionProps) {
  const { isSubmitting } = useFormikContext()

  const freeTextQuestion = questions.find(
    question => question.format === 'free_text'
  )

  const [isOpenFreeText, setIsOpenFreeText] = useState<boolean>(
    !!freeTextQuestion?.answer?.answer
  )

  const handleOpenFreeText = (answer: string) => {
    // #Task 150: Update AML questions
    if (
      answer === EmploymentStatus.Employed ||
      answer === EmploymentStatus.SelfEmployed
    ) {
      setIsOpenFreeText(true)
    } else {
      setIsOpenFreeText(false)
    }
  }

  return format === 'single_select' ? (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <FormControl component='fieldset'>
          <FormLabel component='legend' sx={{ fontStyle: 'italic' }}>
            {question}
          </FormLabel>
          {/* TODO: check `aria-label` and `name` below are correct */}
          <Field
            aria-label={identifier}
            component={RadioGroup}
            name={identifier}
          >
            {answerOptions.map(option => {
              return (
                <Fragment key={option.id}>
                  <FormControlLabel
                    control={
                      <Radio
                        disabled={isSubmitting}
                        onClick={() => handleOpenFreeText(option.answer)}
                      />
                    }
                    value={option.id}
                    label={option.answer}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                  />
                  {option.commentsRequired &&
                  isOptionSelected(values, identifier, option.id) ? (
                    <CommentFormField
                      identifier={identifier}
                      optionId={option.id}
                      errors={errors}
                    />
                  ) : null}
                </Fragment>
              )
            })}
          </Field>
        </FormControl>
        {errors[identifier] && touched[identifier] && (
          <Typography
            style={{
              fontSize: '0.75rem',
              color: theme.palette.error.main,
              margin: theme.spacing(0.5, 0, 0, 1.75)
            }}
          >
            {errors[identifier]}
          </Typography>
        )}
      </Grid>
      {isOpenFreeText && freeTextQuestion && (
        <Grid item xs={12} mt={2}>
          <FormControl component='fieldset' fullWidth>
            <FormLabel component='legend' sx={{ fontStyle: 'italic' }}>
              {freeTextQuestion.question}
            </FormLabel>
            <FreeTextQuestion
              errors={errors}
              touched={touched}
              {...freeTextQuestion}
            />
          </FormControl>
        </Grid>
      )}
    </Grid>
  ) : (
    <></>
  )
}
