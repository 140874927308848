import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  Card,
  CardContent,
  Theme
} from '@mui/material'
import useStyles from './styles'
import CopyToClipboard from 'react-copy-to-clipboard'
import { ContentCopyRounded as CopyIcon } from '@mui/icons-material'
import { sentenceCase } from 'utils/util'
import { useAuth } from 'components/AuthProvider'
import { useState } from 'react'

interface DepositDetailMapping {
  helperText?: string | undefined
  id: string
  label: string
  value: string
}

export default function DepositDetail(): JSX.Element {
  const classes = useStyles()

  const { user } = useAuth()

  const [copiedText, setCopiedText] = useState<string>('')

  const DEPOSIT_DETAILS_MAPPINGS: DepositDetailMapping[] = [
    {
      id: 'payeeName',
      label: 'Payee name',
      value: 'Goldie Trustee'
    },
    {
      id: 'accountNumber',
      label: 'Account number',
      value: '02-0506-0315800-000'
    },
    {
      helperText:
        'Enter this in the <strong>Reference</strong> field of your internet banking, exactly as shown.',
      id: 'reference',
      label: 'Reference',
      value: user?.profile?.reference || ''
    }
  ]

  return (
    <Box>
      <Card
        sx={{
          my: 2,
          border: (theme: Theme) => `1px solid ${theme.palette.primary.main}`
        }}
      >
        <CardContent>
          <Typography className={classes.descriptionDeposit}>
            We will email you when the funds are deposited using the details
            below, so you can come bank and make the purchase.
          </Typography>
          <Typography className={classes.descriptionDeposit}>
            <span style={{ fontWeight: 700 }}>Note:</span> Prices may change
            while the transfer is processed.
          </Typography>
          {DEPOSIT_DETAILS_MAPPINGS.map(props => {
            const { id, label, value, helperText } = props

            return (
              <Box className={classes.depositDetailWrapper} key={id}>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Box>
                    <Typography
                      variant='h5'
                      className={classes.depositDetailLabelText}
                    >
                      {`${label}${helperText ? '*' : ''}`}
                    </Typography>
                    <Typography
                      variant='h6'
                      className={classes.depositDetailValueText}
                    >
                      {value}
                    </Typography>
                  </Box>
                  <CopyToClipboard
                    onCopy={() => setCopiedText(value)}
                    text={value}
                  >
                    <Tooltip
                      arrow
                      describeChild={false}
                      placement='top'
                      title={
                        copiedText === value ? 'Copied!' : 'Copy to clipboard'
                      }
                    >
                      <IconButton
                        aria-label={sentenceCase(`Copy ${label} to clipboard`)}
                      >
                        <CopyIcon color='primary' fontSize='small' />
                      </IconButton>
                    </Tooltip>
                  </CopyToClipboard>
                </Box>

                {helperText ? (
                  <Typography
                    component='p'
                    variant='body2'
                    color='primary'
                    dangerouslySetInnerHTML={{ __html: `*${helperText}` }}
                    sx={{ mt: 1.5 }}
                  />
                ) : null}
              </Box>
            )
          })}
        </CardContent>
      </Card>
    </Box>
  )
}
