import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { useAuth } from 'components/AuthProvider'
import { loginPath, resendConfirmationEmailPath } from 'components/Routes'

import { Grid, Link, Theme, Typography } from '@mui/material'
import Button from 'components/Button'
import DefaultLayout from 'components/layout/DefaultLayout'
import { Link as RouterLink } from 'react-router-dom'

import { makeStyles } from '@mui/styles'

import { Field, FieldProps, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  link: {
    color: theme.palette.primary.main
  }
}))

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Email is required')
})

export default function RequestResetPassword(): JSX.Element {
  const classes = useStyles()

  const { requestReset } = useAuth()

  const recaptchaEnabled = JSON.parse(
    process.env.REACT_APP_RECAPTCHA_ENABLED || 'true'
  )
  const { executeRecaptcha } = useGoogleReCaptcha()

  async function handleSubmit(values: { email: string }) {
    if (!executeRecaptcha && recaptchaEnabled) return

    const token =
      recaptchaEnabled &&
      executeRecaptcha &&
      (await executeRecaptcha('passwords/send_reset_instructions'))

    requestReset(values.email, token)
  }

  return (
    <DefaultLayout containerMaxWidth='sm' heading='Forgot password?'>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue }) => {
          return (
            <Form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field name='email'>
                    {({ field, form, meta }: FieldProps) => (
                      <TextField
                        field={field}
                        form={form}
                        meta={meta}
                        fullWidth
                        required
                        label='Email address'
                        onChange={e => {
                          setFieldValue('email', e.target.value)
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    color='primary'
                    variant='outlined'
                    fullWidth
                    type='submit'
                  >
                    Reset password
                  </Button>
                </Grid>

                <Grid item container justifyContent='space-around'>
                  <Link
                    color='textPrimary'
                    component={RouterLink}
                    to={loginPath()}
                    underline='always'
                    className={classes.link}
                  >
                    Go back to login
                  </Link>
                </Grid>
                <Grid item container justifyContent='space-around'>
                  <Typography align='center' variant='body1'>
                    <Link
                      color='textPrimary'
                      component={RouterLink}
                      to={resendConfirmationEmailPath()}
                      underline='always'
                      className={classes.link}
                    >
                      Resend confirmation email
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </DefaultLayout>
  )
}
