import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ShareIcon from '@mui/icons-material/Share'
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { useAuth } from 'components/AuthProvider'
import DefaultLayout from 'components/layout/DefaultLayout'
import useStyles from './styles'
import { useCallback } from 'react'

export default function ReferralCode(): JSX.Element {
  const classes = useStyles()

  const { user, setToast } = useAuth()

  const { referralCode } = user?.profile || {}

  const handleCopy = () => {
    setToast({
      message: 'Copied the referral code!',
      open: true,
      duration: 2000,
      type: 'success'
    })
    navigator.clipboard.writeText(referralCode ?? '')
  }

  const handleShare = () => {
    setToast({
      message: 'Copied the sign up link with referral code!',
      open: true,
      duration: 2000,
      type: 'success'
    })
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}/sign_up?referral_code=${referralCode}` ??
        ''
    )
  }

  const renderHeader = useCallback(() => {
    return (
      <Typography component='h1' variant='h1' fontSize='7.5rem' color='primary'>
        Referrals
      </Typography>
    )
  }, [])

  return (
    <DefaultLayout
      backgroundColor='white'
      wrapperContent={{ mt: 4, px: 3 }}
      heading={renderHeader()}
      showBackIcon
    >
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12} py={4}>
            <Typography variant='h2' color='primary.main'>
              Share $10 & get $10, once they’ve verified
            </Typography>
          </Grid>
          <Grid item xs={12} py={4}>
            <Typography variant='body1' color='primary.main'>
              Every Kiwi gets $5 credit when they sign-up but anyone who uses
              your code gets $10 instead. Plus you get $10 as a big thanks,
              because you’re helping Goldie’s community grow & create a better
              financial future for Aotearoa. It means a lot.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.label}>
              Your referral code - share the love ❤️
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <TextField
              fullWidth
              className={classes.input}
              value={referralCode ?? ''}
              focused={false}
              data-testid='referral_code'
              InputProps={{
                id: 'referral_code',
                endAdornment: (
                  <Tooltip title='Copy referral code' arrow>
                    <IconButton onClick={handleCopy}>
                      <ContentCopyIcon
                        fontSize='medium'
                        color='primary'
                        sx={{ cursor: 'pointer' }}
                        data-testid='copy-icon'
                      />
                    </IconButton>
                  </Tooltip>
                ),
                onKeyDown: event => event.preventDefault()
              }}
            />
          </Grid>

          <Grid
            item
            xs={1}
            display='grid'
            justifyContent='center'
            alignContent='center'
          >
            <Tooltip title='Share the sign up link' arrow>
              <IconButton onClick={handleShare}>
                <ShareIcon
                  fontSize='medium'
                  color='primary'
                  sx={{ cursor: 'pointer' }}
                  data-testid='share-icon'
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </DefaultLayout>
  )
}
