export default function Deposit(): JSX.Element {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='40' height='40' rx='20' fill='#FFD233' />
      <path
        d='M17 21.9422C17 23.0172 17.825 23.8839 18.85 23.8839H20.9417C21.8333 23.8839 22.5583 23.1255 22.5583 22.1922C22.5583 21.1755 22.1167 20.8172 21.4583 20.5839L18.1 19.4172C17.4417 19.1839 17 18.8255 17 17.8089C17 16.8755 17.725 16.1172 18.6167 16.1172H20.7083C21.7333 16.1172 22.5583 16.9839 22.5583 18.0589'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.7734 15V25'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.7581 13.08C24.9931 12.4434 24.1086 11.9239 23.122 11.5591C18.46 9.83509 13.2831 12.2168 11.5591 16.8788C9.83506 21.5408 12.2168 26.7177 16.8788 28.4417C21.5408 30.1657 26.7177 27.784 28.4417 23.122C28.8065 22.1354 28.9875 21.1257 29.0019 20.1306'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M23 14H27V10'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
