import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import fineGoldImage from 'images/Golden.png'
import silverImage from 'images/Silver.png'
import { alpha } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
  galleryItemLeft: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '2rem'
  },
  galleryItemRight: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '2rem',

    '& > p': {
      textAlign: 'right'
    }
  },

  bullionImg: {
    width: '30px'
  },

  headingText: {
    textTransform: 'uppercase',
    color: theme.palette.disabled.main
  },

  perShareText: {
    '& > p': {
      color: theme.palette.primary.main,

      '&:last-child': {
        fontSize: '0.5rem',
        textTransform: 'uppercase'
      }
    }
  },
  perShareValue: {
    color: theme.palette.primary.main
  },
  divider: {
    borderColor: theme.palette.primary.dark,
    margin: theme.spacing(1.5, 0)
  },

  btnMyVault: {
    position: 'fixed',
    borderRadius: '6px',
    border: 'none',
    fontSize: '1rem',
    zIndex: 99,
    maxWidth: 175,
    padding: '9px 10px',
    top: 10,
    right: 10,
    '&:hover, &:focus': {
      border: 'none'
    }
  },
  fineGoldImageContainer: {
    height: '50vh',
    backgroundImage: `url('${fineGoldImage}')`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% center',
    position: 'relative',
    margin: theme.spacing(8, 0),
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '75%',
      aspectRatio: '244/54',
      left: '50%',
      bottom: theme.spacing(-12),
      transform: 'translate(-50%, 0%)',
      background:
        'radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%)',
      [theme.breakpoints.between(650, 1367)]: {
        width: '450px'
      }
    }
  },
  silverImageContainer: {
    height: '50vh',
    backgroundImage: `url('${silverImage}')`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% center',
    position: 'relative',
    margin: theme.spacing(8, 0),

    '&::after': {
      content: '""',
      position: 'absolute',
      width: '75%',
      aspectRatio: '244/54',
      left: '50%',
      bottom: theme.spacing(-12),
      transform: 'translate(-50%, 0%)',
      background:
        'radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%)',
      [theme.breakpoints.between(650, 1367)]: {
        width: '450px'
      }
    }
  },
  tableTransition: {
    '& .MuiTableContainer-root': {
      border: 'none',
      '&::-webkit-scrollbar-track': {
        borderRadius: '10px',
        backgroundColor: alpha(theme.palette.primary.main, 0.1)
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        backgroundColor: alpha(theme.palette.primary.main, 0.2)
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.4)
      },
      '&::-webkit-scrollbar-thumb:active': {
        backgroundColor: alpha(theme.palette.primary.main, 0.9)
      }
    },
    '& thead > tr > th': {
      color: theme.palette.primary.main,
      fontSize: '1rem',
      background: 'transparent',
      borderColor: theme.palette.primary.main
    },
    '& tbody': {
      '& > tr > td': {
        color: theme.palette.primary.main,
        fontSize: '1rem',
        borderBottom: `1px solid ${theme.palette.primary.main}`
      },
      '& > tr:last-child > td': {
        borderColor: 'transparent'
      }
    }
  },

  gridValueItem: {
    flex: 1,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    gap: 6,
    height: '100%'
  },
  gridValueItem1: {
    boxShadow: `-1px 1px 3px ${theme.palette.primary.main}`,
    '& .MuiTypography-root': {
      fontSize: '0.8rem',
      color: theme.palette.primary.main
    }
  },
  gridValueItem2: {
    padding: theme.spacing(1, 0, 1, 1),
    // @ts-ignore
    boxShadow: `-1px 1px 3px ${theme.palette.bronze.main}`,
    '& .MuiTypography-root': {
      fontSize: '0.8rem', // @ts-ignore
      color: theme.palette.bronze.main
    }
  },
  gridValueItem3: {
    boxShadow: `1px -1px 3px ${theme.palette.primary.main}`
  },
  gridValueItem4: {
    boxShadow: `-1px 1px 3px ${theme.palette.primary.main}`
  },
  gridValueItem5: {
    boxShadow: `-1px -1px 3px ${theme.palette.primary.main}`
  },
  gridValueItem6: {
    boxShadow: `-1px -1px 3px ${theme.palette.primary.main}`
  }
}))
