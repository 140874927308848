import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import DefaultLayout from 'components/layout/DefaultLayout'
import { isAndroid, isMobileSafari } from 'react-device-detect'

import androidGif from 'images/android-install.gif'

const useStyles = makeStyles(() => ({
  installAndroidGif: {
    height: '60vh',
    backgroundImage: `url('${androidGif}')`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% center'
  }
}))

export default function InstallApp(): JSX.Element {
  const classes = useStyles()

  const deviceSpecificContent = () => {
    if (isAndroid) {
      return (
        <Box>
          <Typography>
            You can install our app to your homescreen by following these
            instructions:
          </Typography>
          <Box mt={2} className={classes.installAndroidGif} />
        </Box>
      )
    } else if (isMobileSafari) {
      return (
        <Box>
          <Typography>
            You can install our app to your homescreen by following these
          </Typography>
          <Typography
            component='a'
            href={
              'https://geekchamp.com/how-to-use-progressive-web-apps-on-iphone/'
            }
            sx={{
              textAlign: 'center',
              color: theme => theme.palette.primary.main,
              textDecoration: 'underline'
            }}
            target='_blank'
          >
            Instructions to install
          </Typography>
        </Box>
      )
    } else {
      return (
        <Box>
          <Typography>
            You can install our app to your home screen by following these
          </Typography>
          <Typography
            component='a'
            href={
              'https://mobilesyrup.com/2020/05/24/how-install-progressive-web-app-pwa-android-ios-pc-mac/'
            }
            sx={{
              textAlign: 'center',
              color: theme => theme.palette.primary.main,
              textDecoration: 'underline'
            }}
            target='_blank'
          >
            Instructions to install
          </Typography>
        </Box>
      )
    }
  }

  return (
    <DefaultLayout heading='Install App'>
      {deviceSpecificContent()}
    </DefaultLayout>
  )
}
