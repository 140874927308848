import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),

    '& a': {
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(0.5)
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  did_not_get_it: {
    color: theme.palette.primary.main
  }
}))
