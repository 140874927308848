/* eslint-disable @typescript-eslint/no-unused-vars */
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material'
import { styled } from '@mui/system'
import Button from 'components/Button'
import {
  namedOperations,
  useCreateCustomerPopupInteractionMutation,
  useCustomerPopupQuery
} from 'generated/graphql'
import catalistImage from 'images/catalist-goldie-collateral-3.png'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

interface PopUpTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    margin: theme.spacing(1)
  }
}))

function PopUpTitle(props: PopUpTitleProps) {
  const { onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          padding: 0,
          position: 'absolute',
          right: 4,
          top: 4,
          color: theme => theme.palette.black.main
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  )
}

export default function PopUp(): JSX.Element | null {
  // *******
  // NOTE!!: This has been canabalized to support ensure we meet our legal requirements with catalist https://trello.com/c/Dp4qfQzU/597-ensure-that-all-users-visit-the-catalist-website
  // *******

  const history = useHistory()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState<boolean>(true)

  const [createCustomerPopupInteraction] =
    useCreateCustomerPopupInteractionMutation()

  const { data } = useCustomerPopupQuery()

  const { bodyText, buttonLink, buttonText, id, title } =
    data?.customerPopup[0] ?? {}

  function onCreateCustomerPopupInteraction(buttonLink?: string) {
    createCustomerPopupInteraction({
      variables: {
        input: {
          popupId: Number(id)
        }
      },
      refetchQueries: [namedOperations.Query.customerPopup],
      awaitRefetchQueries: true,
      onCompleted: () => {
        /*
        if (buttonLink) {
          if (buttonLink.match(/^http/)) {
            window.open(buttonLink, '_blank')
          } else {
            // otherwise, must be an internal link
            history.push(buttonLink)
          }
        }
        */
        return true
      }
    })
  }

  const handleClose = () => {
    setOpen(false)
    // Because we want everyone to have visited catalist, we will still allow
    // users to click 'x' but because we don't create an interaction, they will
    // get prompted again next visit.

    onCreateCustomerPopupInteraction()
  }

  const handleClickRefer = () => {
    onCreateCustomerPopupInteraction(buttonLink)
  }

  // *******
  // NOTE!!: This has been canabalized to support ensure we meet our legal requirements with catalist https://trello.com/c/Dp4qfQzU/597-ensure-that-all-users-visit-the-catalist-website
  // *******

  return data?.customerPopup[0] ? (
    <BootstrapDialog open={open} disableScrollLock>
      <PopUpTitle id='popup-title' onClose={handleClose} />
      <DialogContent
        sx={{
          p: 1,
          '&::-webkit-scrollbar': {
            width: 0,
            height: 0
          }
        }}
      >
        <img
          src={catalistImage}
          alt='goldie - catalist collab'
          width='100%'
          loading='lazy'
        />
        <Typography gutterBottom textAlign='center' variant='h6' component='h2'>
          {title}
        </Typography>

        <Typography gutterBottom textAlign='center' fontWeight={400}>
          {bodyText}
        </Typography>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'center' }}>
        {/*
        <Button
          color='primary'
          variant='outlined'
          
          onClick={handleClickRefer}
        >
          {buttonText}
        </Button>
        */}
        <Typography
          component='a'
          href={buttonLink}
          sx={{
            textAlign: 'center',
            fontSize: '1.5rem',
            color: theme => theme.palette.primary.main,
            textDecoration: 'underline'
          }}
          target='_blank'
          onClick={handleClickRefer}
        >
          {buttonText}
        </Typography>
      </DialogActions>
    </BootstrapDialog>
  ) : null
}
