import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  checkbox: {
    margin: theme.spacing(0.5, 0),
    alignItems: 'flex-start',
    '& span': {
      padding: theme.spacing(0)
    },
    '& p': {
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(0.25)
    }
  },
  matchEmail: {
    padding: `${theme.spacing(0, 3)} !important`,

    '& > span': {
      color: theme.palette.success.main,
      fontSize: '0.75rem'
    }
  },
  haveAccount: {
    display: 'block',
    textAlign: 'center',
    width: '100%',
    color: theme.palette.primary.main
  },
  heading: {
    color: theme.palette.primary.main,
    fontFamily: 'Moore',
    fontSize: '280px',
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: 0.75,
    whiteSpace: 'nowrap',

    [theme.breakpoints.down('sm')]: {
      fontSize: '200px'
    }
  }
}))
