import { FormEvent, useEffect, useState } from 'react'

import { namedOperations, useVerifyOtpMutation } from 'generated/graphql'
import { useAuth } from 'components/AuthProvider'

import Button from 'components/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import InfoIcon from '@mui/icons-material/InfoRounded'
import Link from '@mui/material/Link'
import VerificationInput from 'components/VerificationInput'
import { Box } from '@mui/material'

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  expirationIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1)
  },
  resendWrapper: {
    margin: theme.spacing(2, 0)
  },
  resendLink: {
    verticalAlign: 'top',
    '&[disabled]': {
      color: theme.palette.text.secondary,
      cursor: 'default',
      '&:hover': {
        textDecoration: 'none'
      }
    }
  },
  resendProgress: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'middle'
  },
  verificationInputContainer: {
    width: '100% !important'
  },
  verificationInputCharacter: {
    display: 'inline-block',
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: '8px',
    fontSize: '40px',
    height: '1.1876em !important',
    lineHeight: '0px',
    padding: theme.spacing(4, 2),

    '&:not(:first-child)': {
      marginLeft: theme.spacing(1.5)
    }
  },
  verificationInputCharacterSelected: {
    borderColor: theme.palette.blue.main,
    borderWidth: '2px'
  },
  submit: {
    margin: theme.spacing(2, 2, 2)
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '12px',
    fontWeight: 400,
    margin: theme.spacing(0.5, 1.75, 0)
  }
}))

export interface AuthModalProps {
  isOpen: boolean
  onClose: () => void
}

export default function AuthModal({
  isOpen,
  onClose
}: AuthModalProps): JSX.Element {
  const classes = useStyles()

  const [progress, setProgress] = useState<number>(100)
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const { user, generateOtp } = useAuth()

  const [verifyOtp, { loading: verifyOtpLoading }] = useVerifyOtpMutation({
    // TODO: error handling
    onError: error => setError(error.message),
    onCompleted: () => onClose(),
    // Refetches the current user so that the `otpVerified` field updates
    refetchQueries: [namedOperations.Query.getCurrentUser]
  })

  useEffect(() => {
    if (isResendDisabled) {
      // The state variable `progress` can be used to indicate how much time
      // is left until the 'Resend code' button can be clicked again. What's happening?
      // We start at "100%", and every 500ms, we subtract the value of 100 / (30000 * 2),
      // this means after 30000ms has passed, we get to "0%", and the query is fetched again.
      const timer = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress >= 0) {
            return prevProgress - 100 / ((30000 / 1000) * 2)
          } else {
            setIsResendDisabled(false)
            return 100
          }
        })
      }, 500)

      return () => clearInterval(timer)
    }

    if (isOpen) {
      setError('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResendDisabled, isOpen])

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const otp = formData.get('otp') as string

    verifyOtp({ variables: { input: { otp } } })
  }

  const handleClickResend = () => {
    setIsResendDisabled(true)
    generateOtp()
  }

  const { authenticatorAppEnabled } = user || {}

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      maxWidth='sm'
    >
      <form noValidate onSubmit={handleSubmit}>
        <DialogTitle id='form-dialog-title'>Verify your identity</DialogTitle>
        <DialogContent sx={{ paddingBottom: 0 }}>
          <DialogContentText gutterBottom variant='body1'>
            {authenticatorAppEnabled
              ? 'Please enter the code from your authenticator app below to continue.'
              : 'A verification code has been sent to your email address. Check your inbox for an email from noreply@getgoldie.co.nz and enter the code below to continue.'}
          </DialogContentText>
          {!authenticatorAppEnabled ? (
            <>
              <DialogContentText gutterBottom variant='body1'>
                If you can't find the email, try checking your spam or junk
                folder.
              </DialogContentText>
              <DialogContentText gutterBottom variant='body1'>
                <InfoIcon
                  fontSize='medium'
                  sx={{ mr: 1, verticalAlign: 'bottom' }}
                />
                Your verification code will expire after 3 minutes.
              </DialogContentText>
              <div className={classes.resendWrapper}>
                <Link
                  className={classes.resendLink}
                  color='textPrimary'
                  component='button'
                  disabled={isResendDisabled}
                  onClick={handleClickResend}
                  type='button'
                  variant='body1'
                  underline='hover'
                >
                  Resend code
                </Link>
                {isResendDisabled ? (
                  <CircularProgress
                    size={20}
                    className={classes.resendProgress}
                    variant='determinate'
                    value={progress}
                  />
                ) : null}
              </div>
            </>
          ) : null}
          <VerificationInput onChange={() => setError('')} />
          {error && <Box className={classes.error}>{error}</Box>}
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.submit}
            variant='outlined'
            fullWidth
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className={classes.submit}
            color='primary'
            disabled={verifyOtpLoading}
            fullWidth
            type='submit'
          >
            Verify
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
