import { Box, Container, CssBaseline } from '@mui/material'
import { ReactNode } from 'react'
import DefaultLayout from '../DefaultLayout'
import { BaseLayoutProps } from '../BaseLayout'

import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import { styled } from '@mui/material/styles'

import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector'

import useStyles from './styles'
export interface Props {
  children: ReactNode
  currentStep: Step
}

export interface Step {
  subtitle?: string
  header: string
  path: string
  step: number
}

export function StepLayout({ children, currentStep }: Props): JSX.Element {
  const classes = useStyles()

  const steps = [
    {
      label: 'Download app',
      step: 1,
      active: currentStep.step >= 1,
      completed: currentStep.step > 1
    },

    {
      label: 'Scan this QR code',
      step: 2,
      active: currentStep.step >= 2,
      completed: currentStep.step > 2
    },
    {
      label: 'Enter your code',
      step: 3,
      active: currentStep.step >= 3,
      completed: currentStep.step > 3
    }
  ]

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: theme.palette.primary.main
      }
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: theme.palette.primary.main
      }
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: '#EAEAEA',
      borderRadius: 1
    }
  }))

  return (
    <Container component='main' maxWidth='xs' className={classes.container}>
      <CssBaseline />
      <Box component='section'>
        <Box width='100%' mb={4}>
          <Stepper
            alternativeLabel
            connector={<ColorlibConnector />}
            activeStep={currentStep.step}
          >
            {steps.map(step => (
              <Step
                key={step.step}
                completed={step.completed}
                active={step.active}
                id={`step-${step.step}`}
              >
                <StepLabel className={classes.stepLabel}>
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {children}
      </Box>
    </Container>
  )
}

export default function ({
  children,
  ...rest
}: Props & BaseLayoutProps): JSX.Element {
  return (
    <DefaultLayout {...rest} wrapperContent={{ px: 3 }}>
      <StepLayout {...rest} children={children} />
    </DefaultLayout>
  )
}
