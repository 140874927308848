import { ElementType, useEffect } from 'react'

import { loginPath } from 'components/Routes'
import { useAuth } from 'components/AuthProvider'

import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps
} from 'react-router-dom'

export interface AuthenticatedRouteProps extends Omit<RouteProps, 'component'> {
  component: ElementType
}

const AuthenticatedRoute = ({
  component: Component,
  ...rest
}: AuthenticatedRouteProps): JSX.Element => {
  const { user } = useAuth()

  useEffect(() => {
    if (document.body.classList.contains('hide-grecaptcha') === false) {
      document.body.classList.add('hide-grecaptcha')
    }
  }, [])

  const renderRoute = (props: RouteComponentProps) => {
    return user ? <Component {...props} /> : <Redirect to={loginPath()} />
  }

  return <Route {...rest} render={renderRoute} />
}

export default AuthenticatedRoute
