export default function Sell(): JSX.Element {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='40' height='40' rx='20' fill='#6FBE73' />
      <path
        d='M17.5 21.7483C17.5 22.7183 18.25 23.4983 19.17 23.4983H21.05C21.85 23.4983 22.5 22.8183 22.5 21.9683C22.5 21.0583 22.1 20.7283 21.51 20.5183L18.5 19.4683C17.91 19.2583 17.51 18.9383 17.51 18.0183C17.51 17.1783 18.16 16.4883 18.96 16.4883H20.84C21.76 16.4883 22.51 17.2683 22.51 18.2383'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 15.5V24.5'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30 20C30 25.52 25.52 30 20 30C14.48 30 10 25.52 10 20C10 14.48 14.48 10 20 10'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30 14V10H26'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25 15L30 10'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
