/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  Plugin,
  PointElement,
  ScriptableContext,
  Title,
  Tooltip
} from 'chart.js'
import { useMemo } from 'react'
import { Line } from 'react-chartjs-2'

import type { ChartArea, ChartData, ChartOptions } from 'chart.js'
import { AnyObject } from 'chart.js/types/basic'

import { Box, Typography } from '@mui/material'
import { NumberValue } from 'd3'
import dayjs from 'dayjs'
import theme from 'utils/theme'
import {
  beforeDrawLine,
  defaultTickFormatter,
  externalTooltipHandler,
  getRandomDefaultData
} from './utils'
import { DataPoint } from './types'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

export type GraphPeriodType = '1w' | '1m' | '3m' | '1y' | '5y' | '20y'

interface ColorProps {
  line: string
  tooltipCircle: string
  axis?: string
}

interface LineGraphProps {
  data: DataPoint[]
  showYAxis?: boolean
  showXAxis?: boolean
  tickFormatter?: (value: NumberValue) => string
  graphPeriod: GraphPeriodType
  colors: ColorProps
  beginAtZero?: boolean
  showChangeValue?: boolean
  loading?: boolean
  showBackgroundColor?: boolean
  showPointAxis?: boolean
  isCustomTooltip?: boolean
  type?: 'historicalPerformance' | 'historicalSpotPrices'
  myVaultGraph?: boolean
}

function getXAxisTickLimit(graphPeriod: GraphPeriodType): number {
  switch (graphPeriod) {
    case '1w':
      return 7
    case '1m':
      return 10
    case '3m':
      return 9
    default:
      return 12
  }
}

let width: number, height: number, gradient: CanvasGradient
function getGradient(
  ctx: CanvasRenderingContext2D,
  chartArea: ChartArea,
  color: string
) {
  const chartWidth = chartArea.right - chartArea.left
  const chartHeight = chartArea.bottom - chartArea.top
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    gradient = ctx.createLinearGradient(chartArea.top, 0, chartArea.bottom, 0)
    gradient.addColorStop(0, theme.palette.white.main)
    gradient.addColorStop(1, color)
  }

  return gradient
}

export default function LineGraph({
  data: dataPoint,
  graphPeriod,
  showYAxis = true,
  showXAxis = false,
  tickFormatter = defaultTickFormatter,
  colors: { line, tooltipCircle, axis = 'black' },
  beginAtZero = true,
  showChangeValue = true,
  loading,
  showBackgroundColor = true,
  showPointAxis = true,
  type,
  myVaultGraph
}: LineGraphProps) {
  const data: ChartData<'line'> = useMemo(() => {
    let labels: string[] = Array.from(
      new Array(getRandomDefaultData(graphPeriod))
    ).map(() => new Date().toLocaleDateString())

    let datasets: number[] = Array.from(
      new Array(getRandomDefaultData(graphPeriod))
    ).map(() => 0)

    if (dataPoint.length) {
      labels = dataPoint.map(v => dayjs(v.date).format('DD MMM YYYY'))
      datasets = dataPoint.map(v => v.value)
    }

    return {
      labels,
      datasets: [
        {
          label: '',
          data: datasets,
          borderColor: function (context) {
            const chart = context.chart
            const { ctx, chartArea } = chart

            if (!chartArea) {
              // This case happens on initial chart load
              return
            }
            return getGradient(ctx, chartArea, line)
          },
          tension: 0,
          fill: 'start',
          backgroundColor: (context: ScriptableContext<'line'>) => {
            const ctx = context.chart.ctx
            const gradient = ctx.createLinearGradient(0, 0, 0, 240)
            gradient.addColorStop(0, 'rgba(169, 114, 255, 0.5)')
            gradient.addColorStop(1, 'rgba(209, 178, 255, 0)')
            return showBackgroundColor ? gradient : 'transparent'
          },
          segment: {
            borderWidth: 1
          }
        }
      ]
    }
  }, [graphPeriod, dataPoint, line, showBackgroundColor])

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,

    interaction: {
      intersect: false,
      mode: 'index'
    },
    hover: {
      intersect: false,
      mode: 'index'
    },
    plugins: {
      filler: {
        propagate: false
      },
      legend: {
        display: false
      },
      title: {
        display: false
      },
      tooltip: {
        enabled: false,
        external: args =>
          // @ts-ignore
          externalTooltipHandler(args, dataPoint, showChangeValue)
      }
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          callback: function (value) {
            const labelForValue = this.getLabelForValue(value as number)
            let label = ''
            switch (graphPeriod) {
              case '1w':
              case '1m':
              case '3m':
                label =
                  labelForValue.split(' ')[0] +
                  '/' +
                  (new Date(labelForValue).getMonth() + 1)

                break
              case '1y':
                label = labelForValue.split(' ')[1]
                break
              case '5y':
              case '20y':
                label = labelForValue.split(' ')[2]
                break
              default:
                break
            }

            return label
          },
          // maxTicksLimit: getXAxisTickLimit(graphPeriod),
          maxRotation: 0,
          color: 'transparent'
        }
      },
      y: {
        display: showYAxis,
        // position: 'right',
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          callback: function (value) {
            return tickFormatter(Number(value))
          },
          count: dataPoint.length <= 2 ? 2 : 3,
          color: axis,
          font: {
            size: 12
          },
          mirror: true,
          padding: 2,
          labelOffset: 0,
          z: 1
        },
        beginAtZero: beginAtZero
      }
    },
    elements: {
      point: {
        radius: 0,
        hoverBackgroundColor: theme.palette.primary.main,
        hoverBorderColor: tooltipCircle,
        hoverRadius: 6,
        hoverBorderWidth: 1
      }
    }
  }

  const plugins: Plugin<'line', AnyObject>[] = [
    {
      id: 'annotationLine',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      beforeDraw: showPointAxis ? beforeDrawLine : () => {}
    }
  ]

  const XAxisData = useMemo(() => {
    let divisor = 1
    switch (graphPeriod) {
      case '1w':
        divisor = 1
        break
      case '1m':
        divisor = 7
        break
      case '3m':
        divisor = 15
        break
      case '1y':
        divisor = 50
        break
      case '5y':
        divisor = 365
        break
      default:
        divisor = 912
        break
    }

    return dataPoint.filter((d, i) => {
      if (i % divisor === 0) return d.date
    })
  }, [dataPoint, graphPeriod])

  const renderXAxis = (date: Date) => {
    switch (graphPeriod) {
      case '1w':
      case '1m':
      case '3m':
      case '1y':
        return dayjs(date).format('DD MMM').toUpperCase()
      case '5y':
        return date.getFullYear()
      default:
        return date.getFullYear()
    }
  }

  return (
    <>
      <Line
        options={options}
        data={data}
        plugins={plugins}
        // style={{ cursor: 'pointer', width: '100%', marginTop: '2rem' }}
        style={{ cursor: 'pointer', width: '100%', marginTop: '2rem' }}
      />
      {showXAxis && !loading && (
        <Box mt='-1rem'>
          <Box
            display='flex'
            justifyContent='space-between'
            px={myVaultGraph ? 2 : 0}
          >
            {Array.from(new Array(90)).map((_item, index) => (
              <Box
                key={index}
                style={{
                  background: theme.palette.black.main,
                  borderLeft: `1px solid ${theme.palette.text.primary}`,
                  height: '0.75rem'
                }}
              />
            ))}
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            px={myVaultGraph ? 2 : 0}
          >
            {XAxisData.map(d => {
              return (
                <Box
                  key={d.date.getTime()}
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <Typography
                    style={{
                      fontSize: '12px',
                      color: theme.palette.black.main
                    }}
                  >
                    {renderXAxis(d.date)}
                  </Typography>
                </Box>
              )
            })}
          </Box>
        </Box>
      )}
    </>
  )
}
