import Button from 'components/Button'
import DefaultLayout from 'components/layout/DefaultLayout'
import Typography from '@mui/material/Typography'

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  retryButton: {
    margin: theme.spacing(4, 0, 2)
  }
}))

export interface ErrorFallbackProps {
  clearError: () => void
}

export default function ErrorFallback({
  clearError
}: ErrorFallbackProps): JSX.Element {
  const classes = useStyles()

  function handleRetry() {
    clearError()
  }

  return (
    <DefaultLayout backgroundColor='default' heading='Oops!'>
      <div className={classes.container}>
        <Typography component='h2' variant='h5'>
          Something went horribly wrong...
        </Typography>
        <Button
          className={classes.retryButton}
          color='blue'
          fullWidth
          onClick={handleRetry}
        >
          Retry
        </Button>
      </div>
    </DefaultLayout>
  )
}
