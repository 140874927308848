import RedeemIcon from '@mui/icons-material/Redeem'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import DefaultLayout from 'components/layout/DefaultLayout'
import { redeemVoucherPath, voucherFlowPath } from 'components/Routes/Routes'
import { useHistory } from 'react-router-dom'
import useStyles from './styles'

export default function Gifts(): JSX.Element {
  const classes = useStyles()
  const history = useHistory()

  return (
    <DefaultLayout backgroundColor='white' wrapperContent={{ mt: 0, mb: 2 }}>
      <Grid container rowSpacing={2} py={4}>
        <Grid item xs={12}>
          <Box
            className={classes.fancyButton}
            onClick={() => history.push(voucherFlowPath())}
          >
            <Box>{<ShoppingCartIcon />}</Box>
            <Typography variant='h4'>Buy Gift</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            className={classes.fancyButton}
            onClick={() => history.push(redeemVoucherPath())}
          >
            <Box>{<RedeemIcon />}</Box>
            <Typography variant='h4'>Redeem Gift</Typography>
          </Box>
        </Grid>
      </Grid>
    </DefaultLayout>
  )
}
