import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import { verifyingPath } from 'components/Routes'
import TextFieldCustom from 'components/TextFieldCustom'
import DefaultLayout from 'components/layout/DefaultLayout'
import { ETHNICITIES } from 'constants/index'
import dayjs from 'dayjs'
import { FormikErrors, useFormik } from 'formik'
import {
  GetProfileForCurrentUserQuery,
  Maybe,
  UpdateProfileForOnboardingInput,
  useGetIdentityVerificationStatusQuery,
  useGetProfileForCurrentUserQuery,
  useRequestIdentityVerificationMutation,
  useUpdateProfileForOnboardingMutation // TODO ensure that this screen is updated to handle passports too
} from 'generated/graphql'
import * as _ from 'lodash'
import { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  API_DATE_FORMAT,
  UI_DATE_FORMAT,
  formatDate,
  trimUnderscore
} from 'utils/util'
import * as Yup from 'yup'
import { formFieldData } from '../formFieldData'
import formValidation from '../formValidation'
import { getNotes } from './utils'
export type VerificationErrorProps = RouteComponentProps

interface FormValues {
  firstName: string
  middleName: string
  lastName: string
  dateOfBirth: string
  addressLine1: string
  addressLine2: string
  suburb: string
  city: string
  postcode: string
  driversLicenceNumber?: string
  driversLicenceVersion?: string
  passportExpiry?: string
  passportNumber?: string
  verificationMethod?: 'passport' | 'drivers_licence' | 'other'
  ethnicity?: string
}

interface CopiedFormFieldsState {
  firstName: boolean
  middleName: boolean
  lastName: boolean
  dateOfBirth: boolean
  addressLine1: boolean
  addressLine2: boolean
  suburb: boolean
  city: boolean
  postcode: boolean
  driversLicenceNumber?: boolean
  driversLicenceVersion?: boolean
  passportExpiry?: boolean
  passportNumber?: boolean
  verificationMethod?: boolean
  ethnicity?: boolean
}

interface NoteType {
  date_of_birth: boolean
  first_name: boolean
  last_name: boolean
  license: boolean
  passport: boolean
}

const initialValues: FormValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  addressLine1: '',
  addressLine2: '',
  suburb: '',
  city: '',
  postcode: '',
  driversLicenceNumber: '',
  driversLicenceVersion: '',
  passportExpiry: '',
  passportNumber: '',
  verificationMethod: 'drivers_licence',
  ethnicity: ''
}

const INITIAL_COPIED_FORM_FIELDS = {
  firstName: false,
  middleName: false,
  lastName: false,
  dateOfBirth: false,
  addressLine1: false,
  addressLine2: false,
  suburb: false,
  city: false,
  postcode: false,
  driversLicenceNumber: false,
  driversLicenceVersion: false,
  passportExpiry: false,
  passportNumber: false,
  verificationMethod: false,
  ethnicity: false
}

const validationSchema = Yup.object().shape({
  firstName: formValidation.firstName,
  middleName: formValidation.middleName,
  lastName: formValidation.lastName,
  dateOfBirth: formValidation.dateOfBirth,
  addressLine1: formValidation.addressLine1,
  addressLine2: formValidation.addressLine2,
  suburb: formValidation.suburb,
  city: formValidation.city,
  postcode: formValidation.postcode,
  driversLicenceNumber: formValidation.driversLicenceNumber,
  driversLicenceVersion: formValidation.driversLicenceVersion,
  passportExpiry: formValidation.passportExpiry,
  passportNumber: formValidation.passportNumber,
  ethnicity: formValidation.ethnicity
})

export default function VerificationError({
  history
}: Readonly<VerificationErrorProps>): JSX.Element {
  const { setToast } = useAuth()

  const [copiedFormFields, setCopiedFormFields] =
    useState<CopiedFormFieldsState>(INITIAL_COPIED_FORM_FIELDS)

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    setValues,
    setErrors,
    errors,
    values,
    dirty,
    isValid
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: FormValues) => {
      const {
        driversLicenceNumber,
        driversLicenceVersion,
        passportExpiry,
        passportNumber,
        dateOfBirth,
        ...rest
      } = values

      const input: UpdateProfileForOnboardingInput = {
        ...rest,
        dateOfBirth: formatDate({
          date: dateOfBirth,
          inputFormat: UI_DATE_FORMAT,
          outputFormat: API_DATE_FORMAT
        }),
        verificationData:
          values.verificationMethod === 'drivers_licence'
            ? {
                driversLicenceNumber,
                driversLicenceVersion
              }
            : {
                passportExpiry,
                passportNumber
              }
      }
      updateProfile({ variables: { input } })
    }
  })

  const { data: statusData, loading: statusLoading } =
    useGetIdentityVerificationStatusQuery({
      onError: error =>
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        })
    })

  const { identityVerificationFailureReasons: failureReasons } =
    statusData?.identityVerificationStatus || {}

  async function setFormValue(
    profileForCurrentUserData: GetProfileForCurrentUserQuery,
    formErrors: FormikErrors<FormValues>
  ): Promise<Partial<FormValues>> {
    let { profileForCurrentUser } = profileForCurrentUserData ?? {}
    let values: Partial<FormValues> = {}

    if (profileForCurrentUser.verificationMethod === 'drivers_licence') {
      profileForCurrentUser = _.omit(profileForCurrentUser, [
        'passportExpiry',
        'passportNumber'
      ])
      values = _.omit(initialValues, ['passportExpiry', 'passportNumber'])
    } else {
      profileForCurrentUser = _.omit(profileForCurrentUser, [
        'driversLicenceNumber',
        'driversLicenceVersion'
      ])
      values = _.omit(initialValues, [
        'driversLicenceNumber',
        'driversLicenceVersion'
      ])
    }

    if (profileForCurrentUser) {
      for (const key in profileForCurrentUser) {
        if (
          !formErrors[key as keyof FormValues] &&
          initialValues.hasOwnProperty(key) &&
          profileForCurrentUser[key as keyof FormValues]
        ) {
          if (key === 'dateOfBirth') {
            const date = formatDate({
              date: dayjs(
                profileForCurrentUser[key as keyof FormValues]
              ).format(UI_DATE_FORMAT),
              inputFormat: UI_DATE_FORMAT,
              outputFormat: UI_DATE_FORMAT
            })
            values[key] = date
          } else {
            values[key as keyof FormValues] =
              profileForCurrentUser[key as keyof FormValues]
          }
        }
      }
    }

    return values
  }

  const {
    data: profileForCurrentUserData,
    loading: profileForCurrentUserLoading
  } = useGetProfileForCurrentUserQuery({
    onError: error =>
      setToast({
        open: true,
        message: error.message,
        type: 'error',
        duration: 3000
      }),
    skip: statusLoading
  })

  const {
    addressLine1,
    addressLine2,
    city,
    dateOfBirth,
    driversLicenceNumber,
    driversLicenceVersion,
    firstName,
    lastName,
    postcode,
    suburb,
    middleName,
    passportExpiry,
    passportNumber,
    verificationMethod,
    ethnicity
  } = profileForCurrentUserData?.profileForCurrentUser || {}

  useEffect(() => {
    ;(async () => {
      let formErrors: FormikErrors<FormValues> = {}

      if (failureReasons) {
        /* Notes response license, date of birth, first name, last name (not address) */
        const notes = getNotes<NoteType>(
          failureReasons[failureReasons.length - 1]['notes'] as string
        )
        /* only address we should use in identityVerificationFailureReasons */
        const {
          address_verified,
          drivers_licence_verified,
          passport_verified
        } = failureReasons[failureReasons.length - 1]

        formErrors = {
          firstName: !notes.first_name ? 'firstName wrong' : '',
          lastName: !notes.last_name ? 'lastName wrong' : '',
          dateOfBirth: !notes.date_of_birth ? 'dateOfBirth wrong' : '',
          addressLine1: !address_verified ? 'addressLine1 wrong' : '',
          addressLine2: !address_verified ? 'addressLine2 wrong' : '',
          city: !address_verified ? 'city wrong' : '',
          postcode: !address_verified ? 'postcode wrong' : '',
          suburb: !address_verified ? 'suburb wrong' : '',
          ethnicity: !ethnicity ? 'ethnicity wrong' : ''
        }

        if (verificationMethod === 'drivers_licence') {
          formErrors['driversLicenceNumber'] = !drivers_licence_verified
            ? 'driversLicenceNumber wrong'
            : ''
          formErrors['driversLicenceVersion'] = !drivers_licence_verified
            ? 'driversLicenceVersion wrong'
            : ''
        } else {
          formErrors['passportExpiry'] = !passport_verified
            ? 'passportExpiry wrong'
            : ''
          formErrors['passportNumber'] = !passport_verified
            ? 'passportNumber wrong'
            : ''
        }
      }

      if (profileForCurrentUserData && !statusLoading) {
        const values = await setFormValue(profileForCurrentUserData, formErrors)
        setValues((values as FormValues) || {})
        setErrors(formErrors || {})
      }
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileForCurrentUserData, statusLoading, failureReasons])

  const [requestIdentityVerification, { loading: requestVerificationLoading }] =
    useRequestIdentityVerificationMutation({
      onCompleted: () => {
        history.push(verifyingPath())
      },
      onError: error =>
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        })
    })

  const [updateProfile, { loading: updateProfileLoading }] =
    useUpdateProfileForOnboardingMutation({
      onCompleted: () => {
        requestIdentityVerification({ variables: { input: {} } })
      },
      onError: error =>
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        })
    })

  const submitDisabled =
    !dirty || !isValid || updateProfileLoading || requestVerificationLoading

  function renderFailureDetails(): string | null {
    if (failureReasons?.length) {
      const notes = getNotes<NoteType>(
        failureReasons[failureReasons.length - 1]['notes'] as string
      )

      const { address_verified, drivers_licence_verified, passport_verified } =
        failureReasons[failureReasons.length - 1]

      const latestFailureReason = {
        ...notes,
        address: address_verified
      }

      if (verificationMethod === 'drivers_licence') {
        latestFailureReason['license'] = drivers_licence_verified
      } else {
        latestFailureReason['passport'] = passport_verified
      }

      const failedItems = Object.entries(latestFailureReason).reduce(
        (items: string[], reason: [string, boolean | string]) => {
          const [key, value] = reason

          if (!value && key !== 'verified') {
            return [...items, trimUnderscore(key.replace('_verified', ''))]
          }
          return items
        },
        []
      )

      return failedItems.join(', ')
    }
    return null
  }

  const copyToFormField =
    (name: keyof FormValues, value?: Maybe<string> | undefined) => () => {
      if (value) {
        setFieldValue(name, value)
        setCopiedFormFields(prev => ({
          ...prev,
          [name]: true
        }))
      }
    }

  function renderCopyLink(
    name: keyof FormValues,
    value?: Maybe<string> | undefined
  ) {
    if (!value) return

    return copiedFormFields[name] ? (
      <Typography sx={{ mt: 1.5 }}>You wrote {value}</Typography>
    ) : (
      <Link
        color='textPrimary'
        component='button'
        onClick={copyToFormField(name, value)}
        type='button'
        sx={{ mt: 1.5 }}
      >
        You wrote {value}
      </Link>
    )
  }

  function checkError(field: keyof FormValues): boolean {
    return !!errors[field]
  }

  function getHelperText(field: keyof FormValues): string {
    return checkError(field) ? (errors[field] as string) : ''
  }

  return (
    <DefaultLayout
      backgroundColor='white'
      heading='Uh oh, was there a typo?'
      loading={statusLoading || profileForCurrentUserLoading}
    >
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant='body1'>
              You need to fill in your details exactly as they appear on your
              driver's licence, even if that's not what you usually use. You can
              leave the optional fields empty.
            </Typography>
          </Grid>

          {!!renderFailureDetails() && (
            <Grid item xs={12}>
              <Typography variant='body1'>
                We are having trouble verifying your {renderFailureDetails()}.
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextFieldCustom
              {...formFieldData.firstName}
              error={checkError('firstName')}
              helperText={getHelperText('firstName')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
            />
            {checkError('firstName') && renderCopyLink('firstName', firstName)}
          </Grid>

          <Grid item xs={12}>
            <TextFieldCustom
              {...formFieldData.middleName}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.middleName}
            />
            {checkError('middleName') &&
              renderCopyLink('middleName', middleName)}
          </Grid>

          <Grid item xs={12}>
            <TextFieldCustom
              {...formFieldData.lastName}
              error={checkError('lastName')}
              helperText={getHelperText('lastName')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
            />
            {checkError('lastName') && renderCopyLink('lastName', lastName)}
          </Grid>

          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat={UI_DATE_FORMAT}
                value={dayjs(
                  checkError('dateOfBirth') ? '' : values.dateOfBirth,
                  UI_DATE_FORMAT
                )}
                label='Date of birth'
                onChange={date => {
                  const formattedDate = date
                    ? formatDate({
                        date: dayjs(date).format(UI_DATE_FORMAT),
                        inputFormat: UI_DATE_FORMAT,
                        outputFormat: UI_DATE_FORMAT
                      })
                    : ''
                  setFieldValue('dateOfBirth', formattedDate)
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    onBlur={handleBlur}
                    label='Date of birth'
                    name='dateOfBirth'
                    required
                    fullWidth
                    error={checkError('dateOfBirth')}
                    helperText={getHelperText('dateOfBirth')}
                    value={values.dateOfBirth}
                  />
                )}
              />
            </LocalizationProvider>
            {checkError('dateOfBirth') &&
              renderCopyLink(
                'dateOfBirth',
                formatDate({
                  date: dateOfBirth,
                  inputFormat: API_DATE_FORMAT,
                  outputFormat: UI_DATE_FORMAT
                })
              )}
          </Grid>

          <Grid item xs={12}>
            <TextFieldCustom
              {...formFieldData.addressLine1}
              error={checkError('addressLine1')}
              helperText={getHelperText('addressLine1')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.addressLine1}
            />
            {checkError('addressLine1') &&
              renderCopyLink('addressLine1', addressLine1)}
          </Grid>

          <Grid item xs={12}>
            <TextFieldCustom
              {...formFieldData.addressLine2}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.addressLine2}
            />
            {checkError('addressLine2') &&
              renderCopyLink('addressLine2', addressLine2)}
          </Grid>

          <Grid item xs={12}>
            <TextFieldCustom
              {...formFieldData.suburb}
              error={checkError('suburb')}
              helperText={getHelperText('suburb')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.suburb}
            />
            {checkError('suburb') && renderCopyLink('suburb', suburb)}
          </Grid>

          <Grid item xs={12} sm={8}>
            <TextFieldCustom
              {...formFieldData.city}
              error={checkError('city')}
              helperText={getHelperText('city')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
            />
            {checkError('city') && renderCopyLink('city', city)}
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextFieldCustom
              {...formFieldData.postcode}
              error={checkError('postcode')}
              helperText={getHelperText('postcode')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.postcode}
            />
            {checkError('postcode') && renderCopyLink('postcode', postcode)}
          </Grid>

          {verificationMethod === 'drivers_licence' ? (
            <>
              <Grid item xs={12}>
                <TextFieldCustom
                  {...formFieldData.driversLicenceNumber}
                  error={checkError('driversLicenceNumber')}
                  helperText={getHelperText('driversLicenceNumber')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.driversLicenceNumber}
                />
                {checkError('driversLicenceNumber') &&
                  renderCopyLink('driversLicenceNumber', driversLicenceNumber)}
              </Grid>

              <Grid item xs={12}>
                <TextFieldCustom
                  {...formFieldData.driversLicenceVersion}
                  error={checkError('driversLicenceVersion')}
                  helperText={getHelperText('driversLicenceVersion')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.driversLicenceVersion}
                />
                {checkError('driversLicenceVersion') &&
                  renderCopyLink(
                    'driversLicenceVersion',
                    driversLicenceVersion
                  )}
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <TextFieldCustom
                  {...formFieldData.passportNumber}
                  error={checkError('passportNumber')}
                  helperText={getHelperText('passportNumber')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.passportNumber}
                />
                {checkError('passportNumber') &&
                  renderCopyLink('passportNumber', passportNumber)}
              </Grid>

              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat={UI_DATE_FORMAT}
                    value={dayjs(
                      checkError('passportExpiry') ? '' : values.passportExpiry,
                      API_DATE_FORMAT
                    )}
                    label='Passport expiry'
                    onChange={date => {
                      const formattedDate = date
                        ? formatDate({
                            date: dayjs(date).format(API_DATE_FORMAT),
                            inputFormat: API_DATE_FORMAT,
                            outputFormat: API_DATE_FORMAT
                          })
                        : ''
                      setFieldValue('passportExpiry', formattedDate)
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        onBlur={handleBlur}
                        label='Passport expiry'
                        name='passportExpiry'
                        required
                        fullWidth
                        error={checkError('passportExpiry')}
                        helperText={getHelperText('passportExpiry')}
                        value={values.passportExpiry}
                      />
                    )}
                  />
                </LocalizationProvider>
                {checkError('passportExpiry') &&
                  renderCopyLink(
                    'passportExpiry',
                    formatDate({
                      date: passportExpiry as string,
                      inputFormat: API_DATE_FORMAT,
                      outputFormat: API_DATE_FORMAT
                    })
                  )}
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel
                id={formFieldData.ethnicity.name}
                error={checkError('ethnicity')}
              >
                {formFieldData.ethnicity.label}
              </InputLabel>
              <Select
                label=''
                name='ethnicity'
                value={values.ethnicity}
                onChange={handleChange}
                onBlur={handleBlur}
                onClickCapture={handleBlur}
                required
                error={checkError('ethnicity')}
              >
                {ETHNICITIES.map(ethnicity => (
                  <MenuItem key={ethnicity} value={ethnicity}>
                    {ethnicity}
                  </MenuItem>
                ))}
              </Select>
              {errors.ethnicity && (
                <FormHelperText error>{errors.ethnicity}</FormHelperText>
              )}
            </FormControl>
            {checkError('ethnicity') && renderCopyLink('ethnicity', ethnicity)}
          </Grid>

          <Grid item xs={12} mb='2rem'>
            <Button
              color='blue'
              disabled={submitDisabled}
              fullWidth
              type='submit'
            >
              Try again
            </Button>
          </Grid>
        </Grid>
      </form>
    </DefaultLayout>
  )
}
