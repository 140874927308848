import { gql } from '@apollo/client'

export const PROFILE_FOR_CURRENT_USER = gql`
  query profileForCurrentUser {
    profile: profileForCurrentUser {
      identifier
      dateOfBirth
      email
      firstName
      middleName
      lastName
      preferredName
      driversLicenceNumber
      driversLicenceVersion
      nzMobilePhoneNumber
      addressLine1
      addressLine2
      suburb
      city
      postcode
      onboarded
      reference
    }
  }
`

export const UPDATE_PROFILE = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    profile: updateProfile(input: $input) {
      addressLine1
      addressLine2
      amlVerificationQuestionsAnswered
      amlVerified
      bankAccountName
      bankAccountNumber
      city
      complete
      dateOfBirth
      driversLicenceNumber
      driversLicenceVersion
      email
      firstName
      identifier
      identityVerified
      identityVerificationFailureReasons
      identityVerificationRequestInProgress
      lastName
      locked
      middleName
      nzMobilePhoneNumber
      onboarded
      postcode
      preferredName
      reference
      suburb
    }
  }
`
