import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
  buttonMyVault: {
    position: 'relative',
    textTransform: 'none',
    padding: 0
  },
  buttonMyVaultIconDown: {
    fontSize: 24,
    strokeWidth: 0.5
  },
  popover: {
    '& .MuiPaper-root': {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 8
    }
  },
  divider: {
    marginTop: 0,
    marginBottom: 0,
    borderColor: theme.palette.primary.main
  }
}))
