export const DEBOUNCE_INTERVAL = 1000

export const REVIEW_ORDER_EXPIRED = 'review_order_expired'

export const TRADING_IS_PAUSED = 'trading_is_paused'

export const patternTwoDigitsAfterComma = /^\d+(\.\d{0,2})?$/

export const patternOneDigitsAfterComma = /^\d+(\.\d{0,1})?$/

export const CONTAINER_WIDTH = 'sm'

export const CURRENT_PURCHASE_FEE = 0.0085

export const ETHNICITIES = [
  'NZ European',
  'Maori',
  'Samoa',
  'Cook island',
  'Tongan',
  'Asian',
  'Indian',
  'African',
  'Latin American',
  'Middle Eastern',
  'Other',
  'Prefer not to answer'
]
