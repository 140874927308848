import { useAuth } from 'components/AuthProvider'
import { GET_CURRENT_USER } from 'components/AuthProvider/queries'
import { PRODUCT_PERFORMANCE_SUMMARIES } from 'components/pages/MyVault/queries'
import { PORTFOLIO_PERFORMANCE_FOR_CURRENT_USER } from 'components/pages/TradeProduct/queries'
import { Maybe, useActivateVaultMutation } from 'generated/graphql'
import { DocumentNode } from 'graphql'
import { useEffect, useState } from 'react'
import { generateUsername } from 'utils/util'

interface VaultishThing {
  name?: Maybe<string>
  id?: Maybe<string>
}

export default function useVault() {
  const { user, setLoading } = useAuth()

  const [activatedVault, setActivatedVault] = useState<VaultishThing>(
    user && user?.activatedVault?.name
      ? {
          name: user?.activatedVault?.name,
          id: user?.activatedVault?.id
        }
      : {
          name: generateUsername(user),
          id: user?.activatedVault?.id
        }
  )

  const [activeVault, { loading }] = useActivateVaultMutation()

  const handleActiveVault = async (id: number | null | string) => {
    setLoading(true)
    try {
      const productsToDisplay = user?.securitiesOwned
        ?.filter(security => security.hasHistory)
        .map(security => security.product)

      const productPerformanceQueries: Array<{
        query: DocumentNode
        variables?: { productId: number }
      }> =
        productsToDisplay?.map(product => ({
          query: PORTFOLIO_PERFORMANCE_FOR_CURRENT_USER,
          variables: { productId: Number(product?.id) }
        })) ?? []

      const { data } = await activeVault({
        variables: {
          input: {
            id: Number(id)
          }
        },
        refetchQueries: productPerformanceQueries?.concat([
          {
            query: GET_CURRENT_USER
          },
          {
            query: PRODUCT_PERFORMANCE_SUMMARIES
          }
        ]),
        awaitRefetchQueries: true
      })

      const vault = data?.activateVault?.vault

      if (vault) {
        setActivatedVault(
          vault?.name
            ? {
                name: vault?.name,
                id: vault?.id
              }
            : {
                name: generateUsername(user),
                id: vault?.id
              }
        )
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setActivatedVault(
      user?.activatedVault && user?.activatedVault?.name
        ? {
            name: user?.activatedVault?.name,
            id: user.activatedVault?.id
          }
        : {
            name: generateUsername(user),
            id: user?.activatedVault?.id
          }
    )
  }, [user])

  return {
    loading,
    handleActiveVault,
    activatedVault,
    activatedVaultId: Number(activatedVault.id)
  }
}
