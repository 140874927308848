import { Box, Grid, Typography } from '@mui/material'
import { useAuth } from 'components/AuthProvider/AuthProvider'
import Button from 'components/Button'
import DefaultLayout from 'components/layout/DefaultLayout'
import VerificationInput from 'components/VerificationInput'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import theme from 'utils/theme'
import * as Yup from 'yup'

interface FormValues {
  otp: string
}

const initialValues: FormValues = {
  otp: ''
}

const validationSchema = Yup.object().shape({
  otp: Yup.string()
    .required('Verify code is required')
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits')
})

export default function VerifyOtp(): JSX.Element {
  const { verifyOtp, loading, error: verifyError } = useAuth()

  const recaptchaEnabled = JSON.parse(
    process.env.REACT_APP_RECAPTCHA_ENABLED || 'true'
  )
  const { executeRecaptcha } = useGoogleReCaptcha()

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: FormValues) => handleVerifyOtp(values)
  })

  const { errors, touched, handleSubmit, setValues, setErrors, setTouched } =
    formik

  useEffect(() => {
    setErrors({
      otp: verifyError ?? undefined
    })
  }, [loading, verifyError, setErrors])

  const handleChange = (code: string) => {
    setValues({
      otp: code
    })
    setErrors({ otp: undefined })
    setTouched({ otp: false })
  }

  const handleBlur = () => {
    setTouched({ otp: true })
  }

  const handleVerifyOtp = async (values: FormValues) => {
    if (!executeRecaptcha && recaptchaEnabled) return
    if (errors.otp) return

    const recaptchaToken =
      recaptchaEnabled && executeRecaptcha
        ? await executeRecaptcha('sessions/create')
        : undefined

    verifyOtp({
      recaptcha_token: recaptchaToken,
      otp_attempt: values.otp
    })
  }

  return (
    <DefaultLayout
      containerMaxWidth='xs'
      wrapperContent={{ mt: 0 }}
      heading='Verify Otp'
    >
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography align='center'>
              Enter the code generated by your auth app below
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 6 }}>
            <VerificationInput
              data-testid='verification-input'
              validChars='0-9'
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', mb: 6 }}>
            {errors?.otp && touched?.otp && (
              <Box
                sx={{
                  color: theme.palette.error.main,
                  fontSize: '16px'
                }}
              >
                {errors.otp}
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sx={{ mt: 4, textAlign: 'center' }}>
            <Button fullWidth color='primary' type='submit' disabled={loading}>
              Verify
            </Button>
          </Grid>
        </Grid>
      </form>
    </DefaultLayout>
  )
}
