import queryString from 'query-string'
import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { RouteComponentProps, useLocation } from 'react-router-dom'

import { resendConfirmationEmail } from 'api/users'
import { useAuth } from 'components/AuthProvider'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Grid, Link, TextField, Typography } from '@mui/material'
import DefaultLayout from 'components/layout/DefaultLayout'

import Button from 'components/Button'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useStyles from './styles'

export type ConfirmEmailProps = RouteComponentProps

interface FormValues {
  confirmationToken: string
}

interface ConfirmEmailState {
  resendCode: boolean
}

const initialValues = {
  confirmationToken: ''
}

const validationSchema = Yup.object().shape({
  confirmationToken: Yup.string()
    .required('Confirmation code is required')
    .length(6, 'Confirmation code must be 6 characters')
})

export default function ConfirmEmail({
  location
}: ConfirmEmailProps): JSX.Element {
  const classes = useStyles()

  const { confirmEmail, loading, error, setToast } = useAuth()

  const { state } = useLocation<ConfirmEmailState>()

  const { email, confirmation_token } = queryString.parse(location.search)

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ confirmationToken }: FormValues) => {
      handleConfirmEmail(email as string, confirmationToken)
    }
  })

  const {
    errors,
    touched,
    values,
    handleSubmit,
    setErrors,
    handleChange,
    handleBlur
  } = formik

  const { mutate: resend } = useMutation(resendConfirmationEmail, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) =>
      setToast({
        open: true,
        message: error.message,
        type: 'error',
        duration: 3000
      }),
    onSuccess: () =>
      setToast({
        open: true,
        message: 'Good as gold! Confirmation email sent.',
        type: 'success',
        duration: 2000
      })
  })

  const recaptchaEnabled = JSON.parse(
    process.env.REACT_APP_RECAPTCHA_ENABLED || 'true'
  )

  const { executeRecaptcha } = useGoogleReCaptcha()

  async function handleConfirmEmail(email: string, token: string) {
    if (!executeRecaptcha && recaptchaEnabled) return

    const recaptchaToken =
      recaptchaEnabled &&
      executeRecaptcha &&
      (await executeRecaptcha('users/confirm'))

    confirmEmail(email, token, recaptchaToken)
  }

  const handleClickResend = async () => {
    if (!executeRecaptcha && recaptchaEnabled) return

    const recaptchaToken =
      recaptchaEnabled &&
      executeRecaptcha &&
      (await executeRecaptcha('users/resend'))

    resend({ email: email as string, recaptcha_token: recaptchaToken })
  }

  useEffect(() => {
    if (
      email &&
      confirmation_token &&
      (executeRecaptcha || !recaptchaEnabled)
    ) {
      handleConfirmEmail(email as string, confirmation_token as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmation_token, email, executeRecaptcha, recaptchaEnabled])

  useEffect(() => {
    if (error) {
      setErrors({
        confirmationToken: error
      })
    }
  }, [error, setErrors])

  useEffect(() => {
    if (state?.resendCode) handleClickResend()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <DefaultLayout
      containerMaxWidth='xs'
      heading='Check your email'
      loading={loading}
    >
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              A confirmation code has been sent to{' '}
              {email ? <strong>{email}</strong> : 'your email address'}. Please
              check your email and enter the code below to activate your
              account.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              defaultValue={values.confirmationToken}
              autoFocus
              fullWidth
              required
              id='confirmationToken'
              label='Six digit confirmation code'
              name='confirmationToken'
              type='text'
              error={Boolean(
                errors.confirmationToken && touched.confirmationToken
              )}
              helperText={
                Boolean(
                  errors.confirmationToken && touched.confirmationToken
                ) && errors.confirmationToken
              }
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent='center' mt={4}>
          <Button color='primary' disabled={loading} fullWidth type='submit'>
            Continue
          </Button>
        </Grid>
        <Grid container justifyContent='center' alignItems='center' mt={4}>
          <Typography className={classes['did_not_get_it']}>
            Didn't get it?
          </Typography>
          <Link
            component='a'
            onClick={handleClickResend}
            type='button'
            variant='body1'
          >
            Resend code
          </Link>
        </Grid>
      </form>
    </DefaultLayout>
  )
}
