import { UserType } from 'components/AuthProvider'
import { IToast } from 'components/AuthProvider/AuthProvider'
import LineGraph from 'components/LineGraph'
import { GraphPeriodType } from 'components/PerformanceGraph/utils'
import LoadingWrapper from 'components/layout/LoadingWrapper'
import { NumberValue } from 'd3-scale'
import usePerformanceGraph from 'hooks/usePerformanceGraph'
import theme from 'utils/theme'

interface PerformanceGraphProps {
  setOpenModal?: (open: boolean) => void
  setToast?: (toast: IToast) => void
  graphPeriod?: GraphPeriodType
  productId?: number
  user?: UserType
  myVaultGraph?: boolean
}

export default function PerformanceGraph({
  graphPeriod = '1m',
  productId,
  myVaultGraph
}: Readonly<PerformanceGraphProps>): JSX.Element {
  const { loading, builtData } = usePerformanceGraph({ productId, graphPeriod })

  return (
    <LoadingWrapper loading={loading}>
      {builtData && (
        <LineGraph
          data={builtData}
          graphPeriod={graphPeriod}
          beginAtZero={false}
          colors={{
            line: theme.palette.black.main,
            tooltipCircle: theme.palette.primary.main,
            axis: theme.palette.black.main
          }}
          tickFormatter={(value: NumberValue) => `$${Number(value).toFixed(0)}`}
          showChangeValue={false}
          showXAxis
          loading={loading}
          showBackgroundColor={false}
          isCustomTooltip={false}
          type='historicalPerformance'
          myVaultGraph={myVaultGraph}
        />
      )}
    </LoadingWrapper>
  )
}
