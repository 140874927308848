import { Divider, Grid, Typography } from '@mui/material'
import { ReviewPaymentData } from 'api/payments/types'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import { PaymentsProvider } from 'components/PaymentsProvider'
import { walletPath } from 'components/Routes'
import TransactionButtons from 'components/TransactionButtons/TransactionButtons'
import DefaultLayout from 'components/layout/DefaultLayout/DefaultLayout'
import { ReviewOrderQuery } from 'generated/graphql'
import { useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { formattedPrice } from 'utils/util'
import useStyles from './styles'

export interface Props {
  reviewOrderData?: ReviewOrderQuery
  reviewOrderId: string
}

export const FEE_PLAN_TYPES: { [key: string]: string } = {
  INSURANCE: 'Standard Insurance Fee',
  TRANSACTION: 'Standard Transaction Fee'
}

interface ITransactionForm {
  payment_amount: string
  fee: string
}

export default function RechargeConfirm(): JSX.Element {
  const classes = useStyles()
  const { setToast } = useAuth()
  const history = useHistory()

  const [disabledButton, setDisabledButton] = useState<boolean>(true)

  const { state } = useLocation<{ amount: string | number }>()

  const { amount } = state ?? {}

  const [transactionForm, setTransactionForm] =
    useState<ITransactionForm | null>(null)

  const creditCardTotal = useMemo(() => {
    const { payment_amount, fee } = transactionForm ?? {}
    return Number(payment_amount) - Number(fee)
  }, [transactionForm])

  const handleConfirm = useCallback(async () => {
    if (!disabledButton) {
      const paymentForm = document.getElementById(
        'credit-or-debit-form'
      ) as HTMLFormElement
      if (paymentForm) {
        paymentForm.requestSubmit()
      }
    }
  }, [disabledButton])

  const handleRechargeSuccess = () => {
    setDisabledButton(false)
    setToast({
      open: true,
      message: 'Transfer successfully!',
      duration: 3000,
      type: 'success'
    })
    history.push(walletPath())
  }

  const formInformation = [
    {
      label: 'Credit Card',
      value: creditCardTotal,
      unit: '$'
    },
    {
      label: 'CC fee of',
      value: transactionForm?.fee,
      unit: '$'
    }
  ]

  const handleFillTransactionForm = (review_data: ReviewPaymentData) => {
    setTransactionForm({
      payment_amount: review_data.payment_amount,
      fee: review_data.fee
    })
  }

  return (
    <PaymentsProvider>
      <DefaultLayout
        wrapperContainer={{ px: 2, py: 4 }}
        wrapperContent={{ m: 0 }}
        showBackIcon
        heading='Recharge Confirm'
      >
        <Grid item>
          <Grid item overflow='hidden' container justifyContent='center'>
            <Grid item>
              <Typography component='h1' className={classes.amount}>
                Recharge amount
                <b>{` $${amount} `}</b>
              </Typography>
            </Grid>
          </Grid>

          {transactionForm && (
            <>
              <Grid item container spacing={2}>
                {formInformation.map(info => (
                  <Grid
                    item
                    container
                    justifyContent='space-between'
                    alignItems='center'
                    key={info.label}
                  >
                    <Grid item>
                      <Typography className={classes.label}>
                        {info.label}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {info.unit + formattedPrice(info.value ?? '0')}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Divider />

              <Grid
                item
                container
                xs={12}
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item>
                  <Typography component='p' variant='body1' fontWeight='700'>
                    Total
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component='p' variant='body1' fontWeight='700'>
                    ${transactionForm?.payment_amount}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          <Grid item mt={4}>
            <TransactionButtons
              price={Number(amount)}
              setDisabledButton={setDisabledButton}
              createTransaction={handleRechargeSuccess}
              handleCreatePaymentSuccess={handleFillTransactionForm}
              setTransactionForm={setTransactionForm}
            />
          </Grid>
          <Grid item flex={1}>
            <Button
              fullWidth
              type='button'
              color='primary'
              disabled={disabledButton}
              onClick={handleConfirm}
            >
              Confirm payment ${transactionForm?.payment_amount ?? amount}
            </Button>
          </Grid>
        </Grid>
      </DefaultLayout>
    </PaymentsProvider>
  )
}
