import HelpIcon from '@mui/icons-material/Help'
import { Box, Divider, Grid, Typography } from '@mui/material'
import Currency from 'components/Currency'
import CustomTooltip from 'components/Tooltip/Tooltip'
import { useWalletForCurrentUserQuery } from 'generated/graphql'
import { useEffect, useMemo } from 'react'
import useStyles from './styles'

export default function BalanceCard(): JSX.Element {
  const classes = useStyles()

  const { data, refetch } = useWalletForCurrentUserQuery()

  const { walletForCurrentUser } = data ?? {}

  const { balance, walletBalance, rewardCredit, voucherCredit } =
    walletForCurrentUser ?? {}

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const walletCredit = useMemo(() => {
    return walletBalance ? parseFloat(walletBalance) : 0
  }, [walletBalance])

  const rewardCreditPrice = useMemo(() => {
    return rewardCredit ? parseFloat(rewardCredit) : 0
  }, [rewardCredit])

  const voucherCreditPrice = useMemo(() => {
    return voucherCredit ? parseFloat(voucherCredit) : 0
  }, [voucherCredit])

  const totalBalance = useMemo(() => {
    return balance ? parseFloat(balance) : 0
  }, [balance])

  return (
    <Grid container alignItems='center' className={classes.balanceCard}>
      <Grid
        item
        xs={12}
        container
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item>
          <Typography component='h3' variant='body1'>
            Goldie credit
          </Typography>
        </Grid>
        <Grid item>
          <Typography component='h2' variant='h2'>
            <Currency amount={Number(totalBalance)} />
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item container justifyContent='space-between'>
        <Grid item>
          <Grid item>
            <CustomTooltip
              Icon={
                <Box display='flex' alignItems='center' gap={0.2}>
                  <Typography component='h4' variant='body1'>
                    Wallet credit
                  </Typography>
                  <HelpIcon style={{ fontSize: '1rem' }} color='white' />
                </Box>
              }
              title={`
              Wallet credit is money you have transferred to Goldie, or money from the proceeds of a you can use this to make more purchases or withdraw to your personal bank account.
              `}
              isHover
              showCloseIcon={false}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.balanceValue}>
              <Currency amount={Number(walletCredit)} />
            </Typography>
          </Grid>
        </Grid>

        <Grid item>
          <Grid item>
            <CustomTooltip
              Icon={
                <Box display='flex' alignItems='center' gap={0.2}>
                  <Typography component='h4' variant='body1'>
                    Reward credit
                  </Typography>
                  <HelpIcon style={{ fontSize: '1rem' }} color='white' />
                </Box>
              }
              title={`
              Credit from special promotions and referral bonuses, you can only use this to make a purchase and it cannot be withdrawn. It will have to be used within a certain timeframe
              `}
              isHover
              showCloseIcon={false}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.balanceValue} textAlign='center'>
              <Currency amount={Number(rewardCreditPrice)} />
            </Typography>
          </Grid>
        </Grid>

        <Grid item>
          <Grid item>
            <CustomTooltip
              Icon={
                <Box display='flex' alignItems='center' gap={0.2}>
                  <Typography component='h4' variant='body1'>
                    Voucher credit
                  </Typography>
                  <HelpIcon style={{ fontSize: '1rem' }} color='white' />
                </Box>
              }
              title={`
              Credit from the redemption of gifts, it cannot be withdrawn and must first be spent in a purchase
              `}
              isHover
              showCloseIcon={false}
            />
          </Grid>
          <Grid item textAlign='right'>
            <Typography className={classes.balanceValue}>
              <Currency amount={Number(voucherCreditPrice)} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
