import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { ButtonProps } from '@mui/material'

export default makeStyles((theme: Theme) => ({
  root: {
    textTransform: 'uppercase',
    textAlign: 'center',

    '&:hover': {
      borderColor: `${theme.palette.black.main} !important`,
      backgroundColor: `${theme.palette.black.main} !important`,
      color: `${theme.palette.white.main} !important`,
      '& *': {
        color: `${theme.palette.white.main} !important`
      }
    },

    /*
    // Remove these styles on button for no. I think we can do all of this by properly understanding how use the MUI theme and button components.
    background: (props: ButtonProps) => {
      const color: ButtonProps['color'] = props.color
      return color === 'primary'
        ? theme.palette.primary.main
        : theme.palette.white.main
    },

    color: (props: ButtonProps) => {
      const color: ButtonProps['color'] = props.color
      return color === 'primary'
        ? theme.palette.white.main
        : theme.palette.primary.main
    },

    border: (props: ButtonProps) => {
      const color: ButtonProps['color'] = props.color
      return color === 'primary'
        ? 'none'
        : `1.5px solid ${theme.palette.primary.main}`
    },

    '&:hover': {
      background: (props: ButtonProps) => {
        const color: ButtonProps['color'] = props.color
        return color === 'primary'
          ? theme.palette.white.main
          : theme.palette.primary.main
      },

      color: (props: ButtonProps) => {
        const color: ButtonProps['color'] = props.color
        return color === 'primary'
          ? theme.palette.primary.main
          : theme.palette.white.main
      },

      border: (props: ButtonProps) => {
        const color: ButtonProps['color'] = props.color
        return color === 'primary'
          ? `1.5px solid ${theme.palette.primary.main}`
          : 'none'
      },

      '& svg': {
        color: (props: ButtonProps) => {
          const color: ButtonProps['color'] = props.color
          return color === 'primary'
            ? theme.palette.primary.main
            : theme.palette.white.main
        }
      }
    },
    */

    '&:disabled': {
      border: 'none'
    },

    lineHeight: '150%',
    flex: 'none',
    order: 0,
    flexGrow: 1,
    position: 'relative'
  },
  arrow: {
    marginLeft: '0.25rem',
    fontSize: '1rem',
    color: (props: ButtonProps) => {
      const color: ButtonProps['color'] = props.color
      return color
        ? theme.palette[color].contrastText
        : theme.palette.white.main
    }
  }
}))
