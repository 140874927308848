import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  amount: {
    color: theme.palette.disabled.contrastText,
    fontSize: '1rem',
    textAlign: 'center'
  },
  label: {
    color: theme.palette.disabled.contrastText,
    lineHeight: '140%'
  },
  stickyFooter: {
    margin: '0 auto',
    zIndex: 10,

    borderTopRightRadius: theme.spacing(4),
    borderTopLeftRadius: theme.spacing(4),
    padding: theme.spacing(2, 2, 3),
    background: theme.palette.primary.main
  },
  disabledStickyFooter: {
    margin: '0 auto',
    zIndex: 10,

    borderTopRightRadius: theme.spacing(4),
    borderTopLeftRadius: theme.spacing(4),
    padding: theme.spacing(2, 2, 3),
    background: theme.palette.disabled.main
  },
  total: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',

    color: theme.palette.white.main,
    fontSize: '1rem',
    fontWeight: '700',

    '& > p': {
      color: theme.palette.white.main,
      fontSize: '0.75rem'
    }
  }
  /*
  disabledBtn: {
    border: 'none',
    backgroundColor: `${theme.palette.white.main} !important`,
    color: `${theme.palette.disabled.main} !important`
  } */
}))
