import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { BackgroundColorTypes, ColorTypes } from 'types'

export interface BaseLayoutStyleProps {
  color: ColorTypes
  backgroundColor: BackgroundColorTypes
}

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 !important',
    height: '100%'
  },
  pageBackground: {
    display: 'flex',
    flexDirection: 'column',

    position: 'relative',
    width: '100%',
    height: '100%',

    paddingY: '2rem',
    zIndex: 9,

    backgroundColor: theme.palette.background.default,
    color: (props: BaseLayoutStyleProps) => {
      return theme.palette[props.color].main
    }
  },
  noGrow: {
    flexBasis: 'auto',
    flexGrow: '0',
    flexShrink: '0'
  },
  grow: {
    position: 'relative',
    flexGrow: '1',
    flexShrink: '0'
  },
  growCenter: {
    position: 'relative',
    flexGrow: '1',
    flexShrink: '0',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  stickyFooter: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 9,
    width: '100%'
  },

  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 16px',

    '& > div:last-of-type': {
      marginTop: 3,
      '& > svg': {
        fontSize: '2.5rem !important',
        margin: '0 !important'
      }
    },
    '& > button > p, svg': {
      color: 'black !important'
    }
  },

  kiaOra: {
    fontFamily: 'Arimo',
    fontSize: '16px',
    fontWeight: 700,
    textAlign: 'right'
  },

  wallet: {
    color: theme.palette.primary.main,
    fontFamily: 'Arimo',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'right'
  }
}))
