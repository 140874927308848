import * as React from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import TextField from '@mui/material/TextField'
import { TextFieldProps } from '@mui/material'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumericFormatAmount = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          })
        }}
        thousandSeparator
        valueIsNumericString
        prefix='Edit: $'
      />
    )
  }
)

export const NumericFormatWeight = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      onChange={undefined}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator
      valueIsNumericString
      suffix=' g'
    />
  )
})

export default function FormattedInputs(props: TextFieldProps) {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: (props?.name === 'amount'
          ? NumericFormatAmount
          : // eslint-disable-next-line @typescript-eslint/no-explicit-any
            NumericFormatWeight) as any
      }}
      sx={{
        border: 'none',
        '& fieldset': { display: 'none' },
        '& input': {
          p: 0,
          color: 'primary.main',
          pl: 1,
          pr: '2px',
          bgcolor: 'transparent',
          textAlign: 'right'
        }
      }}
      variant='outlined'
    />
  )
}
