import { gql } from '@apollo/client'

export const PORTFOLIO_PERFORMANCE_FOR_CURRENT_USER = gql`
  query productPerformance($productId: Int!) {
    productPerformance(productId: $productId) {
      currentValue
      netProfit
      ownedAllotmentsCount
      periodInvestedInDays
      totalCost
      totalReturn
      totalReturnAsPercentage
      unit
      spotPrice {
        bidPricePerDg
        bidPricePerOz
        spotTime
        askPricePerDg
        askPricePerOz
      }
    }
  }
`
export const SECURITIES_OWNED_FOR_CURRENT_USER = gql`
  query securitiesOwnedForCurrentUser($productId: Int!) {
    securitiesOwnedForCurrentUser(productId: $productId) {
      allotmentsOwned
      securities {
        ownedAllotmentsCount
        security {
          id
          product {
            id
            name
            sku
            description
            tradable
          }
          serialNumber
          unit
        }
      }
      user {
        profile {
          addressLine1
          addressLine2
          amlVerificationQuestionsAnswered
          amlVerified
          bankAccountName
          bankAccountNumber
          city
          complete
          dateOfBirth
          driversLicenceNumber
          driversLicenceVersion
          email
          firstName
          identifier
          identityVerificationFailureReasons
          identityVerificationRequestInProgress
          identityVerified
          lastName
          locked
          middleName
          nzMobilePhoneNumber
          onboarded
          postcode
          preferredName
          reference
          suburb
        }
      }
    }
  }
`

export const ACTIVE_VAULT = gql`
  mutation ActivateVault($input: ActivateVaultInput!) {
    activateVault(input: $input) {
      vault {
        dateOfBirth
        email
        id
        name
        relationship
        balance
      }
    }
  }
`
