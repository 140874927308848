export default function RedeemGift(): JSX.Element {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.9697 9H2.96973V17C2.96973 20 3.96973 21 6.96973 21H14.9697C17.9697 21 18.9697 20 18.9697 17V9Z'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.9997 16.2188L12.2314 17.9905'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 16.2188H14'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 14.1116L9.76824 12.3398'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 14.1094H8'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.5 6V7C20.5 8.1 19.97 9 18.5 9H3.5C1.97 9 1.5 8.1 1.5 7V6C1.5 4.9 1.97 4 3.5 4H18.5C19.97 4 20.5 4.9 20.5 6Z'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6398 4.00141H5.11978C4.77978 3.63141 4.78978 3.06141 5.14978 2.70141L6.56978 1.28141C6.93978 0.911406 7.54978 0.911406 7.91978 1.28141L10.6398 4.00141Z'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.8696 4.00141H11.3496L14.0696 1.28141C14.4396 0.911406 15.0496 0.911406 15.4196 1.28141L16.8396 2.70141C17.1996 3.06141 17.2096 3.63141 16.8696 4.00141Z'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
