import { RouteComponentProps } from 'react-router-dom'

import { useActivityForCurrentUserQuery } from 'generated/graphql'
import usePagination from 'hooks/usePagination'

import { Box, Pagination, Typography } from '@mui/material'
import DefaultLayout from 'components/layout/DefaultLayout'
import ActivityItem from './ActivityItem'

import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { useAuth } from 'components/AuthProvider'
import { useEffect } from 'react'
import { Vault } from './ActivityItem/ActivityItem'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),

    '& > section': {
      width: '100%',
      margin: theme.spacing(2, 0, 3),

      '& > p': {
        textAlign: 'center'
      }
    }
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',

    margin: theme.spacing(2, 0, 0)
  }
}))

export type ActivityProps = RouteComponentProps

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Activity(_props: ActivityProps): JSX.Element {
  const classes = useStyles()

  const { setToast } = useAuth()

  const [pagination, setPagination] = usePagination()
  const { page, perPage } = pagination || {}

  const {
    data: activityForCurrentUserData,
    loading: activityForCurrentUserLoading,
    refetch
  } = useActivityForCurrentUserQuery({
    onError: error =>
      setToast({
        open: true,
        message: error.message,
        type: 'error',
        duration: 3000
      }),
    variables: { limit: perPage, page: page }
  })

  const { items, totalPages } =
    activityForCurrentUserData?.activityForCurrentUser || {}

  const handleChangePage = (_event: React.ChangeEvent<unknown>, page: number) =>
    setPagination({ page })

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <DefaultLayout
      heading='Account activity'
      showBackIcon
      loading={activityForCurrentUserLoading}
    >
      <div className={classes.container}>
        {items && items.length ? (
          items.map(activity => {
            return (
              <ActivityItem
                key={activity.id}
                activity={activity}
                vault={activity.vault as Vault}
              />
            )
          })
        ) : (
          <Box component='section'>
            <Typography component='p'>
              Your account has no activity yet
            </Typography>
          </Box>
        )}
        {page && totalPages && items?.length ? (
          <Pagination
            className={classes.pagination}
            page={page}
            count={totalPages}
            onChange={handleChangePage}
          />
        ) : null}
      </div>
    </DefaultLayout>
  )
}
