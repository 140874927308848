import { Grid } from '@mui/material'
import FormField from 'components/formik/FormField'
import { FormikErrors, FormikTouched, useFormikContext } from 'formik'
import { AmlVerificationQuestion } from 'generated/graphql'
import { useEffect } from 'react'
import { FormValues } from '../OnboardingWizard'
import * as Yup from 'yup'
import { formatFieldToLabel } from 'utils/util'

interface FreeTextQuestionProps extends AmlVerificationQuestion {
  errors: FormikErrors<FormValues>
  touched: FormikTouched<FormValues>
}

export default function FreeTextQuestion({
  identifier,
  question,
  errors,
  touched
}: FreeTextQuestionProps) {
  const { registerField } = useFormikContext()

  useEffect(() => {
    registerField(identifier, {
      validate: function (v) {
        const error = Yup.string()
          .required(`${formatFieldToLabel(identifier)} is required`)
          .validate(v)
        return error
          .then(() => {
            return null
          })
          .catch(error => {
            return error.message
          })
      }
    })

    return () => {
      registerField(identifier, {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        validate: function () {}
      })
    }
  }, [identifier, registerField])

  return (
    <Grid item xs={12}>
      <FormField
        name={identifier}
        label={question}
        required
        fullWidth
        error={!!(errors[identifier] && touched[identifier])}
        helperText={
          errors[identifier] && touched[identifier]
            ? (errors[identifier] as string)
            : ''
        }
      />
    </Grid>
  )
}
