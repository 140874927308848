import DefaultLayout from 'components/layout/DefaultLayout'
import { useAuth } from 'components/AuthProvider'

export default function TermsAndConditions(): JSX.Element {
  const { user } = useAuth()

  return (
    <DefaultLayout heading='Terms & Conditions' showFooter={!!user}>
      <a
        href='/Client Agreement Goldie Vaults and Goldie Nominee-2023-11-22.pdf'
        target='_blank'
      >
        Download client agreement
      </a>
    </DefaultLayout>
  )
}
