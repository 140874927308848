import {
  VisibilityRounded as VisibilityIcon,
  VisibilityOffRounded as VisibilityOffIcon
} from '@mui/icons-material'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link
} from '@mui/material'
import { useAuth } from 'components/AuthProvider'
import { LogInPayload } from 'components/AuthProvider/AuthProvider'
import Button from 'components/Button/Button'
import Input from 'components/Input'
import { forgotPasswordPath, loginPath, signUpPath } from 'components/Routes'
import GoldieLogo from 'components/icons/GoldieLogo/GoldieLogo'
import DefaultLayout from 'components/layout/DefaultLayout'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { TextField } from 'formik-mui'
import { useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Link as RouterLink } from 'react-router-dom'
import theme from 'utils/theme'
import * as Yup from 'yup'
import useStyles from './styles'

interface FormValues {
  email: string
  password: string
  keepSignedIn: string
}

const INITIAL_VALUES: FormValues = {
  email: '',
  password: '',
  keepSignedIn: 'keepSignedIn'
}

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Email has invalid format')
    .trim()
    .required('Email is required'),
  password: Yup.string().required('Password is required')
})

export default function Login(): JSX.Element {
  const classes = useStyles()

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [showPassword, setShowPassword] = useState(false)

  const { login, loading, error } = useAuth()

  const recaptchaEnabled = JSON.parse(
    process.env.REACT_APP_RECAPTCHA_ENABLED || 'true'
  )
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleToggleShowPassword = () => setShowPassword(!showPassword)

  async function handleSubmit(values: FormValues) {
    const { email, password, keepSignedIn } = values

    if (!executeRecaptcha && recaptchaEnabled) return
    if (errorMessage) return

    const recaptchaToken =
      recaptchaEnabled && executeRecaptcha
        ? await executeRecaptcha('sessions/create')
        : undefined

    const params: LogInPayload = {
      email: email.trim(),
      password,
      keepMeSignedIn: keepSignedIn,
      recaptchaToken
    }

    login(params)
  }

  const renderHeading = (): JSX.Element => {
    return (
      <Link
        color='textPrimary'
        component={RouterLink}
        to={loginPath()}
        data-testid='logo'
      >
        <GoldieLogo width='100%' fill={theme.palette.primary.main} />
      </Link>
    )
  }

  useEffect(() => {
    if (!loading && error) setErrorMessage(error)
    else setErrorMessage('')
  }, [error, loading])

  return (
    <DefaultLayout
      wrapperContent={{ mt: 8, paddingX: 5 }}
      loading={loading}
      loadingAnimation
      heading={renderHeading()}
    >
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({ setFieldValue, isSubmitting }: FormikProps<FormValues>) => {
          return (
            <Form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field name='email'>
                    {(fieldProps: FieldProps) => (
                      <Input
                        {...fieldProps}
                        fullWidth
                        required
                        label='Email address'
                        onChange={e => {
                          setFieldValue('email', e.target.value)
                          setErrorMessage('')
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name='password' autoComplete='current-password'>
                    {({ field, form, meta }: FieldProps) => (
                      <TextField
                        field={field}
                        form={form}
                        meta={meta}
                        fullWidth
                        required
                        label='Password'
                        onChange={e => {
                          setFieldValue('password', e.target.value)
                          setErrorMessage('')
                        }}
                        InputProps={{
                          // This is where the toggle 'view password' button is added
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleToggleShowPassword}
                                size='large'
                                tabIndex={-1}
                              >
                                {showPassword ? (
                                  <VisibilityIcon color='primary' />
                                ) : (
                                  <VisibilityOffIcon color='primary' />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        sx={{ background: 'none' }}
                        type={showPassword ? 'text' : 'password'}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid container sx={{ justifyContent: 'flex-start', mt: 2 }}>
                <Field name='keepSignedIn' label='keepSignedIn'>
                  {() => (
                    <FormControlLabel
                      id='keepSignedIn'
                      name='keepSignedIn'
                      control={
                        <Checkbox
                          defaultChecked
                          value='keepSignedIn'
                          color='primary'
                          onChange={e =>
                            setFieldValue(
                              'keepSignedIn',
                              e.target.checked ? 'keepSignedIn' : null
                            )
                          }
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                        />
                      }
                      label='Keep me logged in'
                      className={classes.checkbox}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={12}>
                {errorMessage ? (
                  <Box className={classes.error}>{errorMessage}</Box>
                ) : null}
              </Grid>

              <Grid item container>
                <Button
                  //className={classes.loginBtn}
                  disabled={loading || isSubmitting}
                  variant='outlined'
                  fullWidth
                  type='submit'
                >
                  Enter
                </Button>
              </Grid>

              <Grid item container justifyContent='space-between' mt={2}>
                <Grid item>
                  <Link
                    color='textPrimary'
                    component={RouterLink}
                    className={classes.forgotPw}
                    to={signUpPath()}
                  >
                    Sign Up
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    color='textPrimary'
                    component={RouterLink}
                    className={classes.forgotPw}
                    to={forgotPasswordPath()}
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </DefaultLayout>
  )
}
