import DefaultLayout from 'components/layout/DefaultLayout'
import { useAuth } from 'components/AuthProvider'

export default function InformationMemorandum(): JSX.Element {
  const { user } = useAuth()

  return (
    <DefaultLayout heading='Information Memorandum' showFooter={!!user}>
      <a
        href='/Information Memorandum - Goldie Nominee Limited-2023-11-22.pdf'
        target='_blank'
      >
        Download Information Memorandum
      </a>
    </DefaultLayout>
  )
}
