import { createTheme, PaletteColor, ThemeOptions } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1Serif: React.CSSProperties
    h2Serif: React.CSSProperties
    h3Serif: React.CSSProperties
    h4Serif: React.CSSProperties
    h5Serif: React.CSSProperties
    h6Serif: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1Serif?: React.CSSProperties
    h2Serif?: React.CSSProperties
    h3Serif?: React.CSSProperties
    h4Serif?: React.CSSProperties
    h5Serif?: React.CSSProperties
    h6Serif?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Serif: true
    h2Serif: true
    h3Serif: true
    h4Serif: true
    h5Serif: true
    h6Serif: true
  }
}

declare module '@mui/material/styles/createTheme' {
  interface Palette {
    disabled: PaletteColor
    blue: PaletteColor
    darkGray: PaletteColor
    green: PaletteColor
    lavender: PaletteColor
    yellow: PaletteColor
    black: PaletteColor
    white: PaletteColor
    default: PaletteColor
    ironSand: PaletteColor
    status: {
      positive: string
      negative: string
    }
    secondBackground: PaletteColor
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    disabled: PaletteColor
    blue: PaletteColor
    darkGray: PaletteColor
    green: PaletteColor
    lavender: PaletteColor
    yellow: PaletteColor
    black: PaletteColor
    white: PaletteColor
    default: PaletteColor
    ironSand: PaletteColor
    status: {
      positive: string
      negative: string
    }
    secondBackground: PaletteColor
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    disabled: PaletteColor
    blue: PaletteColor
    darkGray: PaletteColor
    green: PaletteColor
    lavender: PaletteColor
    yellow: PaletteColor
    black: PaletteColor
    white: PaletteColor
    default: PaletteColor
    ironSand: PaletteColor
    status: {
      positive: string
      negative: string
    }
    secondBackground?: PaletteColor
  }
  // allow configuration using `createPalette`
  interface PaletteOptions {
    disabled: PaletteColor
    blue: PaletteColor
    darkGray: PaletteColor
    green: PaletteColor
    lavender: PaletteColor
    yellow: PaletteColor
    black: PaletteColor
    white: PaletteColor
    default: PaletteColor
    status: {
      positive: string
      negative: string
    }
    secondBackground?: Partial<PaletteColor>
    sand?: Partial<PaletteColor>
    ironSand?: Partial<PaletteColor>
    roseGold?: Partial<PaletteColor>
    yellowGold?: Partial<PaletteColor>
    gold?: Partial<PaletteColor>
    bronze?: Partial<PaletteColor>
  }

  interface TypeBackground {
    white: string
    black: string
    blue: string
    darkGray: string
    green: string
    lavender: string
    yellow: string
  }

  interface TypeText {
    white: string
  }
}

declare module '@mui/material/Button/Button' {
  interface ButtonPropsColorOverrides {
    secondary: false
    error: false
    info: false
    success: false
    warning: false
    inherit: false

    disabled: true
    blue: true
    darkGray: true
    green: true
    lavender: true
    yellow: true
    black: true
    white: true
    default: true
    primary: true
  }
}

declare module '@mui/material/Link/Link' {
  interface LinkPropsColorOverrides {
    primary: false
    secondary: false
    error: false
    info: false
    success: false
    warning: false
    inherit: false

    disabled: true
    blue: true
    darkGray: true
    green: true
    lavender: true
    yellow: true
    black: true
    white: true
    default: true
  }
}

declare module '@mui/material/Card/Card' {
  interface CardPropsColorOverrides {
    primary: false
    secondary: false
    error: false
    info: false
    success: false
    warning: false
    inherit: false

    disabled: true
    blue: true
    darkGray: true
    green: true
    lavender: true
    yellow: true
    black: true
    white: true
    default: true
  }
}

declare module '@mui/material/CircularProgress/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    primary: false
    secondary: false
    error: false
    info: false
    success: false
    warning: false
    inherit: false

    disabled: true
    blue: true
    darkGray: true
    green: true
    lavender: true
    yellow: true
    black: true
    white: true
    default: true
  }
}

declare module '@mui/material/Link/Link' {
  interface LinkPropsColorOverrides {
    primary: false
    secondary: false
    error: false
    info: false
    success: false
    warning: false
    inherit: false

    disabled: true
    blue: true
    darkGray: true
    green: true
    lavender: true
    yellow: true
    black: true
    white: true
  }
}

declare module '@mui/material/SvgIcon/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    secondary: false
    error: false
    info: false
    success: false
    warning: false
    inherit: false

    primary: true
    disabled: true
    blue: true
    darkGray: true
    green: true
    lavender: true
    yellow: true
    black: true
    white: true
  }
}

declare module '@mui/material/Chip/Chip' {
  interface ChipPropsColorOverrides {
    primary: true
    secondary: false
    error: false
    info: false
    success: false
    warning: false
    inherit: false

    disabled: true
    blue: true
    darkGray: true
    green: true
    lavender: true
    yellow: true
    black: true
    white: true
  }
}

const palette = {
  primary: {
    main: '#D60078',
    light: '#FFFFFF',
    dark: '#000000',
    contrastText: '#F8F4FF'
  },
  secondary: {
    main: '#D60078' // until we learn to use 'secondary' let's just leave this with a safe value
  },
  background: {
    default: '#FBFBF8',
    white: '#FFFFFF',

    black: '#191919',
    blue: '#00AEEF',
    darkGray: '#282828',
    green: '#6FBE73',
    lavender: '#CDB1FF',
    yellow: '#F8D65D'

    // paper: '#F7F7F7'
  },
  secondBackground: {
    main: '#D60078'
  },
  blue: {
    light: '#D6EAFF',
    main: '#00AEEF',
    dark: '#8EA0B2',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  darkGray: {
    light: '#535353',
    main: '#282828',
    dark: '#1C1C1C',
    contrastText: '#FFFFFF'
  },
  green: {
    light: '#8BCB8F',
    main: '#6FBE73',
    dark: '#4D8550',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  lavender: {
    light: '#D7C0FF',
    main: '#CDB1FF',
    dark: '#8F7BB2',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  yellow: {
    light: '#F9DE7D',
    main: '#F4E400',
    dark: '#AD9541',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  black: {
    light: '#474747',
    main: '#211E16',
    dark: '#111111',
    contrastText: '#FFFFFF'
  },
  white: {
    main: '#FFFFFF',
    light: '#FFFFFF',
    dark: '#B2B2B2',
    //contrastText: 'rgba(0, 0, 0, 0.87)'
    contrastText: '#D60078'
  },
  default: {
    light: '#FFFFFF',
    main: '#D60078',
    dark: '#AFAFAC',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  disabled: {
    light: '#E0E0E0',
    main: '#C1C1C1',
    dark: '#979797',
    contrastText: '#9798A1'
  },
  error: {
    main: '#F73B4C'
  },
  warning: {
    main: '#FC6630'
  },
  info: {
    main: '#23AFF7',
    contrastText: '#D60078'
  },
  success: {
    main: '#6FBE73'
  },
  text: {
    primary: '#211E16',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: '#757575',
    white: '#FFFFFF'
  },
  status: {
    positive: '#6FBE73',
    negative: '#FF4242'
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  sand: {
    main: '#FBFBF8'
  },
  ironSand: {
    main: '#211E16'
  },
  roseGold: {
    main: '#D60078',
    lighter: 'hsl(324.41, 100%, 90%)' // Same color as `main` but lighter
  },
  yellowGold: {
    main: '#F4E400'
  },
  gold: {
    main: '#89764B'
  },
  bronze: {
    main: '#705641'
  }
}

const themeOptions: ThemeOptions = {
  palette: palette,
  typography: {
    fontFamily: ['Neue Haas Unica'].join(','),
    button: {
      fontFamily: ['Neue Haas Unica', 'sans-serif'].join(','),
      fontStyle: 'normal',
      lineHeight: '150%'
    },
    h1: {
      fontFamily: 'Moore',
      textAlign: 'center',
      color: '#D60078',
      fontSize: '5rem'
    },
    h1Serif: {
      fontFamily: ['Neue Haas Unica', 'sans-serif'].join(','),
      fontSize: '3.75rem'
    },
    h2: {
      fontFamily: ['Neue Haas Unica', 'sans-serif'].join(','),
      fontSize: '3.25rem'
    },
    h2Serif: {
      fontFamily: ['Neue Haas Unica', 'sans-serif'].join(','),
      fontSize: '3.25rem'
    },
    h3: {
      fontFamily: ['Neue Haas Unica', 'sans-serif'].join(','),
      fontSize: '2.5rem'
    },
    h3Serif: {
      fontFamily: ['Neue Haas Unica', 'sans-serif'].join(','),
      fontSize: '2.5rem'
    },
    h4: {
      fontFamily: ['Neue Haas Unica', 'sans-serif'].join(','),
      fontSize: '2.25rem'
    },
    h4Serif: {
      fontFamily: ['Neue Haas Unica', 'sans-serif'].join(','),
      fontSize: '2.25rem'
    },
    h5: {
      fontFamily: ['Neue Haas Unica', 'sans-serif'].join(','),
      fontSize: '1.75rem'
    },
    h5Serif: {
      fontFamily: ['Neue Haas Unica', 'sans-serif'].join(','),
      fontSize: '1.75rem'
    },
    h6: {
      fontFamily: ['Neue Haas Unica', 'sans-serif'].join(','),
      fontSize: '1.25rem'
    },
    h6Serif: {
      fontFamily: ['Neue Haas Unica', 'sans-serif'].join(','),
      fontSize: '1.25rem'
    },
    body1: {
      fontFamily: ['Neue Haas Unica', 'sans-serif'].join(','),
      fontSize: '1rem',
      fontWeight: 500,
      color: '#211E16'
    },
    body2: {
      fontFamily: ['Neue Haas Unica', 'sans-serif'].join(','),
      fontSize: '0.6875rem',
      color: '#211E16'
    }
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: '5px'
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          color: '#D60078'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar: {
          borderRadius: '50px'
        },
        determinate: {
          backgroundColor: '#EDEDED'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            backgroundColor: palette.background.default,
            borderRadius: '1rem'
          }
        }
      },
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          border: `1px solid ${palette.primary.main}`,
          background: '#FFFFFF',
          borderRadius: '0.5rem',

          ':after': {
            border: 'none'
          },
          ':hover': {
            background: '#FFFFFF'
          },
          ':hover:not(.Mui-disabled):before': {
            borderBottom: 'none'
          },
          '&.Mui-focused': {
            border: '1px solid',
            borderColor: palette.primary.main
          },
          '&.Mui-error': {
            border: '1px solid #F73B4C'
          },
          '&.Mui-disabled:before': {
            borderBottom: 'none'
          }
        },
        underline: {
          ':before': {
            border: 'none'
          }
        },
        input: {
          padding: '14px 24px 4px'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          left: '12px',
          top: '-4px',
          color: '#D60078'
        }
      },
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '1.25rem',
          boxShadow: 'none'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '1.25rem !important',
          boxShadow: 'none'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        color: 'primary',
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          // backgroundColor: '#D60078',
          borderRadius: '0.5rem',
          fontSize: '1.125rem',
          // Commented out for now because it makes all the other buttons look... not great.
          //boxShadow:
          //  '0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
          height: '60px',

          '&.Mui-disabled': {
            color: '#FFFFFF',
            backgroundColor: '#C1C1C1'
          }
        }
      }
    },
    MuiCircularProgress: {
      defaultProps: {
        color: 'blue'
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#E6E6E6',
          margin: '26px 0 20px'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          fontSize: '16px'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          borderRadius: '0.5rem',
          padding: '14px 24px 4px',
          border: `1px solid ${palette.primary.main}`
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem'
        },
        notchedOutline: {
          borderColor: palette.primary.main
        }
      }
    }
  }
}

export default createTheme({ ...themeOptions })
