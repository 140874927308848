import axios from 'utils/axios'

import { PaymentsParams, PaymentsResponse } from './types'

export async function payments(
  params: PaymentsParams,
  recaptchaToken?: string
): Promise<PaymentsResponse> {
  const response = await axios.post<PaymentsResponse>('/payments', {
    amount: params.amount,
    from_name: params.fromName,
    to_name: params.toName,
    from_email: params.fromEmail,
    to_email: params.toEmail,
    note: params.note ?? '',
    recaptcha_token: recaptchaToken,
    stripe_token: params.stripe_token,
    payment_type: params.payment_type
  })

  return response.data
}
