import Box from '@mui/material/Box'
import CircularProgress, {
  CircularProgressProps
} from '@mui/material/CircularProgress'

import { makeStyles } from '@mui/styles'
import LoadingAnimation from 'images/loading-animation.gif'

const useStyles = makeStyles(() => ({
  centred: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '9'
  }
}))

export interface LoadingWrapperProps {
  centred?: boolean | undefined
  children: JSX.Element | null
  className?: string | undefined
  color?: CircularProgressProps['color']
  size?: CircularProgressProps['size']
  loading: boolean
  animation?: boolean
}

export default function LoadingWrapper({
  centred = true,
  children,
  className,
  color = 'default',
  size = 120,
  loading,
  animation = false
}: LoadingWrapperProps): JSX.Element | null {
  const styles = useStyles()

  return loading ? (
    animation ? (
      <Box className={styles.centred}>
        <img src={LoadingAnimation} alt='loading-animation' />
      </Box>
    ) : (
      <Box className={centred ? styles.centred : ''}>
        <CircularProgress className={className} color={color} size={size} />
      </Box>
    )
  ) : (
    children
  )
}
