export default function Recharge(): JSX.Element {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 13.9422C9 15.0172 9.825 15.8839 10.85 15.8839H12.9417C13.8333 15.8839 14.5583 15.1255 14.5583 14.1922C14.5583 13.1755 14.1167 12.8172 13.4583 12.5839L10.1 11.4172C9.44167 11.1839 9 10.8255 9 9.80885C9 8.87552 9.725 8.11719 10.6167 8.11719H12.7083C13.7333 8.11719 14.5583 8.98385 14.5583 10.0589'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.7734 7V17'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.7581 5.07995C16.9931 4.44343 16.1086 3.92394 15.122 3.55909C10.46 1.83509 5.28308 4.2168 3.55907 8.87881C1.83506 13.5408 4.21678 18.7177 8.87878 20.4417C13.5408 22.1657 18.7177 19.784 20.4417 15.122C20.8065 14.1354 20.9875 13.1257 21.0019 12.1306'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M15 6H19V2'
        stroke='#1E1E1E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
