import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import LoadingWrapper from 'components/layout/LoadingWrapper'
import useStyles from './styles'

interface ButtonProps extends MuiButtonProps {
  showArrow?: boolean
  loading?: boolean
}

export default function Button(props: ButtonProps): JSX.Element {
  const classes = useStyles(props)

  const { children, showArrow = false, loading = false, ...rest } = props

  return (
    <MuiButton className={classes.root} {...rest}>
      <LoadingWrapper loading={loading} size={25} centred animation={false}>
        <>
          {children}
          {showArrow && <ArrowOutwardIcon className={classes.arrow} />}
        </>
      </LoadingWrapper>
    </MuiButton>
  )
}
