import { makeStyles } from '@mui/styles'
import { Theme, Typography, Stack } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  copyRightText: {
    // @ts-ignore
    color: theme.palette.bronze.main,
    fontSize: 11,
    fontFamily: 'Neue Haas Unica Pro'
  }
}))

export default function Copyright(): JSX.Element {
  const classes = useStyles()

  return (
    <Stack className={classes.wrapper}>
      <Typography className={classes.copyRightText} variant='body1'>
        Copyright Goldie Vaults Limited
      </Typography>
      <Typography className={classes.copyRightText} variant='body1'>
        support@getgoldie.co.nz
      </Typography>
      <Typography className={classes.copyRightText} variant='body1'>
        04 887 3779
      </Typography>
    </Stack>
  )
}
