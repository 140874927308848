import { Box, Grid, Typography } from '@mui/material'
import BalanceCard from 'components/BalanceCard'
import { withdrawFundsPath } from 'components/Routes'
import { activityPath, giftsPath, rechargePath } from 'components/Routes/Routes'
import Recharge from 'components/icons/Recharge'
import RedeemGift from 'components/icons/RedeemGift'
import Withdraw from 'components/icons/Withdraw'
import DefaultLayout from 'components/layout/DefaultLayout'
import { useActivityForCurrentUserQuery } from 'generated/graphql'
import { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import ActivityItem from '../Activity/ActivityItem'
import { Vault } from '../Activity/ActivityItem/ActivityItem'
import useStyles from './styles'

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 5

export type WalletProps = RouteComponentProps

export default function Wallet({ history }: WalletProps): JSX.Element {
  const classes = useStyles()

  const walletList = [
    {
      label: 'Deposit',
      icon: <Recharge />,
      path: rechargePath(),
      localStorage: () => localStorage.removeItem('url_after_POLi_transfer')
    },
    {
      label: 'Withdraw',
      icon: <Withdraw />,
      path: withdrawFundsPath()
    },
    {
      label: 'Gift',
      icon: <RedeemGift />,
      path: giftsPath()
    }
  ]

  function handleClickCarousel(path: string, localStorage?: () => void) {
    localStorage && localStorage()
    history.push(path)
  }

  const {
    data: activityForCurrentUserData,
    loading: activityForCurrentUserLoading,
    refetch
  } = useActivityForCurrentUserQuery({
    variables: { limit: DEFAULT_PER_PAGE, page: DEFAULT_PAGE }
  })

  const { items } = activityForCurrentUserData?.activityForCurrentUser || {}

  useEffect(() => {
    refetch()
  }, [refetch])

  const handleClickSeeAllActivities = () => {
    history.push(activityPath())
  }

  return (
    <DefaultLayout
      backgroundColor='white'
      wrapperContent={{ mb: 4, mt: 2, px: 3 }}
      showBackIcon
      noLogo
      headerTitle='My Wallet'
      loading={activityForCurrentUserLoading}
      heading={<BalanceCard />}
    >
      <Grid container mt={2}>
        <Grid item container flexWrap='nowrap' gap={2}>
          {walletList.map(item => (
            <Grid
              item
              xs={4}
              key={item.path}
              className={classes.walletItem}
              onClick={() => handleClickCarousel(item.path, item.localStorage)}
            >
              <Box>{item.icon}</Box>
              <Typography>{item.label}</Typography>
            </Grid>
          ))}
        </Grid>

        <Grid
          item
          xs={12}
          mt={5}
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <Typography fontSize='18px' fontWeight={700}>
              Account activity
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              fontSize='12px'
              color='#757575'
              onClick={handleClickSeeAllActivities}
            >
              See all
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box mt={1}>
            {items?.length ? (
              items.map(activity => {
                return (
                  <ActivityItem
                    key={activity.id}
                    activity={activity}
                    vault={activity.vault as Vault}
                  />
                )
              })
            ) : (
              <Box component='section'>
                <Typography component='p'>
                  Your account has no activity yet
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </DefaultLayout>
  )
}
