import axios from 'utils/axios'
import { User } from 'types'

export async function signUp(params: {
  email: string
  password: string
  signup_token: string
  recaptcha_token?: string
  marketing_opt_in?: boolean
  referrer_code?: string
}): Promise<User> {
  const { recaptcha_token, ...user } = params
  const response = await axios.post('/sign_up', {
    user,
    recaptcha_token
  })

  return response.data.user
}

export async function confirmEmail({
  email,
  confirmation_token,
  recaptcha_token
}: {
  email: string
  confirmation_token: string
  recaptcha_token?: string
}): Promise<User> {
  const response = await axios.post('/user/confirm', {
    email,
    confirmation_token,
    recaptcha_token
  })

  return response.data.user
}

export async function resendConfirmationEmail({
  email,
  recaptcha_token
}: {
  email: string
  recaptcha_token?: string
}): Promise<{ status: string }> {
  const response = await axios.post('/user/resend', {
    email,
    recaptcha_token
  })

  return response.data
}

export async function requestResetPassword({
  email,
  recaptcha_token
}: {
  email: string
  recaptcha_token?: string
}): Promise<{ status: string }> {
  const response = await axios.post('/user/passwords', {
    email,
    recaptcha_token
  })

  return response.data
}

export async function resetPassword({
  token,
  password,
  recaptcha_token
}: {
  token: string
  password: string
  recaptcha_token?: string
}): Promise<{ status: string }> {
  const response = await axios.put('/user/passwords', {
    reset_password_token: token,
    password,
    recaptcha_token
  })

  return response.data
}

export async function createUserInvite(params: {
  name: string
  email: string
  recaptcha_token?: string
  utm_id?: string
}): Promise<unknown> {
  const { recaptcha_token, utm_id, ...user_invite } = params

  const response = await axios.post('/user_invites', {
    user_invite,
    recaptcha_token,
    utm_id
  })

  return response.data
}
