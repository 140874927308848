/* eslint-disable no-console */
import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Link, Theme } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { isDesktop } from 'react-device-detect'

import { makeStyles } from '@mui/styles'
import { useEffect, useRef, useState } from 'react'
import { installAppPath } from 'components/Routes'
import useOnScreen from 'hooks/useOnScreen'
import theme from 'utils/theme'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    // @ts-ignore
    backgroundColor: theme.palette.roseGold.lighter,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',

    '& > p': {
      textDecoration: 'underline',
      textAlign: 'center',
      flex: 1,
      cursor: 'pointer'
    }
  },
  title: {
    display: 'block',
    textAlign: 'center',
    whiteSpace: 'nowrap'
  }
}))

interface AddToHomeScreenProps {
  isHasVerificationBanner?: boolean
}

export default function AddToHomeScreenBanner({
  isHasVerificationBanner
}: AddToHomeScreenProps): JSX.Element {
  const classes = useStyles()

  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)

  const [showAppInstallBanner, setShowAppInstallBanner] = useState<boolean>(
    JSON.parse(localStorage.getItem('appInstallDismissed') as string) as boolean
  )

  const standAlone = window.matchMedia('(display-mode: standalone)')
  const browser = window.matchMedia('(display-mode: browser)')

  const isPWAInstalled = async () => {
    // TODO: Also ensure app install banner is not displayed on non-mobile device
    if (standAlone.matches) {
      setShowAppInstallBanner(true)
      localStorage.setItem('appInstallDismissed', JSON.stringify(true))
      return
    }
    if (browser.matches) {
      if (
        JSON.parse(
          localStorage.getItem('appInstallDismissed') as string
        ) as boolean
      ) {
        setShowAppInstallBanner(true)
      } else {
        setShowAppInstallBanner(false)
      }
    }
  }

  // TODO: Make CloseIcon click store `appInstallDismissed` setting in localstorage so it is remembered on this device. Don't display banner if it has been dismissed before.
  const handleClose = () => {
    localStorage.setItem('appInstallDismissed', JSON.stringify(true))
    setShowAppInstallBanner(true)
  }

  useEffect(() => {
    isPWAInstalled()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [standAlone])

  useEffect(() => {
    if (!isHasVerificationBanner) {
      document
        .querySelector("meta[name='theme-color']")
        ?.setAttribute(
          'content',
          isVisible
            ? 'hsl(324.41, 100%, 90%)'
            : theme.palette.background.default
        )
    }
  }, [isHasVerificationBanner, isVisible])

  // comment out `isDesktop` while testing if you need.
  if (showAppInstallBanner || isDesktop) {
    return <></>
  }

  return (
    <Box className={classes.container} ref={ref}>
      <Link
        className={classes.title}
        color='textPrimary'
        component={RouterLink}
        to={installAppPath()}
        underline='always'
      >
        Add the Goldie app to your home screen
      </Link>
      <IconButton aria-label='Close drawer' edge='start' onClick={handleClose}>
        <CloseIcon fontSize='large' color='darkGray' />
      </IconButton>
    </Box>
  )
}
