import { NumberParam, useQueryParams, withDefault } from 'use-query-params'

export interface UsePaginationProps {
  page?: number
  perPage?: number
}

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 10

const INITIAL_STATE: UsePaginationProps = {
  page: DEFAULT_PAGE,
  perPage: DEFAULT_PER_PAGE
}

type UsePaginationReturnType = [
  { page: number; perPage: number },
  (nextPagination?: UsePaginationProps) => void
]

export default function usePagination(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: UsePaginationProps = INITIAL_STATE
): UsePaginationReturnType {
  const [pagination, setPagination] = useQueryParams({
    page: withDefault(NumberParam, DEFAULT_PAGE),
    perPage: withDefault(NumberParam, DEFAULT_PER_PAGE)
  })

  const updatePagination = (nextPagination?: UsePaginationProps) => {
    if (shouldUpdate(nextPagination)) {
      const { page, perPage } = nextPagination || {}

      setPagination({ page, perPage })
    }
  }

  const shouldUpdate = (nextPagination?: UsePaginationProps) => {
    return (
      pagination.page !== nextPagination?.page &&
      pagination.perPage !== nextPagination?.perPage
    )
  }

  return [pagination, updatePagination]
}
