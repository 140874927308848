import ReactVerificationInput from 'react-verification-input'
import { VerificationInputProps as ReactVerificationInputProps } from 'react-verification-input'

import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  verificationInputContainer: {
    width: '100% !important'
  },
  verificationInputCharacter: {
    display: 'inline-block',
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: '8px',

    fontSize: '40px',
    height: '1.1876em !important',
    lineHeight: '0px',
    padding: theme.spacing(4, 0),
    color: theme.palette.primary.main,

    '&:not(:first-child)': {
      marginLeft: theme.spacing(1.5)
    }
  },
  verificationInputCharacterSelected: {
    borderColor: theme.palette.primary.main,
    borderWidth: '2px'
  }
}))

export type VerificationInputProps = ReactVerificationInputProps

export default function VerificationInput(
  props: VerificationInputProps
): JSX.Element {
  const classes = useStyles()

  const verificationInputProps: VerificationInputProps = {
    ...props,
    classNames: {
      character: classes.verificationInputCharacter,
      characterSelected: classes.verificationInputCharacterSelected,
      container: classes.verificationInputContainer
    },
    length: 6,
    inputProps: {
      name: 'otp'
    },
    placeholder: '',
    removeDefaultStyles: true
  }

  return <ReactVerificationInput {...verificationInputProps} />
}
