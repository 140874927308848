import FaceIcon from '@mui/icons-material/Face'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import WalletIcon from '@mui/icons-material/Wallet'
import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useAuth } from 'components/AuthProvider'
import DefaultLayout from 'components/layout/DefaultLayout'
import CallIcon from '@mui/icons-material/Call'
import {
  contactPath,
  kidVaultPath,
  settingsPath,
  tutorialPath,
  walletPath
} from 'components/Routes/Routes'
import { useHistory } from 'react-router-dom'
import useStyles from './styles'

export default function More(): JSX.Element {
  const classes = useStyles()

  const history = useHistory()

  const { logout } = useAuth()

  const settings = [
    {
      id: 'settings',
      path: settingsPath(),
      label: 'Settings',
      icon: <SettingsIcon color='primary' />
    },
    {
      id: 'wallet',
      path: walletPath(),
      label: 'Wallet',
      icon: <WalletIcon color='primary' />
    },
    {
      id: 'kids_vault',
      path: kidVaultPath(),
      label: 'Kids Vault',
      icon: <FaceIcon color='primary' />
    },
    {
      id: 'tutorials',
      path: tutorialPath(),
      label: 'Tutorials/Courses',
      icon: <LibraryBooksIcon color='primary' />
    },
    {
      id: 'contact',
      path: contactPath(),
      label: 'Contact',
      icon: <CallIcon color='primary' />
    }
  ]

  const handleClickButton = (path: string) => {
    history.push(path)
  }

  return (
    <DefaultLayout
      backgroundColor='white'
      wrapperContent={{ mt: 0, mb: 2 }}
      showFooter
    >
      <Grid container rowSpacing={2} py={4}>
        {settings.map(setting => (
          <Grid item xs={12} key={setting.id}>
            <Box
              className={classes.setting}
              onClick={() => handleClickButton(setting.path)}
            >
              <Box>{setting.icon}</Box>
              <Typography variant='h5'>{setting.label}</Typography>
            </Box>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Box className={classes.setting} onClick={logout}>
            <LogoutIcon color='primary' />
            <Typography variant='h5'>Log Out</Typography>
          </Box>
        </Grid>
      </Grid>
    </DefaultLayout>
  )
}
