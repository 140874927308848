import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import { tradePath, myVaultPath, autoInvestPath } from 'components/Routes'
import {
  // Product,
  useCreateReviewOrderForSellMutation,
  useGetLatestSpotPriceQuery,
  useProductPerformanceQuery
} from 'generated/graphql'
import { memo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { formattedPrice, roundDownDollarsAndCents } from 'utils/util'
import { voucherFlowPath } from 'components/Routes/Routes'
import { ProductInclLocal } from 'hooks/useProducts'
const drawerBleeding = 80

interface TradeSubNavMenuProps {
  product?: ProductInclLocal
  onClose: () => void
}

function TradeSubNavMenu({
  product,
  onClose
}: Readonly<TradeSubNavMenuProps>): JSX.Element {
  const { setToast } = useAuth()
  const productId = product?.id

  const history = useHistory()

  const { data: latestSpotPrice } = useGetLatestSpotPriceQuery({
    variables: { productId: Number(productId) },
    skip: !productId
  })

  const { data: productPerformance } = useProductPerformanceQuery({
    variables: { productId: Number(productId) },
    skip: !productId
  })

  const [createReviewOrderForSell] = useCreateReviewOrderForSellMutation({
    onError: error => {
      setToast({
        open: true,
        message: error.message,
        type: 'error',
        duration: 3000
      })
    }
  })

  const getMidpointPerG = useCallback(() => {
    const { midpointPerDg } = latestSpotPrice?.latestSpotPrice ?? {}
    return midpointPerDg
      ? roundDownDollarsAndCents(Number(midpointPerDg) * 10).toFixed(2)
      : '0.00'
  }, [latestSpotPrice])

  const getCurrentValue = useCallback(() => {
    const { currentValue } = productPerformance?.productPerformance ?? {}

    const amount = Number(currentValue).toFixed(2)
    return amount ? formattedPrice(amount) : '0.00'
  }, [productPerformance])

  const getWeightInGrams = useCallback(() => {
    const { ownedAllotmentsCount } =
      productPerformance?.productPerformance ?? {}

    return ownedAllotmentsCount ? Number(ownedAllotmentsCount) / 10 : 0
  }, [productPerformance])

  const handleClickBuyMore = useCallback(() => {
    history.push(tradePath({ routeParams: { productId } }))
  }, [history, productId])

  const handleClickSell = useCallback(() => {
    createReviewOrderForSell({
      variables: {
        input: {
          weight: 1,
          productId: Number(productId)
        }
      },
      onCompleted: ({ reviewOrder }) => {
        const { id } = reviewOrder ?? {}
        if (id) {
          history.push(
            myVaultPath({
              queryParams: { type: 'sell', id }
            })
          )
        }
      }
    }).then()
  }, [history, productId, createReviewOrderForSell])

  return (
    <Box>
      <Box textAlign='right'>
        <IconButton aria-label='Close drawer' edge='start' onClick={onClose}>
          <CloseIcon fontSize='large' />
        </IconButton>
      </Box>
      <Stack height={drawerBleeding} id='bullion'>
        <Box paddingX={2} display='flex' justifyContent='space-between'>
          <Box>
            <Typography color='primary' fontSize='1rem'>
              {product?.name.toUpperCase()} BULLION
            </Typography>
            <Typography color='primary' fontSize='1rem'>
              99.99% FINE {product?.name.toUpperCase()}
            </Typography>
          </Box>
          <Box textAlign='right'>
            <Typography color='primary' fontSize='0.75rem'>
              PER GRAM: ${getMidpointPerG()}
            </Typography>
            <Typography color='primary' fontSize='0.75rem'>
              YOU OWN: ${getCurrentValue()}
            </Typography>
          </Box>
        </Box>
      </Stack>

      <Stack px={2} pb={4}>
        <Typography color='common.black' fontSize='1rem'>
          You own {getWeightInGrams()} grams.
        </Typography>
        <Typography color='common.black' fontSize='1rem'>
          Current value is ${getCurrentValue()}
        </Typography>
      </Stack>

      <Stack px={2} py={3} gap={1}>
        <Button
          variant='outlined'
          fullWidth
          onClick={() => history.push(autoInvestPath())}
        >
          Auto Invest
        </Button>
        <Button variant='outlined' fullWidth onClick={handleClickBuyMore}>
          Buy More
        </Button>
        <Button
          variant='outlined'
          fullWidth
          onClick={() => history.push(voucherFlowPath())}
        >
          Gift
        </Button>
        <Button variant='outlined' fullWidth onClick={handleClickSell}>
          Sell
        </Button>
      </Stack>
    </Box>
  )
}

export default memo(TradeSubNavMenu)
