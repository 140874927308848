import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  header: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: '3rem',
    margin: theme.spacing(4, 0)
  },
  input: {
    '& input': {
      paddingLeft: theme.spacing(0)
    },

    '& .MuiFilledInput-root': {
      paddingLeft: '16px',
      background: 'none !important',

      '& .MuiInputAdornment-root': {
        marginRight: '2px'
      },

      '& p': {
        color: theme.palette.black.main
      }
    }
  },
  buttonPoli: {
    background: '#DC1928',
    lineHeight: '70%',
    padding: theme.spacing(1.5, 0)
  },
  buttonStripe: {
    background: '#635BFF',
    lineHeight: '70%',
    padding: theme.spacing(1.5, 0)
  },
  buttonApplePay: {
    background: theme.palette.black.main,
    lineHeight: '70%',
    padding: theme.spacing(1.5, 0)
  }
}))
