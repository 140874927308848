import EmailIcon from '@mui/icons-material/EmailOutlined'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import { Box, Grid, Typography } from '@mui/material'
import DefaultLayout from 'components/layout/DefaultLayout'
import useStyles from './styles'

export const contact = [
  {
    id: 'email',
    label: 'Email',
    path: 'mailto:support@getgoldie.co.nz',
    icon: <EmailIcon />
  },
  {
    id: 'chat_on_the_phone',
    label: 'Chat on the phone',
    path: 'tel:+6448873779',
    icon: <PhoneAndroidIcon />
  }
]

export default function Contact(): JSX.Element {
  const classes = useStyles()
  return (
    <DefaultLayout heading='Contact' showBackIcon showSecondNav={false}>
      <Grid container gap={2} component='ul' className={classes.root}>
        {contact.map(menu => (
          <Grid component='li' item xs={12} key={menu.id}>
            <Box className={classes.menu} component='a' href={menu.path}>
              <Box>{menu.icon}</Box>
              <Typography>{menu.label}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </DefaultLayout>
  )
}
