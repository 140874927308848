import queryString from 'query-string'
import { useCallback, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { RouteComponentProps } from 'react-router-dom'

import { useAuth } from 'components/AuthProvider'
import { loginPath, resendConfirmationEmailPath } from 'components/Routes'

import {
  VisibilityOffRounded as VisibilityOffIcon,
  VisibilityRounded as VisibilityIcon
} from '@mui/icons-material'
import {
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography
} from '@mui/material'
import Button from 'components/Button'
import DefaultLayout from 'components/layout/DefaultLayout'
import { Link as RouterLink } from 'react-router-dom'

import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import PasswordProgress from 'components/PasswordProgress'
import { Field, FieldProps, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  link: {
    color: theme.palette.primary.main
  }
}))

export type ResetPasswordProps = RouteComponentProps

interface FormValues {
  newPassword: string
  confirmPassword: string
}

const initialValues = {
  newPassword: '',
  confirmPassword: ''
}

const validationSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .required('Confirm new password is required')
    .oneOf([Yup.ref('newPassword'), null], `Confirm new password doesn't match`)
})

export default function ResetPassword({
  location
}: ResetPasswordProps): JSX.Element {
  const classes = useStyles()

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [disableButton, setDisableButton] = useState<boolean>(false)

  const handleToggleShowPassword = () => setShowPassword(!showPassword)

  const { reset, loading } = useAuth()

  const { reset_token } = queryString.parse(location.search)

  const recaptchaEnabled = JSON.parse(
    process.env.REACT_APP_RECAPTCHA_ENABLED || 'true'
  )
  const { executeRecaptcha } = useGoogleReCaptcha()

  async function handleSubmit(values: FormValues) {
    if (!executeRecaptcha && recaptchaEnabled) return

    const recaptchaToken =
      recaptchaEnabled &&
      executeRecaptcha &&
      (await executeRecaptcha('passwords/reset'))

    reset(reset_token as string, values.newPassword, recaptchaToken)
  }

  const renderHeader = useCallback(() => {
    return (
      <Typography component='h1' variant='h1' fontSize='4.5rem' color='primary'>
        Change password
      </Typography>
    )
  }, [])

  return (
    <DefaultLayout
      backgroundColor='white'
      containerMaxWidth='xs'
      heading={renderHeader()}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PasswordProgress
                  autoComplete='new-password'
                  label='New password'
                  required
                  onChange={(password: string, hasError?: boolean) => {
                    setFieldValue('newPassword', password)
                    if (hasError) setDisableButton(true)
                    else setDisableButton(false)
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Field name='confirmPassword' autoComplete='new-password'>
                  {({ field, form, meta }: FieldProps) => (
                    <TextField
                      field={field}
                      form={form}
                      meta={meta}
                      disabled={false}
                      fullWidth
                      label='Confirm new password'
                      required
                      onChange={e => {
                        setFieldValue('confirmPassword', e.target.value)
                      }}
                      InputProps={{
                        style: { background: '#ffffff' },
                        // This is where the toggle 'view password' button is added
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={() => handleToggleShowPassword()}
                              size='large'
                              tabIndex={-1}
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      sx={{ background: 'none' }}
                      type={showPassword ? 'text' : 'password'}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={12} mt={2}>
                <Button
                  color='primary'
                  fullWidth
                  disabled={loading || disableButton}
                  type='submit'
                >
                  Change password
                </Button>
              </Grid>
              <Grid item container justifyContent='space-around'>
                <Link
                  color='textPrimary'
                  component={RouterLink}
                  to={loginPath()}
                  underline='always'
                  className={classes.link}
                >
                  Go back to login
                </Link>
              </Grid>
              <Grid item container justifyContent='space-around'>
                <Typography align='center' variant='body1'>
                  <Link
                    color='textPrimary'
                    component={RouterLink}
                    to={resendConfirmationEmailPath()}
                    underline='always'
                    className={classes.link}
                  >
                    Resend confirmation email
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </DefaultLayout>
  )
}
