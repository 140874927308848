import dayjs from 'dayjs'
import * as Yup from 'yup'

export const DATE_OF_BIRTH_MASK = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const DATE_OF_BIRTH_REGEX =
  /^(?:0[1-9]|[12][0-9]|3[01])[/](?:0[1-9]|1[012])[/](?:19\d{2}|20[01][0-9]|2020)$/
export const NZ_MOBILE_PHONE_NUMBER_REGEX = /^0[0-9]{8,11}$/
export const DRIVERS_LICENCE_NUMBER_REGEX = /^[A-Za-z]{2}[0-9]{6}$/
export const DRIVERS_LICENCE_VERSION_REGEX = /^[0-9]{3}$/
export const PASSPORT_NUMBER_REGEX = /^[A-Za-z]{2}[0-9]{6}$/

export const VALIDATION_VERIFY_STEP_ONE = {
  firstName: Yup.string().max(100).required('This field is required'),
  middleName: Yup.string().max(100),
  lastName: Yup.string().max(100).required('This field is required'),
  preferredName: Yup.string().max(100),
  dateOfBirth: Yup.string()
    .matches(DATE_OF_BIRTH_REGEX, {
      excludeEmptyString: true,
      message: 'Invalid. Must be in the format DD/MM/YYYY'
    })
    .required('This field is required'),
  addressLine1: Yup.string().max(100).required('This field is required'),
  addressLine2: Yup.string().max(100),
  suburb: Yup.string().max(100),
  city: Yup.string().max(100).required('This field is required'),
  postcode: Yup.string().length(4).required('This field is required'),
  nzMobilePhoneNumber: Yup.string()
    .matches(NZ_MOBILE_PHONE_NUMBER_REGEX, {
      excludeEmptyString: true,
      message: 'Invalid. Must be between 9 and 12 digits starting with a zero'
    })
    .required('This field is required'),
  ethnicity: Yup.string().required('This field is required')
}

export const VALIDATION_VERIFY_STEP_TWO = {
  driversLicenceNumber: Yup.string().when('verificationMethod', {
    is: (value: string) => value === 'drivers_licence',
    then: Yup.string()
      .matches(DRIVERS_LICENCE_NUMBER_REGEX, {
        excludeEmptyString: true,
        message: 'Invalid. Must be 2 letters, followed by 6 digits'
      })
      .required('Drivers licence number is required')
  }),
  driversLicenceVersion: Yup.string().when('verificationMethod', {
    is: (value: string) => value === 'drivers_licence',
    then: Yup.string()
      .matches(DRIVERS_LICENCE_VERSION_REGEX, {
        excludeEmptyString: true,
        message: 'Invalid. Must be 3 digits'
      })
      .required('Drivers licence version is required')
  }),
  passportNumber: Yup.string().when('verificationMethod', {
    is: (value: string) => value === 'passport',
    then: Yup.string()
      .matches(PASSPORT_NUMBER_REGEX, {
        excludeEmptyString: true,
        message: 'Invalid passport number'
      })
      .required('Passport number is required')
  }),
  passportExpiry: Yup.string().when('verificationMethod', {
    is: (value: string) => value === 'passport',
    then: Yup.string()
      .required('Passport expiry is required')
      .test('passportExpiry', 'Invalid expiry date', value => {
        return dayjs(value) > dayjs()
      })
  })
}

const VALIDATION_FIELDS_MAPPING = {
  ...VALIDATION_VERIFY_STEP_ONE,
  ...VALIDATION_VERIFY_STEP_TWO
}

export default VALIDATION_FIELDS_MAPPING
