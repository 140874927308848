import { Divider, Grid, Slide, Typography } from '@mui/material'
import { ReviewPaymentData } from 'api/payments/types'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import { dashboardPath } from 'components/Routes'
import { voucherPath } from 'components/Routes/VoucherFlowRoutes'
import DefaultLayout from 'components/layout/DefaultLayout'
import { useBuyVoucherMutation } from 'generated/graphql'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { formattedPrice } from 'utils/util'
import useStyles from './styles'

export default function VoucherReview(): JSX.Element {
  const classes = useStyles()

  const history = useHistory()
  const { setToast } = useAuth()
  const { state } = useLocation<ReviewPaymentData>()

  const { voucher_amount, fee, payment_amount, to_email, to_name, note } =
    state || {}

  const [buyVoucher, { loading }] = useBuyVoucherMutation()

  const total = formattedPrice(
    Number(voucher_amount) + Number(payment_amount) + Number(fee)
  )

  const handleBuyVoucher = () => {
    buyVoucher({
      variables: {
        input: {
          amount: Number(voucher_amount),
          toEmail: to_email,
          toName: to_name,
          note
        }
      },
      onCompleted: data => {
        setToast({
          message: data.buyVoucher?.message as string,
          open: true,
          duration: 3000,
          type: 'success'
        })
        history.push(dashboardPath())
      },
      onError: error =>
        setToast({
          message: error.message,
          open: true,
          duration: 3000,
          type: 'error'
        })
    })
  }

  const formInformation = [
    {
      label: 'From Wallet Balance',
      value: voucher_amount,
      unit: '$'
    },
    {
      label: 'Credit Card',
      value: payment_amount,
      unit: '$'
    },
    {
      label: 'CC fee of',
      value: fee,
      unit: '$'
    }
  ]

  useEffect(() => {
    if (!state) {
      history.push(voucherPath())
    }
  }, [history, state])

  return (
    <DefaultLayout
      wrapperContainer={{ px: 2, py: 4 }}
      wrapperContent={{ m: 0 }}
      showBackIcon
    >
      <Grid p={2} className={classes.root}>
        <Grid item overflow='hidden' container justifyContent='center'>
          <Slide direction='up' in={true} timeout={700}>
            <Grid item>
              <Typography component='h1' variant='h1' textAlign='center'>
                Voucher Review
              </Typography>
              <Typography component='h2' textAlign='center'>
                {`We will email a gift voucher to ${to_name} at ${to_email}`}
              </Typography>
            </Grid>
          </Slide>
        </Grid>

        <Divider />

        <Grid item container spacing={2}>
          {formInformation.map(info => (
            <Grid
              item
              container
              justifyContent='space-between'
              alignItems='center'
              key={info.label}
            >
              <Grid item>
                <Typography className={classes.label}>{info.label}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {info.unit + formattedPrice(info.value)}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Divider />

        <Grid
          item
          container
          xs={12}
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid item>
            <Typography component='p' variant='body1' fontWeight='700'>
              Total
            </Typography>
          </Grid>
          <Grid item>
            <Typography component='p' variant='body1' fontWeight='700'>
              ${total}
            </Typography>
          </Grid>
        </Grid>
        {!!note && (
          <Grid item xs={12} mt={4}>
            <Typography>Message: {note}</Typography>
          </Grid>
        )}

        <Grid item container mt={3}>
          <Button
            color='primary'
            variant='outlined'
            data-testid='review-and-send-gift-button'
            fullWidth
            type='submit'
            disabled={loading}
            onClick={handleBuyVoucher}
          >
            Review & send gift
          </Button>
        </Grid>
      </Grid>
    </DefaultLayout>
  )
}
