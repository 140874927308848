import { Grid, Slide, Typography } from '@mui/material'
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { FormEvent, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { voucherPath } from 'components/Routes/VoucherFlowRoutes'

import Button from 'components/Button'
import DefaultLayout from 'components/layout/DefaultLayout'

import { PaymentsResponse, ReviewPaymentData } from 'api/payments/types'
import { useAuth } from 'components/AuthProvider'
import { usePayments } from 'components/PaymentsProvider'
import { dashboardPath } from 'components/Routes'
import {
  namedOperations,
  useBuyVoucherMutation,
  useWalletForCurrentUserQuery
} from 'generated/graphql'
import { formattedPrice } from 'utils/util'
import useStyles from './styles'

interface VoucherCheckoutProps {
  payment: PaymentsResponse
}

function VoucherCheckout({ payment }: VoucherCheckoutProps): JSX.Element {
  const stripe = useStripe()
  const elements = useElements()
  const classes = useStyles()
  const { setToast } = useAuth()
  const history = useHistory()

  const { state } = useLocation<ReviewPaymentData>()

  const { fee, payment_amount, to_email, to_name, voucher_amount } = state || {}

  const { review_data } = payment

  const total = formattedPrice(Number(voucher_amount) + Number(payment_amount))

  const [disableBtn, setDisableBtn] = useState<boolean>(false)

  const [buyVoucher, { loading: buyVoucherLoading }] = useBuyVoucherMutation()

  const { refetch } = useWalletForCurrentUserQuery({ skip: true })

  const formInformation = [
    {
      label: 'From Wallet Balance',
      value: voucher_amount,
      unit: '$'
    },
    {
      label: 'Credit Card',
      value: Number(payment_amount) - Number(fee),
      unit: '$'
    },
    {
      label: 'CC fee of',
      value: fee,
      unit: '$'
    }
  ]

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    setDisableBtn(true)

    if (!stripe) return
    if (!elements) return

    const {
      data: {
        walletForCurrentUser: { balance }
      }
    } = await refetch()

    if (Number(balance) < Number(review_data.wallet_use_amount)) {
      return setToast({
        message: 'Your wallet balance has changed, please try again',
        open: true,
        type: 'error',
        duration: 3000
      })
    }

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}/#${dashboardPath()}`
      }
    })
    if (error) {
      setToast({
        message: error.message as string,
        open: true,
        type: 'error',
        duration: 3000
      })
      setDisableBtn(false)
    } else {
      buyVoucher({
        variables: {
          input: {
            paymentIntentId: payment.payment_intent?.id as string,
            toEmail: review_data?.to_email,
            toName: review_data?.to_name,
            amount: Number(review_data?.voucher_amount)
          }
        },
        onCompleted: () => {
          setToast({
            message: 'Your gift it on its way 💝',
            open: true,
            type: 'success',
            duration: 3000
          })
          history.push(dashboardPath())
        },
        onError: error => {
          setToast({
            message: error.message,
            open: true,
            type: 'error',
            duration: 3000
          })
          setDisableBtn(false)
        },
        refetchQueries: [namedOperations.Query.walletForCurrentUser],
        awaitRefetchQueries: true
      })
    }
  }

  return (
    <DefaultLayout wrapperContent={{ mt: 0, mb: 4 }} center showBackIcon>
      <form
        className={classes.form}
        id='payment-form'
        data-testid='payment-form'
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2} className={classes.root}>
          <Grid item xs={12} overflow='hidden'>
            <Slide direction='up' in={true} timeout={700}>
              <Grid
                item
                container
                flexDirection='column'
                justifyContent='center'
              >
                <Typography component='h1' variant='h2' textAlign='center'>
                  Voucher Review
                </Typography>
                <Typography component='h2'>
                  We will email a gift voucher to
                  <b>{` ${to_name} `}</b>
                  at
                  <b>{` ${to_email} `}</b>
                </Typography>
              </Grid>
            </Slide>
          </Grid>

          <Grid item container spacing={1}>
            {formInformation.map(info => (
              <Grid
                item
                container
                justifyContent='space-between'
                alignItems='center'
                key={info.label}
              >
                <Grid item>
                  <Typography className={classes.label}>
                    {info.label}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {info.unit + formattedPrice(info.value)}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid
            item
            container
            xs={12}
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item>
              <Typography component='p' variant='body1' fontWeight='700'>
                Total
              </Typography>
            </Grid>
            <Grid item>
              <Typography component='p' variant='body1' fontWeight='700'>
                ${total}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <PaymentElement id='payment-element' />
          </Grid>

          <Grid item xs={12}>
            <Button
              disabled={!stripe || !elements || buyVoucherLoading || disableBtn}
              fullWidth
              color='primary'
              size='medium'
              type='submit'
            >
              Pay & Send Gift
            </Button>
          </Grid>
        </Grid>
      </form>
    </DefaultLayout>
  )
}

export default function StripeWrapper(): JSX.Element | null {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_CLIENT_PUBLIC_KEY ?? ''
  )

  const { payment } = usePayments()
  const history = useHistory()

  if (payment === undefined) {
    history.push(voucherPath())
    return null
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret: payment.payment_intent?.client_secret }}
    >
      <VoucherCheckout payment={payment} />
    </Elements>
  )
}
