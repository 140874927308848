import { useAuth } from 'components/AuthProvider'
import DefaultLayout from 'components/layout/DefaultLayout'

export default function PrivacyPolicy(): JSX.Element {
  const { user } = useAuth()

  return (
    <DefaultLayout heading='Privacy Policy' showFooter={!!user}>
      <a href='/GOLDIE Privacy Policy-2023-11-22.pdf' target='_blank'>
        Download privacy policy
      </a>
    </DefaultLayout>
  )
}
