import { useAuth } from 'components/AuthProvider'

import HelpIcon from '@mui/icons-material/Help'
import { Box, Card, CardContent, Grid, Link, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CustomTooltip from 'components/Tooltip/Tooltip'
import DefaultLayout from 'components/layout/DefaultLayout'
const useStyles = makeStyles(theme => ({
  accountGroup: {
    padding: theme.spacing(2, 2.5),
    backgroundColor: theme.palette.background.paper
  },
  heading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    marginBottom: theme.spacing(4)
  }
}))

export default function BankAccount(): JSX.Element {
  const classes = useStyles()
  const { user, loading } = useAuth()

  const renderHeading = () => {
    return (
      <Box className={classes.heading}>
        <Typography variant='h1'>Bank account</Typography>
        <CustomTooltip
          title={
            <>
              Keeping your money safe is important to us so we use the original
              account you deposited from for withdrawals. This helps to keep
              your investment safe in case someone else accesses your account.
              If you need to change it, please get in touch with us at{' '}
              <Link component='a' href='mailto:support@getgoldie.co.nz'>
                support@getgoldie.co.nz
              </Link>
              .
            </>
          }
          Icon={<HelpIcon fontSize='medium' color='primary' />}
          placement='bottom'
        />
      </Box>
    )
  }

  return (
    <DefaultLayout heading={renderHeading()} showBackIcon loading={loading}>
      <Grid container spacing={2}>
        {user?.profile?.bankAccountNumber ? (
          <Grid item xs={12}>
            <Card className={classes.accountGroup}>
              <CardContent>
                <Typography variant='h5'>
                  {user?.profile?.bankAccountNumber}
                </Typography>
                <Typography
                  variant='h5'
                  sx={{ color: 'primary.main', textTransform: 'uppercase' }}
                >
                  {user?.profile?.bankAccountName}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography variant='body1' align='center'>
              You have no bank accounts. Deposit funds first to verify your bank
              account.
            </Typography>
          </Grid>
        )}
      </Grid>
    </DefaultLayout>
  )
}
