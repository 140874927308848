import {
  GetHistoricalPerformanceQuery as GetHistoricalPerformanceQueryResponse,
  HistoricalHoldingBalance
} from 'generated/graphql'

import * as d3 from 'd3'

export interface DataPoint {
  date: Date
  value: number
  change: number
}

export type GraphPeriodType = '1w' | '1m' | '3m' | '1y' | '5y' | '20y'

interface BuildDataHistoricalHoldingBalancesProps {
  data: GetHistoricalPerformanceQueryResponse | undefined
  productPriceToday: number
  graphPeriod?: GraphPeriodType
}

const parseTime = d3.timeParse('%Y-%m-%d')

const getDesiredGraphDays = (graphPeriod: GraphPeriodType) => {
  switch (graphPeriod) {
    case '1w':
      return 7
    case '1m':
      return 30
    case '3m':
      return 90
    case '1y':
      return 365
    case '5y':
      return 1825
    case '20y':
      return 7300
    default:
      return 0
  }
}

const getNearestDaysToCompare = (length: number) => {
  if (length < 7) return 7
  else if (length > 7 && length < 30) return 30
  else if (length > 30 && length < 90) return 90
  return 365
}

const getDaysToRemove = (length: number) => {
  if (length > 7 && length < 30) return 7
  else if (length > 30 && length < 90) return 30
  else if (length > 90 && length < 365) return 90
  return 365
}

export const buildDataHistoricalHoldingBalances = ({
  data,
  productPriceToday,
  graphPeriod
}: BuildDataHistoricalHoldingBalancesProps): DataPoint[] => {
  const productPerformanceDataForToday = {
    date: new Date(),
    value: productPriceToday,
    change: 0
  }

  const historicalHoldingBalances: DataPoint[] = data?.historicalHoldingBalances
    .length
    ? data?.historicalHoldingBalances.reduce(
        (acc: DataPoint[], val: HistoricalHoldingBalance) => {
          const date = parseTime(val.spotDate)
          const newData = [...acc]
          if (date)
            newData.push({
              date,
              value: val.totalHoldingBalance,
              change: 0
            })
          // sort data by date
          return newData.sort((a, b) => (a.date < b.date ? -1 : 1))
        },
        []
      )
    : []

  /* 
    Fake: Days/2 < data
    Remove: Days/2 > data
  */
  const daysToGenerate = (): number => {
    if (graphPeriod) {
      const countMissingDays: number =
        getDesiredGraphDays(graphPeriod) - historicalHoldingBalances.length - 1
      return countMissingDays
    } else {
      const compareCondition =
        getNearestDaysToCompare(historicalHoldingBalances.length) / 2 <
        historicalHoldingBalances.length

      if (
        compareCondition ||
        getNearestDaysToCompare(historicalHoldingBalances.length) === 7
      ) {
        // Fake
        return (
          getNearestDaysToCompare(historicalHoldingBalances.length) -
          historicalHoldingBalances.length -
          1
        )
      } else {
        // Remove
        historicalHoldingBalances.splice(
          0,
          historicalHoldingBalances.length -
            getDaysToRemove(historicalHoldingBalances.length)
        )
        return 0
      }
    }
  }

  /* Fake "countMissingDays" days earlier to display a graph with "countMissingDays" point on zero */
  const fakeData: DataPoint[] = Array.from(
    new Array(Math.max(0, daysToGenerate()))
  )
    .map((_, index) => {
      const date =
        historicalHoldingBalances.length > 0
          ? new Date(historicalHoldingBalances[0].date)
          : new Date()

      date.setDate(date.getDate() - (index + 1))

      return {
        date,
        value: 0,
        change: 0
      }
    })
    .reverse()

  return [
    ...fakeData,
    ...historicalHoldingBalances,
    productPerformanceDataForToday
  ]
}
