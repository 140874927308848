import { Typography } from '@mui/material'
import Button from 'components/Button'
import DepositDetail from 'components/DepositDetail'
import { autoInvestPath } from 'components/Routes'
import DefaultLayout from 'components/layout/DefaultLayout'
import { useCallback } from 'react'
import { useHistory } from 'react-router'

export default function AutoInvestSuccess(): JSX.Element {
  const history = useHistory()

  const goToAutoInvest = useCallback(
    () => history.push(autoInvestPath()),
    [history]
  )

  return (
    <DefaultLayout heading='' showBackIcon>
      <Typography variant='body1' mb={2}>
        Remember to setup an automatic payment from your bank account to ours to
        ensure that your auto-investment will do the work for you!
      </Typography>

      <DepositDetail />

      <Button fullWidth variant='outlined' onClick={goToAutoInvest}>
        Got it
      </Button>
    </DefaultLayout>
  )
}
