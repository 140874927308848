import { Route, Switch, useLocation } from 'react-router-dom'

import StepLayout, { Step } from 'components/layout/StepLayout'
import {
  twoStepCodePath,
  twoStepDownloadPath,
  twoStepScanPath
} from 'components/Routes'
import TwoStepCode from './TwoStepCode'
import TwoStepDownload from './TwoStepDownload'
import TwoStepScan from './TwoStepScan'

export interface Props {
  setBackupCodes: (value: string[]) => void
}

export default function EnableAuthenticator({
  setBackupCodes
}: Props): JSX.Element {
  const location = useLocation()

  const steps: Step[] = [
    {
      header: 'Download app',
      path: twoStepDownloadPath(),
      step: 1
    },
    {
      header: 'Scan this QR code',
      path: twoStepScanPath(),
      step: 2
    },
    {
      header: 'Enter your code',
      path: twoStepCodePath(),
      step: 3
    }
  ]

  const currentStep =
    steps.find(step => step.path === location.pathname) ?? steps[0]

  return (
    <StepLayout backgroundColor='white' currentStep={currentStep} showBackIcon>
      <Switch>
        <Route path={twoStepDownloadPath()} component={TwoStepDownload} />
        <Route path={twoStepScanPath()} component={TwoStepScan} />
        <Route path={twoStepCodePath()}>
          <TwoStepCode setBackupCodes={setBackupCodes} />
        </Route>
        <Route path='*' component={TwoStepDownload} />
      </Switch>
    </StepLayout>
  )
}
