import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Typography
} from '@mui/material'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import PasswordProgress from 'components/PasswordProgress'
import { loginPath } from 'components/Routes'
import FormField from 'components/formik/FormField'
import DefaultLayout from 'components/layout/DefaultLayout'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { TextField } from 'formik-mui'
import { useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
import theme from 'utils/theme'
import * as Yup from 'yup'
import useStyles from './styles'
import { SignUpPayload } from 'components/AuthProvider/AuthProvider'

interface FormValues {
  email: string
  password: string
  signUpToken: string
  marketingOptedInAt: boolean
  confirmEmail: string
  referrerCode: string
}

const INITIAL_VALUES: FormValues = {
  email: '',
  password: '',
  signUpToken: '',
  confirmEmail: '',
  referrerCode: '',
  marketingOptedInAt: true
}

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Email has invalid format')
    .trim()
    .required('Email is required'),
  confirmEmail: Yup.string()
    .required('Confirm Email is required')
    .trim()
    .oneOf([Yup.ref('email'), null], `Emails don't match`)
})

export default function SignUp(): JSX.Element {
  const classes = useStyles()
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [disableButton, setDisableButton] = useState<boolean>(false)

  const { search } = useLocation()

  const { location } = useHistory<{ email: string }>()

  const referrerCode = search ? search.split('=')[1] : ''

  const defaultEmail = location?.state?.email ?? ''

  const { signUp, loading, error } = useAuth()

  const recaptchaEnabled = JSON.parse(
    process.env.REACT_APP_RECAPTCHA_ENABLED || 'true'
  )

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleToggleAcceptTerms = () => setTermsAccepted(!termsAccepted)

  // This is a uncontrolled form! No need to manage state for each input!
  async function handleSubmit(values: FormValues) {
    if (!executeRecaptcha && recaptchaEnabled) return
    if (errorMessage) return

    const recaptchaToken =
      recaptchaEnabled &&
      executeRecaptcha &&
      (await executeRecaptcha('registrations/create'))

    const params: SignUpPayload = {
      email: values.email.trim(),
      marketingOptedInAt: values.marketingOptedInAt,
      password: values.password,
      signUpToken: values.signUpToken,
      referrerCode: values.referrerCode,
      recaptchaToken
    }
    signUp(params)
  }

  useEffect(() => {
    if (!loading && error) setErrorMessage(error)
    else setErrorMessage('')
  }, [error, loading])

  const renderHeading = () => {
    return (
      <>
        <Typography component='h1' className={classes.heading}>
          Sign up
        </Typography>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: '13px'
          }}
          textAlign='center'
        >
          Fill in below, to have the credit instantly added to your account once
          verified
        </Typography>
      </>
    )
  }

  return (
    <DefaultLayout
      wrapperContent={{ mt: 2, px: 2, pt: 10 }}
      heading={renderHeading()}
    >
      <Formik
        initialValues={{
          ...INITIAL_VALUES,
          email: defaultEmail,
          referrerCode
        }}
        onSubmit={handleSubmit}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({
          setFieldValue,
          errors,
          touched,
          values
        }: FormikProps<FormValues>) => {
          return (
            <Form className={classes.form} data-testid='signup_form'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field name='email'>
                    {({ field, form, meta }: FieldProps) => (
                      <TextField
                        field={field}
                        form={form}
                        meta={meta}
                        fullWidth
                        label='Email address'
                        onChange={e => {
                          setFieldValue('email', e.target.value)
                          setErrorMessage('')
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <FormField
                    autoComplete='email'
                    label='Confirm email address'
                    name='confirmEmail'
                    showOptionalText={false}
                    error={!!(errors.confirmEmail && touched.confirmEmail)}
                  />
                </Grid>

                {!errors.confirmEmail && touched.confirmEmail && (
                  <Grid item xs={12} className={classes.matchEmail}>
                    <Box component='span'>Nice, they match!</Box>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <PasswordProgress
                    autoComplete='new-password'
                    label='Password'
                    onChange={(password: string, hasError?: boolean) => {
                      setFieldValue('password', password)
                      setErrorMessage('')
                      if (hasError) setDisableButton(true)
                      else setDisableButton(false)
                    }}
                  />
                </Grid>

                {JSON.parse(
                  process.env.REACT_APP_ENABLE_REFERRAL_CODE || 'true'
                ) && (
                  <Grid item xs={12}>
                    <Field name='referrerCode'>
                      {({ field, form, meta }: FieldProps) => (
                        <TextField
                          field={field}
                          form={form}
                          meta={meta}
                          fullWidth
                          label='Referrer code'
                          onChange={e => {
                            setFieldValue('referrerCode', e.target.value)
                            setErrorMessage('')
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                )}

                {/* <Grid container mt={1}>
                  <Grid item xs={12}>
                    <Link
                      color='textPrimary'
                      component={RouterLink}
                      className={classes.haveAccount}
                      to={interestPath()}
                    >
                      No referrer code? Request an invite here
                    </Link>
                  </Grid>
                </Grid> */}
              </Grid>

              <Grid container justifyContent='flex-start' mt={2}>
                <FormGroup>
                  <FormControlLabel
                    id='acceptTerms'
                    name='acceptTerms'
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        value='acceptTerms'
                        color='primary'
                        checked={termsAccepted}
                        onChange={handleToggleAcceptTerms}
                        data-testid='checkbox_accept_terms'
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          fontSize: '13px'
                        }}
                      >
                        I have received and read the{' '}
                        <Link
                          color='textPrimary'
                          component={RouterLink}
                          textAlign='center'
                          to='/Information Memorandum - Goldie Nominee Limited-2023-11-22.pdf'
                          underline='always'
                          variant='subtitle2'
                          style={{
                            color: theme.palette.primary.main,
                            fontSize: '13px'
                          }}
                          target='_blank'
                        >
                          Goldie Information Memorandum
                        </Link>
                        , and I accept the{' '}
                        <Link
                          color='textPrimary'
                          component={RouterLink}
                          textAlign='center'
                          to='/Client Agreement Goldie Vaults and Goldie Nominee-2023-11-22.pdf'
                          underline='always'
                          variant='subtitle2'
                          style={{
                            color: theme.palette.primary.main,
                            fontSize: '13px'
                          }}
                          target='_blank'
                        >
                          Terms & Conditions
                        </Link>{' '}
                        and the{' '}
                        <Link
                          color='textPrimary'
                          component={RouterLink}
                          textAlign='center'
                          to='/Catalist Investor Terms and Conditions-2023-11-22.pdf'
                          underline='always'
                          variant='subtitle2'
                          style={{
                            color: theme.palette.primary.main,
                            fontSize: '13px'
                          }}
                          target='_blank'
                        >
                          Catalist Terms & Conditions
                        </Link>
                      </Typography>
                    }
                  />

                  <FormControlLabel
                    id='privacyPolicy'
                    name='privacyPolicy'
                    className={classes.checkbox}
                    sx={{ alignItems: 'flex-start' }}
                    control={
                      <Checkbox
                        value='privacyPolicy'
                        color='primary'
                        checked={values.marketingOptedInAt}
                        onChange={e =>
                          setFieldValue('marketingOptedInAt', e.target.checked)
                        }
                        data-testid='checkbox_accept_privacy_policy'
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          fontSize: '13px'
                        }}
                      >
                        Keep me updated with special offers, news and general
                        updates. Check out our{' '}
                        <Link
                          color='textPrimary'
                          component={RouterLink}
                          textAlign='center'
                          to={'/GOLDIE Privacy Policy-2023-11-22.pdf'}
                          underline='always'
                          variant='subtitle2'
                          style={{
                            color: theme.palette.primary.main,
                            fontSize: '13px'
                          }}
                          target='_blank'
                        >
                          Privacy Policy
                        </Link>
                      </Typography>
                    }
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                {errorMessage ? (
                  <Box
                    className='error'
                    color={theme => theme.palette.error.main}
                  >
                    {errorMessage}
                  </Box>
                ) : null}
              </Grid>

              <Grid item xs={12} mt={4}>
                <Button
                  variant='outlined'
                  disabled={loading || !termsAccepted || disableButton}
                  fullWidth
                  type='submit'
                >
                  Sign up
                </Button>
              </Grid>

              <Grid container sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Link
                    color='textPrimary'
                    component={RouterLink}
                    className={classes.haveAccount}
                    to={loginPath()}
                  >
                    Already have an account? Login
                  </Link>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </DefaultLayout>
  )
}
