import { Grid, Link, Typography } from '@mui/material'
import Button from 'components/Button'
import { dashboardPath, myVaultPath, tradePath } from 'components/Routes'
import GoldieLogo from 'components/icons/GoldieLogo/GoldieLogo'
import useProducts from 'hooks/useProducts'
import {
  RouteComponentProps,
  Link as RouterLink,
  useHistory
} from 'react-router-dom'
import useStyles from './styles'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': ModelViewerJSX &
        React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
    }
  }
}
interface ModelViewerJSX {
  src: string
  poster?: string
  alt: string
  ar: boolean
  autoplay?: boolean
}

interface MatchParams {
  productId: string
}

export type BuyCelebrationProps = RouteComponentProps<MatchParams>

export default function BuyCelebration(): JSX.Element {
  const classes = useStyles()
  const history = useHistory<{ weight: string; product: string }>()

  const { weight } = history.location.state ?? {}
  const { productId, product } = useProducts()

  const handleClickViewAsset = () => {
    history.push(
      tradePath({
        routeParams: {
          productId
        }
      })
    )
  }

  const handleClickMyVault = () => {
    history.push(myVaultPath())
  }

  return (
    <Grid className={classes.wrapper} container justifyContent='center'>
      <Grid maxWidth='sm' container p={2}>
        <Grid item container justifyContent='center'>
          <Link
            color='textPrimary'
            component={RouterLink}
            to={dashboardPath()}
            className={classes.logo}
          >
            <GoldieLogo width='32%' />
          </Link>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.text}>{weight} g.</Typography>
          <Typography className={classes.text}>Beautiful.</Typography>
        </Grid>

        <Grid item>
          <Typography className={classes.text}>
            You've officially invested {product?.name}.
          </Typography>
        </Grid>

        <Grid item>
          <Typography className={classes.text}>
            It's sitting securely right now in Wellington.
          </Typography>
        </Grid>

        <Grid item container gap={2} alignItems='flex-end'>
          <Button className={classes.button} onClick={handleClickViewAsset}>
            View ASSET
          </Button>
          <Button className={classes.button} onClick={handleClickMyVault}>
            Go to MY VAULT
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
