import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import { kidVaultPath } from 'components/Routes'
import DefaultLayout from 'components/layout/DefaultLayout'
import { Form, Formik } from 'formik'
import {
  namedOperations,
  useCreateOrUpdateVaultMutation,
  useVaultDetailQuery
} from 'generated/graphql'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { API_DATE_FORMAT, UI_DATE_FORMAT, formatDate } from 'utils/util'
import * as Yup from 'yup'
import useStyles from './styles'

type Relationship =
  | 'parent'
  | 'grandparent'
  | 'sibling'
  | 'family'
  | 'extended-family'
  | 'other'
  | ''

interface FormValues {
  name: string
  email?: string
  relationship?: Relationship
  dateOfBirth?: string
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Preferred name is required field'),
  email: Yup.string().email()
})

export default function UpdateKidVault(): JSX.Element {
  const classes = useStyles()

  const history = useHistory()

  const { setToast } = useAuth()

  const { id } = useParams<{ id: string }>()

  const { data, refetch } = useVaultDetailQuery({
    variables: {
      vaultId: Number(id)
    }
  })

  useEffect(() => {
    refetch()
  }, [refetch, id])

  const initialVault = data?.vaultDetail

  const [updateVaultUser, { loading }] = useCreateOrUpdateVaultMutation({
    onCompleted: () => {
      setToast({
        message: 'Update kid vault successfully!',
        open: true,
        duration: 3000,
        type: 'success'
      })
      history.push(kidVaultPath())
    },
    onError: error =>
      setToast({
        message: error.message,
        open: true,
        duration: 3000,
        type: 'error'
      }),
    refetchQueries: [namedOperations.Query.getCurrentUser]
  })

  const initialValues = {
    name: initialVault?.name ?? '',
    dateOfBirth: initialVault?.dateOfBirth
      ? formatDate({
          date: initialVault?.dateOfBirth,
          inputFormat: API_DATE_FORMAT,
          outputFormat: UI_DATE_FORMAT
        })
      : '',
    email: initialVault?.email ?? '',
    relationship: (initialVault?.relationship as Relationship) ?? ''
  }

  const handleSubmit = ({
    name,
    dateOfBirth,
    email,
    relationship
  }: FormValues) => {
    updateVaultUser({
      variables: {
        input: {
          id: Number(id),
          name,
          dateOfBirth: dateOfBirth
            ? formatDate({
                date: dateOfBirth,
                inputFormat: UI_DATE_FORMAT,
                outputFormat: API_DATE_FORMAT
              })
            : null,
          email,
          relationship
        }
      }
    })
  }

  return (
    <DefaultLayout backgroundColor='white'>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ handleChange, handleBlur, values, errors, touched }) => (
          <Form>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  fontWeight={700}
                  textAlign='center'
                  textTransform='uppercase'
                  variant='h6'
                >
                  Enter your kid's name
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type='text'
                  id='name'
                  label='Preferred name'
                  name='name'
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(errors.name && touched.name)}
                  helperText={
                    Boolean(errors.name && touched.name) && errors.name
                  }
                />
              </Grid>

              <Grid item xs={12} mt={4}>
                <Typography fontWeight={700}>Optional Info</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type='text'
                  id='email'
                  label='Email'
                  name='email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(errors.email && touched.email)}
                  helperText={
                    Boolean(errors.email && touched.email)
                      ? errors.email
                      : 'Add email so the caregiver or child can be updated'
                  }
                />
              </Grid>

              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <DatePicker<FormValues>
                    name='dateOfBirth'
                    label='Date of birth'
                    inputFormat={UI_DATE_FORMAT}
                    outputFormat={UI_DATE_FORMAT}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id='relationship' sx={{ left: '0' }}>
                      Your relationship
                    </InputLabel>
                    <Select
                      labelId='relationship'
                      label='Relationship'
                      name='relationship'
                      value={values.relationship}
                      onChange={handleChange}
                      className={classes.select}
                    >
                      <MenuItem value='parent'>Parent</MenuItem>
                      <MenuItem value='grandparent'>Grandparent</MenuItem>
                      <MenuItem value='sibling'>Sibling</MenuItem>
                      <MenuItem value='family'>Family</MenuItem>
                      <MenuItem value='extended-family'>
                        Extended Family
                      </MenuItem>
                      <MenuItem value='other'>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={12} mt={8}>
                <Button
                  disabled={loading}
                  color='primary'
                  type='submit'
                  fullWidth
                >
                  Update kid vault
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </DefaultLayout>
  )
}
