import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  wrapper: {
    '& h1': {
      textTransform: 'uppercase',
      fontSize: '2.5rem',
      color: theme.palette.primary.main
    },

    '& h2': {
      fontSize: '1.875rem',
      margin: theme.spacing(3, 0, 0),

      '&:last-child': {
        margin: theme.spacing(0, 0, 3)
      }
    }
  },
  list: {
    '& img': {
      width: '2rem',
      height: '2rem'
    },
    '& p': {
      margin: theme.spacing(0, 2)
    }
  }
}))
