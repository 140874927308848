import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { AuthorizationStatus } from 'api/sessions/sessions'
import { loginPath } from 'components/Routes'
import { PublicRoutes } from 'components/Routes/Routes'
import { getCookie } from 'utils/cookies'

const authLink = setContext((_, { headers }) => {
  const token = getCookie('CSRF-TOKEN')

  return {
    headers: {
      ...headers,
      'X-CSRF-Token': token
    }
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const publicRoutes = PublicRoutes().map(item => item.props.path)

  if (networkError && 'result' in networkError) {
    if (
      networkError.result.status === AuthorizationStatus.Unauthorized &&
      !publicRoutes.includes(window.location.pathname)
    ) {
      window.location.replace(loginPath())
    }
  }

  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
})

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GOLDIE_GRAPHQL_ENDPOINT,
  credentials: 'include'
})

const typePolicies = {
  // We need to manually define a cache ID for the Profile type, as there is no consistent `id` field,
  // due to it being populated dynamically each time the query is called.
  // See: https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
  Profile: { keyFields: ['identifier'] }
}

const cache = new InMemoryCache({ typePolicies })

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache
})

export default client
export { typePolicies }
