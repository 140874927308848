import { ElementType, useEffect } from 'react'

import { dashboardPath } from 'components/Routes'
import { useAuth } from 'components/AuthProvider'

import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps
} from 'react-router-dom'

export interface PublicRouteProps extends Omit<RouteProps, 'component'> {
  component: ElementType
}

export enum RouteEnum {
  Public = 'public',
  Private = 'private'
}

const PublicRoute = ({
  component: Component,
  ...rest
}: PublicRouteProps): JSX.Element => {
  const { user } = useAuth()

  useEffect(() => {
    if (document.body.classList.contains('hide-grecaptcha') === true) {
      document.body.classList.remove('hide-grecaptcha')
    }
  }, [])

  const renderRoute = (props: RouteComponentProps) => {
    return user ? <Redirect to={dashboardPath()} /> : <Component {...props} />
  }

  return (
    <Route
      {...rest}
      render={renderRoute}
      // @ts-ignore
      location={{ ...rest.location, state: RouteEnum.Public }}
    />
  )
}

export default PublicRoute
