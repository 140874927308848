import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  wrapper: {
    height: '100vh',
    padding: theme.spacing(2),
    background: ' linear-gradient(180deg, #FBFBF8 0%, #F4E400 100%)'
  },

  logo: {
    '& > div': {
      display: 'flex',
      justifyContent: 'center'
    },
    '& svg': {
      fill: theme.palette.primary.main
    }
  },

  text: {
    fontSize: '2.875rem',
    color: theme.palette.primary.main
  },

  button: {
    background: 'transparent',
    borderColor: theme.palette.primary.main,
    borderWidth: '2px !important',
    borderStyle: 'solid',

    flex: 1,
    color: theme.palette.primary.main,
    textTransform: 'none',
    padding: theme.spacing(0)
  }
}))
