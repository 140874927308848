import { makeStyles } from '@mui/styles'

export default makeStyles(theme => ({
  balanceCard: {
    background: theme.palette.primary.main,
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '2rem',

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.5)
    },

    '& h2': {
      color: theme.palette.white.main,
      fontSize: '3rem'
    },

    '& h3': {
      color: theme.palette.white.main
    },

    '& h4': {
      color: theme.palette.white.main,
      fontSize: '12px'
    },

    '& hr': {
      margin: theme.spacing(2, 0),
      borderColor: theme.palette.white.main
    }
  },
  balanceValue: {
    fontWeight: 700,
    fontSize: '18px',
    color: theme.palette.white.main
  }
}))
