import {
  ArrowBack,
  KeyboardArrowDown,
  KeyboardArrowUp
} from '@mui/icons-material'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import queryString from 'query-string'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import { FEE_PLAN_TYPES } from 'components/pages/Review/ReviewPurchase'
import { PORTFOLIO_PERFORMANCE_FOR_CURRENT_USER } from 'components/pages/TradeProduct/queries'
import { dashboardPath, myVaultPath } from 'components/Routes'
import SwitchVault from 'components/SwitchVault/SwitchVault'
import TradingPaused from 'components/TradingPaused'
import InputNumber from './InputNumber'

import { REVIEW_ORDER_EXPIRED, TRADING_IS_PAUSED } from 'constants/index'
import {
  namedOperations,
  useCreateMarketTransactionMutation,
  useReviewOrderQuery,
  useUpdateReviewOrderForSellMutation,
  useUpdateReviewOrderWithVaultMutation,
  Vault
} from 'generated/graphql'

import ScrollToTop from 'components/layout/ScrollToTop'
import { GET_LATEST_SPOT_PRICE } from 'hooks/useSpotPrice/queries'
import useStyles from './styles'
import { upperFirst } from 'lodash'

export default function ReviewSell() {
  const history = useHistory()
  const { id: reviewOrderId } = queryString.parse(history.location.search)

  const { setToast } = useAuth()
  const styles = useStyles()
  const [amount, setAmount] = useState('0')
  const [weight, setWeight] = useState(0)
  const [tradingPaused, setTradingPaused] = useState(false)

  const { data: reviewOrderData, loading: loadingReviewOrder } =
    useReviewOrderQuery({
      variables: { reviewOrderId: parseInt(`${reviewOrderId}`) },
      skip: !reviewOrderId,
      onCompleted: () => {
        setTradingPaused(false)
      },
      onError: error => {
        if (
          error.graphQLErrors[0].extensions?.['error_status'] ===
          TRADING_IS_PAUSED
        ) {
          setTradingPaused(true)
        }
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        })
        if (
          error.graphQLErrors[0].extensions?.['error_status'] ===
          REVIEW_ORDER_EXPIRED
        ) {
          history.push(dashboardPath())
        }
      }
    })

  const { fees, vault, product, pricePerUnit } =
    reviewOrderData?.reviewOrder ?? {}

  const productName = useMemo(() => {
    return product?.name
  }, [product?.name])

  const [
    updateReviewOrder,
    { data: updateReviewOrderData, loading: loadingUpdateReviewOrder }
  ] = useUpdateReviewOrderForSellMutation()

  const [
    updateReviewOrderWithVault,
    { loading: loadingUpdateReviewOrderWithVault }
  ] = useUpdateReviewOrderWithVaultMutation()

  const [createMarketTransaction, { loading: loadingCreateMarketTransaction }] =
    useCreateMarketTransactionMutation({
      onCompleted: () => {
        setToast({
          open: true,
          message: `Good as ${product?.name}! Market transaction created.`,
          type: 'success',
          duration: 3000
        })
        setTradingPaused(false)
        history.replace(myVaultPath())
      },
      onError: error => {
        if (
          error.graphQLErrors[0].extensions?.['error_status'] ===
          TRADING_IS_PAUSED
        ) {
          setTradingPaused(true)
        }
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        })
        if (
          error.graphQLErrors[0].extensions?.['error_status'] ===
          REVIEW_ORDER_EXPIRED
        ) {
          history.push(dashboardPath())
        }
      },
      // Refetches the current user (so that the correct vault and
      // wallet details are displayed on the Dashboard)
      refetchQueries: [
        namedOperations.Query.getCurrentUser,
        namedOperations.Query.products,
        namedOperations.Query.productPerformanceSummaries,
        {
          query: PORTFOLIO_PERFORMANCE_FOR_CURRENT_USER,
          variables: { productId: Number(product?.id) }
        },
        {
          query: GET_LATEST_SPOT_PRICE,
          variables: { productId: Number(product?.id) }
        }
      ],
      awaitRefetchQueries: true,
      variables: {
        input: {
          reviewOrderId: parseInt(`${reviewOrderId}`),
          vaultId: vault ? Number(vault?.id) : null
        }
      }
    })

  const loading = useMemo(() => {
    return (
      loadingReviewOrder ||
      loadingUpdateReviewOrder ||
      loadingCreateMarketTransaction ||
      loadingUpdateReviewOrderWithVault
    )
  }, [
    loadingCreateMarketTransaction,
    loadingReviewOrder,
    loadingUpdateReviewOrder,
    loadingUpdateReviewOrderWithVault
  ])

  useEffect(() => {
    updateReviewOrder({
      variables: {
        input: {
          reviewOrderId: Number(reviewOrderId),
          weight: weight * 10
        }
      }
    })
  }, [weight, updateReviewOrder, reviewOrderId])

  const handleChangeVault = async (id: number | null): Promise<Vault> => {
    const { data } = await updateReviewOrderWithVault({
      variables: {
        input: {
          reviewOrderId: Number(reviewOrderId),
          vaultId: id
        }
      }
    })

    return data?.updateReviewOrderWithVault?.vault as Vault
  }

  const handleChangeAmountInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === amount) {
      return
    }
    const am = Number(e.target.value)
    const we = parseFloat(
      (Math.floor(am / Number(pricePerUnit)) / 10).toFixed(1)
    )
    setAmount(Number(e.target.value).toFixed(2).toString())
    setWeight(we)
  }

  const handleChangeWeightInput = (e: ChangeEvent<HTMLInputElement>) => {
    const we = Number(e.target.value)
    if (we === weight) {
      return
    }
    const am = calculateAmount(we)
    setWeight(we)
    setAmount(am)
  }

  const increaseWeight = () => {
    const we =
      weight >= Number(allotmentsOwned / 10)
        ? Number(allotmentsOwned / 10)
        : Number(weight + 0.1).toFixed(1)
    handleChangeWeightInput({
      target: { value: we }
    } as ChangeEvent<HTMLInputElement>)
  }

  const decreaseWeight = () => {
    const we = weight <= 0 ? 0 : Number(weight - 0.1).toFixed(1)
    handleChangeWeightInput({
      target: { value: we }
    } as ChangeEvent<HTMLInputElement>)
  }

  const onConfirmSell = async () => {
    await createMarketTransaction()
  }

  const liveProductPrice = Number(
    reviewOrderData?.reviewOrder?.spotPrice * 10 || 0
  ).toFixed(2)

  const allotmentsOwned =
    reviewOrderData?.reviewOrder?.vault?.securitiesOwned?.find(
      item => item?.product?.id === product?.id
    )?.allotmentsOwned || 0
  const productInVault = Number(vault?.balance || 0).toFixed(2)

  const gramRemaining = (Number(allotmentsOwned / 10 || 0) - weight).toFixed(1)

  const productRemaining = Number(
    reviewOrderData?.reviewOrder?.spotPrice * Number(gramRemaining) * 10 || 0
  ).toFixed(2)

  const total = Number(
    updateReviewOrderData?.reviewOrder?.totalPriceWithFees || 0
  ).toFixed(2)

  const transactionFee = useMemo(() => {
    if (updateReviewOrderData) {
      return Number(
        updateReviewOrderData?.reviewOrder?.fees?.find(
          fee => fee.plan.name === FEE_PLAN_TYPES.TRANSACTION
        )?.fee || 0
      ).toFixed(2)
    }
    return Number(
      fees?.find(fee => fee.plan.name === FEE_PLAN_TYPES.TRANSACTION)?.fee || 0
    ).toFixed(2)
  }, [fees, updateReviewOrderData])

  const isOverWeight = useMemo(() => {
    return weight > Number(allotmentsOwned / 10)
  }, [allotmentsOwned, weight])

  const calculateAmount = useCallback(
    (we: number) => {
      return we > 0 ? (we * Number(liveProductPrice)).toFixed(2) : '0'
    },
    [liveProductPrice]
  )

  if (tradingPaused) {
    return <TradingPaused />
  }

  return (
    <ScrollToTop>
      <Box className={styles.sell_page}>
        <Box className={styles.header}>
          <IconButton onClick={() => history.push(myVaultPath())}>
            <ArrowBack color='primary' fontSize='large' />
          </IconButton>
          <Stack alignItems='flex-end'>
            <Typography variant='body1' textTransform='uppercase'>
              {productName} SALE PRICE LIVE:&nbsp;
              <Typography variant='body1' color='primary.main' component='span'>
                ${liveProductPrice}
              </Typography>
            </Typography>
            <Typography variant='body1' textTransform='uppercase'>
              {productName} IN VAULT:&nbsp;
              <Typography variant='body1' color='primary.main' component='span'>
                ${productInVault}
              </Typography>
            </Typography>
          </Stack>
        </Box>

        <Typography variant='body1' pl={2} pt={2}>
          CHOOSE VAULT:
        </Typography>

        <Box className={styles.choose_vault}>
          <SwitchVault handleActive={handleChangeVault} />
        </Box>

        <Box className={styles.choose_vault}>
          <Stack>
            <Typography variant='body1' textTransform='uppercase'>
              {productName} BULLION
            </Typography>
            <Typography variant='body1' textTransform='uppercase'>
              99.99% FINE {productName}
            </Typography>
          </Stack>
          <Stack justifyContent='center' alignItems='flex-end' gap={1}>
            <IconButton onClick={increaseWeight}>
              <KeyboardArrowUp
                color='primary'
                fontSize='medium'
                transform='scale(1.5, 1.8)'
              />
            </IconButton>
            <Stack alignItems='center'>
              <InputNumber
                name='amount'
                value={amount}
                onChange={handleChangeAmountInput}
              />
              <InputNumber
                name='weight'
                value={weight}
                onChange={handleChangeWeightInput}
              />
            </Stack>
            <IconButton onClick={decreaseWeight}>
              <KeyboardArrowDown
                color='primary'
                fontSize='medium'
                transform='scale(1.5, 1.8)'
              />
            </IconButton>
          </Stack>
        </Box>

        <Box className={styles.choose_vault}>
          <Stack>
            <Typography variant='body1'>
              {upperFirst(productName)} Remaining
            </Typography>
            <Typography variant='body1'>&nbsp;</Typography>
          </Stack>
          <Stack alignItems='flex-end'>
            <Typography variant='body1'>${productRemaining}</Typography>
            <Typography variant='body1'>{gramRemaining}g</Typography>
          </Stack>
        </Box>

        <Box className={styles.choose_vault}>
          <Stack>
            <Typography variant='h5' color='primary.main'>
              TOTAL
            </Typography>
            <Typography variant='body2' color='primary.main'>
              &nbsp;
            </Typography>
            <Typography variant='body2' color='bronze.main'>
              &nbsp;
            </Typography>
          </Stack>
          <Stack textAlign='right'>
            <Typography variant='h5' color='primary.main'>
              ${total}
            </Typography>
            <Typography variant='body2' color='primary.main'>
              Include 0.5% transaction fee, ${transactionFee}
            </Typography>
            <Typography variant='body2' color='bronze.main'>
              What's this?
            </Typography>
          </Stack>
        </Box>
        <Box className={styles.choose_vault}>
          {isOverWeight && (
            <Typography variant='body1' textAlign='right' color='error'>
              Weight must be less than or equal to{' '}
              {Number(allotmentsOwned / 10)}
            </Typography>
          )}
        </Box>

        <Button
          variant='outlined'
          className={styles.btnConfirm}
          onClick={onConfirmSell}
          disabled={!weight || loading || isOverWeight}
          loading={loadingCreateMarketTransaction}
        >
          Confirm Sell
        </Button>
      </Box>
    </ScrollToTop>
  )
}
