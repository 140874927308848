import { Box, Typography } from '@mui/material'
import DefaultLayout from 'components/layout/DefaultLayout'

export default function ComingSoon(): JSX.Element {
  return (
    <DefaultLayout backgroundColor='white' center showBackIcon>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant='h3' textTransform='uppercase'>
          Coming soon
        </Typography>
      </Box>
    </DefaultLayout>
  )
}
