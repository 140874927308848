import { currencies, Currency } from 'components/Currency'
import Superscript from '..'

export interface Props {
  currency?: Currency
  suffix?: string
  text: string | JSX.Element
}

export default function CurrencySuperscript({
  currency = currencies.NZD,
  suffix,
  text
}: Props): JSX.Element {
  return <Superscript text={text} prefix={currency.symbol} suffix={suffix} />
}
