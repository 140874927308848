import * as React from 'react'
import { Stack, Typography, CircularProgress } from '@mui/material'

/** types */
import type { TableBaseProps } from './models'

/** styles */
import useStyles from './styles'

function TableLoading<T>(
  props: Pick<TableBaseProps<T>, 'loading' | 'emptyText'> & { empty?: boolean }
) {
  const { loading, empty, emptyText = 'No data!' } = props

  const classes = useStyles()

  return (
    <Stack
      className={classes.tableLoading}
      display={loading || empty ? 'flex' : 'none'}
    >
      {loading ? <CircularProgress /> : <Typography>{emptyText}</Typography>}
    </Stack>
  )
}

export default React.memo(TableLoading)
