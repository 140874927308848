import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  background: {
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% center'
  },
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'transparent',
    backdropFilter: 'blur(20px)'
  },
  divider: {
    borderColor: theme.palette.divider,
    margin: theme.spacing(1, 0)
  },
  label: {
    color: theme.palette.disabled.contrastText,
    lineHeight: '140%'
  },
  value: {
    '& > span': {
      fontSize: '0.625rem'
    }
  },

  switchVaultRoot: {
    padding: theme.spacing(3, 0),
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',

    '& > p': {
      fontSize: '18px',
      fontWeight: 700,
      whiteSpace: 'nowrap'
    }
  },
  name: {
    textTransform: 'capitalize',
    fontSize: '18px',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  accordion: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '0.75rem !important'
  },
  accordionSummary: {
    position: 'relative',
    padding: theme.spacing(0, 1.5),
    minHeight: '2rem !important',

    '& > div': {
      margin: '0px !important'
    }
  },
  expandIcon: {
    color: theme.palette.primary.main,
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  expandIconExpanded: {
    transform: 'rotate(180deg)'
  },

  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',

    padding: theme.spacing(2),

    '& > section': {
      border: '1px solid #C1C1C1',
      background: theme.palette.white.main,
      margin: '0 auto',
      padding: theme.spacing(1)
    }
  },

  vaultList: {
    padding: theme.spacing(4, 2.5),
    borderRadius: '24px',
    border: '1px solid #C1C1C1',
    background: theme.palette.white.main,

    '& section': {
      maxHeight: '200px',
      overflowY: 'scroll',

      '&::-webkit-scrollbar': {
        width: '1px'
      }
    }
  },
  stickyFooter: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    maxWidth: '1200px',
    margin: '0 auto',
    zIndex: 10,

    borderTopRightRadius: theme.spacing(4),
    borderTopLeftRadius: theme.spacing(4),
    padding: theme.spacing(2, 2, 3),
    background: theme.palette.primary.main
  },
  disabledStickyFooter: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    maxWidth: '1200px',
    margin: '0 auto',
    zIndex: 10,

    borderTopRightRadius: theme.spacing(4),
    borderTopLeftRadius: theme.spacing(4),
    padding: theme.spacing(2, 2, 3),
    background: theme.palette.disabled.main
  },
  total: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',

    color: theme.palette.white.main,
    fontSize: '1rem',
    fontWeight: '700',

    '& > p': {
      color: theme.palette.white.main,
      fontSize: '0.75rem'
    }
  },
  button: {
    border: 'none',
    backgroundColor: `${theme.palette.white.main} !important`,
    color: `${theme.palette.primary.main} !important`
  },
  disabledBtn: {
    border: 'none',
    backgroundColor: `${theme.palette.white.main} !important`,
    color: `${theme.palette.disabled.main} !important`
  },

  amountInput: {
    border: 'none',
    background: 'transparent',
    width: '30%',
    font: 'inherit',
    color: theme.palette.primary.main,
    textAlign: 'right',

    '&:focus': {
      outline: 'none'
    }
  }
}))
