export default function InsuranceFee(): JSX.Element {
  return (
    <svg
      width='41'
      height='40'
      viewBox='0 0 41 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.349609' width='40' height='40' rx='20' fill='#26BFEF' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.2146 13.1245C27.6516 13.2775 27.9436 13.6895 27.9436 14.1525V20.9255C27.9436 22.8185 27.2556 24.6255 26.0406 26.0255C25.4296 26.7305 24.6566 27.2795 23.8356 27.7235L20.2776 29.6455L16.7136 27.7225C15.8916 27.2785 15.1176 26.7305 14.5056 26.0245C13.2896 24.6245 12.5996 22.8165 12.5996 20.9215V14.1525C12.5996 13.6895 12.8916 13.2775 13.3286 13.1245L19.9106 10.8115C20.1446 10.7295 20.3996 10.7295 20.6326 10.8115L27.2146 13.1245Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.6719 18.9191L19.5639 20.8121L23.4619 16.9141'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle
        cx='25.3496'
        cy='25'
        r='4'
        fill='#26BFEF'
        stroke='white'
        strokeWidth='1.2'
      />
      <path
        d='M25.1087 28.0195V21.9766H25.487V28.0195H25.1087ZM26.1997 23.9098C26.1782 23.7037 26.0875 23.5432 25.9275 23.4283C25.7692 23.3134 25.5631 23.256 25.3094 23.256C25.131 23.256 24.978 23.2835 24.8504 23.3386C24.7228 23.3937 24.6251 23.4684 24.5575 23.5628C24.4898 23.6573 24.4552 23.7651 24.4537 23.8862C24.4537 23.987 24.476 24.0743 24.5206 24.1483C24.5667 24.2222 24.629 24.2852 24.7074 24.3371C24.7858 24.3875 24.8727 24.4299 24.968 24.4646C25.0634 24.4992 25.1595 24.5283 25.2564 24.5519L25.6992 24.6652C25.8776 24.7077 26.049 24.7651 26.2135 24.8375C26.3796 24.9099 26.528 25.0012 26.6587 25.1114C26.7909 25.2215 26.8955 25.3545 26.9724 25.5103C27.0493 25.6661 27.0877 25.8486 27.0877 26.0579C27.0877 26.3412 27.017 26.5906 26.8755 26.8062C26.734 27.0202 26.5295 27.1878 26.262 27.309C25.996 27.4286 25.6738 27.4884 25.2956 27.4884C24.9281 27.4884 24.609 27.4302 24.3384 27.3137C24.0693 27.1973 23.8586 27.0273 23.7064 26.8039C23.5557 26.5804 23.4742 26.3081 23.4619 25.9871H24.3038C24.3161 26.1555 24.3668 26.2956 24.456 26.4073C24.5452 26.519 24.6613 26.6024 24.8043 26.6575C24.9488 26.7126 25.1103 26.7401 25.2886 26.7401C25.4747 26.7401 25.6377 26.7118 25.7776 26.6551C25.9191 26.5969 26.0298 26.5167 26.1097 26.4144C26.1897 26.3105 26.2305 26.1893 26.232 26.0508C26.2305 25.925 26.1943 25.8211 26.1236 25.7393C26.0529 25.6558 25.9537 25.5866 25.8261 25.5315C25.7 25.4749 25.5524 25.4245 25.3832 25.3805L24.8458 25.2388C24.4568 25.1365 24.1492 24.9815 23.9232 24.7738C23.6987 24.5645 23.5865 24.2867 23.5865 23.9405C23.5865 23.6557 23.6618 23.4063 23.8125 23.1922C23.9647 22.9782 24.1715 22.8122 24.4329 22.6942C24.6943 22.5746 24.9903 22.5148 25.3209 22.5148C25.6561 22.5148 25.9498 22.5746 26.202 22.6942C26.4557 22.8122 26.6548 22.9766 26.7994 23.1875C26.9439 23.3968 27.0185 23.6376 27.0231 23.9098H26.1997Z'
        fill='white'
      />
    </svg>
  )
}
