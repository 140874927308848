import { gql } from '@apollo/client'

export const GET_LATEST_SPOT_PRICE = gql`
  query getLatestSpotPrice($productId: Int!) {
    latestSpotPrice(productId: $productId) {
      id
      midpointPerDg
      askPricePerDg
      bidPricePerDg
      spotTime
    }
  }
`
