import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
  menuModal: {
    top: '60px'
  },
  menuBackdrop: {
    backgroundColor: theme.palette.background.default
  },
  menuDrawer: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden',
    position: 'absolute',
    boxShadow: 'none'
  },
  listItemLink: {
    position: 'relative',
    paddingLeft: theme.spacing(2),
    '&.MuiAccordion-root:before': {
      height: 0
    },
    background: 'none'
  },
  listItemText: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: '24px',
    fontWeight: 500
  },
  listSecondaryItemText: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    fontSize: '24px',
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  'listSecondaryItemText-disable': {
    textDecoration: 'line-through',
    textDecorationThickness: '0.4rem',
    textDecorationColor: theme.palette.primary.main
  },

  menuLeft: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden',

    display: 'flex',
    flexDirection: 'column',

    minWidth: '263px',

    [theme.breakpoints.down('lg')]: {
      minWidth: '200px'
    },

    '& .MuiAccordionSummary-root': {
      justifyContent: 'flex-start',
      minHeight: '38px'
    },
    '& .MuiAccordionSummary-content': {
      flexGrow: 0,
      margin: 0,
      minWidth: 130
    }
  },

  menuRight: {
    position: 'absolute',
    right: 0,
    zIndex: 9,
    alignItems: 'flex-end'
  },

  comingSoon: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer,
    bottom: 20,
    left: 80
  },

  kiaOra: {
    fontFamily: 'Arimo',
    fontSize: '16px',
    fontWeight: 700,
    textAlign: 'right'
  },

  wallet: {
    color: theme.palette.primary.main,
    fontFamily: 'Arimo',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'right'
  },
  wrap: {
    width: '100%',
    height: '100%'
  },

  /// new styles

  container: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  rightContent: {
    position: 'sticky',
    left: 0,
    top: 0,
    borderRight: `1px solid black`,
    height: '100vh',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  stickyRightContent: {
    position: 'absolute',
    top: 0,
    right: 0,
    paddingTop: '1rem',
    paddingRight: '1rem'
  },

  leftContent: {
    position: 'sticky',
    right: 0,
    top: 0,
    padding: '1rem',
    height: '100vh',
    textAlign: 'right'
  },

  appBar: {
    flexBasis: 'auto',
    flexGrow: '0',
    flexShrink: '0',

    position: 'sticky',
    top: 0,
    boxShadow: 'none',
    transition: 'top 0.5s',

    padding: '0 !important',

    zIndex: 10
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    padding: '0px 16px',

    background: theme.palette.background.default,

    '& > div:last-of-type': {
      marginTop: 3,
      '& > svg': {
        fontSize: '2.5rem !important',
        margin: '0 !important'
      }
    },
    '& > button > p, svg': {
      color: 'black !important'
    }
  },
  menuListWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1
  },
  accordionSummary: {
    padding: 0,
    minHeight: 38,
    '& > div': {
      margin: 0
    }
  },
  accordionDetails: {
    padding: `0 0 0 2rem`
  },

  pageTitle: {
    // Can support different font sizes or appearances at different sizes
    /*
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '6.5rem'
    }
    */
  },

  // menu icon
  menuIconWrap: {
    paddingLeft: 0,
    paddingRight: 0
  },
  menuIcon: {
    '& #top_line, #bottom_line': {
      transformBox: 'fill-box',
      transformOrigin: 'center'
    },
    '& #top_line': {
      animation: '$down-equal-rotate 0.2s ease-out both'
    },
    '& #bottom_line': {
      animation: '$up-equal-rotate 0.2s ease-out both'
    }
  },
  menuIconOpen: {
    '& #top_line': {
      animation: '$down-rotate 0.2s ease-out both'
    },
    '& #bottom_line': {
      animation: '$up-rotate 0.2s ease-out both'
    }
  },
  '@keyframes down-rotate': {
    '0%': {
      animationTimingFunction: 'cubic-bezier(0.16, -0.88, 0.97, 0.53)',
      transform: 'translateY(0px)'
    },
    '30%': {
      animationTimingFunction: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
      transformOrigin: 'center',
      transform: 'translateY(10px)'
    },
    '100%': {
      transformOrigin: 'center',
      transform: 'translateY(10px) rotate(-45deg) scale(0.9)'
    }
  },
  '@keyframes up-rotate': {
    '0%': {
      animationTimingFunction: 'cubic-bezier(0.16, -0.88, 0.97, 0.53)',
      transform: 'translateY(0px)'
    },
    '30%': {
      animationTimingFunction: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
      transformOrigin: 'center',
      transform: 'translateY(-10px)'
    },
    '100%': {
      transformOrigin: 'center',
      transform: 'translateY(-10px) rotate(45deg) scale(0.9)'
    }
  },
  '@keyframes down-equal-rotate': {
    '0%': {
      transformOrigin: 'center',
      transform: 'translateY(10px) rotate(-45deg) scale(0.9)'
    },
    '30%': {
      animationTimingFunction: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
      transformOrigin: 'center',
      transform: 'translateY(10px)'
    },
    '100%': {
      animationTimingFunction: 'cubic-bezier(0.16, -0.88, 0.97, 0.53)',
      transform: 'translateY(0px)'
    }
  },
  '@keyframes up-equal-rotate': {
    '0%': {
      transformOrigin: 'center',
      transform: 'translateY(-10px) rotate(45deg) scale(0.9)'
    },
    '30%': {
      animationTimingFunction: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
      transformOrigin: 'center',
      transform: 'translateY(-10px)'
    },
    '100%': {
      animationTimingFunction: 'cubic-bezier(0.16, -0.88, 0.97, 0.53)',
      transform: 'translateY(0px)'
    }
  }
}))
