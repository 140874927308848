import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  galleryItemLeft: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '2rem'
  },
  galleryItemRight: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '2rem',

    '& > p': {
      textAlign: 'right'
    }
  },

  bullionImg: {
    borderRadius: 25,
    width: '30px'
  },

  headingText: {
    textTransform: 'uppercase',
    color: theme.palette.disabled.main
  },

  perShareText: {
    '& > p': {
      color: theme.palette.primary.main,

      '&:last-child': {
        fontSize: '0.5rem',
        textTransform: 'uppercase'
      }
    }
  },
  perShareValue: {
    color: theme.palette.primary.main
  },
  divider: {
    borderColor: theme.palette.primary.main,
    margin: theme.spacing(4, 0)
  },
  aboveTheFoldContainer: {
    flexWrap: 'nowrap',
    gap: 8,
    marginBottom: '4rem',
    paddingX: '2rem'
  },
  productImage: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 15
    }
  },
  productImageNoVaultProduct: {
    opacity: 0.4,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 15
    }
  },
  rightArrowIcon: {
    alignSelf: 'center'
  },
  accordionRoot: {
    position: 'sticky',
    left: 0,
    right: 0,

    width: '100%',

    borderRadius: '0 !important',
    background: 'transparent',
    zIndex: 1,

    '& > div:first-child': {
      '& > div': {
        margin: theme.spacing(0)
      }
    },
    '&.MuiAccordion-root:before': {
      height: 0,
      borderRadius: 0
    }
  },

  arrowIcon: {
    fontSize: '4rem',
    stroke: theme.palette.background.default,
    strokeWidth: 1.3
  },

  accordionSummary: {
    padding: 0,
    zIndex: 9,
    // background: theme.palette.background.default
    background: 'rgba(250,250,250, 0.7)'
  },

  accordionDetails: {
    padding: 0,
    background: 'rgba(250,250,250, 0.7)'
  },

  tableWrapper: {
    paddingTop: theme.spacing(1),
    '& thead': {
      '& th': {
        // borderBottom: '2px solid',
        padding: theme.spacing(0),
        '&:first-of-type': {
          padding: theme.spacing(0)
        }
      }
    },
    '& tbody': {
      '& td': {
        // borderBottom: `2px solid`,
        padding: theme.spacing(0),
        '&:first-of-type': {
          padding: theme.spacing(0)
        }
      }
    },
    '& table': {
      overflow: 'hidden'
    }
  },
  drawer: {
    '& .MuiBackdrop-root': {
      background: 'none'
    },
    '& .MuiPaper-root': {
      inset: 0,
      background: 'transparent',
      backdropFilter: 'blur(20px)',
      position: 'absolute',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0
      }
    }
  },

  videoModalWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 3)
  },
  sell_page: {
    width: '100%',
    height: '100%'
  }
}))
