import { TextField } from '@mui/material'
import { ChangeEvent, FocusEvent } from 'react'

interface TextFieldCustom {
  label?: string
  name: string
  required?: boolean
  value?: string
  error?: boolean
  helperText?: string
  onChange?: (e: ChangeEvent) => void
  onBlur?: (e: FocusEvent) => void
}

export default function TextFieldCustom({
  label,
  name,
  required = false,
  value,
  error,
  helperText,
  onChange,
  onBlur
}: TextFieldCustom): JSX.Element {
  return (
    <TextField
      fullWidth
      id={name}
      label={label}
      name={name}
      required={required}
      type='text'
      error={error}
      helperText={helperText}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
  )
}
