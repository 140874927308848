import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
  addressFinderInput: {
    width: '100%',
    display: 'inline-block',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    height: '1.1876em',
    padding: theme.spacing(2.5, 3),
    boxSizing: 'border-box',

    '&:hover': {
      borderColor: '#212121'
    },

    '&:focus': {
      borderColor: theme.palette.blue.main,
      borderWidth: '1px',
      outline: 'none'
    },

    '&::placeholder': {
      color: theme.palette.primary.main,
      fontWeight: 600
    }
  },
  nextButtonContainer: {
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2
    }
  },
  saveButtonContainer: {
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 1
    }
  },
  addressFinderInputError: {
    width: '100%',
    display: 'inline-block',
    border: '1px solid',
    borderColor: theme.palette.error.main,
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    height: '1.1876em',
    padding: theme.spacing(2.5, 3),
    boxSizing: 'border-box',

    '&:focus': {
      borderColor: theme.palette.error.main,
      borderWidth: '1px',
      outline: 'none'
    },

    '&::placeholder': {
      color: theme.palette.error.main,
      fontWeight: 600
    }
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '12px',
    fontWeight: 400,
    margin: theme.spacing(0.5, 0, 0, 1.75)
  },
  datePicker: {
    '& svg': {
      color: theme.palette.primary.main
    }
  }
}))
