import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
  kid: {
    background: theme.palette.background.paper,
    borderRadius: '16px',
    padding: theme.spacing(3, 3),

    '& > div': {
      width: '1.5rem',
      height: '1.5rem',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    '& > p': {
      lineHeight: '150%',
      fontSize: '1rem',
      color: '#292D32',
      textTransform: 'capitalize',

      '&:first-child': {
        fontWeight: 700
      }
    }
  },
  button: {
    textAlign: 'center',
    padding: theme.spacing(2)
  }
}))
