import { FormikContextType, useFormikContext } from 'formik'

import { RouteComponentProps } from 'react-router-dom'

import { verifyFinancialDetailsPath } from 'components/Routes'
import {
  UpdateProfileForOnboardingInput,
  useUpdateProfileForOnboardingMutation
} from 'generated/graphql'
import { FormValues } from '../'
import { formFieldData } from '../formFieldData'

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import FormField from 'components/formik/FormField'

import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import * as _ from 'lodash'
import { useMemo, useState } from 'react'

export type IdDetailsFormProps = RouteComponentProps

export default function IdDetailsForm({
  history
}: Readonly<IdDetailsFormProps>): JSX.Element {
  const { setToast } = useAuth()

  const {
    values,
    errors,
    touched,
    setFieldValue
  }: FormikContextType<FormValues> = useFormikContext()

  const [option, setOption] = useState<string>(
    values.verificationMethod ?? 'drivers_licence'
  )

  const [updateProfile, { loading: updateProfileLoading }] =
    useUpdateProfileForOnboardingMutation({
      // TODO: error handling
      onError: error =>
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        })
    })

  const disableButton = useMemo(() => {
    if (option === 'other') {
      return false
    } else if (!_.isEmpty(errors)) {
      return true
    } else if (option === 'drivers_licence') {
      return !values.driversLicenceNumber || !values.driversLicenceVersion
    } else if (option === 'passport') {
      return !values.passportExpiry || !values.passportNumber
    }

    return true
  }, [errors, option, values])

  function handleClickNext() {
    if (_.isEmpty(errors)) {
      updateProfile({ variables: { input: getFormValues() } }).then(
        response => {
          if (!response.errors) {
            history.push({
              pathname: verifyFinancialDetailsPath(),
              state: {
                verificationMethod: option
              }
            })
          } else {
            // TODO: error handling
          }
        }
      )
    }
  }

  function getFormValues(): UpdateProfileForOnboardingInput {
    const {
      driversLicenceNumber,
      driversLicenceVersion,
      passportNumber,
      passportExpiry
    } = values

    if (option === 'drivers_licence') {
      return {
        verificationMethod: option,
        verificationData: {
          driversLicenceNumber,
          driversLicenceVersion
        }
      }
    } else if (option === 'passport') {
      return {
        verificationMethod: option,
        verificationData: {
          passportNumber,
          passportExpiry
        }
      }
    } else {
      return {
        verificationMethod: 'other'
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = (event.target as HTMLInputElement).value ?? ''
    setOption(selectedOption)
    setFieldValue('verificationMethod', selectedOption)
  }

  const renderForm = () => {
    switch (option) {
      case 'drivers_licence':
        return (
          <>
            <Grid item xs={12}>
              <FormField
                {...formFieldData.driversLicenceNumber}
                error={
                  !!(
                    errors.driversLicenceNumber && touched.driversLicenceNumber
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                {...formFieldData.driversLicenceVersion}
                error={
                  !!(
                    errors.driversLicenceVersion &&
                    touched.driversLicenceVersion
                  )
                }
              />
            </Grid>
          </>
        )

      case 'passport':
        return (
          <>
            <Grid item xs={12}>
              <FormField
                {...formFieldData.passportNumber}
                error={!!(errors.passportNumber && touched.passportNumber)}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker<FormValues>
                name='passportExpiry'
                label='Passport Expiry'
              />
            </Grid>
          </>
        )

      default:
        return (
          <Grid xs={12}>
            <Grid item mt={4}>
              <Typography variant='h6'>
                Alternative identity verification process
              </Typography>
              <Typography variant='body1'>
                <p>
                  If you don't have a NZ drivers licence or NZ passport,
                  unfortunately we cannot automatically verify your identity at
                  this time, however you may email us a <i>certified</i>{' '}
                  identity document and we can manually verify you.
                </p>
                <p>
                  How do I certify a document? To certify a document, it will
                  need to have been sighted, signed, dated, and include the full
                  name of one of the following people within the last 3 months:
                </p>
                <ul>
                  <li>justice of the peace (JP)</li>
                  <li>registered medical doctor</li>
                  <li>chartered accountant.</li>
                  <li>registered teacher</li>
                  <li>minister of religion</li>
                  <li>lawyer</li>
                  <li>notary public</li>
                  <li>member of parliament</li>
                  <li>member of the police</li>
                </ul>
                The document must also include the following statement from the
                certifier: “This is a certified true copy of the original as
                sighted by me and is of true likeness to the individual.”
              </Typography>
            </Grid>
            <Grid item mt={4}>
              <Typography>
                Finally email your certified identity document to us at:
              </Typography>
              <Typography
                component='a'
                href={`mailto:support@getgoldie.co.nz?subject=${encodeURIComponent(
                  'Goldie - alternative identity document'
                )}&body=${encodeURIComponent(
                  'Kia Ora,\r\nIf you don’t have an NZ drivers licence or NZ passport, please attach a certified identity document and our team will manually verify you. We know is it’s a pain sorry, but we have to follow the rules too.\r\nWe’re still trying to improve this process to make it easier in the future.'
                )}`}
                target='_blank'
                color='primary'
                sx={{
                  textAlign: 'center',
                  textDecoration: 'underline'
                }}
              >
                support@getgoldie.co.nz
              </Typography>
            </Grid>
          </Grid>
        )
    }
  }

  return (
    <>
      <Typography variant='h6' my={2.5}>
        &emsp;So that we can verify you, please make sure your details match
        what’s on your ID.
      </Typography>

      <Grid container spacing={2} paddingX={3}>
        <FormControl defaultValue={option ?? null}>
          <RadioGroup
            defaultValue={option ?? null}
            value={option ?? null}
            onChange={handleChange}
            name='verificationMethod'
          >
            <FormControlLabel
              value='drivers_licence'
              control={<Radio />}
              label='Drivers Licence'
            />
            <FormControlLabel
              value='passport'
              control={<Radio />}
              label='New Zealand Passport'
            />
            <FormControlLabel value='other' control={<Radio />} label='Other' />
          </RadioGroup>
        </FormControl>
        {renderForm()}
      </Grid>
      <Grid mt={8} mb={6}>
        <Typography fontSize='0.75rem' mb={2}>
          By clicking “Next” you’re giving your consent for us to use third
          parties to check this information.
        </Typography>
        <Button
          color='primary'
          disabled={updateProfileLoading || disableButton}
          fullWidth
          onClick={handleClickNext}
          type='button'
        >
          Next
        </Button>
      </Grid>
    </>
  )
}
