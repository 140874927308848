import { gql } from '@apollo/client'

export const PORTFOLIO_PERFORMANCE_FOR_CURRENT_USER = gql`
  query marketOrdersForProduct($productId: Int!) {
    marketOrdersForProduct(productId: $productId) {
      id
      createdAt
      orderType
      quantity
      unit
      pricePerUnit
    }
  }
`

export const PRODUCT_PERFORMANCE_SUMMARIES = gql`
  query productPerformanceSummaries {
    productPerformanceSummaries {
      currentValue
      product {
        id
        name
        description
        sku
        tradable
      }
      totalCost
      totalReturn
      totalReturnAsPercentage
    }
  }
`
