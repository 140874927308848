import { depositFundsPath, dashboardPath } from 'components/Routes'

import { Grid, Link, Typography } from '@mui/material'
import Button from 'components/Button'
import DefaultLayout from 'components/layout/DefaultLayout'
import { NavLink, useHistory } from 'react-router-dom'

export default function VerificationSuccess(): JSX.Element {
  const history = useHistory()

  return (
    <DefaultLayout backgroundColor='white' heading='Good as gold!'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='body1'>Nice one - you're in!</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>
            You are now verified, and able to{' '}
            <Link
              color='textPrimary'
              component={NavLink}
              to={depositFundsPath()}
            >
              deposit funds
            </Link>{' '}
            into your wallet.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            color='primary'
            fullWidth
            onClick={() => history.push(dashboardPath())}
          >
            Get started
          </Button>
        </Grid>
      </Grid>
    </DefaultLayout>
  )
}
