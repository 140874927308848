import { useAuth } from 'components/AuthProvider'
import { PaymentsProvider } from 'components/PaymentsProvider'
import { dashboardPath } from 'components/Routes'
import { REVIEW_ORDER_EXPIRED, TRADING_IS_PAUSED } from 'constants/index'
import { useReviewOrderQuery } from 'generated/graphql'
import queryString from 'query-string'
import { useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import PauseTrading from '../PauseTrading/PauseTrading'
import ReviewPurchase from './ReviewPurchase'
import ReviewSale from './ReviewSale'
interface MatchParams {
  reviewOrderId: string
}

export type ReviewProps = RouteComponentProps<MatchParams>

export const FEE_PLAN_TYPES: { [key: string]: string } = {
  INSURANCE: 'Standard Insurance Fee',
  TRANSACTION: 'Standard Transaction Fee'
}

export default function Review({
  match: {
    params: { reviewOrderId }
  }
}: ReviewProps): JSX.Element {
  const { setToast } = useAuth()
  const history = useHistory()

  const [tradingPaused, setTradingPaused] = useState<boolean>(false)

  const { type } = queryString.parse(history.location.search)

  const { data: reviewOrderData, loading: reviewOrderLoading } =
    useReviewOrderQuery({
      variables: { reviewOrderId: parseInt(reviewOrderId) },
      onCompleted: () => {
        setTradingPaused(false)
      },
      onError: error => {
        if (
          error.graphQLErrors[0].extensions?.['error_status'] ===
          TRADING_IS_PAUSED
        ) {
          setTradingPaused(true)
        }
        setToast({
          open: true,
          message: error.message,
          type: 'error',
          duration: 3000
        })
        if (
          error.graphQLErrors[0].extensions?.['error_status'] ===
          REVIEW_ORDER_EXPIRED
        ) {
          history.push(dashboardPath())
        }
      }
    })

  const renderReviewType = () => {
    if (type === 'buy') {
      return (
        <ReviewPurchase
          reviewOrderData={reviewOrderData}
          reviewOrderId={reviewOrderId}
          reviewOrderLoading={reviewOrderLoading}
        />
      )
    } else {
      return (
        <ReviewSale
          reviewOrderData={reviewOrderData}
          reviewOrderId={reviewOrderId}
          reviewOrderLoading={reviewOrderLoading}
        />
      )
    }
  }

  if (tradingPaused) {
    return <PauseTrading />
  }

  return <PaymentsProvider>{renderReviewType()}</PaymentsProvider>
}
