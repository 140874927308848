import { dashboardPath } from 'components/Routes'

import { Grid, Typography } from '@mui/material'
import Button from 'components/Button'
import DefaultLayout from 'components/layout/DefaultLayout'
import { useHistory } from 'react-router-dom'

export default function VerificationOther(): JSX.Element {
  const history = useHistory()

  return (
    <DefaultLayout backgroundColor='white' heading=''>
      <Typography variant='h1' component='h1' fontSize='4rem'>
        Alternative identification document
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='body1'>
            <p>
              As you have selected ‘other’ identity verification, we cannot
              automatically verify your identity. However you can email us a
              certified identity document and we can manually verify you.
            </p>
            <p>
              How do I certify a document? To certify a document, it will need
              to have been sighted, signed, dated, and include the full name of
              one of the following people within the last 3 months:
            </p>
            <ul>
              <li>justice of the peace (JP)</li>
              <li>registered medical doctor</li>
              <li>chartered accountant.</li>
              <li>registered teacher</li>
              <li>minister of religion</li>
              <li>lawyer</li>
              <li>notary public</li>
              <li>member of parliament</li>
              <li>member of the police</li>
            </ul>
            The document must also include the following statement from the
            certifier: “This is a certified true copy of the original as sighted
            by me and is of true likeness to the individual.”
          </Typography>
        </Grid>
        <Grid item mt={4}>
          <Typography>
            Finally email your certified identity document to us at:
          </Typography>
          <Typography
            component='a'
            href={`mailto:support@getgoldie.co.nz?subject=${encodeURIComponent(
              'Goldie - alternative identity document'
            )}&body=${encodeURIComponent(
              'Kia Ora,\r\nIf you don’t have an NZ drivers licence or NZ passport, please attach a certified identity document and our team will manually verify you. We know is it’s a pain sorry, but we have to follow the rules too.\r\nWe’re still trying to improve this process to make it easier in the future.'
            )}`}
            target='_blank'
            color='primary'
            sx={{
              textAlign: 'center',
              textDecoration: 'underline'
            }}
          >
            support@getgoldie.co.nz
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            color='primary'
            fullWidth
            onClick={() => history.push(dashboardPath())}
          >
            Go to your dashboard
          </Button>
        </Grid>
      </Grid>
    </DefaultLayout>
  )
}
