import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import {
  CircularProgress,
  Divider,
  Popover,
  Stack,
  Typography
} from '@mui/material'
import * as React from 'react'

import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
// import { createKidVaultPath } from 'components/Routes'
// import LoadingWrapper from 'components/layout/LoadingWrapper'
import { Vault } from 'generated/graphql'
// import { useCallback, useEffect, useState } from 'react'
// import { useHistory } from 'react-router'
import { generateUsername } from 'utils/util'
// import useStyles from './styles'
import useVault from 'hooks/useVault/useVault'
import useStyles from './styles'

interface SwitchVaultProps {
  text?: string
  handleActive?: (id: number | null) => Promise<Vault | null>
  showCreateButton?: boolean
  loading?: boolean
  spotPrice?: string
}

export default function ButtonMyVault(props: SwitchVaultProps) {
  const { handleActive, loading = false } = props

  const classes = useStyles()
  const { user } = useAuth()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const { activatedVault, handleActiveVault } = useVault()

  const vaultList = React.useMemo(() => {
    return user?.vaults?.filter(vault => {
      if (!activatedVault.id && !vault.name) {
        return false
      }
      return vault.id !== activatedVault.id
    })
  }, [user?.vaults, activatedVault])

  const togglePopover = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (vaultList?.length) {
        setAnchorEl(prev => (prev ? null : event.currentTarget || null))
      }
    },
    [vaultList?.length]
  )

  return (
    <>
      <Button
        aria-describedby='my-vault-popover'
        variant='text'
        onClick={togglePopover}
        className={classes.buttonMyVault}
      >
        <Typography
          variant='h6'
          color='primary.dark'
          whiteSpace='nowrap'
          overflow='hidden'
          textOverflow='ellipsis'
        >
          {activatedVault.name}'s vault
        </Typography>
        {Boolean(vaultList?.length) && (
          <KeyboardArrowDown className={classes.buttonMyVaultIconDown} />
        )}
      </Button>
      <Popover
        id='my-vault-popover'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={togglePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        className={classes.popover}
      >
        <Stack padding={2} textAlign='center' position='relative' gap={2}>
          {loading ? (
            <Stack
              position='absolute'
              justifyContent='center'
              alignItems='center'
              width='100%'
              bgcolor='transparent'
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0
              }}
              zIndex={1}
            >
              <CircularProgress color='default' />
            </Stack>
          ) : null}
          {vaultList?.map((vault, index) => {
            return (
              <React.Fragment key={vault.id}>
                <Typography
                  color='primary.main'
                  onClick={() => {
                    handleActiveVault(Number(vault.id))
                      .then(() => {
                        handleActive && handleActive(Number(vault.id))
                      })
                      .finally(() => setAnchorEl(null))
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  {vault.name || generateUsername(user)}'s VAULT
                </Typography>
                {index === vaultList.length - 1 ? null : (
                  <Divider className={classes.divider} />
                )}
              </React.Fragment>
            )
          })}
        </Stack>
      </Popover>
    </>
  )
}
