import { makeStyles } from '@mui/styles'

export default makeStyles(theme => ({
  icon: {
    width: '60px'
  },
  balanceCard: {
    background: theme.palette.primary.main,
    padding: theme.spacing(2, 2.5),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '2rem',

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.5)
    },

    '& h3': {
      color: theme.palette.disabled.light
    },

    '& p': {
      color: theme.palette.white.main
    },

    '& hr': {
      margin: theme.spacing(2, 0),
      borderColor: theme.palette.divider
    }
  },
  balanceValue: {
    fontWeight: 700,
    fontSize: '20px'
  },
  walletItem: {
    background: theme.palette.background.paper,
    borderRadius: '1rem',
    padding: theme.spacing(3, 1.5),

    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',

    '& p': {
      width: '100%',
      whiteSpace: 'nowrap',
      fontWeight: 400
    },

    '& > div': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  activities: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    padding: '16px',
    borderRadius: '16px',
    background: theme.palette.background.paper
  }
}))
