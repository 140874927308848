import { convertUnderscoreFieldToCamelField } from 'utils/util'

enum DriversLicence {
  Number = 'licence_number',
  Version = 'licence_version'
}

export const getErrorFieldList = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  identityVerificationFailureReasons: any[] = []
): string[] => {
  let errorFieldList: string[] = []

  identityVerificationFailureReasons?.forEach(failureReason => {
    if (failureReason['error_fields']) {
      errorFieldList = (failureReason['error_fields'] as string[]).map(
        (field: string) => {
          if (
            field === DriversLicence.Number ||
            field === DriversLicence.Version
          ) {
            return convertUnderscoreFieldToCamelField(`drivers_${field}`)
          }
          return convertUnderscoreFieldToCamelField(field)
        }
      )
    }
  })

  return errorFieldList
}

export function getNotes<T>(notes: string): T {
  return notes.split('\n').reduce((prev, cur) => {
    let obj = {}
    if (typeof cur === 'string') {
      const [key, value] = cur.split(': ')

      const keyArray = key.toLowerCase().split(' ')

      /* pop the "match" text in note field */
      keyArray.pop()

      obj = {
        [keyArray.join('_')]: value === 'Yes'
      }
    }
    return { ...prev, ...obj }
  }, {}) as T
}
