import { useState } from 'react'

import { useAuth } from 'components/AuthProvider'

import BackupCodesModal from './BackupCodesModal'
import DisableAuthenticator from './DisableAuthenticator'
import EnableAuthenticator from './EnableAuthenticator'

export default function TwoStepAuthentication(): JSX.Element {
  const { user } = useAuth()
  const [backupCodes, setBackupCodes] = useState<string[]>([])
  const [hasSeenBackupModal, setHasSeenBackupModal] = useState(false)

  function handleCloseModal() {
    setHasSeenBackupModal(true)
  }

  const { authenticatorAppEnabled } = user || {}

  return (
    <>
      {authenticatorAppEnabled ? (
        <DisableAuthenticator />
      ) : (
        <EnableAuthenticator setBackupCodes={setBackupCodes} />
      )}
      <BackupCodesModal
        backupCodes={backupCodes}
        isOpen={backupCodes.length > 0 && !hasSeenBackupModal}
        onClose={handleCloseModal}
      />
    </>
  )
}
