import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    zIndex: 1,
    padding: theme.spacing(0),
    background: theme.palette.background.default,
    height: '100vh'
  },
  heading: {
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '6.5rem'
    }
  }
}))
