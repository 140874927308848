import { Box, Grid, Typography } from '@mui/material'
import { useAuth } from 'components/AuthProvider'
import Button from 'components/Button'
import { createKidVaultPath, updateKidVaultPath } from 'components/Routes'
import DefaultLayout from 'components/layout/DefaultLayout'
import { useHistory } from 'react-router'
import { generateUsername } from 'utils/util'
import useStyles from './styles'

export default function KidVault(): JSX.Element {
  const classes = useStyles()

  const { user } = useAuth()

  const history = useHistory()

  const vaults = user?.vaults ?? []

  const handleClickVault = (id: string) => {
    history.push(
      updateKidVaultPath({
        routeParams: { id: id }
      })
    )
  }

  const renderCreateButton = () => {
    const handleCreateNewKidVault = () => {
      history.push(createKidVaultPath())
    }

    return (
      <Box className={classes.button}>
        <Button
          color='primary'
          variant='outlined'
          fullWidth
          onClick={handleCreateNewKidVault}
        >
          New kid's vault
        </Button>
      </Box>
    )
  }

  return (
    <DefaultLayout
      wrapperContent={{ mt: 0, mb: 2 }}
      showBackIcon
      heading='Kids Vault'
    >
      <Grid container rowSpacing={2} py={4}>
        {vaults.map(vault => (
          <Grid item xs={12} key={vault.id}>
            <Box
              className={classes.kid}
              onClick={() => handleClickVault(vault.id)}
            >
              <Typography>{vault.name || generateUsername(user)}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      {renderCreateButton()}
    </DefaultLayout>
  )
}
