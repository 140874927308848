import { Box, Link, Theme } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { verifyPath } from 'components/Routes'

import { makeStyles } from '@mui/styles'
import { useEffect, useRef } from 'react'
import useOnScreen from 'hooks/useOnScreen'
import theme from 'utils/theme'

const useStyles = makeStyles((theme: Theme) => ({
  bannerContent: {
    padding: theme.spacing(1.5, 0),
    backgroundColor: theme.palette.yellow.main,
    borderRadius: 0
  },
  title: {
    display: 'block',
    textAlign: 'center',
    whiteSpace: 'nowrap'
  },
  underline: {
    textDecoration: 'underline'
  }
}))

export default function VerificationBanner(): JSX.Element {
  const classes = useStyles()

  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    document
      .querySelector("meta[name='theme-color']")
      ?.setAttribute(
        'content',
        isVisible
          ? theme.palette.background.yellow
          : theme.palette.background.default
      )
  }, [isVisible])

  return (
    <Box className={classes.bannerContent} ref={ref}>
      <Link
        className={classes.title}
        color='textPrimary'
        component={RouterLink}
        to={verifyPath()}
        underline='hover'
      >
        Verify your account and get credit{' '}
        <span className={classes.underline}>here</span>
      </Link>
    </Box>
  )
}
